import { fanModeConstants } from '_constants';

//import { history } from '_helpers';

export const fanModeActions = {
    turnFanModeOn,
    turnFanModeOff, 
};


function turnFanModeOn(callback = null) {

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: fanModeConstants.TURN_FAN_MODE_ON_REQUEST}; }
    function success(data)  { return { type: fanModeConstants.TURN_FAN_MODE_ON_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}

function turnFanModeOff(callback = null) {

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: fanModeConstants.TURN_FAN_MODE_OFF_REQUEST}; }
    function success(data)  { return { type: fanModeConstants.TURN_FAN_MODE_OFF_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}