import { cookiePreferencesConstants } from '_constants';

//import { history } from '_helpers';

export const cookiePreferencesActions = {
    cookiePreferencesSet,
    cookiePreferencesUnset
};


function cookiePreferencesSet() {
    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: cookiePreferencesConstants.COOKIE_PREFERENCES_SET_REQUEST}; }
    function success(data)  { return { type: cookiePreferencesConstants.COOKIE_PREFERENCES_SET_SUCCESS, data }; }
}


function cookiePreferencesUnset() {
    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: cookiePreferencesConstants.COOKIE_PREFERENCES_UNSET_REQUEST}; }
    function success(data)  { return { type: cookiePreferencesConstants.COOKIE_PREFERENCES_UNSET_SUCCESS, data }; }
}
