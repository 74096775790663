import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import defaultAvatar from "assets/img/default-avatar.png"
class CardAuthor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
    };
  }

  render() {
    let specialistTraits =  this.props.otherSpecialistTraits 
    let businessTraits =  this.props.otherBusinessTraits
    let parsedOtherTraits = []
    if (specialistTraits && specialistTraits.length > 1) {
      let tempArray = []
      for (let idx = 1; idx < specialistTraits.length; idx ++) {
          tempArray.push(Object.keys(specialistTraits[idx])[0])
        
      }
      parsedOtherTraits = tempArray
    } else if (businessTraits && businessTraits.length > 1) {
      let tempArray = []
      for (let idx = 1; idx < businessTraits.length; idx++) {
        console.log(idx, 'idx')
          tempArray.push(businessTraits[idx])
      }
      parsedOtherTraits = tempArray    
    }
     

    console.log (parsedOtherTraits, "parsedOtherTraits")
    console.log (specialistTraits, "specialisttraits")
    console.log (businessTraits, "businessTraits")

    return (
      <div className="author">
        {/* <a href={this.props.link ? this.props.link : "#"}> */}
          <img
            className="circular profile-image-box border-gray"
            src={this.props.avatar}
            alt={this.props.avatarAlt}
            onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
          />
          <br></br>          <br></br>
          <h5 className="title">{this.props.title}</h5>
        {/* </a> */}
        <div>
          <h6 className="inline-block text-primary">{this.props.description}</h6>
          {this.state.user && this.state.user.is_business && this.props.rating > 0.5 &&
          <h6 className="text-warning inline-block"> &nbsp; {this.props.rating} 
          </h6>}
        </div>
        {parsedOtherTraits && parsedOtherTraits.length > 0 &&
        <div style={{marginTop:"3px"}}>
          {  parsedOtherTraits.map( (otherTrait, key) =>
            <div className="inline-block" key={key}>
              <h6 className="text-darkerGray text-center">
              { key !== 0 &&
              <span>
                &nbsp;&nbsp;&nbsp;
              </span>
              }
              {otherTrait}
              {(key !== parsedOtherTraits.length - 1) &&
              <span>
                &nbsp;&nbsp;&nbsp;|
              </span>
              }
              </h6>
            </div>
          )}
        </div> 
        }
      </div>      
    );
  }
}

CardAuthor.propTypes = {
  // Where the user to be redirected on clicking the avatar
  link: PropTypes.string,
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default CardAuthor;
