import React, { Component } from 'react';
import { socialMediaActions } from '_actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import  {FaTwitter} from 'react-icons/fa'
import {Button} from "components";
class TwitterLogin extends Component {


    constructor(props) {
      super(props);
  
      this.onButtonClick = this.onButtonClick.bind(this);
    }

    twitterGetRequestToken() {
      const redirectUri = this.props.redirectUri
      this.props.dispatch(socialMediaActions.getTwitterRequestToken(redirectUri, (success, data) => {
        console.log("Twitter Request Token", success, data)
        if (success) {
          this.getRequestToken(data.token)
        } 
      }))
    }
  
    onButtonClick(e) {
      e.preventDefault();
      return this.twitterGetRequestToken();
    }
  
    getRequestToken(oauthToken) {
      window.location = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}&force_login=${this.props.forceLogin}&screen_name=${this.props.screenName}`;
      console.log("popup", window)
    }
  
    getDefaultButtonContent() {
      const defaultIcon = this.props.showIcon? <FaTwitter color='#ffffff' size={25}/> : null;
  
      return (
        <span>
          {defaultIcon}&nbsp;&nbsp;&nbsp;&nbsp;{this.props.text}
        </span>
      );
    }
  
    render() {
      
      return (
        <Button
          onClick={this.onButtonClick}
          disabled={this.props.disabled}
          className="btn-twitter"
          > {this.props.children ? this.props.children : this.getDefaultButtonContent()}
        </Button>
        );
      // const twitterButton = React.createElement(
      //   'Button', {
      //     onClick: this.onButtonClick,
      //     style: this.props.style,
      //     disabled: this.props.disabled,
      //     className: this.props.className,
      //   }, this.props.children ? this.props.children : this.getDefaultButtonContent()
      // );
      // return twitterButton;
    }
  }
  
  TwitterLogin.propTypes = {
    tag: PropTypes.string,
    text: PropTypes.string,
    loginUrl: PropTypes.string,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    dialogWidth: PropTypes.number,
    dialogHeight: PropTypes.number,
    showIcon: PropTypes.bool,
    credentials: PropTypes.oneOf(['omit', 'same-origin', 'include']),
    customHeaders: PropTypes.object,
    forceLogin: PropTypes.bool,
    screenName: PropTypes.string,
  };
  
  TwitterLogin.defaultProps = {
    tag: 'button',
    text: 'Connect Twitter',
    disabled: false,
    dialogWidth: 600,
    dialogHeight: 400,
    showIcon: true,
    credentials: 'same-origin',
    customHeaders: {},
    forceLogin: false
  };
  
  function mapStateToProps(state) {
    return {
      
    };
  }
  
  const connectedTwitterLogin = connect(mapStateToProps)(TwitterLogin);
  export { connectedTwitterLogin as TwitterLogin };
  