export const stripeConstants = {
    GET_CONNECT_URL_REQUEST: 'GET_CONNECT_URL_REQUEST',
    GET_CONNECT_URL_SUCCESS: 'GET_CONNECT_URL_SUCCESS',
    GET_CONNECT_URL_FAILURE: 'GET_CONNECT_URL_FAILURE',

    GET_CONNECT_ID_REQUEST: 'GET_CONNECT_ID_REQUEST',
    GET_CONNECT_ID_SUCCESS: 'GET_CONNECT_ID_SUCCESS',
    GET_CONNECT_ID_FAILURE: 'GET_CONNECT_ID_FAILURE',

    GET_CUSTOMER_ID_REQUEST: 'GET_CUSTOMER_ID_REQUEST',
    GET_CUSTOMER_ID_SUCCESS: 'GET_CUSTOMER_ID_SUCCESS',
    GET_CUSTOMER_ID_FAILURE: 'GET_CUSTOMER_ID_FAILURE',

    RETRIEVE_SOURCES_REQUEST: 'RETRIEVE_SOURCES_REQUEST',
    RETRIEVE_SOURCES_SUCCESS: 'RETRIEVE_SOURCES_SUCCESS',
    RETRIEVE_SOURCES_FAILURE: 'RETRIEVE_SOURCES_FAILURE',

    REGISTER_PAYOUT_ACCOUNT_REQUEST: 'REGISTER_PAYOUT_ACCOUNT_REQUEST',
    REGISTER_PAYOUT_ACCOUNT_SUCCESS: 'REGISTER_PAYOUT_ACCOUNT_SUCCESS',
    REGISTER_PAYOUT_ACCOUNT_FAILURE: 'REGISTER_PAYOUT_ACCOUNT_FAILURE',

    SOURCE_ACTION_REQUEST: 'SOURCE_ACTION_REQUEST',
    SOURCE_ACTION_SUCCESS: 'SOURCE_ACTION_SUCCESS',
    SOURCE_ACTION_FAILURE: 'SOURCE_ACTION_FAILURE',

    CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
    CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
    CREATE_CUSTOMER_FAILURE: 'CREATE_CUSTOMER_FAILURE',

}
