import React from 'react';
import { connect } from 'react-redux';
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { cookiePreferencesActions } from '_actions';

class CookieManager extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
      this.acceptCookies = this.acceptCookies.bind(this);
      this.rejectCookies = this.rejectCookies.bind(this);
    }

  
    componentDidMount() {
      
    }

    acceptCookies() {   
        ReactPixel.grantConsent(); 
        ReactGA.gtag('consent', 'update', { 'ad_storage' : 'granted' });
        ReactGA.gtag('consent', 'update', { 'analytics_storage' : 'granted' });
        this.props.dispatch(cookiePreferencesActions.cookiePreferencesSet())
    }
  
    rejectCookies() {
        ReactPixel.revokeConsent();
        ReactGA.gtag('consent', 'update', { 'ad_storage' : 'denied' });
        ReactGA.gtag('consent', 'update', { 'analytics_storage' : 'denied' });
        this.props.dispatch(cookiePreferencesActions.cookiePreferencesSet())
    }
  
    render() {
      const { alert, cookiePreferencesUnset, 
        // getCurrentLocation, currentLocation, 
      } = this.props
      const userAllowedCookies = getCookieConsentValue() 
      {console.log("cookieValue", userAllowedCookies)}      

      const cookies = cookiePreferencesUnset
      return (
        <>
            <CookieConsent 
             enableDeclineButton
             location="bottom"
             buttonText="Accept all"
             declineButtonText="Decline"
             buttonClasses="btn-primary mt-0 btn btn-primary btn-sm mb-3"
             declineButtonClasses="btn-outline-primary mt-0 btn btn-primary btn-sm mb-3"
             buttonWrapperClasses="mx-3"
             visible={cookiePreferencesUnset ? "show" : "byCookieValue"}
             disableButtonStyles={true}
             declineButtonStyle={{}}
             buttonStyle={{}}
             containerClasses="bg-white col-lg-12 col-md-12 col-sm-12 col-xs-12 card-box-shadow"
             style={{
                alignItems: "baseline",
                    background: "#353535",
                    color: "white",
                    display: "block",
                    justifyContent: "space-between",
                    left: "0",
                    position: "fixed",
                    width: "100%",
                    zIndex: "9999",

             }}
             contentClasses="text-default mx-3"
             onAccept={this.acceptCookies}
             onDecline={this.rejectCookies}
            >
            <h3>We value your privacy.</h3>
            <p style={{fontSize:"0.92rem"}}>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. By selecting "Decline", you will be opted out of advertisement and analytics cookies. </p>
            </CookieConsent>
        
        </>
      );
    }
  }
  
  function mapStateToProps(state) {
    const {  alert, authentication, locationData, cookiePreferencesData} = state;
    const { loggedIn } = authentication
    const { alertType, alertMessage } = alert
    const { getCurrentLocation, currentLocation } = locationData;
    const { cookiePreferencesSet, cookiePreferencesUnset } = cookiePreferencesData 
  
    return {
      alert, alertType, alertMessage, loggedIn,
      getCurrentLocation, currentLocation,
      cookiePreferencesSet, cookiePreferencesUnset
    };
  }
  
  const connectedCookieManager = connect(mapStateToProps)(CookieManager);
  export default connectedCookieManager