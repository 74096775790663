import { currencyValue, currencyCode } from '_helpers';

export function parseProductPrice(userCountryCode, priceRange, maximumFractionDigits=2) {
  console.log("Parse Price", userCountryCode, priceRange)
  const userCurrency = currencyCode(userCountryCode)
  if (!priceRange || Object.keys(priceRange).length < 1) {
    return false;
  } else {
    if (priceRange[userCurrency] ) {
      const defaultPriceRange = priceRange[userCurrency]
      const price = defaultPriceRange[0]
      return currencyValue(userCurrency, price, maximumFractionDigits)
      
    } else {
      const defaultCurrency = Object.keys(priceRange)[0]
      const defaultPriceRange = priceRange[defaultCurrency]
      const price = defaultPriceRange[0]
      return currencyValue(defaultCurrency, price, maximumFractionDigits)
    }
  }
}

export function getSubscriptionStateColor(state) {
  // case Active = "Active"
  // case Pending = "Pending"
  // case Dispute = "Dispute"
  // case Unpaid = "Unpaid"
  // case Canceled = "Canceled"
  // case Trialing = "Trialing"
  // case PastDue = "PastDue"
  // case Expired = "Expired"
  // case Ending = "Ending"
  // case Other = "Other"
  
  switch (state) {
    case 'Active':
      return 'text-success' 
    case 'Pending':
      return 'text-white'
    case 'Dispute':
      return 'text-danger'
    case 'Unpaid':
      return 'text-danger'
    case 'Canceled':
      return 'text-white'
    case 'Trialing':
      return 'text-info'
    case 'PastDue':
      return 'text-danger' 
    case 'Expired':
      return 'text-danger' 
    case 'Ending':
      return 'text-warning' 
    default:
      return 'text-white'
  }
}
