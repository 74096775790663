export const ticketConstants = {
    GET_TICKET_REQUEST: 'GET_TICKET_REQUEST',
    GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
    GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',

    GET_TICKETS_REQUEST: 'GET_TICKETS_REQUEST',
    GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
    GET_TICKETS_FAILURE: 'GET_TICKETS_FAILURE',

    CREATE_UPDATE_TICKET_REQUEST: 'CREATE_UPDATE_TICKET_REQUEST',
    CREATE_UPDATE_TICKET_SUCCESS: 'CREATE_UPDATE_TICKET_SUCCESS',
    CREATE_UPDATE_TICKET_FAILURE: 'CREATE_UPDATE_TICKET_FAILURE',

    PURCHASE_TICKET_REQUEST: 'PURCHASE_TICKET_REQUEST',
    PURCHASE_TICKET_SUCCESS: 'PURCHASE_TICKET_SUCCESS',
    PURCHASE_TICKET_FAILURE: 'PURCHASE_TICKET_FAILURE',

    GET_PURCHASED_TICKET_REQUEST: 'GET_PURCHASED_TICKET_REQUEST',
    GET_PURCHASED_TICKET_SUCCESS: 'GET_PURCHASED_TICKET_SUCCESS',
    GET_PURCHASED_TICKET_FAILURE: 'GET_PURCHASED_TICKET_FAILURE',

    GET_PURCHASED_TICKET_RECEIPT_REQUEST: 'GET_PURCHASED_TICKET_RECEIPT_REQUEST',
    GET_PURCHASED_TICKET_RECEIPT_SUCCESS: 'GET_PURCHASED_TICKET_RECEIPT_SUCCESS',
    GET_PURCHASED_TICKET_RECEIPT_FAILURE: 'GET_PURCHASED_TICKET_RECEIPT_FAILURE',

    GET_PRIVATE_TICKETS_CODES_REQUEST: 'GET_PRIVATE_TICKETS_CODES_REQUEST',
    GET_PRIVATE_TICKETS_CODES_SUCCESS: 'GET_PRIVATE_TICKETS_CODES_SUCCESS',
    GET_PRIVATE_TICKETS_CODES_FAILURE: 'GET_PRIVATE_TICKETS_CODES_FAILURE',

    REFUND_TICKETS_REQUEST: 'REFUND_TICKETS_REQUEST',
    REFUND_TICKETS_SUCCESS: 'REFUND_TICKETS_SUCCESS',
    REFUND_TICKETS_FAILURE: 'REFUND_TICKETS_FAILURE',

}
