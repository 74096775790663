export const traitConstants = {
    SPECIALIST_TRAITS_QUERY_REQUEST: 'SPECIALIST_TRAITS_QUERY_REQUEST',
    SPECIALIST_TRAITS_QUERY_SUCCESS: 'SPECIALIST_TRAITS_QUERY_SUCCESS',
    SPECIALIST_TRAITS_QUERY_FAILURE: 'SPECIALIST_TRAITS_QUERY_FAILURE',

    BUSINESS_TRAITS_QUERY_REQUEST: 'BUSINESS_TRAITS_QUERY_REQUEST',
    BUSINESS_TRAITS_QUERY_SUCCESS: 'BUSINESS_TRAITS_QUERY_SUCCESS',
    BUSINESS_TRAITS_QUERY_FAILURE: 'BUSINESS_TRAITS_QUERY_FAILURE',

    USER_TRAITS_QUERY_REQUEST: 'USER_TRAITS_QUERY_REQUEST',
    USER_TRAITS_QUERY_SUCCESS: 'USER_TRAITS_QUERY_SUCCESS',
    USER_TRAITS_QUERY_FAILURE: 'USER_TRAITS_QUERY_FAILURE',
};