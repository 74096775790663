import { config } from '_constants';
import { authHeader } from '_helpers';
import axios from 'axios';

export const userService = {
    login,
    logout,
    register,
    userRequest,
    getTimeZone,
    userDownloadRequest,
    userViewAssetInBufferRequest,
    appleAuthorize,
    // userQuery,
    // getAll,
    // getById,
    // update,
    //delete: _delete
};

axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
       console.log("In axios interceptor")
        //place your reentry code
        console.log("handleError", error, error.response, error.response.status)
        console.log("Session Timed Out")
        const fatal = error.response
        return Promise.reject(fatal)
   }
   console.log("interceptor: other errors")
   return error;
 });

// const restrictedServerRequests = [
//     'create_asset',
//     'purchase_asset',
//     'report_new_case',
//     'create_collection',
//     'business_create_update_event',
//     'business_create_update_sub_event',
//     'bid_book_candidate',
//     'location_create_update',
//     'create_plan',
//     'update_plan',
//     'create_post',
//     'create_comment',
//     'create_product',
//     'specialist_schedule',
//     'specialist_template',
//     'registerPayoutAccount',
//     'stripe_source_action',
//     'create_subscription',
//     'business_ticket_create_update',
//     'create_tier',
// ]

function login(username, password) {
    // const requestOptions = {
    //     method: 'POST',
    //     mode: 'no-cors',
    //     cache: "no-cache",
    //     withcredentials: "same-origin",
    //     //withCredentials: false,
    //     headers: { 
    //         //"Content-Type": "application/json; charset=utf-8",
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //     },     
    //     body: JSON.stringify({ 
    //         username: username, 
    //         password: password,
    //         request: 'login', 
    //     })
    // };

    //return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
    //console.log(requestOptions)
    console.log(config.apiUrl)
    //return fetch(`${config.apiUrl}`, requestOptions)
    //return axios.post(`${config.apiUrl}`, 'key1=value1&key2=value2',{withCredentials: false})
    return axios.post(`${config.apiUrl}`, {
        username: username, 
        password: password,
        attempts: 1,  // TODO: FIX ME
        request: 'login', 
    })
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.session) {
                console.log(user.session)
            } else {
                console.log("no user.session")
                console.log(user)
            }
            return user;
        });
}

function register(params) {
    return axios.post(`${config.apiUrl}`, params, { headers: authHeader()})
    .then(handleResponse)
    .then(user => {
        // login successful if there's a jwt token in the response
        if (user.session) {
            console.log(user.session)
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user['is_user'] = !(user.is_business || user.is_specialist || user.is_agency)
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            console.log("no user.session")
            console.log(user)
        }
        return user;
    })
}

function logout() {
    // remove user from local storage to log user out
    console.log("Logging out")
    
    return axios.post(`${config.apiUrl}`, { 'request': 'logout', }, 
        { headers: authHeader()} )
        .then(handleResponse)
        .then( (data) => {
            console.log("logout response", data)
            if (data.success) {
                console.log("user.service.logout(): success")
            }
            return data;
        })
}

function userRequest(params) {

    // const request = params.request 
    // const restrictedRequest = localStorage.getItem('restrictedRequest') 
    // console.log("params", params, request)

    // if (restrictedRequest && (restrictedRequest === request) ) {
    //     return 
    // }
    // if (request && restrictedServerRequests.includes(request)) {
    //     localStorage.setItem('restrictedRequest', request)
    // }

    return axios.post(`${config.apiUrl}`, params, { headers: authHeader()})
    .then(handleResponse)
}

function userDownloadRequest(params) {
    let url = `${config.apiUrl}`
    url +=  '?request=' + params.request
    url +=  '&id=' + params.asset_id
    console.log("downloadRequest url", url)

    return axios({
	url: url, //your url
	method: 'GET',
	responseType: 'blob', // important
    }).then((response) => {
	console.log('response is ')
	console.log(response)
        if (response['status'] === 200) {
	    //console.log("filename", response['headers']['content-disposition'])
	    let disposition = response['headers']['content-disposition']
	    let startIndex = disposition.indexOf("filename=") + 9; // Adjust '+ 10' if filename is not the right one.
	    let endIndex = disposition.length; //Check if '- 1' is necessary
	    let filename = disposition.substring(startIndex, endIndex);
	    console.log("filename: " + filename)
	    const url = window.URL.createObjectURL(new Blob([response.data]));
	    const link = document.createElement('a');
	    link.href = url;
	    link.setAttribute('download', filename); //or any other extension
	    document.body.appendChild(link);
	    link.click();
	    return Promise.resolve({ 
		'success':  true,
		'message': 'download succeeded',
		'filename': filename})
	} else if (response['status'] === 204) {
	    //console.log("no response from header code 204")
	    return Promise.resolve({ 
		'success':  false,
		'message': 'download failed'
	    })
	} else {
            console.log("server return code > 300");
            return Promise.reject(response);
	}
    });
}



function userViewAssetInBufferRequest(params) {
    let url = `${config.apiUrl}`
    url +=  '?request=' + params.request
    url +=  '&id=' + params.asset_id
    console.log("downloadRequest url", url)

    return axios({
	url: url, //your url
	method: 'GET',
	responseType: 'blob', // important
    }).then((response) => {
	console.log('response is ')
	console.log(response)
        if (response['status'] === 200) {
	    //console.log("filename", response['headers']['content-disposition'])
	    let disposition = response['headers']['content-disposition']
	    let startIndex = disposition.indexOf("filename=") + 9; // Adjust '+ 10' if filename is not the right one.
	    let endIndex = disposition.length; //Check if '- 1' is necessary
	    let filename = disposition.substring(startIndex, endIndex);
	    console.log("filename: " + filename)
	    const url = window.URL.createObjectURL(new Blob([response.data]));
	    // const link = document.createElement('a');
	    // link.href = url;
	    // link.setAttribute('download', filename); //or any other extension
        console.log("rsp", url )

	    // document.body.appendChild(url);
        const rsp = { 
            success:  true,
            message: 'download succeeded',
            url: url}

            return Promise.resolve(rsp);
        } else if (response['status'] === 204) {
	    //console.log("no response from header code 204")
        const rsp = { 
            success:  false,
            message: 'download failed'
	    }
        handleResponse(rsp)
	} else {
            console.log("server return code > 300");
            return Promise.reject(response);
	}
    });
}


function getTimeZone(params) {   
    let url  = 'https://maps.googleapis.com/maps/api/timezone/json?'
    url += 'location=' + params.lat + ',' + params.lng
    url += '&timestamp=' + new Date().getTime() / 1000
    url += '&key=' + config.GOOGLE_API_KEY
    console.log("getTimeZone url", url)
    //return axios.get('https://maps.googleapis.com/maps/api/timezone/json',
        // {
        //     location: params.lat + ',' + params.lng,
        //     timestamp: new Date().getTime() / 1000,
        //     key: config.GOOGLE_API_KEY
        // })
    return axios.get(url)
      .then( response => {
        console.log("getTimeZone", response, response['data']);
        if (response['status'] === 200 && response['data']['status'] === 'OK') {
            console.log("timeZone", response['data']['timeZoneId'])
            return response['data']['timeZoneId']
        } else {
            return ''
        }
      })
      .catch( error => {
        console.log("getTimeZone error", error)
      });
}

function appleAuthorize(params) {
    let url = 'https://appleid.apple.com/auth/authorize?'
    url += 'response_type=code'
    url += '&response_mode=form_post'
    url += '&client_id=com.livenyte.livenyte'
    url += '&redirect_uri=' + params.redirect_uri
    // url += '&state=' + params.state
    url += '&scope=name%20email'
    url += '&use_popup=true'

    return url
    // console.log("Apple Url", url)
    // return axios.get(url, { crossdomain: true })
    //   .then( response => {
    //       console.log("appleAuthorize", response)
    //       return response
    //   })
    //   .catch( error => {
    //     console.log("appleAuthorize error", error)
    //   });
}

// function userRequestError(error) {
//     console.log("userRequestError", error.response)
// }

// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }

// function getById(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }



// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }


function handleResponse(response) {
    console.log("handleResponse", response)
    const data = response.data.data;
    console.log(data)
    // const restrictedRequest = localStorage.getItem('restrictedRequest') 


    if (response.status >= 200 && response.status <= 300) {
        console.log("In handleResponse", response);
        // if (data.success === false) {
        //     //const error = data && data.hasOwnProperty('message') ? data.message : response.statusText;
        //     //return Promise.reject(error);
        //     return Promise.reject(response)
        // }
        return Promise.resolve(data);
    } else {
        console.log("server return code > 300");
        // if (response.status === 401) {
        //     logout();
        //     window.location.reload(true);
        // }
        return Promise.reject(response);
    }
}
