import React from "react";
import { connect } from 'react-redux';
import { eventActions } from '_actions';
import {
  // CardFooter,
  // CardTitle,
  Row,
  Col,
  Container,

//  UncontrolledTooltip,

} from "reactstrap";

import {
  Button,
  EventForUserCard
} from "components";

import * as moment from 'moment';

import { getGeoLocation, history  } from '_helpers';

class FeaturedEventsSection extends React.Component {
    constructor(props) {
      super(props);
      const user = JSON.parse(localStorage.getItem('user'));
      this.state = {
        latitude:     props.hasOwnProperty("location") && props.location.state ? props.location.state.latitude : '',
        longitude:    props.hasOwnProperty("location") && props.location.state ? props.location.state.longitude : '',
        address:      props.hasOwnProperty("location") && props.location.state ? props.location.state.address : user != null ? user.address : '',
        subAdminArea: props.hasOwnProperty("location") && props.location.state ? props.location.state.subAdminArea : '',
      };

      if (this.state.address !== '') {
        let geocoder = new window.google.maps.Geocoder();
        getGeoLocation(geocoder, this.state.address, geoLocation => {
                console.log(geoLocation)
                if (geoLocation.lat && geoLocation.lng) {
                    this.setState({ 
                        latitude: geoLocation.lat,
                        longitude: geoLocation.lng,
                        subAdminArea: geoLocation.subAdminArea,
                        submitted: false });
                    }
            })
      }

      this.goToNearbyEvents = this.goToNearbyEvents.bind(this)

    }

    goToNearbyEvents() {
        history.push('/dash/user-dashboard')
    }

    componentWillMount() {
        console.log("this.props.info", this.props.info)
        console.log("this.props.curentLocation", this.props.currentLocation)
    
        if (this.props.info && this.props.info.location) {
          this.setState({
            address: this.props.info.location 
          });
          console.log("Test A")
          // let geocoder = new window.google.maps.Geocoder();
          // getGeoLocation(geocoder, this.state.address, geoLocation => {
          //         console.log("cwp", this.state.address )
          //         if (geoLocation.lat && geoLocation.lng) {
          //             this.setState({ 
          //                 latitude: geoLocation.lat,
          //                 longitude: geoLocation.lng,
          //                 subAdminArea: geoLocation.subAdminArea,
          //                 submitted: false });
          //             }
          //             console.log("latitude was set", this.state.latitude)
          //     })
          console.log("failing here?", this.props.currentLocation)
          this.props.dispatch(eventActions.getEvents({pos: this.props.currentLocation}));        
        } else {
            console.log("Test B")
          if (this.state.address === '' && this.props.currentLocation) {
            this.setState({
              latitude: this.props.currentLocation.lat,
              longitude: this.props.currentLocation.lng,
              subAdminArea: this.props.currentLocation.subAdminArea ? this.props.currentLocation.subAdminArea : 'near you',
            })
          }
          this.props.dispatch(eventActions.getEvents({}));        
        }      
    }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }

    render() {
      const { events, fetchingEvents} = this.props;
      // const user = JSON.parse(localStorage.getItem('user'));
      const now = moment()
      if (fetchingEvents) {
        return(
          <div/>    
        )
      }
      const _events =  !fetchingEvents && events && events.filter(event => (!event.hasOwnProperty("bid_id") &&  moment(event.ev_end_time).isAfter(now) )  ) 
      let slicedEvents = _events && _events.slice(0,3) 

    // let filterStatus = ""
      return (
          <>
          {slicedEvents && slicedEvents.length > 0 && 
        <section className="py-7 section-nucleo-icons bg-secondary overflow-hidden">
            <Container>
            <Row className="justify-content-center">
                <span className="display-3 text-center surtitle text-default  mb-0 livenyte-title special-underline-livenyte mb-2">Featured Events</span>
            </Row>
          
                <Row className="mt-1">
                {slicedEvents && slicedEvents.map( (event, key) => 
                    <Col md={6} lg={4}  xs={12} key={key} className="pt-4">
                    <EventForUserCard selectedEvent={event} hideBottomBar={true}/>
                    </Col>
                )}
                </Row>
                <Row>
                <Col xs={12} lg={12} className="ml-auto mr-auto" style={{marginTop:"15px",}}>
                    <div className={this.props.is_xs || this.props.is_xxs || this.props.is_sm ? 'text-center' : 'text-right' }> 
                    <a href="#pablo" onClick={() => this.goToNearbyEvents()}
                    
                    // to={{pathname: `/dash/upcoming-events`, 
                    //             state: {address: fltAddress, latitude: latitude, longitude: longitude, subAdminArea: this.state.subAdminArea } }}
                                >
                        {!this.props.is_xs && !this.props.is_sm && !this.props.is_xxs && 
                        <h5 className="surtitle-default text-default">See more&nbsp; {">"}

                        </h5>
                        }
                        { (this.props.is_xs || this.props.is_sm || this.props.is_xxs) &&
                        <Button className="landing-body mb-4 pb-2" outline color="default" style={{width:'90%'}} >&nbsp;&nbsp;&nbsp;&nbsp;See More&nbsp;&nbsp;&nbsp;&nbsp;</Button>

                        }
                        </a>
                    </div>
            </Col>
    
            </Row>
        </Container>
        </section>
            }
       
        </>
      );
    }
  }
  
  //export {BusinessDashboard} ;
  function mapStateToProps(state) {
    const { memberInfo, eventsData, screenSizeData, locationData } = state;
    const { events, fetchingEvents } = eventsData;
    const { info } = memberInfo;
    const { getCurrentLocation, currentLocation } = locationData;
    const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;

    console.log("Following: mapStateToProps");
    return {
      info,   
      events, fetchingEvents, 
      getCurrentLocation, currentLocation,
      is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest,

      
    };
  }
  
  const connectedFeaturedEventsSection = connect(mapStateToProps)(FeaturedEventsSection);
  export { connectedFeaturedEventsSection as FeaturedEventsSection };
  
  