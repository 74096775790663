import React from 'react';
import styled from 'styled-components';

const strokePath =
  // 'M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z';
'M31.881 12.557a2.303 2.303 0 0 0-1.844-1.511l-8.326-1.238-3.619-7.514A2.318 2.318 0 0 0 16 1c-.896 0-1.711.505-2.092 1.294l-3.619 7.514-8.327 1.238A2.3 2.3 0 0 0 .12 12.557a2.207 2.207 0 0 0 .537 2.285l6.102 6.092-1.415 8.451a2.224 2.224 0 0 0 .948 2.203 2.351 2.351 0 0 0 2.449.131L16 27.811l7.26 3.908a2.367 2.367 0 0 0 2.449-.131 2.225 2.225 0 0 0 .947-2.203l-1.416-8.451 6.104-6.092c.603-.603.81-1.485.537-2.285zm-8.293 6.806a2.216 2.216 0 0 0-.627 1.934l1.416 8.451-7.26-3.906a2.361 2.361 0 0 0-2.235 0l-7.26 3.906 1.416-8.451a2.212 2.212 0 0 0-.626-1.934L2.31 13.271l8.326-1.24a2.306 2.306 0 0 0 1.743-1.268L16 3.251l3.62 7.513a2.31 2.31 0 0 0 1.742 1.268l8.328 1.24-6.102 6.091z'

const fillPath =
  // 'M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12z';
  'M29.895 12.52a1.943 1.943 0 0 0-1.549-1.319l-7.309-1.095-3.29-6.984C17.42 2.43 16.751 2 16 2s-1.42.43-1.747 1.122l-3.242 6.959-7.357 1.12c-.72.11-1.313.615-1.549 1.319a2.004 2.004 0 0 0 .465 2.046l5.321 5.446-1.257 7.676a1.984 1.984 0 0 0 .811 1.959 1.89 1.89 0 0 0 2.02.114l6.489-3.624 6.581 3.624a1.893 1.893 0 0 0 2.02-.114 1.982 1.982 0 0 0 .811-1.959l-1.259-7.686 5.323-5.436a2.004 2.004 0 0 0 .465-2.046z'

const Star = ({ width, isToggled }) => (
  <Svg
    viewBox="0 0 32 32"
    style={{
      width,
    }}
  >
    <path
      d={isToggled ? fillPath : strokePath}
      fill={isToggled ? 'rgb(218, 165, 32)' : 'rgb(35, 35, 35, 35)'}
    />
  </Svg>
);

const Svg = styled.svg`
  display: block;
  backface-visibility: hidden;
  fill: none;
`;

export default Star;
