import { productConstants } from '_constants';


function mergeProducts(products, newProducts) {
    if (products && products.length > 0) {
        console.log("mergeProducts", products, newProducts)
        if (newProducts && newProducts.length > 0) {
            let mergedProducts = products.reduce ( (acc, product) => {
                if (newProducts.filter( newProduct => newProduct.id === product.id).length === 0) {
                    acc.push(product)
                }
                return acc;
            }, [...newProducts])
            return mergedProducts
        } else {
            return products;
        }
    } else {
        return newProducts
    }
}

export function productData(state = {}, action) {
    switch(action.type) {
        case productConstants.GET_PRODUCT_TYPES_REQUEST:
            return {
                ...state,
                getProductTypes: true,
            };
        case productConstants.GET_PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                productTypes: action.productTypes,
                getProductTypes: false,
            };
        case productConstants.GET_PRODUCT_TYPES_FAILURE:
            return {
                ...state,
                getProductTypes: false,
            };        
        case productConstants.GET_PRODUCTS_REQUEST:
            return {
                ...state,
                getProducts: true,
            };
        case productConstants.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: mergeProducts(state.products, action.products),
                getProducts: false,
            };
        case productConstants.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                getProducts: false,
            };        
        case productConstants.CREATE_PRODUCT_REQUEST:
            return {
                ...state,
                createProduct: true,
            };
        case productConstants.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                createProduct: false,
            };
        case productConstants.CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                createProduct: false,
            };         
        case productConstants.UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProduct: true,
            };
        case productConstants.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                updateProduct: false,
            };
        case productConstants.UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                updateProduct: false,
            };  
        case productConstants.DISABLE_PRODUCT_REQUEST:
            return {
                ...state,
                disableProduct: true,
            };
        case productConstants.DISABLE_PRODUCT_SUCCESS:
            return {
                ...state,
                disableProduct: false,
            };
        case productConstants.DISABLE_PRODUCT_FAILURE:
            return {
                ...state,
                disableProduct: false,
            };   
        case productConstants.ENABLE_PRODUCT_REQUEST:
            return {
                ...state,
                enableProduct: true,
            };
        case productConstants.ENABLE_PRODUCT_SUCCESS:
            return {
                ...state,
                enableProduct: false,
            };
        case productConstants.ENABLE_PRODUCT_FAILURE:
            return {
                ...state,
                enableProduct: false,
            };   
        case productConstants.GET_FEATURED_PRODUCTS_REQUEST:
            return {
                ...state,
                getFeaturedProducts: true,
            };
        case productConstants.GET_FEATURED_PRODUCTS_SUCCESS:
            console.log("subscriber count", action.data)
            return {
                ...state,
                featuredProducts: action.data.products,
                bySubscriberCount: action.data.by_subscriber_count,
                byAssetCount: action.data.by_asset_count,
                byAssetCreationDate: action.data.by_asset_creation_date,
                getFeaturedProducts: false,
            };
        case productConstants.GET_FEATURED_PRODUCTS_FAILURE:
            return {
                ...state,
                getFeaturedProducts: false,
            };

        case productConstants.GET_REMITS_FOR_PRODUCTS_REQUEST:
            return {
                ...state,
                getProductRemits: true,
	        };

        case productConstants.GET_REMITS_FOR_PRODUCTS_SUCCESS:
            return {
            ...state,
                getProductRemits: false,
                productRemits: action.remits,
            };
        case productConstants.GET_REMITS_FOR_PRODUCTS_FAILURE:
            return {
                ...state,
                getProductRemits: false,
            };

        case productConstants.PURCHASE_PRODUCT_REQUEST:
            return {
                ...state,
                purchaseProduct: true,
            };
        case productConstants.PURCHASE_PRODUCT_SUCCESS:
            return {
                ...state,
                purchaseProduct: false,
            };
        case productConstants.PURCHASE_PRODUCT_FAILURE:
            return {
                ...state,
                purchaseProduct: false,
            };                                   
        default:
            return state;
        }

}
