import { subscriptionConstants } from '_constants';

export function subscriptionData(state = {}, action) {
    switch(action.type) {
        case subscriptionConstants.GET_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                getSubscriptions: true,
            };
        case subscriptionConstants.GET_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                subscriptions: action.subscriptions,
                getSubscriptions: false,
            };
        case subscriptionConstants.GET_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                getSubscriptions: false,
            };        
        case subscriptionConstants.CREATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                createSubscription: true,
            };
        case subscriptionConstants.CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                createSubscription: false,
            };
        case subscriptionConstants.CREATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                createSubscription: false,
            };         
        case subscriptionConstants.UPDATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                updateSubscription: true,
            };
        case subscriptionConstants.UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                updateSubscription: false,
            };
        case subscriptionConstants.UPDATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                updateSubscription: false,
            };  
        case subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                cancelSubscription: true,
            };
        case subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                cancelSubscription: false,
            };
        case subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                cancelSubscription: false,
            };  
        case subscriptionConstants.REACTIVATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                reactivateRubscription: true,
            };
        case subscriptionConstants.REACTIVATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                reactivateRubscription: false,
            };
        case subscriptionConstants.REACTIVATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                reactivateRubscription: false,
            };                                            
        default:
            return state;
        }

}
