
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

export function getGeoLocation(geocoder, address, handler) {
    // let geocoder = new window.google.maps.Geocoder();
    // let geocoder = new GoogleMap.Geocoder();
    geocoder.geocode( { 'address': address}, function(results, status) {
        if (status === 'OK') {
            console.log(results);
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            console.log(lat);
            console.log(lng);
            let geoLocation = {lat: lat, lng: lng,};
            results[0].address_components.forEach(element => {
                switch(element.types[0]) {
                    case 'locality':
                        geoLocation['locality'] = element.long_name;
                        break;
                    case 'postal_town':
                        geoLocation['locality'] = element.long_name;
                        break;
                    case 'administrative_area_level_2':
                        geoLocation['subAdminArea'] = element.long_name.replace(/County$/, '');
                        break;
                    case 'administrative_area_level_1':
                        geoLocation['adminArea'] = element.short_name;
                        break;
                    case 'country':
                        geoLocation['country'] = element.short_name;
                        break;
                    case 'route':
                        geoLocation['route'] = element.long_name
                        break;
                    default:
                        break;
                }
            });
            if (!geoLocation.hasOwnProperty('subAdminArea') && geoLocation.hasOwnProperty('route')) {
                getGeoLocation.subAdminArea = geoLocation.route
            }
            handler(geoLocation)
        } else {
            handler({})
        }
    })
}
