import { recsConstants } from '_constants';

const initialState = {};

export function recsData(state = initialState, action) {
    switch(action.type) {
        case recsConstants.GET_RECS_FOR_ID_REQUEST:
            return {
                ...state,
                fetchingRecsForId: true,
            };
        case recsConstants.GET_RECS_FOR_ID_SUCCESS:
            return {
                ...state,
                fetchingRecsForId: false,
                forIdRecs: action.data.recs,
            };
        case recsConstants.GET_RECS_FOR_ID_FAILURE:
            return {
                ...state,
                fetchingRecsForId: false,
            };
        case recsConstants.GET_RECS_BY_ID_REQUEST:
            return {
                ...state,
                fetchingRecsById: true,
            };
        case recsConstants.GET_RECS_BY_ID_SUCCESS:
            return {
                ...state,
                fetchingRecsById: false,
                byIdRecs: action.data.recs,
            };
        case recsConstants.GET_RECS_BY_ID_FAILURE:
            return {
                ...state,
                fetchingRecsById: false,
            };
        case recsConstants.GET_PENDING_REC_REQS_BY_ME_REQUEST:
            return {
                ...state,
                fetchingPendingRecReqsByMe: true,
            };
        case recsConstants.GET_PENDING_REC_REQS_BY_ME_SUCCESS:
            return {
                ...state,
                fetchingPendingRecReqsByMe: false,
                pendingRecRecs: action.data.pending_rec_req,
            };
        case recsConstants.GET_PENDING_REC_REQS_BY_ME_FAILURE:
            return {
                ...state,
                fetchingPendingRecReqsByMe: false,
            };
        case recsConstants.REQUEST_REC_REQUEST:
            return {
                ...state,
                requestRec: true,
            };
        case recsConstants.REQUEST_REC_SUCCESS:
            return {
                ...state,
                requestRec: false,
            };
        case recsConstants.REQUEST_REC_FAILURE:
            return {
                ...state,
                requestRec: false,
            };           
        case recsConstants.WRITE_REC_REQUEST:
            return {
                ...state,
                writeRec: true,
            };
        case recsConstants.WRITE_REC_SUCCESS:
            return {
                ...state,
                writeRec: false,
            };
        case recsConstants.WRITE_REC_FAILURE:
            return {
                ...state,
                writeRec: false,
            };           
                

        default:
            return state;
    }
}