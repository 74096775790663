export const audioPlayerConstants = {
    
    UPDATE_PLAYLIST_REQUEST: 'UPDATE_PLAYLIST_REQUEST',
    UPDATE_PLAYLIST_SUCCESS: 'UPDATE_PLAYLIST_SUCCESS',

    CLEAR_PLAYLIST_INSTRUCTIONS_REQUEST: 'CLEAR_PLAYLIST_INSTRUCTIONS_REQUEST',
    CLEAR_PLAYLIST_INSTRUCTIONS_SUCCESS: 'CLEAR_PLAYLIST_INSTRUCTIONS_SUCCESS',

    INSERT_AT_FRONT_OF_PLAYLIST_REQUEST: 'INSERT_AT_FRONT_OF_PLAYLIST_REQUEST',
    INSERT_AT_FRONT_OF_PLAYLIST_SUCCESS: 'INSERT_AT_FRONT_OF_PLAYLIST_SUCCESS',

    ADD_TO_END_OF_PLAYLIST_REQUEST: 'ADD_TO_END_OF_PLAYLIST_REQUEST',
    ADD_TO_END_OF_PLAYLIST_SUCCESS: 'ADD_TO_END_OF_PLAYLIST_SUCCESS',




}
