import { marketingEmailConstants } from '_constants';

const initialState = {};

export function marketingEmailData(state = initialState, action) {
    switch(action.type) {
        case marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_REQUEST:
            return {
                ...state,
                getMarketingEmailTypes: true,
            };
        case marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_SUCCESS:
            return {
                ...state,
                getMarketingEmailTypes: false,
                marketingEmailsTypes: action.data.marketing_email_types
            };
        case marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_FAILURE:
            return {
                ...state,
                getMarketingEmailTypes: false,
            };        
        case marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_REQUEST:
            return {
                ...state,
                getMarketingEmailExclusions: true,
            };
        case marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_SUCCESS:
            return {
                ...state,
                getMarketingEmailExclusions: false,
                marketingEmailsExclusions: action.data.marketing_email_exclusions,
            };
        case marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_FAILURE:
            return {
                ...state,
                getMarketingEmailExclusions: false,
            };  
        case marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_REQUEST:
            return {
                ...state,
                updateMarketingEmailExclusions: true,
            };
        case marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_SUCCESS:
            return {
                ...state,
                updateMarketingEmailExclusions: false,
            };
        case marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_FAILURE:
            return {
                ...state,
                updateMarketingEmailExclusions: false,
            };
                                               
        default:
            return state;
        }

        
    }
