import { recsConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from 'helpers';

export const recsActions = {
    getRecsForMe,
    getRecsForId,
    getRecsByMe,
    getRecsById,
    getPendingRecRequestsByMe,
    requestRec,
    writeRec,

};

function getRecsForMe(callback = null) {
    const user = JSON.parse(localStorage.getItem('user'))

    return getRecsForId(user.user_id, callback)
}

function getRecsForId(forId, callback = null) {

    const params = {
        request: 'get_recs_for_id',
        for_id: forId,
      };
  
    console.log("getRecsForId", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getRecsForId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: recsConstants.GET_RECS_FOR_ID_REQUEST} }
    function success(data)   { return { type: recsConstants.GET_RECS_FOR_ID_SUCCESS, data } }
    function failure(error)  { return { type: recsConstants.GET_RECS_FOR_ID_FAILURE, error } }
}

function getRecsByMe(callback = null) {
    const user = JSON.parse(localStorage.getItem('user'))

    return getRecsById(user.user_id, callback)
}
function getRecsById(byId, callback = null) {

    const params = {
        request: 'get_recs_by_id',
        by_id: byId,
      };
  
    console.log("getRecsById", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getRecsById: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: recsConstants.GET_RECS_BY_ID_REQUEST} }
    function success(data)   { return { type: recsConstants.GET_RECS_BY_ID_SUCCESS, data } }
    function failure(error)  { return { type: recsConstants.GET_RECS_BY_ID_FAILURE, error } }
}

function getPendingRecRequestsByMe(callback = null) {

    const params = {
        request: 'get_pending_rec_requests',
      };
  
    console.log("getPendingRecRequestsByMe", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPendingRecRequestsByMe: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: recsConstants.GET_PENDING_REC_REQS_BY_ME_REQUEST} }
    function success(data)   { return { type: recsConstants.GET_PENDING_REC_REQS_BY_ME_SUCCESS, data } }
    function failure(error)  { return { type: recsConstants.GET_PENDING_REC_REQS_BY_ME_FAILURE, error } }
}

function requestRec(args, callback = null) {

    const params = {
        request: 'request_rec',
      };
      if (args.hasOwnProperty('email')) {
          params['email'] = args.email
      }
      if (args.hasOwnProperty('by_name')) {
          params['by_name'] = args.by_name
      }
      if (args.hasOwnProperty('by_id')) {
          params['by_id'] = args.by_id
      }
  
    console.log("requestRec", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("requestRec: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: recsConstants.REQUEST_REC_REQUEST} }
    function success(data)   { return { type: recsConstants.REQUEST_REC_SUCCESS, data } }
    function failure(error)  { return { type: recsConstants.REQUEST_REC_FAILURE, error } }
}

function writeRec(args, callback = null) {
    const params = {
        request: 'write_rec',
        rec: args.rec
      };
      if (args.hasOwnProperty('code')) {
          params['code'] = args.code
      }
      if (args.hasOwnProperty('for_id')) {
          params['for_id'] = args.for_id
      }
  
    console.log("writeRec", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("writeRec: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: recsConstants.WRITE_REC_REQUEST} }
    function success(data)   { return { type: recsConstants.WRITE_REC_SUCCESS, data } }
    function failure(error)  { return { type: recsConstants.WRITE_REC_FAILURE, error } }

}

