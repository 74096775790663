import { assetConstants } from '_constants';

function mergeAssets(assets, newAssets) {
    if (assets && assets.length > 0) {
        console.log("mergeAssets", assets, newAssets)
        if (newAssets && newAssets.length > 0) {
            let mergedAssets = assets.reduce ( (acc, asset) => {
                if (newAssets.filter( newAsset => newAsset.id === asset.id).length === 0) {
                    acc.push(asset)
                }
                return acc;
            }, [...newAssets])
            return mergedAssets
        } else {
            return assets;
        }
    } else {
        return newAssets
    }
}

function deleteFromAssets(assets, assetId) {
    console.log("deleteFromAssets", assets, assetId)
    if (assets && assets.length > 1) {
        const newAssets = assets.filter( asset => asset.id !== assetId)
        return newAssets
    } else {
        return assets
    }    
}

export function assetData(state = {}, action) {
    switch(action.type) {
        case assetConstants.GET_ASSET_CATEGORIES_REQUEST:
            return {
                ...state,
                getAssetCategories: true,
            };
        case assetConstants.GET_ASSET_CATEGORIES_SUCCESS:
            return {
                ...state,
                assetCategories: action.assetCategories,
                getAssetCategories: false,
            };
        case assetConstants.GET_ASSET_CATEGORIES_FAILURE:
            return {
                ...state,
                getAssetCategories: false,
            };        
        case assetConstants.GET_ASSETS_REQUEST:
            return {
                ...state,
                getAssets: true,
            };
        case assetConstants.GET_ASSETS_SUCCESS:
            return {
                ...state,
                assets: mergeAssets(state.assets, action.assets),
                getAssets: false,
            };
        case assetConstants.GET_ASSETS_FAILURE:
            return {
                ...state,
                getAssets: false,
            };
        case assetConstants.GET_ASSETS_FOR_PRODUCT_REQUEST:
            return {
                ...state,
                getAssetsForProduct: true,
            };
        case assetConstants.GET_ASSETS_FOR_PRODUCT_SUCCESS:
            return {
                ...state,
                assetsForProduct: action.assets,
                getAssetsForProduct: false,
            };
        case assetConstants.GET_ASSETS_FOR_PRODUCT_FAILURE:
            return {
                ...state,
                getAssetsForProduct: false,
            };     
        case assetConstants.GET_ASSET_FOR_USER_REQUEST:
            return {
                ...state,
                getAssetForUser: true,
            };
        case assetConstants.GET_ASSET_FOR_USER_SUCCESS:
            return {
                ...state,
                assetsForUser: mergeAssets(action.assetsForUser, [action.asset]),
                getAssetForUser: false,
            };
        case assetConstants.GET_ASSET_FOR_USER_FAILURE:
            return {
                ...state,
                getAssetForUser: false,
            };                         
        case assetConstants.CREATE_ASSET_REQUEST:
            return {
                ...state,
                createAsset: true,
            };
        case assetConstants.CREATE_ASSET_SUCCESS:
            return {
                ...state,
                createAsset: false,
            };
        case assetConstants.CREATE_ASSET_FAILURE:
            return {
                ...state,
                createAsset: false,
            };         
        case assetConstants.UPDATE_ASSET_REQUEST:
            return {
                ...state,
                updateAsset: true,
            };
        case assetConstants.UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                updateAsset: false,
            };
        case assetConstants.UPDATE_ASSET_FAILURE:
            return {
                ...state,
                updateAsset: false,
            };  
        case assetConstants.DELETE_ASSET_REQUEST:
            return {
                ...state,
                deleteAsset: true,
            };
        case assetConstants.DELETE_ASSET_SUCCESS:
            return {
                ...state,
                assets: deleteFromAssets(state.assets, action.assetId),
                deleteAsset: false,
            };
        case assetConstants.DELETE_ASSET_FAILURE:
            return {
                ...state,
                deleteAsset: false,
            };   
        case assetConstants.PURCHASE_ASSET_REQUEST:
            return {
                ...state,
                purchaseAsset: true,
            };
        case assetConstants.PURCHASE_ASSET_SUCCESS:
            return {
                ...state,
                purchaseAsset: false,
            };
        case assetConstants.PURCHASE_ASSET_FAILURE:
            return {
                ...state,
                purchaseAsset: false,
            };                                   
        case assetConstants.DOWNLOAD_ASSET_REQUEST:
            return {
                ...state,
                downloadAsset: true,
            };
        case assetConstants.DOWNLOAD_ASSET_SUCCESS:
            return {
                ...state,
                downloadAsset: false,
            };
        case assetConstants.DOWNLOAD_ASSET_FAILURE:
            return {
                ...state,
                downloadAsset: false,
            };                                   
        default:
            return state;
        }

}
