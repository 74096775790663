import { userConstants, registerConstants } from '_constants';

export function registration(state = {}, action) {
  switch (action.type) {
    case registerConstants.REGISTER_REQUEST:
      return { 
        ...state,
        registering: true 
      };
    case registerConstants.REGISTER_SUCCESS:
      return { 
        registering: false 
      };
    case registerConstants.REGISTER_FAILURE:
      return { 
        ...state,
        registering: false 
      };

    case userConstants.GET_FACEBOOK_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        email: action.data.info.email === undefined ? state.email : action.data.info.email.address,
      }
    case registerConstants.REGISTER_NAME:
      return {
        ...state,
        name: action.name,
      }
    case registerConstants.REGISTER_EMAIL:
      return {
        ...state,
        username: action.email,
        email: action.email,
      }
    case registerConstants.REGISTER_ADDRESS:
      return {
        ...state,
        address: action.address.address,
        latitude: action.address.geoLocation.lat,
        longitude: action.address.geoLocation.lng,
        locality: action.address.geoLocation.locality,
        administrative_area: action.address.geoLocation.adminArea,
        sub_administrative_area: action.address.geoLocation.subAdminArea,
        country: action.address.geoLocation.country,
      }
    case registerConstants.REGISTER_PASSWORD:
      return {
        ...state,
        password: action.password,
      }
    case registerConstants.REGISTER_ACC_TYPE:
      return {
        ...state,
        accType: action.accType,
      }
    case registerConstants.REGISTER_AFFILIATE:
      return {
        ...state,
        affiliate_code: action.affiliateCode,
      }
    case registerConstants.CLEAR_ALL_REGISTRATION_DATA:
      return {}

    default:
      return state
  }
}