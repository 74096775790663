import { hmpgTmplConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';

//import { history } from '_helpers';

export const hmpgTmplActions = {
    getTmplTypes,
    getUserTmpl,
    getMemberId,
    setUsername,
    updateUsername,
    getUsername
};

function getTmplTypes(args={}, callback = null) {
    // required arguments: none
    // optional arguments: 
    // traits: array of specialist traits
    // returns array: [ ['for_traits'] => [], ['others'] => [] ]

    const params = {
        request: 'get_tmpl_types',
    };

    if (args.hasOwnProperty('traits')) {
        params['traits'] = JSON.stringify(args.traits);
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Templates");
                    console.log(data);
                
                    dispatch(success(data.tmpls));
                    if (callback) {
                        callback(true, data.tmpls);
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again";
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg); }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTmplTypes: fatal error");
                    dispatch(userActions.endSession(error));
                }
            }
        );
    };
    
    function request()      { return { type: hmpgTmplConstants.GET_TMPL_TYPES_REQUEST}; }
    function success(tmpls) { return { type: hmpgTmplConstants.GET_TMPL_TYPES_SUCCESS, tmpls }; }
    function failure(error) { return { type: hmpgTmplConstants.GET_TMPL_TYPES_FAILURE, error }; }
}

function getUserTmpl(args={}, callback = null) {
    // required arguments: none
    // optional arguments: for_user
    //  if option arguments for_user (user's id) is specified, only the PUBLISHED template is returned
    // returns array: [ ['DRAFT'] => [], ['PUBLISHED'] => [] ]

    const params = {
        request: 'get_user_tmpl',
    };
    if (args.hasOwnProperty('for_user')) {
	params['for_user'] = args.for_user;
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got User Template");
                    console.log(data);
                
                    dispatch(success(data.tmpls));
                    if (callback) {
                        callback(true, data.tmpls);
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again";
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg); }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getUserTmpl: fatal error");
                    dispatch(userActions.endSession(error));
                }
            }
        );
    };
    
    function request()      { return { type: hmpgTmplConstants.GET_USER_TEMPLATE_REQUEST}; }
    function success(tmpls) { return { type: hmpgTmplConstants.GET_USER_TEMPLATE_SUCCESS, tmpls }; }
    function failure(error) { return { type: hmpgTmplConstants.GET_USER_TEMPLATE_FAILURE, error }; }
}

function getMemberId(args, callback=null) {
    const params = {
        request: 'get_member_id',
        username: args.username
      };


    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getMemberId");
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data.member_id)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getMemberId")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: hmpgTmplConstants.GET_MEMBER_ID_REQUEST} }
    function success(data)   { return { type: hmpgTmplConstants.GET_MEMBER_ID_SUCCESS, data } }
    function failure(error)  { return { type: hmpgTmplConstants.GET_MEMBER_ID_FAILURE, error } }
}

function setUsername(args, userId, callback=null) {
    // args: name must be a string
    const params = {
        request: 'set_username',
        username: args.username
    };


    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("setUsername");
                    console.log(data)
                    dispatch(success(data));
                    if (userId) {
                        dispatch(getUsername({userId: userId}))
                    }
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("setUserName")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: hmpgTmplConstants.SET_USERNAME_REQUEST} }
    function success(data)   { return { type: hmpgTmplConstants.SET_USERNAME_SUCCESS, data } }
    function failure(error)  { return { type: hmpgTmplConstants.SET_USERNAME_FAILURE, error } }
}

function updateUsername(args, userId, callback=null) {
    // args: name must be a string
    const params = {
        request: 'update_username',
        username: args.username
    };


    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("updateUsername");
                    console.log(data)
                    dispatch(success(data));
                    if (userId) {
                        dispatch(getUsername({userId: userId}))
                    }
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateUserName")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: hmpgTmplConstants.UPDATE_USERNAME_REQUEST} }
    function success(data)   { return { type: hmpgTmplConstants.UPDATE_USERNAME_SUCCESS, data } }
    function failure(error)  { return { type: hmpgTmplConstants.UPDATE_USERNAME_FAILURE, error } }
}

function getUsername(args, callback=null) {
    // args: name must be a string
    const params = {
        request: 'get_username',
        user_id: args.userId
    };


    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getUsername");
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data.username)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateUserName")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: hmpgTmplConstants.GET_USERNAME_REQUEST} }
    function success(data)   { return { type: hmpgTmplConstants.GET_USERNAME_SUCCESS, data } }
    function failure(error)  { return { type: hmpgTmplConstants.GET_USERNAME_FAILURE, error } }
}
