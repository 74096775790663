import { notificationConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const notificationActions = {
    getNotifications,
    getBidNotifications,
    markNotificationAsRead,
    deleteNotification,
    getNotificationsForFollowed
};

function getNotifications() {
    const params = {
        request: 'get_notifications',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Notifications");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getNotifications: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: notificationConstants.GET_NOTIFICATIONS_REQUEST} }
    function success(data)   { return { type: notificationConstants.GET_NOTIFICATIONS_SUCCESS, data } }
    function failure(error)  { return { type: notificationConstants.GET_NOTIFICATIONS_FAILURE, error } }
}

function getBidNotifications(bidId) {
    const params = {
        request: 'get_bid_notifications',
      };
      if (bidId != null) {
          params['bidId'] = bidId
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)        
        .then(
            data => {
                if (data.success) {
                    console.log("Got Notifications For Bid");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBidNotifications: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: notificationConstants.GET_BID_NOTIFICATIONS_REQUEST} }
    function success(data)   { return { type: notificationConstants.GET_BID_NOTIFICATIONS_SUCCESS, data } }
    function failure(error)  { return { type: notificationConstants.GET_BID_NOTIFICATIONS_FAILURE, error } }
}

function markNotificationAsRead(notificationID, callback = null) {
    const params = {
        request: 'mark_notifications_as_read',
      };
      if (notificationID != null) {
          params['notification_ids'] = notificationID
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Mark Notification As Read");
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { 
                        callback (data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("markNotificationAsRead: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: notificationConstants.MARK_NOTIFICATION_AS_READ_REQUEST} }
    function success(data)   { return { type: notificationConstants.MARK_NOTIFICATION_AS_READ_SUCCESS, data } }
    function failure(error)  { return { type: notificationConstants.MARK_NOTIFICATION_AS_READ_FAILURE, error } }
}

function deleteNotification(notificationID, callback = null) {
    const params = {
        request: 'delete_notifications',
      };
      if (notificationID != null) {
          params['notification_ids'] = notificationID
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Delete Notification");
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { 
                        callback (data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteNotification: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: notificationConstants.DELETE_NOTIFICATION_REQUEST} }
    function success(data)   { return { type: notificationConstants.DELETE_NOTIFICATION_SUCCESS, data } }
    function failure(error)  { return { type: notificationConstants.DELETE_NOTIFICATION_FAILURE, error } }
}

function getNotificationsForFollowed() {
    const params = {
        request: 'get_notifications_for_followed',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("got NotificationsForFollowed");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getNotificationsForFollowed: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_REQUEST} }
    function success(data)   { return { type: notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_SUCCESS, data } }
    function failure(error)  { return { type: notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_FAILURE, error } }
}