import { watchConstants } from '_constants';

const initialState = {
    getWatchedItemsRequest: false,
    watchedProducts: [], 
    watchedEvents: [], 
    watchedAssets:[],
    watchedPosts:[]
};

function mergeWatchedItems(watchedItems, newWatchedItems, watchItem) {
    console.log("mergefail", watchedItems, newWatchedItems, watchItem)
    if (newWatchedItems.hasOwnProperty(watchItem)) {
        console.log("mergefail 12345", newWatchedItems[watchItem])
        return newWatchedItems[watchItem];
    } else {
        return watchedItems ? watchedItems : [];
    }
}

export function watchData(state = initialState, action) {
    switch(action.type) {
	// watch
        case watchConstants.WATCH_ITEM_REQUEST:
            return {
                ...state,
                watchItemRequest: true,
            };
        case watchConstants.WATCH_ITEM_SUCCESS:
            return {
                ...state,
                watchItemRequest: false,
            };
        case watchConstants.WATCH_ITEM_FAILURE:
            return {
                ...state,
                watchItemRequest: false,
            };

	// unwatch
        case watchConstants.UNWATCH_ITEM_REQUEST:
            return {
                ...state,
                unwatchItemRequest: true,
            };
        case watchConstants.UNWATCH_ITEM_SUCCESS:
            return {
                ...state,
                unwatchItemRequest: false,
            };
        case watchConstants.UNWATCH_ITEM_FAILURE:
            return {
                ...state,
                unwatchItemRequest: false,
            };

	// get watched
        case watchConstants.GET_WATCHED_ITEMS_REQUEST:
            return {
                ...state,
                getWatchedItemsRequest: true,
            };
        case watchConstants.GET_WATCHED_ITEMS_SUCCESS:
            return {
                ...state,
                watchedProducts: mergeWatchedItems(state.watchedProducts,   action.data, 'products'),
                watchedEvents:   mergeWatchedItems(state.watchedEvents,   action.data, 'events'),
                watchedAssets:   mergeWatchedItems(state.watchedAssets,   action.data, 'assets'),
                watchedPosts:    mergeWatchedItems(state.watchedPosts,   action.data, 'posts'),
                getWatchedItemsRequest: false,
            };
        case watchConstants.GET_WATCHED_ITEMS_FAILURE:
            return {
                ...state,
                getWatchedItemsRequest: false,

            };

        default:
            return state;
    }
}
