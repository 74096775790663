import { affiliateConstants } from '_constants';
import { userService } from '_services';
import { memberActions, userActions } from '_actions';

export const affiliateActions = {
    getPromotions,
    getClients,
    getEvents,
    registerAsAffiliate,
};

function getPromotions(callback = null) {
    const params = {
        'request': 'affiliate_promotion'
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)        
        .then(
            data => {
                console.log("getPromotions", data)
                if (data.success) {
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPromotions: fatal error")
                    dispatch(userActions.endSession(error))    
                } else {
                    // handle other error codes TBD
                }
            }
        )
    }

    function request()      { return { type: affiliateConstants.AFFILIATE_GET_PROMOTION_REQUEST} }
    function success(data)  { return { type: affiliateConstants.AFFILIATE_GET_PROMOTION_SUCCESS, data } }
    function failure(error) { return { type: affiliateConstants.AFFILIATE_GET_PROMOTION_FAILURE, error } }
}

function registerAsAffiliate(callback =null) {
    const params = {
        request: 'register_as_affiliate'
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)        
        .then(
            data => {
                console.log("registerAsAffiliate", data)
                if (data.success) {
                    dispatch(memberActions.memberQuery());        
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("registerAsAffiliate: fatal error")
                    dispatch(userActions.endSession(error))    
                } else {
                    // handle other error codes TBD
                }
            }
        )
    }

    function request()      { return { type: affiliateConstants.REGISTER_AS_AFFILIATE_REQUEST} }
    function success(data)  { return { type: affiliateConstants.REGISTER_AS_AFFILIATE_SUCCESS, data } }
    function failure(error) { return { type: affiliateConstants.REGISTER_AS_AFFILIATE_FAILURE, error } }
}


function getClients(callback = null) {
    const params = {
        request: 'affiliate_get_clients',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                console.log("getClients", data)
                if (data.success) {
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("(affiliates) getClients: fatal error")
                    dispatch(userActions.endSession(error))    
                } else {
                    // handle other error codes TBD
                }
            }
        )
    }

    function request()      { return { type: affiliateConstants.GET_AFFILIATE_CLIENTS_REQUEST} }
    function success(data)  { return { type: affiliateConstants.GET_AFFILIATE_CLIENTS_SUCCESS, data } }
    function failure(error) { return { type: affiliateConstants.GET_AFFILIATE_CLIENTS_FAILURE, error } }
}

function getEvents(callback = null) {
    const params = {
        request: 'affiliate_event_query',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                console.log("get Affiliate Events data", data)
                if (data.success) {                    
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getEvents: fatal error")
                    dispatch(userActions.endSession(error))    
                } else {
                    // handle other error codes TBD
                }
            }
        )
    }

    function request()      { return { type: affiliateConstants.GET_AFFILIATE_EVENTS_REQUEST} }
    function success(data)  { return { type: affiliateConstants.GET_AFFILIATE_EVENTS_SUCCESS, data } }
    function failure(error) { return { type: affiliateConstants.GET_AFFILIATE_EVENTS_FAILURE, error } }
}


