import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// import { Button } from "components";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import YouTube from 'react-youtube';

import { CardImg, } from "reactstrap";

import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';

// import { config } from '_constants'
import {parseMediaTypeFromUrl, getStreamGalleryMediaURL } from '_helpers';

const opts =  {
  height: '240',
  width: '240',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

class LargeMediaViewer extends React.Component {
  constructor(props) {
    super(props);
    console.log("ImageUpload", props)
    const disableContextMenu = function (e) {
      e.preventDefault()
    }

    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                       this.props.imageUrl ? this.props.imageUrl : 
                                             defaultImage,
      index: props.index !== undefined ? props.index : null,
      disableContextMenu: disableContextMenu, 
    };
    this.handleContextMenuPressed = this.handleContextMenuPressed.bind(this)

  }

  handleContextMenuPressed(e) {
    const { disableContextMenu } = this.state
    disableContextMenu(e)
  }

  componentDidMount() {
      document.body.addEventListener('contextmenu', this.handleContextMenuPressed);
  }

  componentWillUnmount() {
    document.body.removeEventListener('contextmenu', this.handleContextMenuPressed );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render() {
    const { imagePreviewUrl } = this.state 
    const mediaExt = imagePreviewUrl.split('.').pop()
    const isVideo = parseMediaTypeFromUrl(imagePreviewUrl) === "video"
    const isPhoto = parseMediaTypeFromUrl(imagePreviewUrl) === "image"
    const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0 
    const imageUrl = imagePreviewUrl.replace('/cmprss/Photos', '/Photos')
    console.log("imageURL",  imagePreviewUrl)

    console.log("isVideo", isVideo, mediaExt)
    // const request = `/?request=stream_video&video=`
    const videoId = imagePreviewUrl.substring(imagePreviewUrl.lastIndexOf("/") + 1)
    const videoURL = getStreamGalleryMediaURL(videoId)
    console.log("videoURL", videoURL)
    const date = new Date().getTime()
    const fullImageUrl = imagePreviewUrl.replace('/cmprss', '')

    return (
      <div className="text-center">
            { isVideo && 
            <div 
            // className="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer"
            >
                <Replay 
                source= {videoURL} 
                initialPlaybackProps={{ isPaused: true, volume: 0.4 }}
                // options={{
                //     controls: {
                //       includeControls: [
                //         "playPauseButton",
                //         "skipButton",
                //         "timeline",
                //         "gotoLiveButton",
                //         "timeDisplay",
                //         "volume",
                //         "fullscreenButton",
                //         "exitButton",
                //         "qualitySelector",
                //         // "pipButton"
                //       ]
                //     }
                //   }}
                
                />
              {/* <video width="320" height="240" controls autoPlay={false}>
                  <source src={imageUrl} type={"video/" + mediaExt} autostart="false"/>
                  Your browser does not support the video tag.
              </video> */}
            </div>
              }
          <div className="embed-responsive ">
            {isPhoto &&
              <CardImg
              alt="..."
              src={`${fullImageUrl}?${date}`}
              top
            />
            }
            {isYoutube && 
              <YouTube
              videoId={imageUrl}
              opts={opts}
              onReady={this._onReady}
              containerClassName="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer"
              />}
        </div>
      </div>
    );
  }
}

LargeMediaViewer.propTypes = {
  avatar: PropTypes.bool
};

export default LargeMediaViewer;
