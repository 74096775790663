import ReactCrop from 'react-image-crop';
import React from 'react';
// import PropTypes from "prop-types";
import { Button, Row, Modal, ModalHeader, ModalBody } from "reactstrap";

import defaultImage from "assets/img/default-avatar.png";
import defaultAvatar from "assets/img/default-avatar.png";
import 'react-image-crop/dist/ReactCrop.css';
import { notificationMessageActions } from "_actions"

class ProfileImageUpload extends React.Component {
    constructor(props) {
      super(props);
      console.log("ImageUpload", props)
      this.state = {
        file: null,
        croppedImageUrl: null,
        imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                         this.props.imageUrl ? this.props.imageUrl : 
                                               defaultImage,
        index: props.index !== undefined ? props.index : null,
        src: null,
        crop: {
          aspect: 1,
          width: 50,
          x: 0,
          y: 0,
        },
        modalNotice: false,

      };
    //   this.handleImageChange = this.handleImageChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.toggleModalNotice = this.toggleModalNotice.bind(this);
      this.closeModalNotice = this.closeModalNotice.bind(this);

    //   this.handleRemove = this.handleRemove.bind(this);
    }


  toggleModalNotice() {
    this.setState({
      modalNotice: !this.state.modalNotice
    });
  }

  closeModalNotice() {
    this.setState({
      modalNotice: false,
      imageRef: null, 
    });
  }
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          if (file.size > 7500000) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your Profile Picture must be less than 7.5 mb.", 2 ))
              return
          } else {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
              this.setState({ src: reader.result, modalNotice: true }),
            );
            reader.readAsDataURL(file);
          }
        }
      };

      handleClick() {
        this.refs.fileInput.click();
        console.log("ImageUpload: handleClick:", this.state)
      }
    
      onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    
        // Make the library regenerate aspect crops if loading new images.
        const { crop } = this.state;
    
        if (crop.aspect && crop.height && crop.width) {
          this.setState({
            crop: { ...crop, height: null },
          });
        } else {
          this.makeClientCrop(crop, pixelCrop);
        }
      };
    
      onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
      };
    
      onCropChange = crop => {
        this.setState({ crop });
      };
    
      async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            pixelCrop,
            'newFile.jpeg',
          )
          .then( 
            resolve => {
              console.log("resolve", resolve, croppedImageUrl)
              this.setState({blob: resolve})
            },
            reject => {
              console.log("reject", reject)
              this.setState({blob: reject})
            }
          );
        }
      }


    
      getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            blob.name = fileName;
            resolve(blob);
          }, 'image/jpeg');
        });

      }

      handleImageChange(e) {
        console.log("handleImageChange", this.state)
        e.preventDefault();
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result,
            modalNotice: false,
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(this.state.blob)
      }

      
    
    
      render() {
        const { crop, croppedImageUrl, src } = this.state;
        console.log("imageRef", this.imageRef)
        console.log("state/props", this.state, this.props)
        return (

            <div className="fileinput text-center">
              <input type="file" accept=".jpg, .jpeg, .png," onChange={this.onSelectFile} ref="fileInput" />
              <div>
                <img className="rounded-circle cursor-pointer avatar-profile" onClick={() => this.handleClick()}  src={this.state.imagePreviewUrl} alt="..." 
                />
              </div>
              
              <Row>
                <Modal
                  isOpen={this.state.modalNotice}
                  toggle={this.toggleModalNotice}
                  className="modal-notice "
                  size="lg"
                  fade={false}
                  onHide={this.closeModalNotice}
                >
              <ModalHeader toggle={this.toggleModalNotice}>
                Update Profile Picture
              </ModalHeader>
              <ModalBody>
              <div className="text-center">
                <div>
                  {(this.imageRef === null || this.imageRef === undefined) && 
                  <img className="rounded-circle cursor-pointer" onClick={() => this.handleClick()}  src={this.state.imagePreviewUrl} alt="..." 
                  />}
                  {src && 
                  
                  (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    className="wrap-max-height"
                  />
                )}
                {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />}
                </div>
                <div className="pt-2"> 
                  {(this.imageRef === null || this.imageRef === undefined) ? (
                    // <Button className="btn-primary" simple color="primary"  onClick={() => this.handleClick()}>
                    //   {this.props.avatar ? "Add Profile Photo" : "Update Profile Photo"}
                    // </Button>
                    <span></span>
                  ) : (
                    <span>
                      <Button outline size="sm" color="default" onClick={() => this.handleClick()}>
                        Change
                      </Button>
                      {this.props.avatar ? <br /> : null}
                      <Button outline size="sm"  color="primary" simple onClick={(e) => this.handleImageChange(e)}>
                        Save
                      </Button>
                    </span>
                  )}
                    {this.state.file !== null &&
                        <Button outline size="sm"  color="default"  onClick={() => this.handleClick()}>
                        Change
                    </Button> }
                </div>
              </div>
              </ModalBody>

              </Modal>
            </Row>
          </div>
        );
      }
    }


      export default ProfileImageUpload;


