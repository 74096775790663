import React from "react";
// used for making the prop types of this component
import { connect } from 'react-redux';
import { 
  Card,
  CardHeader, 
  CardBody, 
  Col, 
  Collapse,
  Row,

} from "reactstrap";

import { GalleryUpload } from "components";
import { memberActions } from '_actions';
class GalleryAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: this.props.defaultOpened !== undefined ?  ["collapseOne"] : [""],
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleYoutubeUrl = this.handleYoutubeUrl.bind(this);
  }
  
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  handleImageChange(imageData) {
    console.log("UserPage handleImageChange", imageData)
    const mediaType = 'Image';

    if (imageData.newImage) {
      this.props.dispatch(memberActions.uploadProfileImage(imageData.state.imagePreviewUrl, mediaType, imageData.state.index))
    }
  }
  handleYoutubeUrl(parsedYoutubeId, index) {
    const mediaType = 'Youtube';
    console.log("in handleyoutube", index)
    if (parsedYoutubeId && index >= 0) {
      // console.log("Youtube would get called")
      this.props.dispatch(memberActions.uploadProfileImage(parsedYoutubeId, mediaType, index))
    }
  }


  render() {

    console.log("gallery props", this.props)
    const gallery = this.props.gallery
    return (
      <div className="accordion">
        <Card className="card-plain" >
          <CardHeader className="cursor-pointer"
            role="tab"                            
            aria-expanded={this.state.openedCollapses.includes(
              "collapseOne"
            )}
            onClick={() => this.collapsesToggle("collapseOne")}
            >
            <h5 className="mb-0"> Gallery </h5>
          </CardHeader> 
            <Collapse 
            role="tabpanel"               
            isOpen={this.state.openedCollapses.includes("collapseOne")}
            >
            <CardBody>
              <Row className="text-center"> 
              { gallery && gallery.length > 0 && gallery.map( (img, idx) => 
                  <Col xs={12} sm={6} md={6} lg={4} key={idx} >
                    <GalleryUpload dispatch={this.props.dispatch} callback={this.handleImageChange} youtubeCallback={this.handleYoutubeUrl} img imageUrl={img} index={idx} />
                    {/* <ImageUpload callback={this.handleImageChange}  imageUrl="placeholder" index={idx} /> */}

                  </Col>       
                )}  
              </Row>
            </CardBody>
          </Collapse>     
        </Card>
      </div>
    );
  }
}

GalleryAccordion.propTypes = {
  // Which one of the component will be opened by default
  // example: [{title: "Title of the Collapse", text: "Text/Body of the Collapse"}]
};

function mapStateToProps(state) {
  const { memberInfo, } = state;

  const { fetchingInfo, info, fetchingUserInfo, userInfo } = memberInfo;

  return {
    fetchingInfo, info,
    fetchingUserInfo,  userInfo,
  };
}

const connectedGalleryAccordion= connect(mapStateToProps)(GalleryAccordion);
export { connectedGalleryAccordion as GalleryAccordion };
