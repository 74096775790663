import { stripeConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const stripeActions = {
    getConnectURL,
    getConnectId,
    getCustomerId,
    retrieveSources,
    registerPayoutAccount,
    sourceAction,
    createCustomer
};

function getConnectURL() {
    const params = {
        request: 'stripe_get_account_url',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Get Connect URL");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getConnectURL: fatal error")
                    //dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.GET_CONNECT_URL_REQUEST} }
    function success(data)   { return { type: stripeConstants.GET_CONNECT_URL_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.GET_CONNECT_URL_FAILURE, error } }
}

function getConnectId() {
    const params = {
        request: 'stripe_get_connect_id',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Get Connect Id");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getConnectId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.GET_CONNECT_ID_REQUEST} }
    function success(data)   { return { type: stripeConstants.GET_CONNECT_ID_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.GET_CONNECT_ID_FAILURE, error } }
}

function getCustomerId() {
    const params = {
        request: 'stripe_get_customer_id',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Get Customer Id");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    dispatch(createCustomer());
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCustomerId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.GET_CUSTOMER_ID_REQUEST} }
    function success(data)   { return { type: stripeConstants.GET_CUSTOMER_ID_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.GET_CUSTOMER_ID_FAILURE, error } }
}

function retrieveSources() {
    const params = {
        request: 'stripe_retrieve_sources',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Retrieve Sources");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("retrieveSources: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.RETRIEVE_SOURCES_REQUEST} }
    function success(data)   { return { type: stripeConstants.RETRIEVE_SOURCES_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.RETRIEVE_SOURCES_FAILURE, error } }
}

function registerPayoutAccount(authCode, callback = null) {
    const params = {
        request: 'register_stripe_payout_account',
        auth_code: authCode,
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Register Payout Account", data)
                    dispatch(success(data));
                    dispatch(stripeActions.getConnectURL());
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("registerPayoutAccount: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.REGISTER_PAYOUT_ACCOUNT_REQUEST} }
    function success(data)   { return { type: stripeConstants.REGISTER_PAYOUT_ACCOUNT_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.REGISTER_PAYOUT_ACCOUNT_FAILURE, error } }
}

function sourceAction(action, paymentMethod, defaultSource = null, callback = null) {
    const params = {
        request: 'stripe_source_action',
        action: action,   // one of 'Add', 'Delete', 'Update',
        token: paymentMethod,
    }    
    if (defaultSource !== null) {
        params['default_source'] = defaultSource
    }

    if (action !== 'Add' && action !== 'Delete' && action !== 'Update') {
        failure("Action should be one of 'Add', 'Delete', or 'Failure'")
    }

    console.log("sourceActionParams", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Source Action");
                    console.log(data)
                    dispatch(success(data));
                    dispatch(stripeActions.retrieveSources());
                    if (callback) { callback(true, data) }

                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("sourceAction: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.SOURCE_ACTION_REQUEST} }
    function success(data)   { return { type: stripeConstants.SOURCE_ACTION_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.SOURCE_ACTION_FAILURE, error } }
}

function createCustomer() {
    const params = {
        request: 'stripe_create_customer'
    }    

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Stripe Register Payout Account");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createCustomer: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: stripeConstants.CREATE_CUSTOMER_REQUEST} }
    function success(data)   { return { type: stripeConstants.CREATE_CUSTOMER_SUCCESS, data } }
    function failure(error)  { return { type: stripeConstants.CREATE_CUSTOMER_FAILURE, error } }
}