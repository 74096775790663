export const marketingEmailConstants = {
    GET_MARKETING_EMAIL_TYPES_REQUEST: 'GET_MARKETING_EMAIL_TYPES_REQUEST',
    GET_MARKETING_EMAIL_TYPES_SUCCESS: 'GET_MARKETING_EMAIL_TYPES_SUCCESS',
    GET_MARKETING_EMAIL_TYPES_FAILURE: 'GET_MARKETING_EMAIL_TYPES_FAILURE',

    GET_MARKETING_EMAIL_EXCLUSIONS_REQUEST: 'GET_MARKETING_EMAIL_EXCLUSIONS_REQUEST',
    GET_MARKETING_EMAIL_EXCLUSIONS_SUCCESS: 'GET_MARKETING_EMAIL_EXCLUSIONS_SUCCESS',
    GET_MARKETING_EMAIL_EXCLUSIONS_FAILURE: 'GET_MARKETING_EMAIL_EXCLUSIONS_FAILURE',

    UPDATE_MARKETING_EMAIL_EXCLUSIONS_REQUEST: 'UPDATE_MARKETING_EMAIL_EXCLUSIONS_REQUEST',
    UPDATE_MARKETING_EMAIL_EXCLUSIONS_SUCCESS: 'UPDATE_MARKETING_EMAIL_EXCLUSIONS_SUCCESS',
    UPDATE_MARKETING_EMAIL_EXCLUSIONS_FAILURE: 'UPDATE_MARKETING_EMAIL_EXCLUSIONS_FAILURE',

}

