import React from "react";
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { numberFunction } from '_helpers';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,

  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Table,
} from "reactstrap";

import { dateFromISO8601 } from '_helpers';
import { Link } from 'react-router-dom';
import { CardCategory } from 'components';


class ReviewForIdCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [],
      // reviews: [] 
      // [revId, isSender, message, rating, time, unread, spId, spName, imageUrl, eventId]
    }
  }

  componentDidMount() {
    
  }

  collapsesToggle = collapse => {
    console.log("selectedCollapse", collapse)
    const {openedCollapses} = this.state
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      const tmpArray = openedCollapses
      tmpArray.push(collapse)
      this.setState({
        openedCollapses: tmpArray
      });
    }
  };

  render() {
    // const user = JSON.parse(localStorage.getItem('user'));
    const review = this.props.review
    console.log("review", review)
    return (
      <div>
        <Card className="card-stats">
            <CardHeader>
                <CardCategory>
                {dateFromISO8601(review.date, review.time_zone, "LLL")} 
                </CardCategory>
            </CardHeader>
            <CardBody>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(`collapse_review_${review.id}`)}
                  >
                  <p className="line-styling text-sm">{review.review}</p>
              </Collapse>
                  {!this.state.openedCollapses.includes(`collapse_review_${review.id}` )&& 
                  <p className="line-styling two-line text-sm">{review.review}</p>
                  }
                  <p className="mt-3 mb-0 text-sm" >
                    <span
                      className="text-nowrap font-weight-600 cursor-pointer"
                      role="tab"
                      onClick={() => this.collapsesToggle(`collapse_review_${review.id}`)}
                      aria-expanded={this.state.openedCollapses.includes(
                        `collapse_review_${review.id}`)}
                      >
                      {this.state.openedCollapses.includes(
                        `collapse_review_${review.id}`) ? 'See less' : 'See more' }
                    </span>
                  </p>
            </CardBody>
            <CardFooter>
              {review.is_specialist &&
                <div>
                    <Link style={{display:"inline-block"}} to={ { pathname: `/dash/c_profile/${numberFunction(parseInt(review.s_id, 10))}`, 
                                    state: {sp_id: parseInt(review.s_id, 10) }} }>                                  
                      <img className="avatar-md rounded-circle mr-3"  src={review.photo} alt="..." />
                    </Link> 
                    <Link style={{display:"inline-block"}} to={ { pathname: `/dash/c_profile/${numberFunction(parseInt(review.s_id, 10))}`, 
                                    state: {sp_id: parseInt(review.s_id, 10) }} }>                                  
                      <span style={{fontSize:'.9125rem'}}>{review.name} </span>
                    </Link>
                    &nbsp; <span style={{fontSize:'.9125rem'}} className="title">{review.rating}</span>
                  </div>
              }
              {review.is_business && 
              <div>
                <Link style={{display:"inline-block"}} to={ { pathname: `/dash/b_profile/${numberFunction(parseInt(review.id))}`, 
                              state: {business_id: parseInt(review.id) }} }>                                  
                  <img className="avatar-md rounded-circle mr-3"  src={review.photo} alt="..." />
                </Link>

                <Link style={{display:"inline-block"}} to={ { pathname: `/dash/b_profile/${numberFunction(parseInt(review.id))}`, 
                                state: {business_id: parseInt(review.id) }} }>                                  
                {review.name}      
                </Link>
                &nbsp; <span style={{fontSize:'.9125rem'}} className="title">{review.rating}</span>
              </div>
              }
            </CardFooter>
        </Card>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {    
           
  };
}
const connectedReviewForIdCard = connect(mapStateToProps)(ReviewForIdCard);

export { connectedReviewForIdCard as ReviewForIdCard };
