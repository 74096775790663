import { followingConstants } from '_constants';

const initialState = {};

export function followingData(state = initialState, action) {
    switch(action.type) {
        // Get Locations
        case followingConstants.GET_FOLLOWING_REQUEST:
        return {
            ...state,
            getFollowing: true,
        };
        case followingConstants.GET_FOLLOWING_SUCCESS:
            return {
                ...state,
                followBusiness: action.data.follow_business,
                followCelebrity: action.data.follow_celebs,
                getFollowing: false,
            };
        case followingConstants.GET_FOLLOWING_FAILURE:
            return {
                ...state,
                getFollowing: false,
            };

        // Create/Delete a following
        case followingConstants.FOLLOW_CREATE_DELETE_REQUEST:
        return {
            ...state,
            createDeleteFollowing: true,
        };
        case followingConstants.FOLLOW_CREATE_DELETE_SUCCESS:
            return {
                ...state,
                createDeleteFollowing: false,
            };
        case followingConstants.FOLLOW_CREATE_DELETE_FAILURE:
            return {
                ...state,
                createDeleteFollowing: false,
            };
        //Multiple Celebs from getCelebsFollowingInfo
        case followingConstants.GET_FOLLOWING_CELEBS_INFO_REQUEST:
            return {
                ...state,
                getFollowingCelebsInfoRequest: true,
            };
        case followingConstants.GET_FOLLOWING_CELEBS_INFO_SUCCESS:
            return {
                ...state,
                getFollowingCelebsInfoRequest: false,
                followingCelebs: action.data.celebs,
            };
        case followingConstants.GET_FOLLOWING_CELEBS_INFO_FAILURE:
            return {
                ...state,
                getFollowingCelebsInfoRequest: false,
            };
        //Multiple Businesses from get_businesses_from_id
        case followingConstants.GET_FOLLOWING_BUSINESSES_INFO_REQUEST:
            return {
                ...state,
                getFollowingBusinessesInfoRequest: true,
            };
        case followingConstants.GET_FOLLOWING_BUSINESSES_INFO_SUCCESS:
            return {
                ...state,
                getFollowingBusinessesInfoRequest: false,
                followingBusinesses: action.data.businesses,
            };
        case followingConstants.GET_FOLLOWING_BUSINESSES_INFO_FAILURE:
            return {
                ...state,
                getFollowingBusinessesInfoRequest: false,
            };
                //SEARCH CELEBS
        case followingConstants.SEARCH_CELEBS_REQUEST:
            return {
                ...state,
                searchCelebsRequest: true,
            };
        case followingConstants.SEARCH_CELEBS_SUCCESS:
            return {
                ...state,
                searchCelebsRequest: false,
                searchResults: action.data.match,
            };
        case followingConstants.SEARCH_CELEBS_FAILURE:
            return {
                ...state,
                searchCelebsRequest: false,
            };
            //Multiple Celebs from getCelebsFollowingInfo
        case followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_REQUEST:
            return {
                ...state,
                getSearchFollowingCelebsInfoRequest: true,
            };
        case followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_SUCCESS:
            return {
                ...state,
                getSearchFollowingCelebsInfoRequest: false,
                searchFollowingCelebs: action.data.celebs,
            };
        case followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_FAILURE:
            return {
                ...state,
                getSearchFollowingCelebsInfoRequest: false,
            };
        //Multiple Businesses from get_businesses_from_id
        case followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_REQUEST:
            return {
                ...state,
                getSearchFollowingBusinessesInfoRequest: true,
            };
        case followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_SUCCESS:
            return {
                ...state,
                getSearchFollowingBusinessesInfoRequest: false,
                searchFollowingBusinesses: action.data.businesses,
            };
        case followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_FAILURE:
            return {
                ...state,
                getSearchFollowingBusinessesInfoRequest: false,
            };


        default:
            return state;
    }
}