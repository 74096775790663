export const socialMediaConstants = {
    SOCIAL_MEDIA_REGISTER_REQUEST: 'SOCIAL_MEDIA_REGISTER_REQUEST',
    SOCIAL_MEDIA_REGISTER_SUCCESS: 'SOCIAL_MEDIA_REGISTER_SUCCESS',
    SOCIAL_MEDIA_REGISTER_FAILURE: 'SOCIAL_MEDIA_REGISTER_FAILURE',

    SOCIAL_MEDIA_CONNECT_REQUEST: 'SOCIAL_MEDIA_CONNECT_REQUEST',
    SOCIAL_MEDIA_CONNECT_SUCCESS: 'SOCIAL_MEDIA_CONNECT_SUCCESS',
    SOCIAL_MEDIA_CONNECT_FAILURE: 'SOCIAL_MEDIA_CONNECT_FAILURE',

    GET_INSTAGRAM_ACCESS_TOKEN_REQUEST: 'GET_INSTAGRAM_ACCESS_TOKEN_REQUEST',
    GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS: 'GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS',
    GET_INSTAGRAM_ACCESS_TOKEN_FAILURE: 'GET_INSTAGRAM_ACCESS_TOKEN_FAILURE',

    GET_YOUTUBE_TOKEN_REQUEST: 'GET_YOUTUBE_TOKEN_REQUEST',
    GET_YOUTUBE_TOKEN_SUCCESS: 'GET_YOUTUBE_TOKEN_SUCCESS',
    GET_YOUTUBE_TOKEN_FAILURE: 'GET_YOUTUBE_TOKEN_FAILURE',

    GET_TWITTER_REQUEST_TOKEN_REQUEST: 'GET_TWITTER_REQUEST_TOKEN_REQUEST',
    GET_TWITTER_REQUEST_TOKEN_SUCCESS: 'GET_TWITTER_REQUEST_TOKEN_SUCCESS',
    GET_TWITTER_REQUEST_TOKEN_FAILURE: 'GET_TWITTER_REQUEST_TOKEN_FAILURE',

    SOCIAL_MEDIA_IS_CONNECTED_REQUEST: 'SOCIAL_MEDIA_IS_CONNECTED_REQUEST',
    SOCIAL_MEDIA_IS_CONNECTED_SUCCESS: 'SOCIAL_MEDIA_IS_CONNECTED_SUCCESS',
    SOCIAL_MEDIA_IS_CONNECTED_FAILURE: 'SOCIAL_MEDIA_IS_CONNECTED_FAILURE',

    SOCIAL_MEDIA_IS_REGISTERED_REQUEST: 'SOCIAL_MEDIA_IS_REGISTERED_REQUEST',
    SOCIAL_MEDIA_IS_REGISTERED_SUCCESS: 'SOCIAL_MEDIA_IS_REGISTERED_SUCCESS',
    SOCIAL_MEDIA_IS_REGISTERED_FAILURE: 'SOCIAL_MEDIA_IS_REGISTERED_FAILURE ',



};