export const agencyConstants = {
    AGENCY_QUERY_REQUEST: 'AGENCY_QUERY_REQUEST',
    AGENCY_QUERY_SUCCESS: 'AGENCY_QUERY_SUCCESS',
    AGENCY_QUERY_FAILURE: 'AGENCY_QUERY_FAILURE',
    
    GET_CLIENTS_REQUEST: 'GET_CLIENTS_REQUEST',
    GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
    GET_CLIENTS_FAILURE: 'GET_CLIENTS_FAILURE',

    LOGIN_AS_CLIENT_REQUEST: 'LOGIN_AS_CLIENT_REQUEST',
    LOGIN_AS_CLIENT_SUCCESS: 'LOGIN_AS_CLIENT_SUCCESS',
    LOGIN_AS_CLIENT_FAILURE: 'LOGIN_AS_CLIENT_FAILURE',

    LOGOUT_AS_CLIENT_REQUEST: 'LOGOUT_AS_CLIENT_REQUEST',
    LOGOUT_AS_CLIENT_SUCCESS: 'LOGOUT_AS_CLIENT_SUCCESS',
    LOGOUT_AS_CLIENT_FAILURE: 'LOGOUT_AS_CLIENT_FAILURE',

    UPDATE_PIN_REQUEST: 'UPDATE_PIN_REQUEST',
    UPDATE_PIN_SUCCESS: 'UPDATE_PIN_SUCCESS',
    UPDATE_PIN_FAILURE: 'UPDATE_PIN_FAILURE',

    AUTHORIZE_AGENCY_ACCESS_REQUEST: 'AUTHORIZE_AGENCY_ACCESS_REQUEST',
    AUTHORIZE_AGENCY_ACCESS_SUCCESS: 'AUTHORIZE_AGENCY_ACCESS_SUCCESS',
    AUTHORIZE_AGENCY_ACCESS_FAILURE: 'AUTHORIZE_AGENCY_ACCESS_FAILURE',

    REVOKE_AGENCY_ACCESS_REQUEST: 'REVOKE_AGENCY_ACCESS_REQUEST',
    REVOKE_AGENCY_ACCESS_SUCCESS: 'REVOKE_AGENCY_ACCESS_SUCCESS',
    REVOKE_AGENCY_ACCESS_FAILURE: 'REVOKE_AGENCY_ACCESS_FAILURE',
    
};
