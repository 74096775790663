export const postsConstants = {
    GET_POSTS_REQUEST: 'GET_POSTS_REQUEST',
    GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
    GET_POSTS_FAILURE: 'GET_POSTS_FAILURE',

    CREATE_POST_REQUEST: 'CREATE_POST_REQUEST',
    CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
    CREATE_POST_FAILURE: 'CREATE_POST_FAILURE',

    DELETE_POST_REQUEST: 'DELETE_POST_REQUEST',
    DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
    DELETE_POST_FAILURE: 'DELETE_POST_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    LIKE_COMMENT_REQUEST: 'LIKE_COMMENT_REQUEST',
    LIKE_COMMENT_SUCCESS: 'LIKE_COMMENT_SUCCESS',
    LIKE_COMMENT_FAILURE: 'LIKE_COMMENT_FAILURE',


}
