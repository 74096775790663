import React from "react";
import { trim } from '_helpers';

export function generateFollowerParams(following) {
    let arrayIds = []
    if (following) {
        for (let user = 0; user <following.length; user++) {
            const id = following[user].followed_id
            // console.log("id", id )
            arrayIds.push(id)
            
                }
            
        }
    let result = arrayIds.join()
    return result    
}

export function getMaxFollowers(followers, addPadding=true) {
    let facebook = followers.Facebook
    let instagram = followers.Instagram
    let twitter = followers.Twitter
    let youtube = followers.Youtube
  
    const max = Math.max(facebook, instagram, twitter, youtube)
  
    if (facebook === max) {
      return (
        <div className={"text-left " + (addPadding ? "pt-2 mt-1" : "") } >
          <i className="fab fa-facebook" style={{color:'#1877f2', fontSize:'16px', transform:'translateY(1px)'}} />
          &nbsp;&nbsp;
      <small>{trim(facebook)} </small>
      </div>
      )
    } else if (instagram === max)  {
      return (
        <div className={"text-left " + (addPadding ? "pt-2 mt-1" : "") } >
          <i className="fab fa-instagram"  style={{color:'rgb(40, 40, 40)', fontSize:'16px', transform:'translateY(1px)'}} />
          &nbsp;&nbsp;
          <small>{trim(instagram)} </small>
      </div>
      )
    } else if (twitter === max)  {
      return (
        <div className={"text-left " + (addPadding ? "pt-2 mt-1" : "") } >
          <i className="fab fa-twitter" style={{color:'rgb(85, 172, 238)',fontSize:'16px', transform:'translateY(1px)'}} />
          &nbsp;&nbsp;
        <small>{trim(twitter)} </small>
      </div>
      )
    } else if (youtube === max)  {
      return (
        <div className={"text-left " + (addPadding ? "pt-2 mt-1" : "") } >
          <i className="fab fa-youtube" style={{color:'rgb(255, 23, 23)',fontSize:'16px', transform:'translateY(1px)'}} />
          &nbsp;&nbsp;
        <small>{trim(youtube)} </small>
      </div>
      )
    } else {
      console.log("no max", facebook, instagram, twitter, youtube, max)
      return <span/>
    }
  }
  
  