export const hmpgTmplConstants = {
    GET_TMPL_TYPES_REQUEST: 'GET_TMPL_TYPES_REQUEST',
    GET_TMPL_TYPES_SUCCESS: 'GET_TMPL_TYPES_SUCCESS',
    GET_TMPL_TYPES_FAILURE: 'GET_TMPL_TYPES_FAILURE',

    GET_USER_TEMPLATE_REQUEST: 'GET_USER_TEMPLATE_REQUEST',
    GET_USER_TEMPLATE_SUCCESS: 'GET_USER_TEMPLATE_SUCCESS',
    GET_USER_TEMPLATE_FAILURE: 'GET_USER_TEMPLATE_FAILURE',

    GET_MEMBER_ID_REQUEST: 'GET_MEMBER_ID_REQUEST',
    GET_MEMBER_ID_SUCCESS: 'GET_MEMBER_ID_SUCCESS',
    GET_MEMBER_ID_FAILURE: 'GET_MEMBER_ID_FAILURE',

    SET_USERNAME_REQUEST: 'SET_USERNAME_REQUEST',
    SET_USERNAME_SUCCESS: 'SET_USERNAME_SUCCESS',
    SET_USERNAME_FAILURE: 'SET_USERNAME_FAILURE',

    UPDATE_USERNAME_REQUEST: 'UPDATE_USERNAME_REQUEST',
    UPDATE_USERNAME_SUCCESS: 'UPDATE_USERNAME_SUCCESS',
    UPDATE_USERNAME_FAILURE: 'UPDATE_USERNAME_FAILURE',

    GET_USERNAME_REQUEST: 'GET_USERNAME_REQUEST',
    GET_USERNAME_SUCCESS: 'GET_USERNAME_SUCCESS',
    GET_USERNAME_FAILURE: 'GET_USERNAME_FAILURE',

}
