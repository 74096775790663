import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {  notificationActions,  } from '_actions';
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  Button,
  ListGroup,
  ListGroupItem,

} from "reactstrap";
// react plugin used to create charts
// import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
import * as moment from 'moment';

import {
  LoadingCard,
  // Progress
} from "components";

// import { table_data } from "variables/general.jsx";

class CelebrityBidNotifications extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hTabs: "ht1",
    };
    this.toggleAndMarkNotification = this.toggleAndMarkNotification.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(notificationActions.getBidNotifications());
  }

  markNotificationAsRead(notificationId) {
    this.props.dispatch(notificationActions.markNotificationAsRead(notificationId, () => {
      this.props.dispatch(notificationActions.getBidNotifications())
    })
    )
  }

  deleteNotification(notificationId) {
    this.props.dispatch(notificationActions.deleteNotification(notificationId, () => {
      this.props.dispatch(notificationActions.getBidNotifications())
    })
    )
  }

  toggleAndMarkNotification(notificationId) {
    const { toggleModal } = this.props
    this.props.dispatch(notificationActions.markNotificationAsRead(notificationId, () => {
      this.props.dispatch(notificationActions.getBidNotifications())
    })
    )
    if (toggleModal) {
      toggleModal()
    }
    
  }

  render() {

      const { fetchingNotifications, notifications, toggleModal  } = this.props;

    console.log("state/props", this.state, this.props)
    if (fetchingNotifications === undefined || fetchingNotifications ) {
      return(
        <LoadingCard/>    
        )
    }

    const unreadNotifications = notifications && notifications.length > 0 && notifications.filter( notification => (notification.state === 'unread' && notification.type === "SPECIALIST_EVENT") ) ? 
    notifications.filter( notification => (notification.state === 'unread'  && notification.type === "SPECIALIST_EVENT") ) : []
    const readNotifications = notifications && notifications.length > 0 && notifications.filter( notification => (notification.state === 'read'  && notification.type === "SPECIALIST_EVENT") ) ? 
    notifications.filter( notification => (notification.state === 'read'  && notification.type === "SPECIALIST_EVENT") ) : []

    return (
      <div>
          {fetchingNotifications === false && notifications !== undefined && notifications !== null && 
            <ModalBody>
              <Nav
                  pills
                  className="nav-pills-primary justify-content-center"
                >
                <NavItem className="cursor-pointer">
                  <NavLink
                    className={this.state.hTabs === "ht1" ? "active" : ""}
                    onClick={() => this.setState({ hTabs: "ht1" })}
                  >
                    Unread
                  </NavLink>
                </NavItem>
                <NavItem className="cursor-pointer">
                  <NavLink
                      className={this.state.hTabs === "ht2" ? "active" : ""}
                      onClick={() => this.setState({ hTabs: "ht2" })}
                  >
                    Read
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                className="tab-space"
                activeTab={this.state.hTabs}
                style={{marginTop:"5px"}}
              >
                <TabPane tabId="ht1">
                  <ListGroup flush>
                    {unreadNotifications && unreadNotifications.map( (notification, key) =>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        key={key}
                      >
                      <Row className="align-items-center">
                      <Link onClick={() => this.toggleAndMarkNotification(notification.notification_id)} to={ {pathname: `/dash/celeb-event/`, 
                                    state: { eventId: notification.event_id, businessId: notification.business_id, bidId: notification.bid_id, schId: notification.sch_id } 
                                  }}>                                   
                        <Col className="col-auto">
                            <img
                              alt="..."
                              className="avatar"
                              src={notification.image_url} 
                            />
                          </Col>
                        </Link>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <Col xs={6} sm={6} md={6} lg={9} style={{paddingLeft:'0', paddingRight:"0"}}>
                              <Link onClick={() => this.toggleAndMarkNotification(notification.notification_id)} to={ {pathname: `/dash/celeb-event/`, 
                                    state: { eventId: notification.event_id, businessId: notification.business_id, bidId: notification.bid_id, schId: notification.sch_id } 
                                  }}>       
                                <h4 className="mb-0 text-sm text-default" >{notification.title} </h4>
                                <p className="text-sm mb-0 ">
                                  <span className="font-weight-light text-gray-medium">{notification.message} </span>
                                </p>
                              </Link>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3} className="text-right" style={{paddingRight:"0", paddingLeft:"0"}}>
                              <small className="text-muted">{moment(notification.modified).fromNow()}</small>
                              <br/>
                              {notification.state === "unread" &&                     
                                <Button style={{marginTop:'5px'}} className={"icon " + (this.props.is_xs ? "icon-sm " : "") + "icon-shape rounded-circle"}  onClick={() => this.markNotificationAsRead(notification.notification_id)} color="primary">
                                  <i className="ni ni-check-bold text-white" ></i>                            
                                </Button>}
                              &nbsp;&nbsp;
                              <Button style={{marginTop:'5px'}} className={"icon " + (this.props.is_xs ? "icon-sm " : "") + "icon-shape rounded-circle"}  onClick={() => this.deleteNotification(notification.notification_id)} color="danger">
                                <i className="ni ni-fat-remove text-white" style={{fontSize:"1.5rem"}} />                    
                              </Button>
                            </Col>
                          </div>
                        </div>
                      </Row>                      
                    </ListGroupItem>
                    )}
                </ListGroup>
                </TabPane>
                <TabPane tabId="ht2">
                  <ListGroup flush>
                    {readNotifications && readNotifications.map( (notification, key) =>
                      <ListGroupItem
                        className="list-group-item-action"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        key={key}
                      >
                      <Row className="align-items-center">
                        <Link onClick={toggleModal} to={ {pathname: `/dash/celeb-event/`, 
                                    state: { eventId: notification.event_id, businessId: notification.business_id, bidId: notification.bid_id, schId: notification.sch_id } 
                                  }}>                            
                          <Col className="col-auto">
                              <img
                                alt="..."
                                className="avatar"
                                src={notification.image_url} 
                              />
                          </Col>
                        </Link>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <Col xs={6} sm={6} md={6} lg={9} style={{paddingLeft:'0', paddingRight:"0"}}>
                            <Link onClick={toggleModal} to={ {pathname: `/dash/celeb-event/`, 
                                    state: { eventId: notification.event_id, businessId: notification.business_id, bidId: notification.bid_id, schId: notification.sch_id } 
                                  }}>                                         
                                <h4 className="mb-0 text-sm text-default" >{notification.title} </h4>
                                <p className="text-sm mb-0 ">
                                  <span className="font-weight-light text-gray-medium">{notification.message} </span>
                                </p>
                              </Link>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3} className="text-right" style={{paddingRight:"0", paddingLeft:"0"}}>
                              <small className="text-muted">{moment(notification.modified).fromNow()}</small>
                              <br/>
                              <Button style={{marginTop:'5px'}} size="sm" className="icon icon-shape rounded-circle" onClick={() => this.deleteNotification(notification.notification_id)} color="danger">
                                <i className="ni ni-fat-remove text-white" style={{fontSize:"1.5rem"}} />                    
                              </Button>
                            </Col>
                          </div>
                        </div>
                      </Row>                      
                    </ListGroupItem>
                    )}
                </ListGroup>
              </TabPane>
            </TabContent>
          </ModalBody>
       }
      </div>
    );
  }
}

//export {BusinessDashboard} ;
function mapStateToProps(state) {
  const {  notificationsData, screenSizeData } = state;
  const { notifications, fetchingNotifications } = notificationsData;
  const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;

  console.log("BusinessBidNotifications: mapStateToProps");
  console.log(notifications)
  return {    
      fetchingNotifications, notifications,
      is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  
  };
}

const connectedCelebrityBidNotifications= connect(mapStateToProps)(CelebrityBidNotifications);
export { connectedCelebrityBidNotifications as CelebrityBidNotifications };

