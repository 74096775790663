import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class BusinessPaymentsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }

  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("BusinessPaymentsHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
              <CardBody className="text-left">
                <h4>Payout Account</h4>
                Connect a debit card or bank account to receive ticket revenue when fans buy tickets for your events. Your payout information will be held secured securely by <i>Stripe</i> . In order to create events and sell tickets, you need to have a Payout Account set up.
                <br/><br/>
                <h4>Payout Schedule</h4>
                Ticket Sale Revenue is typically scheduled to deposit into your bank account or debit card, 3 days after the associated event. 
                <br/><br/>
                <b>*Please Note:</b> Your first payout's processing times are increased for security and verification purposes, which may delay the first payout by a few days.
                <br/><br/>
                <h4>Connected Payment Methods</h4>
                Add credit and/or debit cards to pay for booking fees. If you are a winner when the auction closes, we will attempt to charge your preferred payment method for the winning booking price.
                <br/><br/>
                If you're bid is a winner and your primary payment method has insufficient funds when an auciton closes, your other cards are charged. If none of your cards can pay for the booking fees, your bid is no longer a winner and you lose the auction. 
                Your card(s) are charged in a similar manner when a "Book Now" is performed, except the charge occurs immediately--not when the auction closes.
                <br/><br/>
                If a "Book Now" fails due to insufficent funds, your bid is not deleted. Instead, it is put into an <i>Insufficient Funds</i> state.  
                Please update your payment methods to ensure that you have the appropriate available credit for <i> Book Now </i>, and then retry <i>Book Now </i>.
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future. </i>
              </CardBody>
    
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedBusinessPaymentsHelpModal = connect(mapStateToProps)(BusinessPaymentsHelpModal);
export { connectedBusinessPaymentsHelpModal as BusinessPaymentsHelpModal }; 