import { traitConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from 'helpers';

export const traitActions = {
    getSpecialistTraits,
    getBusinessTraits,
    getUserTraits,
};


function getSpecialistTraits(callback = null) {

    const params = {
        request: 'specialist_trait_query',
      };
  
    console.log("getSpecialistTraits", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getSpecialistTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: traitConstants.SPECIALIST_TRAITS_QUERY_REQUEST} }
    function success(data)   { return { type: traitConstants.SPECIALIST_TRAITS_QUERY_SUCCESS, data } }
    function failure(error)  { return { type: traitConstants.SPECIALIST_TRAITS_QUERY_FAILURE, error } }
}

function getBusinessTraits(callback = null) {

    const params = {
        request: 'get_business_trait_types',
      };
  
    console.log("getBusinessTraits", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBusinessTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: traitConstants.BUSINESS_TRAITS_QUERY_REQUEST} }
    function success(data)   { return { type: traitConstants.BUSINESS_TRAITS_QUERY_SUCCESS, data } }
    function failure(error)  { return { type: traitConstants.BUSINESS_TRAITS_QUERY_FAILURE, error } }
}

function getUserTraits(callback = null) {

    const params = {
        request: 'get_user_trait_types',
      };
  
    console.log("getUserTraits", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getUserTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: traitConstants.USER_TRAITS_QUERY_REQUEST} }
    function success(data)   { return { type: traitConstants.USER_TRAITS_QUERY_SUCCESS, data } }
    function failure(error)  { return { type: traitConstants.USER_TRAITS_QUERY_FAILURE, error } }
}
