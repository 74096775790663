export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',


    REGISTERED_USER_REQUEST: 'REGISTERED_USER_REQUEST',
    REGISTERED_USER_SUCCESS: 'REGISTERED_USER_SUCCESS',
    REGISTERED_USER_FAILURE: 'REGISTERED_USER_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    GET_FACEBOOK_ACCESS_TOKEN_REQUEST: 'GET_FACEBOOK_ACCESS_TOKEN_REQUEST',
    GET_FACEBOOK_ACCESS_TOKEN_SUCCESS: 'GET_FACEBOOK_ACCESS_TOKEN_SUCCESS',
    GET_FACEBOOK_ACCESS_TOKEN_FAILURE: 'GET_FACEBOOK_ACCESS_TOKEN_FAILURE',

    GET_LINKEDIN_ACCESS_TOKEN_REQUEST: 'GET_LINKEDIN_ACCESS_TOKEN_REQUEST',
    GET_LINKEDIN_ACCESS_TOKEN_SUCCESS: 'GET_LINKEDIN_ACCESS_TOKEN_SUCCESS',
    GET_LINKEDIN_ACCESS_TOKEN_FAILURE: 'GET_LINKEDIN_ACCESS_TOKEN_FAILURE',

    GET_APPLE_ACCESS_TOKEN_REQUEST: 'GET_APPLE_ACCESS_TOKEN_REQUEST',
    GET_APPLE_ACCESS_TOKEN_SUCCESS: 'GET_APPLE_ACCESS_TOKEN_SUCCESS',
    GET_APPLE_ACCESS_TOKEN_FAILURE: 'GET_APPLE_ACCESS_TOKEN_FAILURE',

    GET_YELP_DATA_REQUEST: 'GET_YELP_DATA_REQUEST',
    GET_YELP_DATA_SUCCESS: 'GET_YELP_DATA_SUCCESS',
    GET_YELP_DATA_FAILURE: 'GET_YELP_DATA_FAILURE',
    SET_SELECTED_YELP_DATA: 'SET_SELECTED_YELP_DATA',

    CLEAR_REDUX_STORE: 'CLEAR_REDUX_STORE',
    LOGOUT_CLEAR_STORE: 'LOGOUT_CLEAR_STORE',

    TERMS_ACCEPTED_REQUEST: 'TERMS_ACCEPTED_REQUEST',
    TERMS_ACCEPTED_SUCCESS: 'TERMS_ACCEPTED_SUCCESS',
    TERMS_ACCEPTED_FAILURE: 'TERMS_ACCEPTED_FAILURE',

    EXPIRE_SESSION: 'EXPIRE_SESSION',

    // GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    // GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    // GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    // DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    // DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    // DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};
