import { featuredConstants } from '_constants';

const initialState = {};

export function featuredData(state = initialState, action) {
    switch(action.type) {
        // featured celebrity query
        case featuredConstants.FEATURED_CELEBS_REQUEST:
            return {
                ...state,
                fetchingfeaturedCelebsInfo: true,
            };
        case featuredConstants.FEATURED_CELEBS_SUCCESS:
            return {
                ...state,
                fetchingfeaturedCelebsInfo: false,
                featuredCelebs: action.data.celebs,
            };
        case featuredConstants.FEATURED_CELEBS_FAILURE:
            return {
                ...state,
                fetchingfeaturedCelebsInfo: false,
            };
        default:
            return state;
    }            
}
