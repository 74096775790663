export const locationConstants = {
    GET_LOCATIONS_REQUEST: 'GET_LOCATIONS_REQUEST',
    GET_LOCATIONS_SUCCESS: 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_FAILURE: 'GET_LOCATIONS_REQUEST',

    CREATE_UPDATE_LOCATION_REQUEST: 'CREATE_UPDATE_LOCATION_REQUEST',
    CREATE_UPDATE_LOCATION_SUCCESS: 'CREATE_UPDATE_LOCATION_SUCCESS',
    CREATE_UPDATE_LOCATION_FAILURE: 'CREATE_UPDATE_LOCATION_FAILURE',

    DELETE_LOCATION_REQUEST: 'DELETE_LOCATION_REQUEST',
    DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
    DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_REQUEST',

    GET_CURRENT_LOCATION_REQUEST: 'GET_CURRENT_LOCATION_REQUEST',
    GET_CURRENT_LOCATION_SUCCESS: 'GET_CURRENT_LOCATION_SUCCESS',
    GET_CURRENT_LOCATION_FAILURE: 'GET_CURRENT_LOCATION_FAILURE',

}