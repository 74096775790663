import { cookiePreferencesConstants } from '_constants';
const initialState =  {cookiePreferencesUpdated: false } 

export function cookiePreferencesData(state = initialState, action) {
    switch(action.type) {

        case cookiePreferencesConstants.COOKIE_PREFERENCES_SET_REQUEST:
            return {
                ...state,
                cookiePreferencesSet: true,
                cookiePreferencesUnset: false,

            }
        case cookiePreferencesConstants.COOKIE_PREFERENCES_SET_SUCCESS:
            return {
                ...state,
                cookiePreferencesSet: true,
                cookiePreferencesUnset: false,
            }
        case cookiePreferencesConstants.COOKIE_PREFERENCES_UNSET_REQUEST:
            return {
                ...state,
                cookiePreferencesSet: false,
                cookiePreferencesUnset: true,
            }
        case cookiePreferencesConstants.COOKIE_PREFERENCES_UNSET_SUCCESS:
            return {
                ...state,
                cookiePreferencesSet: false,
                cookiePreferencesUnset: true,
            }
        default:
            return state;   
        }
        
        
    }
        