import { feeConstants } from '_constants';

const initialState = {};

export function feesData(state = initialState, action) {
    switch(action.type) {
        // Get Fees
        case feeConstants.GET_FEES_REQUEST:
            return {
                ...state,
                fetchingFees: true,
            };
        case feeConstants.GET_FEES_SUCCESS:
            return {
                ...state,
                fetchingFees: false,
                fees: action.fees,
            };
        case feeConstants.GET_FEES_FAILURE:
            return {
                ...state,
                fetchingFees: false,
            };
        default:
            return state;
    }    
}
