import { locationConstants } from '_constants';
import { userService } from '_services';
import { config} from '_constants';
import { userActions } from '_actions';

export const locationActions = {
    getLocations,
    createUpdateLocation,
    deleteLocation,
    getCurrentLocation,
};

function getLocations(callback = null) {
    const params = {
        request: 'locations_query',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data.locations));
                    if (callback) {
                        callback(data.locations)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getLocations: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: locationConstants.GET_LOCATIONS_REQUEST} }
    function success(data)  { return { type: locationConstants.GET_LOCATIONS_SUCCESS, data } }
    function failure(error) { return { type: locationConstants.GET_LOCATIONS_FAILURE, error } }
}

function createUpdateLocation(name, create, locationId, address, geoLocation, distance, callback = null) {
    let params = {
        create: create ? 1 : 0,
        request: 'location_create_update',
        name: name,
        address: address,        
        latitude: geoLocation.lat,
        longitude: geoLocation.lng,
        locality: geoLocation.locality,
        administrative_area: geoLocation.adminArea,
        sub_administrative_area: geoLocation.subAdminArea,
        country: geoLocation.country,
        distance: distance,
    }
    if (!create) {
        params['location_id'] = locationId
    }
    console.log("location id", locationId)

    console.log("location params", params)

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getLocations())
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateLocation: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: locationConstants.CREATE_UPDATE_LOCATION_REQUEST} }
    function success(data)  { return { type: locationConstants.CREATE_UPDATE_LOCATION_SUCCESS, data } }
    function failure(error) { return { type: locationConstants.CREATE_UPDATE_LOCATION_FAILURE, error } }

}

function deleteLocation(locationName, callback = null) {
    const params = {
        request: 'location_delete',
        location: locationName,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getLocations())
                    if (callback !== null ) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteLocation: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: locationConstants.DELETE_LOCATION_REQUEST} }
    function success(data)  { return { type: locationConstants.DELETE_LOCATION_SUCCESS, data } }
    function failure(error) { return { type: locationConstants.DELETE_LOCATION_FAILURE, error } }

}


function getCurrentLocation(callback = null) {
    // Note: This example requires that you consent to location sharing when
    // prompted by your browser. If you see the error "The Geolocation service
    // failed.", it means you probably did not give permission for the browser to
    // locate you.

    // Try HTML5 geolocation.
    const apiKey = "ac1607783d9847c6a5087c31ca4c8f66"
    const url2 = `https://api.ipify.org?format=json`
    return dispatch => {
        dispatch(request());
        if (navigator.geolocation) {
            console.log("_Test A")
            const request = require("request");
            ;
            navigator.geolocation.getCurrentPosition(function(position) {
              
                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                let ip = null 

                request({
                    url: url2,
                    json: true
                }, function (error1, response1, body1) {
                    if (!error1 && response1.statusCode === 200) {
                        console.log("_Test B")
                        ip = body1.ip
                        const url = `https://api.findip.net/${ip}/?token=${apiKey}`
                        request({
                            url: url,
                            json: true
                        }, function (error, response, body) {
                            if (!error && response.statusCode === 200) {
                                console.log("_Test C")
                                console.log("response-geojp", body); 
                                pos["country_code"] = body.country.iso_code
                            } else {
                              pos["country_code"] = "US"
                            }
                            dispatch(success(pos));
                            if (callback) { 
                                console.log("_Test D")
                                callback(true, pos); 
                            }
                        });
                    }
                });

                // console.log("getCurrentLocation (success): ", pos)
                //   request({
                //       url: url,
                //       json: true
                //   }, function (error, response, body) {
                //       if (!error && response.statusCode === 200) {
                //           console.log("response-geojp", body); 
                //           pos["country_code"] = body.country.iso_code
                //       } else {
                //         pos["country_code"] = "US"
                //       }
                //       dispatch(success(pos));
                //       if (callback) { callback(true, pos); }
                //   });
            }, function(error) {
                console.log("getCurrentLocation (failure): ", error)
                request({
                    url: url2,
                    json: true
                }, function (error1, response1, body1) {
                    let ip = null 
                    if (!error1 && response1.statusCode === 200) {
                        console.log("_Test E")
                        ip = body1.ip
                        const url = `https://api.findip.net/${ip}/?token=${apiKey}`
                        request({
                            url: url,
                            json: true
                        }, function (error, response, body) {
                            let pos = {};
                            if (!error && response.statusCode === 200) {
                                console.log("_Test F")
                                console.log("response-geojp", body); 
                                pos["country_code"] = body.country.iso_code
                                pos["lat"] = body.location.latitude
                                pos["lng"] = body.location.longitude
                            } else {
                                pos = config.defaultLocation
                                pos["country_code"] = "US"
                            }
                            dispatch(failure(pos));
                            if (callback) {callback(true, pos)}
                        })
                    }
                });

                // request({
                //     url: url,
                //     json: true
                // }, function (error, response, body) {
                //     let pos = {};
                //     if (!error && response.statusCode === 200) {
                //         console.log("response-geojp", body); 
                //         pos["country_code"] = body.country.iso_code
                //         pos["lat"] = body.location.latitude
                //         pos["lng"] = body.location.longitude
                //     } else {
                //         pos = config.defaultLocation
                //         pos["country_code"] = "US"
                //     }
                //     dispatch(failure(pos));
                //     if (callback) {callback(false, pos)}
                // });

            // handleLocationError(true);
            });
        } else {
            // Browser doesn't support Geolocation
            const request = require("request");
            console.log("_Test G")
            // console.log("getCurrentLocation (failure): ", config.defaultLocation)
            request({
                url: url2,
                json: true
            }, function (error1, response1, body1) {
                let ip = null 
                if (!error1 && response1.statusCode === 200) {
                    console.log("_Test h")
                    ip = body1.ip
                    const url = `https://api.findip.net/${ip}/?token=${apiKey}`
                    request({
                        url: url,
                        json: true
                    }, function (error, response, body) {
                        let pos = {};
                        if (!error && response.statusCode === 200) {
                            console.log("_Test I")
                            console.log("response-geojp", body); 
                            pos["country_code"] = body.country.iso_code
                            pos["lat"] = body.location.latitude
                            pos["lng"] = body.location.longitude
                        } else {
                            pos = config.defaultLocation
                            pos["country_code"] = "US"
                        }
                        dispatch(failure(pos));
                        if (callback) {callback(true, pos)}
                    })
                }
            });
            // request({
            //     url: url,
            //     json: true
            // }, function (error, response, body) {
            //     let pos = {};
            //     if (!error && response.statusCode === 200) {
            //         console.log("response-geojp", body); 
            //         pos["country_code"] = body.country.iso_code
            //         pos["lat"] = body.location.latitude
            //         pos["lng"] = body.location.longitude
            //     } else {
            //         pos = config.defaultLocation
            //         pos["country_code"] = "US"
            //     }
            //     dispatch(failure(pos));
            //     if (callback) {callback(false, pos)}
            // })
            //handleLocationError(false);
        }
    }

    // function handleLocationError(browserHasGeolocation) {
    //     callback( {lat: 37.255932699999995, lng: -122.03605979999999} )
    // }
    function request()      { return { type: locationConstants.GET_CURRENT_LOCATION_REQUEST} }
    function success(data)  { return { type: locationConstants.GET_CURRENT_LOCATION_SUCCESS, data } }
    function failure(data)  { return { type: locationConstants.GET_CURRENT_LOCATION_FAILURE, data } }
}
