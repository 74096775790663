export const notificationMessageConstants = {
    SET_NOTIFICATION_MESSAGE_REQUEST: 'SET_NOTIFICATION_MESSAGE_REQUEST',
    SET_NOTIFICATION_MESSAGE_SUCCESS: 'SET_NOTIFICATION_MESSAGE_SUCCESS',
    SET_NOTIFICATION_MESSAGE_FAILURE: 'SET_NOTIFICATION_MESSAGE_FAILURE',

    CLEAR_NOTIFICATION_MESSAGE_REQUEST: 'CLEAR_NOTIFICATION_MESSAGE_REQUEST',
    CLEAR_NOTIFICATION_MESSAGE_SUCCESS: 'CLEAR_NOTIFICATION_MESSAGE_SUCCESS',
    CLEAR_NOTIFICATION_MESSAGE_FAILURE: 'CLEAR_NOTIFICATION_MESSAGE_FAILURE',
    
    }
    