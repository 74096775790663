import { scheduleConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';
import * as moment from 'moment';

export const scheduleActions = {
    getSchedules,
    createUpdateSchedule,
    deleteSchedule,
    getTemplates,
    createUpdateDeleteTemplate,
};

function getSchedules(dates = null, callback = null) {
    const params = {
        request: 'specialist_schedule_list',
      };
    if (dates !== null) {
        params['start_date'] = dates.startDate.format('Y-MM-DD')
        if (dates.hasOwnProperty('endDate')) {
            params['end_date'] = dates.endDate.format('Y-MM-DD')
        } else {
            params['end_date'] = dates.startDate.add(1, 'years').format('Y-MM-DD');
        }
    } else {
        params['start_date'] = moment().format('Y-MM-DD');
        params['end_date']   = moment().add(1, 'years').format('Y-MM-DD');
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Schedules", data);
                    dispatch(success(data.schedules));
                    if (callback !== null) {
                        callback(true, data.schedules)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getSchedules: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: scheduleConstants.GET_SCHEDULES_REQUEST} }
    function success(data)   { return { type: scheduleConstants.GET_SCHEDULES_SUCCESS, data } }
    function failure(error)  { return { type: scheduleConstants.GET_SCHEDULES_FAILURE, error } }
}

function getTemplates(callback = null) {
    const params = {
        request: 'specialist_template_list',
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Templates", data);
                    dispatch(success(data.templates));
                    if (callback !== null) {
                        callback(true, data.templates)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTemplates: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: scheduleConstants.GET_TEMPLATES_REQUEST} }
    function success(data)   { return { type: scheduleConstants.GET_TEMPLATES_SUCCESS, data } }
    function failure(error)  { return { type: scheduleConstants.GET_TEMPLATES_FAILURE, error } }
}

function createUpdateSchedule(args, callback = null) {
    const params = {
        request: 'specialist_schedule',
        create: args.create ? 1 : 0,
        location: args.locationName,
        start_time: args.startTime,
        end_time: args.endTime,
        buffer_time: args.bufferTime,
        rate: args.rate,
        book_now: args.bookNow,
        prv_rate: args.privateRate,
        prv_book_now: args.privateBookNow,
        approve_bids: args.approveBids,
        lockout: args.lockout,
    }
    if (args.create === false) {
        params['schedule_id'] = args.scheduleId
    }
    if (args.hasOwnProperty('maxEvents') && args.maxEvents !== '') {
        params['max_events'] = args.maxEvents
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Create/Update Schedule", data);
                    dispatch(success(data));
                    dispatch(getSchedules())
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateSchedule: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: scheduleConstants.CREATE_UPDATE_SCHEDULE_REQUEST} }
    function success(data)   { return { type: scheduleConstants.CREATE_UPDATE_SCHEDULE_SUCCESS, data } }
    function failure(error)  { return { type: scheduleConstants.CREATE_UPDATE_SCHEDULE_FAILURE, error } }
}

function createUpdateDeleteTemplate(args, callback = null) {
    const params = {
        request: 'specialist_template',
        tmpl_name: args.templateName,
        location: args.locationName,
        start_time: args.startTime,
        end_time: args.endTime,
        buffer_time: args.bufferTime,
        rate: args.rate,
        book_now: args.bookNow,
        prv_rate: args.privateRate,
        prv_book_now: args.privateBookNow,
        approve_bids: args.approveBids,
        lockout: args.lockout,
    }
    if (args.hasOwnProperty('create')) {
        params['create'] = args.create
    } else if (args.hasOwnProperty('delete')) {
        params['delete'] = args.delete
    }
    if (args.hasOwnProperty('oldTemplateName')) {
        params['old_tmpl_name'] = args.oldTemplateName
    }
    if (args.hasOwnProperty('maxEvents')) {
        params['max_events'] = args.maxEvents
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Create/Update/Delete Template", data);
                    dispatch(success(data));
                    dispatch(getTemplates())
                    
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateDeleteTemplate: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_REQUEST} }
    function success(data)   { return { type: scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_SUCCESS, data } }
    function failure(error)  { return { type: scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_FAILURE, error } }
}

function deleteSchedule(schId, callback = null) {
    const params = {
        request: 'specialist_delete_schedule',
        sch_id: schId,
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Delete Schedule", data);
                    dispatch(success(schId));
                    dispatch(getSchedules())
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteSchedule: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: scheduleConstants.DELETE_SCHEDULE_REQUEST} }
    function success(schId)   { return { type: scheduleConstants.DELETE_SCHEDULE_SUCCESS, schId } }
    function failure(error)  { return { type: scheduleConstants.DELETE_SCHEDULE_FAILURE, error } }

}