import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Row,
  Col,
  ListGroupItem,
  ListGroup,

} from "reactstrap";
// import { history } from '_helpers';
import { Link } from 'react-router-dom';
import { numberFunction, parseProductPrice, trimProductSubscribers } from '_helpers';

import {  Button, } from "components";
// import { Redirect } from "react-router-dom";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { locationActions } from '_actions';
import defaultAvatar from "assets/img/default-avatar.png";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    const {selectedProduct, } = props.props

    this.state = {
        product: selectedProduct,

    };
    this.props.dispatch(locationActions.getCurrentLocation( (success, pos) => {
        console.log("my location is", pos)
      }))

  }

  componentDidMount() {

  }

  render() {
    const { getCurrentLocation, currentLocation, subscriptions, product } = this.props;

    return (
            <Card className="card-pricing bg-gradient-tertiary border-0 text-left mb-4">
                <CardHeader className="bg-transparent">
                    <Row>
                    <Col xs="10" md="9" > 
                        <h6 className="surtitle text-lighter">{product.product_type} • {trimProductSubscribers(product.subscriber_count)} </h6>
                    </Col>
                    <Col className="text-right" xs={2} md="3">
                    </Col>
                    <Col xs="12">
                        <h3 className="text-white pt-1">
                                {product.name}
                        </h3>
                        <h5 className="text-lighter font-weight-light">{product.description}</h5>
                    </Col>
                    </Row>
                    {/* <Row>
                    <Col xs="12">
                        <h4 className="text-white py-3 mb-0">
                            {product.name}
                            </h4>
                    </Col>
                    </Row> */}
                </CardHeader>
                {/* {
                <Row className="border-0">
                    <Col xs="12">
                        <h6 className="surtitle text-lighter text-center">Trial Available</h6>
                    </Col>
                </Row>
                } */}
                <CardFooter className="bg-transparent">
                    <ListGroup className="list my--3 bg-transparent" flush>
                    <ListGroupItem className="px-0 bg-transparent">
                        <Row className="align-items-center bg-transparent">
                        <Col className="col-auto">
                            <Link to={ { pathname: `/dash/c_profile/${numberFunction(product.owner_id)}`, 
                                state: {sp_id: product.owner_id, }} }>
                            <img
                                alt="..."
                                src={product.creator_photo}
                                className="avatar rounded-circle"
                                onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                            />
                            </Link>

                        </Col>
                        <div className="col ml--2">
                            <h4 className="mb-0">
                            <Link to={ { pathname: `/dash/c_profile/${numberFunction(product.owner_id)}`, 
                                    state: {sp_id: product.owner_id, }} }>
                                <span className="text-white">{product.creator_name}</span>
                            </Link>
                            </h4>
                            {/* <span className="text-success mr-1">●</span> */}
                            {product.creator_traits && product.creator_traits.length > 0 && 
                            <small className="text-lighter">{Object.keys(product.creator_traits[0]) }</small>
                            }
                        </div>
                        <Col className="col-auto">
                            <h6 className="surtitle text-lighter">{subscriptions && subscriptions.some(a => a.product_id === product.id) ? "Subscribed" :"Starting at"}</h6>
                            <div className="mt-1 text-center"> 
                            {!getCurrentLocation && currentLocation.hasOwnProperty('country_code') && parseProductPrice(currentLocation.country_code, product.price_range) &&
                            <Link to={ { pathname: `/dash/marketplaceProduct/${product.id}`, 
                            state: {productId: product.id, product: product, }} }>
                                <Button color="spc-white" outline size="sm" type="button">
                                
                                { subscriptions && subscriptions.some(a => a.product_id === product.id) ? "Go" : parseProductPrice(currentLocation.country_code, product.price_range)}
                                </Button>
                            </Link>
                            } 
                            </div>
                            {/* <h6 className="surtitle text-lighter">Trial Available</h6> */}
                        </Col>
                        </Row>
                    </ListGroupItem>
                    </ListGroup>
                </CardFooter>
                </Card>
    );
  }
}

export default ProductCard 