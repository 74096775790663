export const caseConstants = {

    GET_CASE_CODES_REQUEST: 'GET_CASE_CODES_REQUEST',
    GET_CASE_CODES_SUCCESS: 'GET_CASE_CODES_SUCCESS',
    GET_CASE_CODES_FAILURE: 'GET_CASE_CODES_FAILURE',

    REPORT_NEW_CASE_REQUEST: 'REPORT_NEW_CASE_REQUEST',
    REPORT_NEW_CASE_SUCCESS: 'REPORT_NEW_CASE_SUCCESS',
    REPORT_NEW_CASE_FAILURE: 'REPORT_NEW_CASE_FAILURE',
}
