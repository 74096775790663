import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    CardImg,
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod} from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";


class NearbyEventForCelebCard  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {
    const event = this.props.selectedEvent

    return (
                <Link
                    to={ {pathname: `/dash/event/${event.event_id}`, 
                    // state: { eventId: event.event_id}
                    }}
                  >
                    <Card>
                      <CardImg
                      alt="..."
                      src={event.image_url}
                      onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
                      top
                    />
                      <CardBody style={{paddingBottom:'4px'}}>
                        <CardTitle className="h2 mb-0">
                        {event.event_name}
                        </CardTitle>
                        <h6 className="surtitle py-2">
                          {dispTimePeriod(event.ev_start_time, event.ev_end_time, event.time_zone, true)}                                              
                        </h6>
                      </CardBody>
                      <CardFooter>
                          <Row>
                            {event.hasOwnProperty('sb_times') && event.sb_times && event.sb_times.length > 0 && event.sb_times.map(( (times, key) => 
                            <div>
                              {times.length === 2 &&
                              <Col xs={12} key={key}>
                                  <h6 className="surtitle py-2 text-pink">
                                  {dispTimePeriod(times[0], times[1], event.time_zone, true)}                                              
                                </h6>
                              </Col> 
                              }
                            </div>
                       
                            )
                            )}
                        </Row>
                      </CardFooter>
                    </Card>
                  </Link>
    );
  }
}

function mapStateToProps(state) {
  
    console.log("EventForUserCard: mapStateToProps");
    return {

    };
  }


const connectedNearbyEventForCelebCard = connect(mapStateToProps)(NearbyEventForCelebCard  );
export { connectedNearbyEventForCelebCard as NearbyEventForCelebCard   }; 