import { notificationConstants, eventConstants } from '_constants';

const initialState = {};

export function notificationsData(state = initialState, action) {
    switch(action.type) {
    // Get Notifications
        case notificationConstants.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                fetchingNotifications: true,
            };
        case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                fetchingNotifications: false,
                notifications: action.data.notifications,
            };
        // Business Events Query also returns notificaitons
        case eventConstants.GET_BUS_EVENTS_SUCCESS:
            return {
                ...state,
                notifications: action.notifications,
            };
        case eventConstants.GET_BUS_EVENT_SUCCESS:
            return {
                ...state,
                event_notifications: action.notifications,
            };
        case notificationConstants.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                fetchingNotifications: false,
            };
        // Get Notifications
        case notificationConstants.GET_BID_NOTIFICATIONS_REQUEST:
        return {
            ...state,
            fetchingNotifications: true,
        };
        case notificationConstants.GET_BID_NOTIFICATIONS_SUCCESS:
        return {
            ...state,
            fetchingNotifications: false,
            notifications: action.data.notifications,
        };
        case notificationConstants.GET_BID_NOTIFICATIONS_FAILURE:
        return {
            ...state,
            fetchingNotifications: false,
            notifications: [],
        };
        //Get Notifications For Followed (User Request)
        case notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_REQUEST:
        return {
            ...state,
            fetchingNotificationsForFollowedRequest: true,
        };
        case notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_SUCCESS:
        return {
            ...state,
            fetchingNotificationsForFollowedRequest: false,
            notificationsForFollowed: action.data,
        };
        case notificationConstants.GET_NOTIFICATIONS_FOR_FOLLOWED_FAILURE:
        return {
            ...state,
            fetchingNotificationsForFollowedRequest: false,
        };
        //
        case notificationConstants.MARK_NOTIFICATION_AS_READ_REQUEST:
        return {
            ...state,
            markNotificationAsReadRequest: true,
        };
        case notificationConstants.MARK_NOTIFICATION_AS_READ_SUCCESS:
        return {
            ...state,
            markNotificationAsReadRequest: false,
        };
        case notificationConstants.MARK_NOTIFICATION_AS_READ_FAILURE:
        return {
            ...state,
            markNotificationAsReadRequest: false,
        };
        case notificationConstants.DELETE_NOTIFICATION_REQUEST:
        return {
            ...state,
            deleteNotificationRequest: true,
        };
        case notificationConstants.DELETE_NOTIFICATION_SUCCESS:
        return {
            ...state,
            deleteNotificationRequest: false,
        };
        case notificationConstants.DELETE_NOTIFICATION_FAILURE:
        return {
            ...state,
            deleteNotificationRequest: false,
        };
        default:
            return state
    }
}