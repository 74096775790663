import React from "react";
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { numberFunction } from '_helpers';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Collapse,

  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Table,
} from "reactstrap";

import { dateFromISO8601 } from '_helpers';
import { Link } from 'react-router-dom';



class ReviewCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [],

      // reviews: [] 
      // [revId, isSender, message, rating, time, unread, spId, spName, imageUrl, eventId]
    }
  }

  componentDidMount() {

  }

  collapsesToggle = collapse => {
    console.log("selectedCollapse", collapse)
    const {openedCollapses} = this.state
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      const tmpArray = openedCollapses
      tmpArray.push(collapse)
      this.setState({
        openedCollapses: tmpArray
      });
    }
  };

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const userIsSpecialist = user && user.is_specialist;
    const userIsBusiness = user && user.is_business;
    let reviews = this.props.reviews
    const eventsName = this.props.eventsName
    console.log("reviews", reviews)
    console.log("eventsName", eventsName)
    console.log("openedCollapses", this.state.openedCollapses)
    reviews = reviews && reviews.slice(0,1)

    return (
      <Row>
          {reviews.length > 0 && reviews.map( (review, key) => 
          <Col md={4} xs={12} key={key}>
            {/* {console.log("review", review[9])} */}
            <Card className="card-stats">
              <CardHeader>
              <Link to={{pathname: `/dash/event/${review[9]}`,}} > 
                 <h4 className="title"> {eventsName.hasOwnProperty(review[9]) ? eventsName[review[9]].name : ''} </h4>
                 <h6 className="surtitle"> 
                  {dateFromISO8601(review[4], eventsName.hasOwnProperty(review[9]) ? eventsName[review[9]].time_zone : 'America/Los_Angeles', "LLL")} 
                </h6> 
              </Link>

              </CardHeader>
              
              <CardBody>
              <Collapse
                role="tabpanel"
                isOpen={this.state.openedCollapses.includes(`collapse_review_${review[0]}`)}
                  >
                  <p className="line-styling text-sm">{review[2]}</p>
              </Collapse>
                {!this.state.openedCollapses.includes(`collapse_review_${review[0]}` )&& 
                <p className="line-styling two-line text-sm">{review[2]}</p>
                }
                <p className="mt-3 mb-0 text-sm" >
                  <span
                    className="text-nowrap font-weight-600 cursor-pointer"
                    role="tab"
                    onClick={() => this.collapsesToggle(`collapse_review_${review[0]}`)}
                    aria-expanded={this.state.openedCollapses.includes(
                      `collapse_review_${review[0]}`)}
                    >
                    {this.state.openedCollapses.includes(
                      `collapse_review_${review[0]}`) ? 'See less' : 'See more' }
                  </span>
                </p>
              </CardBody>
              <CardFooter>
                <div> 
                  {userIsBusiness &&
                  <Link style={{display:"inline-block"}} to={ { pathname: `/dash/c_profile/${numberFunction(parseInt(review[6], 10))}`, 
                                  state: {sp_id: parseInt(review[6], 10) }} }>                                  
                  <img className="avatar-md rounded-circle mr-3"  src={review[8]} alt="..." />
                  </Link>}
                  {userIsSpecialist && 
                  <Link style={{display:"inline-block"}} to={ { pathname: `/dash/b_profile/${numberFunction(parseInt(review[6], 10))}`, 
                                  state: {business_id: parseInt(review[6], 10) }} }>                                  
                  <img className="avatar-md rounded-circle mr-3"  src={review[8]} alt="..." />
                  </Link>}
                  
                  {userIsBusiness &&
                  <Link style={{display:"inline-block"}} to={ { pathname: `/dash/c_profile/${numberFunction(parseInt(review[6], 10))}`, 
                                  state: {sp_id: parseInt(review[6], 10) }} }>                                  
                     <span style={{fontSize:'.9125rem'}}>{review[7]}</span>
                  </Link>}
                  {userIsSpecialist && 
                  <Link style={{display:"inline-block"}} to={ { pathname: `/dash/b_profile/${numberFunction(parseInt(review[6], 10))}`, 
                                  state: {business_id: parseInt(review[6], 10) }} }>                                  
                    <span style={{fontSize:'.9125rem'}}> </span>{review[7]}
                  </Link>}
                  &nbsp;&nbsp; <span  style={{fontSize:'.9125rem'}} className="title">{review[3]}</span>
                </div>
                {/* <br/> */}
              </CardFooter>
            </Card>
          </Col>)}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {           
  };
}
const connectedReviewCards = connect(mapStateToProps)(ReviewCards);

export { connectedReviewCards as ReviewCards };
