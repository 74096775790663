import React from "react";
import { Link } from 'react-router-dom';


class HowMuchBox extends React.Component {
  render() {

    return (
            <section className ="section-app" id="app">
            <div className="landing-row">
                <h2 className="landing-h2">How much does LiveNyte cost to use?</h2>
                    <div className="text-center"> 
                        <h5 className = "long-copy"><span className="title text-primary">There are no subscription fees on LiveNyte.</span>
                        </h5>
                    </div>
                    <div> 
                        <p className = "long-copy text-center">
                            When ticket sales and bookings occur through LiveNyte, LiveNyte collects small fees.
                            <br/><br/> 
                            <span className="title">Our ticket fees are less than competitors such as Eventbrite.
                            And, our booking fees are about half of what booking and talent agencies collect. </span>
                            <br/><br/>
                            <Link to={ {pathname: `/dash/faq`}}>
                                <i>For more detailed information about our rates, check out our FAQ.</i>
                            </Link>
                            </p>
                        </div>

            </div>
        </section>
    );
  }
}

export default HowMuchBox;
