import { paymentConstants, } from '_constants';


export function getEventPayments(payments, tickets) {
    let evPayment = []
    let dictEvPayments = {}

    console.log("payments", payments)
    for (let payment of payments) {
        console.log("payment", payment)
        const eventId = payment.event_id
        let idx = evPayment.length
        if (dictEvPayments.hasOwnProperty(eventId))  {
            idx = dictEvPayments[eventId]
            evPayment[idx].bookings.push(payment)
        } else {
            dictEvPayments[eventId] = idx
            const eventPayment = {eventId: eventId, eventName:  payment.event_name, imageUrl:  payment.image_url,
                evStartTime: payment.start_time, evEndTime: payment.end_time, country: payment.country, time_zone: payment.time_zone,
                grossBookingFees: 0.0, grossTicketSales: 0.0, grossTicketsSold: 0.0,
                bookings: [payment], tickets:[]}
            evPayment.push(eventPayment)
        }
        if (payment.state === 'Completed' || payment.state === 'Charged') {
            evPayment[idx].grossBookingFees += payment.amount
        }
    }
    for (let ticket of tickets) {
        console.log("ticket", ticket)
        const eventId = ticket.event_id
        let idx = evPayment.length
        if (dictEvPayments.hasOwnProperty(eventId))  {
            idx = dictEvPayments[eventId]
            evPayment[idx].tickets.push(ticket)
        } else {
            dictEvPayments[eventId] = idx
            const eventPayment = {eventId: eventId, eventName:  ticket.event_name, imageUrl:  ticket.image_url,
                evStartTime: ticket.start_time, evEndTime: ticket.end_time, country: ticket.country, time_zone: ticket.time_zone,
                grossBookingFees: 0.0, grossTicketSales: 0.0, grossTicketsSold: 0.0,
                bookings: [], tickets: [ticket]}
            evPayment.push(eventPayment)
        }
        evPayment[idx].grossTicketSales += ticket.revenue
        evPayment[idx].grossTicketsSold += ticket.num_sold - ticket.num_refunded
    }

    console.log("getEventPayments", evPayment, dictEvPayments)
    return evPayment
}
const initialState = {};

export function paymentsData(state = initialState, action) {
    switch(action.type) {
        // Get Reviews
        case paymentConstants.PAYMENTS_QUERY_REQUEST:
            return {
                ...state,
                fetchingPayments: true,
            };
        case paymentConstants.PAYMENTS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingPayments: false,
                payments: action.data.payments,
                tickets: action.data.tickets,
                evPayments: getEventPayments(action.data.payments, action.data.tickets)
            };
        case paymentConstants.PAYMENTS_QUERY_FAILURE:
            return {
                ...state,
                fetchingPayments: false,
            };

        case paymentConstants.BOOKINGS_QUERY_REQUEST:
            return {
                ...state,
                fetchingBookings: true,
            };
        case paymentConstants.BOOKINGS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingBookings: false,
                bookings: action.data.bookings,
            };
        case paymentConstants.BOOKINGS_QUERY_FAILURE:
            return {
                ...state,
                fetchingBookings: false,
            };
        default:
            return state;
    }
};

