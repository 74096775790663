import { followingConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
import { generateFollowerParams } from '_helpers';

//import { history } from '_helpers';

export const followingActions = {
    getFollowing,    // get following
    followCelebrity,
    getCelebsFollowingInfo, // to add, remove following
    getBusinessesFollowingInfo,
    searchCelebs,
    getSearchCelebsFollowingInfo,
    getSearchBusinessesFollowingInfo,
};

function getFollowing(accType = null, size = null , callback = null) {
    const params = {
        request: 'user_query_following',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Following",  data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                    if(size === "full") {
                        if(accType === "Business") {
                            const busId =  generateFollowerParams(data.follow_business)
                            dispatch(followingActions.getBusinessesFollowingInfo(busId));

                        } else if (accType === "Celebrity") {
                            const spId =  generateFollowerParams(data.follow_celebs)
                            dispatch(followingActions.getCelebsFollowingInfo(spId));
                        }
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getFollowing: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: followingConstants.GET_FOLLOWING_REQUEST} }
    function success(data)   { return { type: followingConstants.GET_FOLLOWING_SUCCESS, data } }
    function failure(error)  { return { type: followingConstants.GET_FOLLOWING_FAILURE, error } }
}

function followCelebrity(args, callback = null) {
    const params = {
        request: 'follow_celebrity',
        followed_id: args.followedId,
        req_type: args.reqType, // one of 'Create' or 'Delete'
        followed_type: args.followedType //Either Celebrity or Business
      };
      console.log("PARAMS", params)
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getFollowing())
                    if (callback) {
                        callback(true, data)
                    }
                    if (args.followedType === "Celebrity") {
                        dispatch(followingActions.getFollowing("Celebrity", 'full') );
                    } else if  (args.followedType === "Business")   {
                        dispatch(followingActions.getFollowing("Business", 'full') );
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("followCelebrity: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: followingConstants.FOLLOW_CREATE_DELETE_REQUEST} }
    function success(data)   { return { type: followingConstants.FOLLOW_CREATE_DELETE_SUCCESS, data } }
    function failure(error)  { return { type: followingConstants.FOLLOW_CREATE_DELETE_FAILURE, error } }
}

function getCelebsFollowingInfo(sp_ids) {
    const params = {
        request: 'get_celebrities_from_id',
    }
    if (sp_ids.length > 0) {
        params.celebs_id = sp_ids;
    }

    return dispatch => {
        console.log("getCelebsFollowingInfo", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCelebsFollowingInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: followingConstants.GET_FOLLOWING_CELEBS_INFO_REQUEST } }
    function success(data)  { return { type: followingConstants.GET_FOLLOWING_CELEBS_INFO_SUCCESS  , data } }
    function failure(error) { return { type: followingConstants.GET_FOLLOWING_CELEBS_INFO_FAILURE , error } }
}

function getBusinessesFollowingInfo(bus_ids) {
    const params = {
        request: 'get_businesses_from_id',
    }
    if (bus_ids.length > 0) {
        params.business_ids = bus_ids
    }

    return dispatch => {
        console.log("getBusinessesFollowingInfo", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBusinessesFollowingInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: followingConstants.GET_FOLLOWING_BUSINESSES_INFO_REQUEST } }
    function success(data)  { return { type: followingConstants.GET_FOLLOWING_BUSINESSES_INFO_SUCCESS  , data } }
    function failure(error) { return { type: followingConstants.GET_FOLLOWING_BUSINESSES_INFO_FAILURE , error } }
}

function getSearchCelebsFollowingInfo(sp_ids) {
    const params = {
        request: 'get_celebrities_from_id',
    }
    if (sp_ids.length > 0) {
        params.celebs_id = sp_ids;
    }

    return dispatch => {
        console.log("getCelebsFollowingInfo", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCelebsFollowingInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_REQUEST } }
    function success(data)  { return { type: followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_SUCCESS  , data } }
    function failure(error) { return { type: followingConstants.GET_SEARCH_FOLLOWING_CELEBS_INFO_FAILURE , error } }
}

function getSearchBusinessesFollowingInfo(bus_ids) {
    const params = {
        request: 'get_businesses_from_id',
    }
    if (bus_ids.length > 0) {
        params.business_ids = bus_ids
    }

    return dispatch => {
        console.log("getBusinessesFollowingInfo", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBusinessesFollowingInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_REQUEST } }
    function success(data)  { return { type: followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_SUCCESS  , data } }
    function failure(error) { return { type: followingConstants.GET_SEARCH_FOLLOWING_BUSINESSES_INFO_FAILURE , error } }
}


function searchCelebs(lookFor, followType) {
    const params = {
        request: 'search_celebrities_regexp',
        lookfor: lookFor,
        followed_type: followType
    }

    return dispatch => {
        console.log("search_celebrities_regexp", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("search_celebrities_regexp: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: followingConstants.SEARCH_CELEBS_REQUEST } }
    function success(data)  { return { type: followingConstants.SEARCH_CELEBS_SUCCESS  , data } }
    function failure(error) { return { type: followingConstants.SEARCH_CELEBS_FAILURE , error } }
}
