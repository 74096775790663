import ReactCrop from 'react-image-crop';
import React from 'react';
// import PropTypes from "prop-types";

import defaultImage from "assets/img/image_placeholder.jpg";
// import defaultAvatar from "assets/img/placeholder.jpg";
import 'react-image-crop/dist/ReactCrop.css';
import YouTube from 'react-youtube';
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import  {FaYoutube} from 'react-icons/fa'
import { Button, Row, Modal, ModalHeader, ModalBody, Card, CardImg, } from "reactstrap";
import { LargeMediaViewer } from "components";
import {parseMediaTypeFromUrl } from '_helpers';
import { notificationMessageActions } from "_actions"

const opts =  {
  height: '600',
  width: '600',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

class GalleryUpload extends React.Component {
    constructor(props) {
      super(props);
      console.log("ImageUpload", props)
      this.state = {
        file: null,
        croppedImageUrl: null,
        imagePreviewUrl: this.props.avatar   ? defaultImage : 
                         this.props.imageUrl ? this.props.imageUrl : 
                                               defaultImage,
        index: props.index !== undefined ? props.index : null,
        src: null,
        crop: {
          aspect: 1,
          width: 50,
          x: 0,
          y: 0,
        },
        modalNotice: false,
        mediaViewerModal: false,
        mountedTimestamp: props.mountedTimestamp ? props.mountedTimestamp : new Date().getTime()

      };
    //   this.handleImageChange = this.handleImageChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
      // this.updateMediaAlert = this.updateMediaAlert.bind(this);
      //For YouTube (sweet alert)
      this.hideAlert = this.hideAlert.bind(this);
      this.addYoutubeVideoAlert = this.addYoutubeVideoAlert.bind(this);
      this.confirmYoutubeVideo = this.confirmYoutubeVideo.bind(this);
      this.toggleModalNotice = this.toggleModalNotice.bind(this);
      this.closeModalNotice = this.closeModalNotice.bind(this);
      this.toggleMediaViewerModal = this.toggleMediaViewerModal.bind(this)

   
    }

    toggleModalNotice() {
      this.setState({
        modalNotice: !this.state.modalNotice
      });
    }

    toggleMediaViewerModal() {
       this.setState({
        mediaViewerModal: !this.state.mediaViewerModal,
      });
    }
  
  
    closeModalNotice() {
      this.setState({
        modalNotice: false,
        src: null
      });
      this.imageRef = null; 
    }

    cancelImageUpload() {
      this.setState({
        src: null,
        // imageRef: null, 
      });   
      this.imageRef = null; 
    }

    onSelectFile = e => {
      console.log("handleImageChange", this.state)
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      console.log("file size" , file.size)
      console.log("file type" , file.type)

      if ( file.type.includes("image/")) {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          if (file.size > 7500000) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your image must be less than 7.5 mb.", 2 ))
              return
          } else {
            reader.addEventListener('load', () =>
              this.setState({ src: reader.result }),
            );
            reader.readAsDataURL(file);
          }
        }
      } else if ( file.type.includes("video/") && file.size > 100000000 )  {
        this.props.dispatch(notificationMessageActions.addNotificationToQueue("Videos must be less than 100 mb. If the video size limit is too small, you can upload the full-sized video to YouTube and add its url instead. ", 2 ))

          return
      } else {
        this.closeModalNotice()
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(file);
      }
    }

    addYoutubeVideoAlert() {
      this.setState({
        alert: (
          <SweetAlert
            input
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Enter YouTube Video URL"
            onConfirm={value => this.confirmYoutubeVideo(value)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            btnSize="sm"
          />
        )
      });
    }
  
  
    confirmYoutubeVideo(url) {
      this.setState({ alert: url });
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      console.log("Url", url)
      const match = url.match(regExp);
      if ( match && match[7].length === 11 ){
          const parsedId = match[7];
          if (parsedId && this.state.index !== null) {
            console.log("parsedId", parsedId)
            this.props.youtubeCallback(parsedId, this.state.index)
            this.closeModalNotice()
          } else {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please refresh the page.", 2 ))
          }
      } else{
        this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please try a different version of that YouTube URL. We were unable to verify it.", 2 ))
      }
      this.hideAlert()
      //setTimeout(this.displayCreatedBidAlert, 1000);
    }
  
      hideAlert() {
      this.setState({
        alert: null
      });
  
      }

      handleMediaType(type) {
        this.hideAlert()
        if (type === "media") {
          this.refs.fileInput.click();
        } else if (type === "youtube")  {
          this.addYoutubeVideoAlert()
          //sweetalert
        }        
        console.log("ImageUpload: handleClick:", this.state)
      }

      _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
    
      onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    
        // Make the library regenerate aspect crops if loading new images.
        const { crop } = this.state;
    
        if (crop.aspect && crop.height && crop.width) {
          this.setState({
            crop: { ...crop, height: null },
          });
        } else {
          this.makeClientCrop(crop, pixelCrop);
        }
      };
    
      onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
      };
    
      onCropChange = crop => {
        this.setState({ crop });
      };
    
      async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            pixelCrop,
            'newFile.jpeg',
          )
          .then( 
            resolve => {
              console.log("resolve", resolve, croppedImageUrl)
              this.setState({blob: resolve})
            },
            reject => {
              console.log("reject", reject)
              this.setState({blob: reject})
            }
          );
        }
      }


    
      getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            blob.name = fileName;
            resolve(blob);
          }, 'image/jpeg');
        });

      }

      handleImageChange(e) {
        console.log("handleImageChange", this.state)
        e.preventDefault();
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(this.state.blob)
      }

   
      render() {
        const { crop, croppedImageUrl, src, imagePreviewUrl, mountedTimestamp } = this.state;
        const mediaExt = imagePreviewUrl.split('.').pop()
        const isVideo = parseMediaTypeFromUrl(imagePreviewUrl) === "video"
        const isPhoto = parseMediaTypeFromUrl(imagePreviewUrl) === "image"
        const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0 
        console.log("imageRef", this.imageRef)
        console.log("state/props", this.state, this.props)
        // const date = new Date().getTime()
        return (
            <div className="fileinput text-center" style={{display:"inherit"}}>
              {this.state.alert}
              <input type="file" className="fileinput" accept=".mov, .mp4, .jpg, .jpeg, .png, .quicktime," onChange={this.onSelectFile} ref="fileInput" />
            <Card className ="mb-0">
              <div >
                { isVideo && 
                <div className="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer" onClick={this.toggleMediaViewerModal}>
                  <video onContextMenu={e => e.preventDefault()} width="320" height="240" controls autoPlay={false} style={{pointerEvents: "none"}}>
                      <source src={this.state.imagePreviewUrl} type={"video/" + mediaExt} autostart="false"/>
                      Your browser does not support the video tag.
                  </video>
                </div>
                  }
              </div>
              {isYoutube && 
                <div onClick={this.toggleMediaViewerModal}>
                  <YouTube
                  videoId={this.state.imagePreviewUrl}
                  opts={opts}
                  onReady={this._onReady}
                  containerClassName="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer"
                  className="pointer-events-none"
                  />
                </div>
                }
              { isPhoto &&
                <CardImg
                  alt="..."
                  src={`${this.state.imagePreviewUrl}?t=${mountedTimestamp}`}
                  // key={Math.random()}
                  onClick={this.toggleMediaViewerModal}
                  className="cursor-pointer"
                  top
                />
                 }
              </Card>
              <div className="mt-3">
                  <Button className="btn-primary" size="sm" color="primary"  onClick={() => this.toggleModalNotice()}>
                    {this.props.imageUrl ? "Update Media" : "Add Media"}
                  </Button>
                <br></br>
              </div>
              <Row>
                <Modal
                  isOpen={this.state.modalNotice}
                  toggle={this.toggleModalNotice}
                  className="modal-notice "
                  size="lg"
                  fade={false}
                  onClosed={this.closeModalNotice}
                >
                <ModalHeader toggle={this.toggleModalNotice}>
                  Select Media Type
                </ModalHeader>
                <ModalBody>
                  <div className="text-center">
                    <div>
                      {src && 
                      (
                      <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        className="wrap-max-height"
                      />
                    )}
                    {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />}
                    </div>
                    <div className="pt-2"> 
                      {(this.imageRef === null || this.imageRef === undefined) ? (
                        // <Button className="btn-primary" simple color="primary"  onClick={() => this.handleClick()}>
                        //   {this.props.avatar ? "Add Profile Photo" : "Update Profile Photo"}
                        // </Button>
                        <span></span>
                      ) : (
                        <span>
                          <Button outline size="sm" color="default" onClick={() => this.cancelImageUpload()}>
                            Change
                          </Button>
                          {this.props.avatar ? <br /> : null}
                          <Button outline size="sm"  color="primary" onClick={(e) => this.handleImageChange(e)}>
                            Save
                          </Button>
                        </span>
                      )}
                        {this.state.file !== null &&
                            <Button outline size="sm"  color="default"  onClick={() => this.cancelImageUpload()}>
                            Change
                        </Button> }
                    </div>
                  </div>
                  {!src && 
                  <div className="text-center">
                    <Button className="btn-primary" size="sm" color="primary"  onClick={() => this.handleMediaType("media")}>
                      {this.state.imagePreviewUrl === defaultImage ? "Add Media" : "Update Media"}
                    </Button>
                    <br/>
                    <Button className="btn-youtube" style={{marginTop:'10px'}} size="sm" onClick={() => this.handleMediaType("youtube")}>
                      <FaYoutube color='#ffffff' size={25}/> &nbsp;&nbsp;&nbsp;&nbsp;{"Add YouTube Video"}
                    </Button>
                  </div>
                  }
                </ModalBody>

                </Modal>
              </Row>
              <Row>
                <Modal
                  isOpen={this.state.mediaViewerModal}
                  toggle={this.toggleMediaViewerModal}
                  modalClassName="text-center"
                  fade={false}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggleMediaViewerModal}> Media </ModalHeader>
                  <ModalBody>
                    {this.state.imagePreviewUrl  && 
                      <LargeMediaViewer img imageUrl={this.state.imagePreviewUrl } />
                    }
                  </ModalBody>
                </Modal>
            </Row>
          </div>

        );
      }
    }


      export default GalleryUpload;


