import { authConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from 'helpers';

export const authActions = {
    claimRecommendCode,
    claimRecommendForId,
    claimRegistrationCode,
    claimResetPasswordCode,
    claimUpdateUserNameCode,
    claimReactivateAccountCode,
    claimDeleteAccountCode, 

    authRegistrationEmail,
    authResetPassword,
    authUpdateUserName,
    authDeleteAccount,
    authPhoneNumSendToken,
    authPhoneNumVerifyCode,
    reactivateAccount,
    // Local Functions
    //claimCode,
    //claimForId,
};

function claimRecommendCode(code, callback = null) {
    return claimCode(code, 'RECOMMEND', callback)
}

function claimRecommendForId(forId, reject, callback = null) {
    return claimForId(forId, 'RECOMMEND', reject, callback)
}

function claimRegistrationCode(code, callback = null) {
    return claimCode(code, 'REGISTRATION', callback)
}

function claimResetPasswordCode(code, callback = null) {
    return claimCode(code, 'CHG_PASSWD', callback)
}

function claimUpdateUserNameCode(code, callback = null) {
    return claimCode(code, 'CHG_EMAIL', callback)
}

function claimReactivateAccountCode(code, callback = null) {
    return claimCode(code, 'REACTIVATE_ACCT', callback)
}

function claimDeleteAccountCode(code, callback = null) {
    return claimCode(code, 'DELETE_ACCT', callback)
}


function claimCode(code, auth, callback = null) {

    const params = {
        request: 'claim_code',
        type: auth,
        code: code,
      };
  
    console.log("claimCode", params)
    return dispatch => {
        dispatch(request(auth));
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("claimCode success", data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("claimCode: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request(auth)   { return { type: authConstants.CLAIM_AUTH_CODE_REQUEST, auth } }
    function success(data)   { return { type: authConstants.CLAIM_AUTH_CODE_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.CLAIM_AUTH_CODE_FAILURE, error } }
}

function claimForId(forId, auth, reject, callback = null) {

    const params = {
        request: 'claim_for_id',
        type: auth,
        for_id: forId,
      };
  
    if (reject) {
	params['reject'] = 1;
    }

    console.log("claimForId", params)
    return dispatch => {
        dispatch(request(auth));
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("claimForId success", data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("claimForId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request(auth)   { return { type: authConstants.CLAIM_AUTH_FOR_ID_REQUEST, auth } }
    function success(data)   { return { type: authConstants.CLAIM_AUTH_FOR_ID_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.CLAIM_AUTH_FOR_ID_FAILURE, error } }
}

function authRegistrationEmail(email, callback = null) {

    const params = {
        request: 'request_registration',
        'email': email,
      };
      
  
    console.log("authRegistrationEmail", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authRegistrationEmail: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: authConstants.AUTH_REGISTRATION_EMAIL_REQUEST} }
    function success(data)   { return { type: authConstants.AUTH_REGISTRATION_EMAIL_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_REGISTRATION_EMAIL_FAILURE, error } }
}

function authResetPassword(email, callback = null) {

    const params = {
        request: 'request_reset_password',
        'email': email,
      };
      
  
    console.log("authResetPassword", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authResetPassword: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: authConstants.AUTH_RESET_PASSWORD_REQUEST} }
    function success(data)   { return { type: authConstants.AUTH_RESET_PASSWORD_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_RESET_PASSWORD_FAILURE, error } }
}

function authDeleteAccount(callback = null) {

    const params = {
        request: 'request_delete_account',
      };
      
  
    console.log("authDeleteAccount", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) {
                        callback(false, msg)
                    }
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authResetPassword: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: authConstants.AUTH_DELETE_ACCOUNT_REQUEST} }
    function success(data)   { return { type: authConstants.AUTH_DELETE_ACCOUNT_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_DELETE_ACCOUNT_FAILURE, error } }
}

function authUpdateUserName(newEmail, callback = null) {

    const params = {
        request: 'request_update_username',
        'new_email': newEmail,
      };
      
  
    console.log("authUpdateUserName", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authUpdateUserName: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: authConstants.AUTH_UPDATE_USERNAME_REQUEST} }
    function success(data)   { return { type: authConstants.AUTH_UPDATE_USERNAME_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_UPDATE_USERNAME_FAILURE, error } }
}

function authPhoneNumSendToken(phone, method, callback) {
    // method one of 'sms', 'call'
    const params = {
        request: 'auth_send_phone_token',
        phone: phone,
        method: method,
      };
      
  
    console.log("authPhoneNumSendToken", params)
    return dispatch => {
        dispatch(request(params));
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authPhoneNumSendToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request(params) { return { type: authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_REQUEST, params} }
    function success(data)   { return { type: authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_FAILURE, error } }
}

function authPhoneNumVerifyCode(phone, code, srv_sid, callback) {
    // method one of 'sms', 'call'
    const params = {
        request: 'auth_verify_phone_code',
        phone: phone,
        code: code,
        srv_sid: srv_sid,
      };
      
  
    console.log("authPhoneNumVerifyCode", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authPhoneNumVerifyCode: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_REQUEST} }
    function success(data)   { return { type: authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_SUCCESS, data } }
    function failure(error)  { return { type: authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_FAILURE, error } }
}

function reactivateAccount(email, callback = null) {
    const params = {
        request: 'reactivate_account',
        email: email,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, data.message) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("reactivateAccount: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: authConstants.REACTIVATE_ACCOUNT_REQUEST} }
    function success(data)  { return { type: authConstants.REACTIVATE_ACCOUNT_SUCCESS, data } }
    function failure(error) { return { type: authConstants.REACTIVATE_ACCOUNT_FAILURE, error } }
}






