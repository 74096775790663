import { combineReducers } from 'redux';

import { registration }   from '_reducers/registration.reducer';
import { memberInfo }     from '_reducers/member.reducer.js'
import { eventsData }     from '_reducers/event.reducer';
import { ticketsData }    from '_reducers/ticket.reducer';
import { alert }          from '_reducers/alert.reducer';
import { authentication } from '_reducers/authentication.reducer';
import { traitData }      from '_reducers/trait.reducer';
import { notificationsData } from '_reducers/notification.reducer';
import { messagesData }   from '_reducers/message.reducer';
import { reviewsData }    from '_reducers/review.reducer';
import { paymentsData }    from '_reducers/payment.reducer';
import { feesData }       from '_reducers/fee.reducer';
import { stripeData }     from '_reducers/stripe.reducer';
import { locationData }   from '_reducers/location.reducer';
import { followingData }   from '_reducers/following.reducer';
import { userConstants }  from '_constants';
import { caseCodeData}    from '_reducers/case.reducer';
import { scheduleData }   from '_reducers/schedule.reducer';
import { socialMediaData }   from '_reducers/socialMedia.reducer';
import { agencyData }   from '_reducers/agency.reducer';
import { affiliatesData } from '_reducers/affiliate.reducer';
import { featuredData } from '_reducers/featured.reducer';
import { recsData } from '_reducers/recs.reducer';
import { authData } from '_reducers/auth.reducer';
import { postsData } from '_reducers/posts.reducer';
import { productData } from '_reducers/product.reducer';
import { tierData } from '_reducers/tier.reducer';
import { planData } from '_reducers/plan.reducer';
import { assetData } from '_reducers/asset.reducer';
import { collectionData } from '_reducers/collection.reducer';
import { subscriptionData } from '_reducers/subscription.reducer';
import { hmpgTmplData } from '_reducers/hmpg_tmpl.reducer';
import { audioPlayerData } from '_reducers/audioPlayer.reducer';
import { notificationMessageData } from '_reducers/notificationMessage.reducer';
import { screenSizeData } from '_reducers/screenSize.reducer';
import { marketingEmailData } from '_reducers/marketingEmail.reducer';
import { watchData } from '_reducers/watch.reducer';
import { fanModeData } from '_reducers/fanMode.reducer';
import { cookiePreferencesData } from '_reducers/cookiePreferences.reducer';

const appReducer = combineReducers({
  authentication,
  registration,
  memberInfo,
  eventsData,
  ticketsData,
  traitData,
  notificationsData,
  messagesData,
  reviewsData,
  paymentsData,
  feesData,
  stripeData,
  caseCodeData,
  locationData,
  scheduleData,
  followingData,
  socialMediaData,
  agencyData,
  affiliatesData,
  featuredData,
  recsData,
  authData,
  postsData,
  assetData,
  productData,
  tierData,
  planData,
  collectionData,
  subscriptionData,
  hmpgTmplData,
  audioPlayerData,
  notificationMessageData,
  screenSizeData,
  marketingEmailData,
  watchData,
  fanModeData,
  cookiePreferencesData,
  alert
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT_CLEAR_STORE){
    state = undefined
  }
  if (action.type === userConstants.CLEAR_REDUX_STORE) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;
