import React from "react";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: this.props.defaultOpened !== undefined ?  ["collapseOne"] : [""],

    };
  }
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: []
      });
    } else {
      this.setState({
        openedCollapses: [collapse]
      });
    }
  };

  render() {
    const isDarkMode = this.props.darkMode ? true : false 
    return (
      <div className="accordion">
      
        {this.props.components.map((prop, key) => {
          return (
            <Card key={key} className="card-plain" >
              <CardHeader
                role="tab" 
                className={isDarkMode ? "cursor-pointer bg-dark text-light" : "cursor-pointer "}                           
                aria-expanded={this.state.openedCollapses.includes(
                  'collapse_' + key)}
                onClick={() => this.collapsesToggle('collapse_' + key)}
                >
                <h4 className={"mb-0 " + (isDarkMode ? "text-light" : "")} > {prop.title} </h4>
              </CardHeader> 
                <Collapse 
                role="tabpanel"               
                isOpen={this.state.openedCollapses.includes('collapse_' + key)}
                >
                <CardBody className={isDarkMode ? "bg-dark" : ""}>
                  <p className={"line-styling-breaks " + (isDarkMode ? "text-white" : "") }>
                    <span > {prop.text}</span>
                  </p>
                </CardBody>
              </Collapse>     
            </Card>
           
          );
        })}
      </div>
    );
  }
}
Accordion.propTypes = {
  // Which one of the component will be opened by default
  defaultOpened: PropTypes.number,
  // The Accordion will have no background color
  plain: PropTypes.bool,
  // example: [{title: "Title of the Collapse", text: "Text/Body of the Collapse"}]
  components: PropTypes.arrayOf(PropTypes.object)
};

export default Accordion;
