import React from "react";
import {
  Row,
  Col,
  ModalBody,
  Table,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class TicketingFeaturesModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
  }

  render() {

    return (
            <ModalBody className="table-dark"> 
                <Row className="row-grid justify-content-center">
                  <Col lg="10">
                    <Table className="table-dark mt-5" responsive>
                      <thead>
                        <tr>
                          <th className="px-0 bg-transparent" scope="col">
                            <span className="text-light font-weight-700">
                              Features
                            </span>
                          </th>
                          <th className="text-center bg-transparent" scope="col">
                            Standard
                          </th>
                          {/* <th className="text-center bg-transparent" scope="col">
                            Alpha Pack
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-0">Ticket types (ex: "General Admission")</td>
                          <td className="text-center">
                            Unlimited
                          </td>
                          {/* <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Public Events</td>
                          <td className="text-center">
                          Unlimited
                          </td>
                          {/* <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Private Events (unlisted)</td>
                          <td className="text-center">
                            Unlimited
                          </td>
                          {/* <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Checkout Form</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">
                            <span className="text-sm text-light">
                              Limited to 1 domain only
                            </span>
                          </td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Digital + Printed Tickets</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Mobile Tickets</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">-</td> */}
                        </tr>
                        <tr>
                          <td className="px-0">At-the-door sales via LiveNyte app </td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">-</td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Ticket scanning via app</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">-</td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Guest check-in via app</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">-</td> */}
                        </tr>
                        <tr>
                          <td className="px-0">Targeted Follower Notiifcations for Event Bookings</td>
                          <td className="text-center">
                            <i className="fas fa-check text-success" />
                          </td>
                          {/* <td className="text-center">-</td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </Col> 
                </Row>
              </ModalBody>
    );
  }
}

function mapStateToProps(state) {
  // const {  } = state;
  return {

  };
}

const connectedTicketingFeaturesModal= connect(mapStateToProps)(TicketingFeaturesModal);
export { connectedTicketingFeaturesModal as TicketingFeaturesModal }; 