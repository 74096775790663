import { alertConstants } from '_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        alertType: 'alert-success',
        alertMessage: action.message
      };
    case alertConstants.ERROR:
      return {
        alertType: 'alert-danger',
        alertMessage: action.message
      };
    case alertConstants.CLEAR:
      return {
        alertType: '',
        alertMessage: ''
      };
    case alertConstants.FATAL:
       return {
         alertType: 'alert-fatal',
         alertMessage: '',
       }
    default:
      return state
  }
}