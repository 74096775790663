import { postsConstants } from '_constants';

const initialState = {createPostReq: false, };

export function postsData(state = initialState, action) {
    switch(action.type) {
        case postsConstants.GET_POSTS_REQUEST:
            return {
                ...state,
                fetchGetPosts: true,
            };
        case postsConstants.GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.data.posts,
                fetchGetPosts: false,
            };
        case postsConstants.GET_POSTS_FAILURE:
            return {
                ...state,
                fetchGetPosts: false,
            };        
        case postsConstants.CREATE_POST_REQUEST:
            return {
                ...state,
                createPostReq: true,
            };
        case postsConstants.CREATE_POST_SUCCESS:
            return {
                ...state,
                createPostReq: false,
            };
        case postsConstants.CREATE_POST_FAILURE:
            return {
                ...state,
                createPostReq: false,
            };        
        case postsConstants.DELETE_POST_REQUEST:
            return {
                ...state,
                deletePostReq: true,
            };
        case postsConstants.DELETE_POST_SUCCESS:
            return {
                ...state,
                deletePostReq: false,
            };
        case postsConstants.DELETE_POST_FAILURE:
            return {
                ...state,
                deletePostReq: false,
            };        
        case postsConstants.CREATE_COMMENT_REQUEST:
            return {
                ...state,
                newCommentReq: true,
            };
        case postsConstants.CREATE_COMMENT_SUCCESS:
            return {
                ...state,
                newCommentReq: false,
            };
        case postsConstants.CREATE_COMMENT_FAILURE:
            return {
                ...state,
                newCommentReq: false,
            };        
        case postsConstants.UPDATE_COMMENT_REQUEST:
            return {
                ...state,
                updateCommentReq: true,
            };
        case postsConstants.UPDATE_COMMENT_SUCCESS:
            return {
                ...state,
                updateCommentReq: false,
            };
        case postsConstants.UPDATE_COMMENT_FAILURE:
            return {
                ...state,
                updateCommentReq: false,
            };        
        case postsConstants.DELETE_COMMENT_REQUEST:
            return {
                ...state,
                deleteCommentReq: true,
            };
        case postsConstants.DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                deleteCommentReq: false,
            };
        case postsConstants.DELETE_COMMENT_FAILURE:
            return {
                ...state,
                deleteCommentReq: false,
            };        
        case postsConstants.LIKE_COMMENT_REQUEST:
            return {
                ...state,
                likeCommentReq: true,
            };
        case postsConstants.LIKE_COMMENT_SUCCESS:
            return {
                ...state,
                likeCommentReq: false,
            };
        case postsConstants.LIKE_COMMENT_FAILURE:
            return {
                ...state,
                likeCommentReq: false,
            };        
            
        default:
            return state;
        }

        
    }
