export const assetConstants = {
    GET_ASSET_CATEGORIES_REQUEST: 'GET_ASSET_CATEGORIES_REQUEST',
    GET_ASSET_CATEGORIES_SUCCESS: 'GET_ASSET_CATEGORIES_SUCCESS',
    GET_ASSET_CATEGORIES_FAILURE: 'GET_ASSET_CATEGORIES_FAILURE',

    GET_ASSETS_REQUEST: 'GET_ASSETS_REQUEST',
    GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',
    GET_ASSETS_FAILURE: 'GET_ASSETS_FAILURE',

    GET_ASSETS_FOR_PRODUCT_REQUEST: 'GET_ASSETS_FOR_PRODUCT_REQUEST',
    GET_ASSETS_FOR_PRODUCT_SUCCESS: 'GET_ASSETS_FOR_PRODUCT_SUCCESS',
    GET_ASSETS_FOR_PRODUCT_FAILURE: 'GET_ASSETS_FOR_PRODUCT_FAILURE',

    GET_ASSET_FOR_USER_REQUEST: 'GET_ASSET_FOR_USER_REQUEST',
    GET_ASSET_FOR_USER_SUCCESS: 'GET_ASSET_FOR_USER_SUCCESS',
    GET_ASSET_FOR_USER_FAILURE: 'GET_ASSET_FOR_USER_FAILURE',
    
    CREATE_ASSET_REQUEST: 'CREATE_ASSET_REQUEST',
    CREATE_ASSET_SUCCESS: 'CREATE_ASSET_SUCCESS',
    CREATE_ASSET_FAILURE: 'CREATE_ASSET_FAILURE',

    UPDATE_ASSET_REQUEST: 'UPDATE_ASSET_REQUEST',
    UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_SUCCESS',
    UPDATE_ASSET_FAILURE: 'UPDATE_ASSET_FAILURE',

    DELETE_ASSET_REQUEST: 'DELETE_ASSET_REQUEST',
    DELETE_ASSET_SUCCESS: 'DELETE_ASSET_SUCCESS',
    DELETE_ASSET_FAILURE: 'DELETE_ASSET_FAILURE',

    PURCHASE_ASSET_REQUEST: 'PURCHASE_ASSET_REQUEST',
    PURCHASE_ASSET_SUCCESS: 'PURCHASE_ASSET_SUCCESS',
    PURCHASE_ASSET_FAILURE: 'PURCHASE_ASSET_FAILURE',

    DOWNLOAD_ASSET_REQUEST: 'DOWNLOAD_ASSET_REQUEST',
    DOWNLOAD_ASSET_SUCCESS: 'DOWNLOAD_ASSET_SUCCESS',
    DOWNLOAD_ASSET_FAILURE: 'DOWNLOAD_ASSET_FAILURE',
}
