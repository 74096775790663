import { tierConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const tierActions = {
    getTiers,
    createTier,
    updateTier,
    disableTier,
    enableTier,
    getRemitsForTiers
};

function getTiers(args, callback = null) {
    // required arguments: productId    
    // optional arguments: none

    const params = {
        request: 'get_tiers',
        product_id: args.productId
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Tiers");
                    console.log(data)
                
                    dispatch(success(data.tiers));
                    if (callback) {
                        callback(true, data.tiers)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTiers: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()         { return { type: tierConstants.GET_TIERS_REQUEST} }
    function success(tiers) { return { type: tierConstants.GET_TIERS_SUCCESS, tiers } }
    function failure(error)    { return { type: tierConstants.GET_TIERS_FAILURE, error } }
}

function createTier(args, callback = null) {
    // required arguments: productId, name, desc
    // optonal arguments: iamge, imageType    

    const params = {
        request: 'create_tier',
        product_id: args.productId, // tier's product id
        name: args.name,
        desc: args.description,
      };

    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Create Tier");
                    console.log(data)

                    dispatch(getTiers({productId: args.productId}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createTier: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: tierConstants.CREATE_TIER_REQUEST} }
    function success(data)  { return { type: tierConstants.CREATE_TIER_SUCCESS, data } }
    function failure(error) { return { type: tierConstants.CREATE_TIER_FAILURE, error } }
}

function updateTier(args, callback = null) {
    // required arguments: tierId, name, desc
    // optonal arguments: typeId, image, imageType    

    const params = {
        request: 'update_tier',
        tier_id: args.tierId,        
        name: args.name,
        desc: args.description,
      };

    if (args.hasOwnProperty('type_id')) {
        params['type_id'] = args.typeId
    }
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Update Tier");
                    console.log(data)
                
                    dispatch(getTiers({productId: args.productId}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateTier: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: tierConstants.UPDATE_TIER_REQUEST} }
    function success(data)   { return { type: tierConstants.UPDATE_TIER_SUCCESS, data } }
    function failure(error)  { return { type: tierConstants.UPDATE_TIER_FAILURE, error } }
}

function disableTier(tierId, productId, callback = null) {
    // required arguments: tierId    

    const params = {
        request: 'disable_tier',
        tier_id: tierId,         
      };

    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Disable Tier");
                    console.log(data)
                
                    dispatch(getTiers({productId: productId}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("disableTier: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: tierConstants.DISABLE_TIER_REQUEST} }
    function success(data)   { return { type: tierConstants.DISABLE_TIER_SUCCESS, data } }
    function failure(error)  { return { type: tierConstants.DISABLE_TIER_FAILURE, error } }
}

function enableTier(tierId, productId, callback = null) {
    // required arguments: tierId    

    const params = {
        request: 'enable_tier',
        tier_id: tierId,         
      };

    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Enable Tier");
                    console.log(data)
                
                    dispatch(getTiers({productId: productId}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("enableTier: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: tierConstants.ENABLE_TIER_REQUEST} }
    function success(data)   { return { type: tierConstants.ENABLE_TIER_SUCCESS, data } }
    function failure(error)  { return { type: tierConstants.ENABLE_TIER_FAILURE, error } }
}

function getRemitsForTiers(args, callback = null) {
    // required arguments: none
    // optional arguments:
    // 'start_date' one of 'this_month', 'last_three_months', 'last_six_months', 'this_year', 'last_year' or a specified date
    // 'end_date' can be specified date; else it will be determined based on start_date
    const params = {
        request: 'get_remits_for_tiers',
      };

    if (args.hasOwnProperty('start_date')) {
	params['start_date'] = args.start_date;
    }
    if (args.hasOwnProperty('end_date')) {
	params['end_date'] = args.end_date;
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Get Remits for Tiers");
                    console.log(data);
                
                    dispatch(success(data.remits));
                    if (callback) {
                        callback(true, data.remits)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again";
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getRemitsForTiers: fatal error");
                    dispatch(userActions.endSession(error)) ;
                }
            }
        );
    }
    
    function request()       { return { type: tierConstants.GET_REMITS_FOR_TIERS_REQUEST} }
    function success(remits) { return { type: tierConstants.GET_REMITS_FOR_TIERS_SUCCESS, remits } }
    function failure(error)  { return { type: tierConstants.GET_REMITS_FOR_TIERS_FAILURE, error } }
}
