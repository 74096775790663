import React from "react";
import { connect } from 'react-redux';

import { Row, Col, Button, } from "reactstrap";
import { ClipLoader } from 'react-spinners';
import {  history } from '_helpers';
import { notificationMessageActions, registerActions } from "_actions"


import { authActions } from '_actions';
class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        // sp_id: props.match.params.id ? reverseNumberFunction(parseInt(props.match.params.id, 10)) :  props.location.state.sp_id, 
        showMobileWarning: false
      };
      this.attemptWebRedirect = this.attemptWebRedirect.bind(this);
      this.redirectToDashboard = this.redirectToDashboard.bind(this);
  }



  redirectToDashboard() {
    const { fanMode } = this.props 
    const user = JSON.parse(localStorage.getItem('user')); 

    // const loggedIn = user && user.hasOwnProperty('email');
    const userIsAgencyAsClient = user && user.agency_as_client;
    const userIsSpecialist = user && user.is_specialist;
    const userIsBusiness = user && user.is_business;
    const userIsUser = user && user.is_user
    const userIsAgency = user && user.is_agency
    if (user) {
      if (userIsUser || fanMode) {
        history.replace('/dash/user-dashboard')
      } else if (userIsAgencyAsClient) {
        history.replace('/dash/celeb-dashboard')
  
      } else if (userIsSpecialist && !fanMode) {
        history.replace('/dash/celeb-dashboard')
  
      } else if (userIsBusiness && !fanMode) {
        history.replace('/dash/business-dashboard')
  
      } else if (userIsAgency  && !fanMode) {
        history.replace('/dash/agency-dashboard')
  
      } else {
        history.replace('/auth/logout')
        console.log("could not redirect")
      }    
    } else {
      history.replace('/auth/logout')
      console.log("could not redirect")
    }
  }

  attemptWebRedirect() {
    this.setState({showMobileWarning: false})
    this.handleURL()

  }
 
  handleURL() {
    const requestType = this.props.match.params.hasOwnProperty('requestType') && this.props.match.params.requestType
    const code = this.props.match.params.hasOwnProperty('code') && this.props.match.params.code
    console.log("Test3", requestType, code)

    if (requestType === 'recreq' && code ) { //Recommendation
        this.props.dispatch(authActions.claimRecommendCode(code, (data) => {

            console.log("data", data)
              this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data.message), (data.success ? 1 : 2)) )

              
              if (data.success) {
                // history.replace('/')
                this.redirectToDashboard()
              }
        }))
    }
  

    if (requestType === 'regemail' && code ) {
      console.log("In reg email",  code)
      this.props.dispatch(authActions.claimRegistrationCode(code, (data) => {

          console.log("data", data)
            this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data.message), (data.success ? 1 : 2)) )
            
            if (data.success) {
              this.props.dispatch(registerActions.clearRegistrationData())
              localStorage.setItem('auth-email', data.auth_user)
              history.replace('/auth/register-page')
            } else {
              if (data.type === "REGISTERED_USER") {
                this.props.dispatch(notificationMessageActions.addNotificationToQueue("Account already exists.", 1))
                this.props.dispatch(notificationMessageActions.addNotificationToQueue("Redirecting to LiveNyte Login Page...", 3))
                setTimeout(function() { history.replace('/auth/login'); }, 5000);
              }
            }
      }))
      
    }

    if (requestType === 'rstpwd' && code ) {
      console.log("In reg email",  code)
      history.replace({
        pathname: '/auth/forgot-password',
          state: { resetCode: code}
      })
    }

    if (requestType === 'updateemail' && code ) {
      console.log("In update email",  code)
      this.props.dispatch(authActions.claimUpdateUserNameCode(code, (data) => {

          console.log("data", data)
            this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data.message), (data.success ? 1 : 2)) )

            if (data.success) {
              this.props.dispatch(notificationMessageActions.addNotificationToQueue("Redirecting to LiveNyte Login Page...", 3))
              setTimeout(function() { history.replace('/auth/logout') }, 500);
            }
      }))
      
    }

    if (requestType === 'reactivate' && code ) {
      console.log("In reactivate ",  code)
      this.props.dispatch(authActions.claimReactivateAccountCode(code, (data) => {

          console.log("data", data)
            this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data.message), (data.success ? 1 : 2)) )

            if (data.success) {
              this.props.dispatch(notificationMessageActions.addNotificationToQueue("Redirecting to LiveNyte Login Page...", 3))
              setTimeout(function() { history.replace('/auth/logout') }, 5000);
            }
      }))
      
    }

    if (requestType === 'delete' && code ) {
      console.log("In delete ",  code)
      history.replace({
        pathname: '/auth/delete-account',
          state: { deleteCode: code}
      })

      // this.props.dispatch(authActions.claimDeleteAccountCode(code, (data) => {

      //     console.log("data", data)
      //       this.props.dispatch(notificationMessageActions.addNotificationToQueue( (data.success ? data.message : data.message), (data.success ? 1 : 2)) )

      //       if (data.success) {
      //         history.replace('/auth/delete-account')
      //       } else {
      //         setTimeout(this.redirectToDashboard, 5000);
      //       }
      // }))
    }

    if (this.props.history.location.pathname.includes('/redirect/home') ) { //Recommendation
      this.redirectToDashboard()
    }
    //fix this


  }

  componentDidMount() {
    console.log("Redirect Props", this.props)
    
    if (!this.props.match.path.includes('/mobile')) {
      this.handleURL()
      console.log("TEST1")
    } else {
      this.setState({showMobileWarning: true})
      console.log("TEST2")
    }
  }

  render() {

    const { claimRequest } = this.props;
    const { showMobileWarning } = this.state;

    if ( claimRequest ) {      
        return(
          <div className="bg-dark py-8">
            <div className="text-center py-8"> 
              <h4 className="text-uppercase ls-1 text-white pb-3">
                Redirecting&nbsp;&nbsp;
              </h4>
              <div className='sweet-loading'>
                <ClipLoader
                  sizeUnit={"px"}
                  size={45}
                  color={'#ffffff'}
                  
                />
              </div> 
            </div> 
          </div>           
        )
      }

    if (showMobileWarning) {
      return (
        <div className="bg-dark py-8">
          <div className="text-center py-8"> 
            <Row>
              <Col lg={8} xs={11} className="ml-auto mr-auto">
                <h4 className="text-uppercase ls-1 text-white pb-3 special-underline">
                  This is a mobile app link&nbsp;&nbsp;
                  </h4>
                  <h4 className="text-white pb-3">
                  If you want to continue on iOS,&nbsp;
                  <a className="text-primary" href="https://itunes.apple.com/us/app/livenyte/id1378430467?mt=8" >
                  install the LiveNyte mobile app from the App Store,
                  </a>
                  &nbsp;then re-click the link from your email.
                  </h4>
                  <h4 className="text-white pb-3">
                  If you want to contiue on Android,&nbsp;
                  <a className="text-primary" href="https://play.google.com/store/apps/details?id=com.kunal.livenyte.livenyte">
                  install the LiveNyte mobile app from the Play Store,
                  </a>
                  &nbsp;then re-click the link from your email and open it with the LiveNyte mobile app.
                  </h4>
                  <h4 className="text-white pb-3">
                  If you want to continue on the LiveNyte website, click the button below. 
                  </h4>
                <Button onClick={() => this.attemptWebRedirect()} color="primary" size="lg">Continue with Web </Button>
              </Col>
            </Row>
          </div> 
        </div>
      )
    }

    return (
    <div className="content">
        <div className="bg-dark py-8">
          <div className="text-center py-8"> 
              <h4 className="text-uppercase ls-1 text-white pb-3">
                Redirecting&nbsp;&nbsp;
              </h4>
              <div className='sweet-loading'>
                <ClipLoader
                  sizeUnit={"px"}
                  size={45}
                  color={'#ffffff'}
                  
                />
              </div> 
            </div> 
        </div>
      </div>    
      );
  }

  
}



function mapStateToProps(state) {
  const {  recsData, fanModeData} = state;
  const { claimRequest } = recsData;
  const { fanMode } = fanModeData;

  return {      
    claimRequest,
    fanMode,

  };
}

const connectedRedirect = connect(mapStateToProps)(Redirect);
export { connectedRedirect as Redirect }; 