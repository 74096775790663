import React from "react";
import {
  Col,
  ModalBody,
  Row,
} from "reactstrap";

import Linkify from 'react-linkify';
import { Button, } from "components";
import { connect } from 'react-redux';
import { config } from "_constants";
import { numberFunction } from '_helpers';        
import { notificationMessageActions } from "_actions"

class ShareBusinessProfileModal extends React.Component {
  constructor(props) {
    super(props);
    const { busId } = props.props;
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
      busId: numberFunction(busId),
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {

  }



  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.props.dispatch(notificationMessageActions.addNotificationToQueue("Link Copied", 3 ))

  }


  render() {
      const { busId } = this.state
      const { is_xs } = this.props
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)

    return (
      <ModalBody className="pt-1">
        <Row> 
        <Col sm={9} xs={12} className="text-left pt-3">
            <Linkify properties={{target: '_blank'}}> 
            {`${config.serverUrl}/dash/b_profile/${busId}`}
              <form style={{visiblity:"hidden", height:"0px"}}>
                <textarea style={{ opacity:"0", width:"1px"}} ref={(textarea) => this.textArea = textarea}
                defaultValue={`${config.serverUrl}/dash/b_profile/${busId}`}>
                </textarea>
              </form>
            </Linkify>
          </Col>
          <Col sm={3} xs={12} className={"pt-3 " + (is_xs ? "text-left" : "text-right")}>
          {document.queryCommandSupported('copy') &&
            <Button onClick={e => this.copyToClipboard(e)} size={"sm"} color={"primary"} style={{marginTop: "0px"}}>
              Copy Link
            </Button>
          }
          </Col>
        </Row>
      </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const { screenSizeData } = state;
  const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;

  return {
    is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest

    };
}

const connectedShareBusinessProfileModal= connect(mapStateToProps)(ShareBusinessProfileModal);
export { connectedShareBusinessProfileModal as ShareBusinessProfileModal }; 