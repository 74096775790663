import { tierConstants } from '_constants';

export function tierData(state = {}, action) {
    switch(action.type) {

        case tierConstants.GET_TIERS_REQUEST:
            return {
                ...state,
                getTiers: true,
            };
        case tierConstants.GET_TIERS_SUCCESS:
            return {
                ...state,
                tiers: action.tiers,
                getTiers: false,
            };
        case tierConstants.GET_TIERS_FAILURE:
            return {
                ...state,
                getTiers: false,
            };        
        case tierConstants.CREATE_TIER_REQUEST:
            return {
                ...state,
                createTier: true,
            };
        case tierConstants.CREATE_TIER_SUCCESS:
            return {
                ...state,
                createTier: false,
            };
        case tierConstants.CREATE_TIER_FAILURE:
            return {
                ...state,
                createTier: false,
            };         
        case tierConstants.UPDATE_TIER_REQUEST:
            return {
                ...state,
                updateTier: true,
            };
        case tierConstants.UPDATE_TIER_SUCCESS:
            return {
                ...state,
                updateTier: false,
            };
        case tierConstants.UPDATE_TIER_FAILURE:
            return {
                ...state,
                updateTier: false,
            };  
        case tierConstants.DISABLE_TIER_REQUEST:
            return {
                ...state,
                disableTier: true,
            };
        case tierConstants.DISABLE_TIER_SUCCESS:
            return {
                ...state,
                disableTier: false,
            };
        case tierConstants.DISABLE_TIER_FAILURE:
            return {
                ...state,
                disableTier: false,
            };   
        case tierConstants.ENABLE_TIER_REQUEST:
            return {
                ...state,
                enableTier: true,
            };
        case tierConstants.ENABLE_TIER_SUCCESS:
            return {
                ...state,
                enableTier: false,
            };
        case tierConstants.ENABLE_TIER_FAILURE:
            return {
                ...state,
                enableTier: false,
            };                                          

        case tierConstants.GET_REMITS_FOR_TIERS_REQUEST:
            return {
                ...state,
                getTierRemits: true,
	        };
        case tierConstants.GET_REMITS_FOR_TIERS_SUCCESS:
            return {
            ...state,
                getTierRemits: false,
                tierRemits: action.remits,
            };
        case tierConstants.GET_REMITS_FOR_TIERS_FAILURE:
            return {
                ...state,
                getTierRemits: false,
            };

        default:
            return state;
        }

}
