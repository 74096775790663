import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "components";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import YouTube from 'react-youtube';
import  {FaYoutube} from 'react-icons/fa'
import 'react-image-crop/dist/ReactCrop.css';
import {parseMediaTypeFromUrl } from '_helpers';
import { notificationMessageActions } from "_actions"

const opts =  {
  height: '240',
  width: '240',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    //console.log("ImageUpload", props)
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                       this.props.imageUrl ? this.props.imageUrl : 
                                             defaultImage,
      index: props.index !== undefined ? props.index : null,
      alert: null,

    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

    //For YouTube (sweet alert)
    this.hideAlert = this.hideAlert.bind(this);
    this.addYoutubeVideoAlert = this.addYoutubeVideoAlert.bind(this);
    this.confirmYoutubeVideo = this.confirmYoutubeVideo.bind(this);

  }
  
  handleImageChange(e) {
    console.log("handleImageChange", this.state)
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log("file size" , file.size)
    console.log("file type" , file.type)

    if ( file.type.includes("image/") && file.size > 7500000 ) {
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your image must be less than 7.5 mb.", 2 ))
      return
    } else if ( file.type.includes("video/") && file.size > 100000000 )  {
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Videos must be less than 100 mb. If the video size limit is too small, you can upload the full-sized video to YouTube and add its url instead. ", 2 ))
      return
    } else {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
        this.props.callback({state: this.state, newImage: true})
      };
      reader.readAsDataURL(file);
    }

  }

  addYoutubeVideoAlert() {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          title="Enter YouTube Video URL"
          onConfirm={value => this.confirmYoutubeVideo(value)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
        />
      )
    });
  }


  confirmYoutubeVideo(url) {
    this.setState({ alert: url });
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    console.log("Url", url)
    const match = url.match(regExp);
    if ( match && match[7].length === 11 ){
        const parsedId = match[7];
        if (parsedId && this.state.index !== null) {
          console.log("parsedId", parsedId)
          this.props.youtubeCallback(parsedId, this.state.index)
        } else {
          this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please refresh the page.", 2 ))
        }
    } else{
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Please try a different version of that YouTube URL. We were unable to verify it.", 2 ))
    }
    this.hideAlert()
    //setTimeout(this.displayCreatedBidAlert, 1000);
  }

    hideAlert() {
    this.setState({
      alert: null
    });

    }

 
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
    console.log("ImageUpload: handleSubmit(): ", this.state.file)
  }
  handleClick(type) {
    console.log("ImageUpload: handleClick:", this.state)
    if (type === "media") {
      this.refs.fileInput.click();
    } else if (type === "youtube")  {
      this.addYoutubeVideoAlert()
      //sweetalert
    }
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  handleRemove() {
    console.log("handleRemove")
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : 
                       this.props.imageUrl ? this.props.imageUrl : defaultImage
    });
    this.refs.fileInput.value = null;
    this.props.callback({state: this.state, newImage: false})
  }

  

  render() {
    console.log("image", this.state)
    const { imagePreviewUrl } = this.state 
    const mediaExt = imagePreviewUrl.split('.').pop()
    const isVideo = parseMediaTypeFromUrl(imagePreviewUrl) === "video"
    const isPhoto = parseMediaTypeFromUrl(imagePreviewUrl) === "image"
    const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0 
    // console.log("isVideo", isVideo, mediaExt)
    return (
      <div className="text-center">
        {this.state.alert}
          <input className="fileinput" type="file" accept=".mov, .mp4, .jpg, .jpeg, .png," onChange={this.handleImageChange} ref="fileInput" />
          <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>          
            { isVideo && 
                <video onContextMenu={e => e.preventDefault()} width="320" height="240" controls autoPlay={false}>
                <source src={this.state.imagePreviewUrl} type={"video/" + mediaExt} autostart="false"/>
                Your browser does not support the video tag.
              </video>}
            {isPhoto &&
              <img width="320" height="240" src={this.state.imagePreviewUrl} alt="..." />}
          </div>
          {isYoutube && 
          <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}> 
            <YouTube
            videoId={this.state.imagePreviewUrl}
            opts={opts}
            onReady={this._onReady}
              />
          </div>}
          <div>
            {this.state.file === null ? (
              <Button className="btn-primary" simple onClick={() => this.handleClick("media")}>
                {this.state.imagePreviewUrl === defaultImage ? "Add Media" : "Update Media"}
              </Button>
            ) : (
              <span>
                <Button simple onClick={() => this.handleClick("media")}>
                  Change
                </Button>
                {this.props.avatar ? <br /> : null}
                <Button color="danger" simple onClick={() => this.handleRemove()}>
                  <i className="fa fa-times" /> Remove
                </Button>
              </span>
            )}
            <br></br>
              <Button className="btn-youtube" onClick={() => this.handleClick("youtube")}>
                <FaYoutube color='#ffffff' size={25}/> &nbsp;&nbsp;&nbsp;&nbsp;{"Add YouTube Video"}
              </Button>
          </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
};

export default ImageUpload;
