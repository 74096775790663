import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";




class BusinessAccountBox extends React.Component {
    render() {
  
      return (

        <div id="business">
            <div className="text-center text-white pt-2 mt-3"> 
                  <h2 className="text-white surtitle text-uppercase">Businesses</h2>
                  <h4 className="text-white text-uppercase surtitle mt-2">Host great events and reach new customers</h4>
            </div>
            <div className="pricing card-group flex-column flex-md-row mb-3 mt-6">
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardBody className="px-lg-7 pb-4">
                    <div className="icon icon-lg icon-shape bg-gradient-livenyte text-white rounded-circle mb-2 mt-3">
                        <i className="ni ni-tie-bow" />
                    </div>
                    <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                      Find Available Talent                   
                    </h4>
                    <p>Create events on LiveNyte and easily find available talent. Submit bids on talent or book them immediately for your events.
                    </p>
                    </CardBody>
                    <CardFooter>
                    <div className="icon icon-lg icon-shape bg-gradient-info text-white rounded-circle mb-2 mt-3">
                        <i className="far fa-thumbs-up  " />
                    </div>
                    <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                      Book with Confidence                    
                    </h4>
                    <p>Read other businesses' reviews of talent before bidding. Book talent who you are good to work with.
                    </p>                        
                    </CardFooter>
                  </Card>
                  <Card className="card-pricing zoom-in-1 bg-dark  border-0 text-center mb-4 rounded">
                        <CardBody className="px-lg-12 pb-3 bg-transparent text-white">
                        <div className="icon icon-lg icon-shape bg-gradient-success text-white rounded-circle mb-2 mt-3">
                            <i className="fas fa-solid fa-ticket" />
                        </div>
                        <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                        Sell Tickets
                        </h4>
                        <p>Create and sell tickets and other services for your events with LiveNyte. With the LiveNyte: Event Check-In App, your staff can scan tickets with their phones and check-in guests.
                        </p>
                        </CardBody>
                        <CardFooter className="rounded bg-transparent text-white">
                        <div className="icon icon-lg icon-shape bg-gradient-tertiary text-white rounded-circle mb-2 mt-3">
                            <i className="fas fa-bullhorn" />
                        </div>
                        <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                        Targeted Promotion
                        </h4>
                        <p>When you book talent through LiveNyte, LiveNyte notifies their followers of your booking. Through this notification, fans can discover your event and purchase tickets.
                        </p>
                        </CardFooter>
                    </Card>
                  </div>
        </div>
      );
    }
  }

export default BusinessAccountBox;
