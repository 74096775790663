
import React from "react";
import  { useEffect, useRef } from "react"

export default function AutoPlaySilentVideo(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })
    console.log("props.id", props, props.id)
    const onLoadedData = props.onLoadedData ? props.onLoadedData : null 
    const isVideoLoaded = props.isVideoLoaded ? props.isVideoLoaded : true 
    return (
    <video 
        className={props.className} 
        ref={videoRef} 
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
        loop 
        autoPlay 
        muted 
        playsInline 
        // width={props.width} 
        id={props.id}
        onContextMenu={e => e.preventDefault()}
        onLoadedData={onLoadedData}
        // height={props.height}
         >
            <source
            src={props.video}
            type="video/mp4"
            />
  </video>
  );
}

