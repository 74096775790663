export const recsConstants = {
    GET_RECS_FOR_ID_REQUEST: 'GET_RECS_FOR_ID_REQUEST',
    GET_RECS_FOR_ID_SUCCESS: 'GET_RECS_FOR_ID_SUCCESS',
    GET_RECS_FOR_ID_FAILURE: 'GET_RECS_FOR_ID_FAILURE',

    GET_RECS_BY_ID_REQUEST: 'GET_RECS_BY_ID_REQUEST',
    GET_RECS_BY_ID_SUCCESS: 'GET_RECS_BY_ID_SUCCESS',
    GET_RECS_BY_ID_FAILURE: 'GET_RECS_BY_ID_FAILURE',

    GET_PENDING_REC_REQS_BY_ME_REQUEST: 'GET_PENDING_REC_REQS_BY_ME_REQUEST',
    GET_PENDING_REC_REQS_BY_ME_SUCCESS: 'GET_PENDING_REC_REQS_BY_ME_SUCCESS',
    GET_PENDING_REC_REQS_BY_ME_FAILURE: 'GET_PENDING_REC_REQS_BY_ME_FAILURE',

    REQUEST_REC_REQUEST: 'REQUEST_REC_REQUEST',
    REQUEST_REC_SUCCESS: 'REQUEST_REC_SUCCESS',
    REQUEST_REC_FAILURE: 'REQUEST_REC_REQUEST',

    WRITE_REC_REQUEST: 'WRITE_REC_REQUEST',
    WRITE_REC_SUCCESS: 'WRITE_REC_SUCCESS',
    WRITE_REC_FAILURE: 'WRITE_REC_FAILURE',



};