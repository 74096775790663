import { caseConstants } from '_constants';
import { userService } from '_services';
import { userActions } from '_actions';

export const caseActions = {
    getCaseCodes,
    reportNewCase,
};

function getCaseCodes(accType, cred = null) { 
    const params = {
        request: 'get_report_codes',
        for_acct: accType,    // Specialist, Business, Agency, User
    }
    if (cred !== null) {
        params['cred'] = JSON.stringify(cred) // cred = {event_id: <optional>, bid_ids: <optional>} 
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Get Case Codes");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCaseCodes: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: caseConstants.GET_CASE_CODES_REQUEST} }
    function success(data)   { return { type: caseConstants.GET_CASE_CODES_SUCCESS, data } }
    function failure(error)  { return { type: caseConstants.GET_CASE_CODES_FAILURE, error } }
}

function reportNewCase(code, description, credentials = null, callback = null) { 
    const params = {
        request: 'report_new_case',
        code: code,
        description: description,
    }
    if (credentials !== null) {
        params['credentials'] = JSON.stringify(credentials) // cred = {event_id: <optional>, bid_ids: <optional>} 
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Reported New Case");
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data.message)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("reportNewCase: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }    
    function request()       { return { type: caseConstants.REPORT_NEW_CASE_REQUEST} }
    function success(data)   { return { type: caseConstants.REPORT_NEW_CASE_SUCCESS, data } }
    function failure(error)  { return { type: caseConstants.REPORT_NEW_CASE_FAILURE, error } }
}


