
import React from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button
} from "reactstrap";
import { connect } from 'react-redux';
import { hasValidPermission, history } from "_helpers";
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import { fanModeActions } from '_actions';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isFanModeToggled: false, 
      collapseOpen: false,
      alert: null, 
      ...this.getCollapseStates(props.routes)
    };
    this.toggleFanMode = this.toggleFanMode.bind(this)
    this.hideAlert = this.hideAlert.bind(this);
    this.switchModeAlert = this.switchModeAlert.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /dash/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, isCollapsedLink) => {
    const { loggedIn, info } = this.props;
    const user = JSON.parse(localStorage.getItem('user')); 
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (!this.showItem(loggedIn, user, info, prop.showFor, prop.name)) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views)
              })}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? <i className={prop.icon} /> : null}
              <span className={"nav-link-text"}>{prop.name}</span>
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views, true)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem
          className={this.activeRoute(prop.layout + prop.path)}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={this.closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : (
              <span className={"nav-link-text " + (isCollapsedLink ? "collapsedLink" : "")} >{prop.name}</span>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

    // Show sidebar item
    showItem(loggedIn, user, info, showFor, name) {
      const { fanMode } = this.props 
      // console.log("showItem", loggedIn, user, showFor, name)
      if (!loggedIn) {
        return showFor.indexOf('LoggedOut') > -1 ? true : false;
      }
      if (!user) {
        return false
      }
      if ((user.is_business && !fanMode                                      && showFor.indexOf('Business')   > -1) ||
          (user.is_specialist && !fanMode                                    && showFor.indexOf('Specialist') > -1) ||
          (user.is_agency                                                            && showFor.indexOf('Agency')     > -1) ||
          ( (user.is_user  || fanMode)                                         && showFor.indexOf('User')       > -1) ||
          (info && info.hasOwnProperty('affiliate_code') && hasValidPermission() && showFor.indexOf('Affiliate')  > -1)) { 
            return true
      }
      return false;
    }

    switchModeAlert() {
      const { fanMode} = this.props 
      const user = JSON.parse(localStorage.getItem('user')); 

      const userIsSpecialist = user && user.is_specialist
      // const userIsBusiness = user && user.is_business

      const switchingToFanMode = fanMode === false 
      const switchingToCreatorMode = !switchingToFanMode && userIsSpecialist
      const switchingTo = switchingToFanMode ? "Fan" : switchingToCreatorMode ? "Creator" : "Business"
      const switchingToRoute = switchingToFanMode ? ('/dash/user-dashboard') : switchingToCreatorMode ? ('/dash/celeb-dashboard') : ('/dash/business-dashboard')
      this.setState({
        alert: (
          <SweetAlert
            title={`Switch to ${switchingTo}`}
            onConfirm={() => this.toggleFanMode(switchingToRoute)}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="primary"
            confirmBtnText="Switch"
            showCancel
            type="info"
          >
          <p className="text-center text-darkerGray"
             style={{ fontSize: "77%"}}>
            <span> 
              <span className="h4">{`You are about to switch to ${switchingTo} mode. If you have any unsaved changes on the current page, they may be lost.`}
              </span>
              {/* <br/>Please click the link in the email to continue the account recovery process. */}
            </span>  
          </p>
          </SweetAlert>
        )
      });
    }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  toggleFanMode(switchingToRoute) {
    const {fanMode} = this.props
    history.push(switchingToRoute)


    if (fanMode) {
      this.props.dispatch(fanModeActions.turnFanModeOff())
    } else {
      this.props.dispatch(fanModeActions.turnFanModeOn())
    }

    this.setState({
      // isFanModeToggled: !this.state.isFanModeToggled,
      alert: null, 
    })

    // const { isFanModeToggled} = this.state 
    // const user = JSON.parse(localStorage.getItem('user')); 

    // const userIsSpecialist = user && user.is_specialist
    // // const userIsBusiness = user && user.is_business

    // const switchingToFanMode = isFanModeToggled === false 
    // const switchingToCreatorMode = !switchingToFanMode && userIsSpecialist
    // // const switchingToBusinessMode = !switchingToFanMode && userIsBusiness

    // const switchingTo = switchingToFanMode ? "Fan mode" : switchingToCreatorMode ? "Creator mode" : "Business mode"
    // const switchingToRoute = switchingToFanMode ? ('/dash/user-dashboard') : switchingToCreatorMode ? ('/dash/celeb-dashboard') : ('/dash/business-dashboard')
      // if (window.confirm(`You are about to switch to ${switchingTo}. If you have any unsaved changes on the current page, they may be lost.`)) {
      //   history.push(switchingToRoute)
      //   this.setState({
      //     isFanModeToggled: !this.state.isFanModeToggled
      //   })
      // } else {
      //   // if reject, skip.
      //   // reject()
      // }
  
    console.log("switch", this.props.fanMode)

  }
  render() {
    const { routes, logo } = this.props;
    const { fanMode } = this.props 
    const user = JSON.parse(localStorage.getItem('user')); 
    const userIsSpecialist = user && user.is_specialist
    const userIsBusiness = user && user.is_business
    // const userIsUser = user && user.is_user 

    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <Link to={ {pathname: `/redirect/home` }  }
              >
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </Link>

            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes, false)}</Nav>
            {(userIsSpecialist || userIsBusiness) && 
            <div>
              <hr className="my-3" />
              <h6 className="navbar-heading p-0 surtitle mb-1 text-default">
                {userIsSpecialist && !fanMode ? "Creator Mode" :
                 userIsBusiness &&  !fanMode ? "Business Mode" : 
                 "Fan Mode"
              } 
                
                </h6>
              <Button className={fanMode ? "bg-gradient-livenyte mt-2" : "btn-outline-primary mt-2"} color="primary"  size="sm" onClick={this.switchModeAlert}>
                {
                userIsSpecialist && fanMode ? "Switch to Creator" :
                 userIsBusiness &&  fanMode ? "Switch to Business" : 
                 "Switch to Fan"
              } 
                
                </Button>
            </div>
            }

            {/* <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink
                  target="_blank"
                >
                  <i className="ni ni-spaceship" />
                  <span className="nav-link-text">Getting started</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                >
                  <i className="ni ni-palette" />
                  <span className="nav-link-text">Foundation</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                >
                  <i className="ni ni-ui-04" />
                  <span className="nav-link-text">Components</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  target="_blank"
                >
                  <i className="ni ni-chart-pie-35" />
                  <span className="nav-link-text">Plugins</span>
                </NavLink>
              </NavItem>
            </Nav> */}
          </Collapse>
        </div>
      </div>
    );
    return (
      <>
      {this.state.alert}
            <Navbar
        className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
      </>
    
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

function mapStateToProps(state) {
  const {  memberInfo, authentication, fanModeData } = state;
  const { loggedIn } = authentication;
  const { fetchingInfo, info, fetchingUserInfo, userInfo, } = memberInfo;
  const { fanMode } = fanModeData;

  return {
    fetchingInfo, info,
    fetchingUserInfo,  userInfo,
    loggedIn,
    fanMode
    
  };
}

const connectedSidebar= connect(mapStateToProps)(Sidebar);
export { connectedSidebar as Sidebar };
