import React from "react";
import rainbowRoom from "assets/img/img-livenyte/rainbowRoom.jpg";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
  } from "reactstrap";

import { PulseLoader } from 'react-spinners';

class ViewFallback extends React.Component {
  render() {
    return (
        <header className="header-4 " 
          style={{height:"100vh", 
          // backgroundSize: "cover", backgroundImage: "url(" + (rainbowRoom) + ")"
          background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(" + (rainbowRoom) + ")",
          backgroundPosition:"center top",
          backgroundSize:"cover"


        }}
         >
        <div className="header-wrapper">
                <div className="page-header header-video">
                <div className="overlay"></div>
        <Container style={{paddingTop: "0vh"}}>
            <Row>
                <Col className="mx-auto px-0" lg="12">
                <Card className=" bg-transparent-carbon card-box-shadow mb-0">
                    <CardBody>
                    <div className={"pb-4 "}> 
                    <h2 className={"display-4 font-weight-light text-white inline-block"}>
                        Loading
                    </h2>
                    <div className='sweet-loading inline-block'
                        style={ { transform: "translate(6px,  4px)" }}>
                        <PulseLoader
                            sizeUnit={"px"}
                            size={7}
                            color={'#fff'}
                        />
                    </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </Container>
            </div>
            </div>
        </header>
    )
  }
}

export default ViewFallback;
