export const followingConstants = {
    GET_FOLLOWING_REQUEST: 'GET_FOLLOWING_REQUEST',
    GET_FOLLOWING_SUCCESS: 'GET_FOLLOWING_SUCCESS',
    GET_FOLLOWING_FAILURE: 'GET_FOLLOWING_FAILURE',

    GET_FOLLOWING_CELEBS_INFO_REQUEST: 'GET_FOLLOWING_CELEBS_INFO_REQUEST',
    GET_FOLLOWING_CELEBS_INFO_SUCCESS: 'GET_FOLLOWING_CELEBS_INFO_SUCCESS',
    GET_FOLLOWING_CELEBS_INFO_FAILURE: 'GET_FOLLOWING_CELEBS_INFO_FAILURE',

    GET_FOLLOWING_BUSINESSES_INFO_REQUEST: 'GET_FOLLOWING_BUSINESSES_INFO_REQUEST',
    GET_FOLLOWING_BUSINESSES_INFO_SUCCESS: 'GET_FOLLOWING_BUSINESSES_INFO_SUCCESS',
    GET_FOLLOWING_BUSINESSES_INFO_FAILURE: 'GET_FOLLOWING_BUSINESSES_INFO_FAILURE',

    FOLLOW_CREATE_DELETE_REQUEST: 'FOLLOW_CREATE_DELETE_REQUEST',
    FOLLOW_CREATE_DELETE_SUCCESS: 'FOLLOW_CREATE_DELETE_SUCCESS',
    FOLLOW_CREATE_DELETE_FAILURE: 'FOLLOW_CREATE_DELETE_FAILURE',

    SEARCH_CELEBS_REQUEST: 'SEARCH_CELEBS_REQUEST',
    SEARCH_CELEBS_SUCCESS: 'SEARCH_CELEBS_SUCCESS',
    SEARCH_CELEBS_FAILURE: 'SEARCH_CELEBS_FAILURE',

    GET_SEARCH_FOLLOWING_CELEBS_INFO_REQUEST: 'GET_SEARCH_FOLLOWING_CELEBS_INFO_REQUEST',
    GET_SEARCH_FOLLOWING_CELEBS_INFO_SUCCESS: 'GET_SEARCH_FOLLOWING_CELEBS_INFO_SUCCESS',
    GET_SEARCH_FOLLOWING_CELEBS_INFO_FAILURE: 'GET_SEARCH_FOLLOWING_CELEBS_INFO_FAILURE',

    GET_SEARCH_FOLLOWING_BUSINESSES_INFO_REQUEST: 'GET_SEARCH_FOLLOWING_BUSINESSES_INFO_REQUEST',
    GET_SEARCH_FOLLOWING_BUSINESSES_INFO_SUCCESS: 'GET_SEARCH_FOLLOWING_BUSINESSES_INFO_SUCCESS',
    GET_SEARCH_FOLLOWING_BUSINESSES_INFO_FAILURE: 'GET_SEARCH_FOLLOWING_BUSINESSES_INFO_FAILURE',
}