
/*eslint-disable*/
import React from "react";
import { Link } from 'react-router-dom';
import { config } from "_constants";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
class AuthFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                  <Link
                      to={ {pathname: ``}}
                      className="font-weight-bold ml-1 "
                      >    LiveNyte
                    </Link>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                    <Link
                      to={ {pathname: ``}}
                      className="nav-link"

                      >
                      Home
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to={ {pathname: `/auth/creatorLanding`}}
                      className="nav-link"

                      >
                      Talent & Creators
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                    className="nav-link"
                      to={ {pathname: `/auth/fanLanding`}}
                    >
                      Fans
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to={ {pathname: `/auth/businessLanding`}}
                      >
                      Businesses
                    </Link>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={config.commonUrl + config.privacyLocalPath}
                      target="_blank"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={config.commonUrl + config.termsLocalPath}
                      target="_blank"
                    >
                      Terms
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default AuthFooter;
