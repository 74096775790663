import React from "react";
import {
  Row,
  Modal,
  ModalHeader,
  CardHeader,
  CardBody,
  CardFooter,
  Card,
  Col,
} from "reactstrap";
import { connect } from 'react-redux';

import {
  Button, AffiliateProgramTermsModal
} from "components";

class AffiliateGuidelines extends React.Component {
  constructor(props) {
    super(props);
    console.log("AffiliateGuidelines constructor()", props)
    this.state = {
      affiliateModal: false,
    };
    this.toggleAffiliateModal = this.toggleAffiliateModal.bind(this);
  }

  toggleAffiliateModal() {
    this.setState({
      affiliateModal: !this.state.affiliateModal
    });
  }

  componentDidMount() {
  }

 

  render() {
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    return (
    <div> 
      <Card style={{background:"#172b4d"}}>
        <CardHeader className="bg-transparent border-0 pb-0">
            {/* <br/> */}
            <div className="text-center pb-3 special-underline"> 
              <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
              Terms + Guidelines 
              </h4>            
            </div>
          </CardHeader>
          <CardBody className="py-0 text-white">
            <Row>
              <Col md={9} xs={12} className="ml-auto mr-auto">
                <div className="text-left">
                  <small className="mt-0 pt-0"> Only Talent & Creators and Businesses are eligible for the affiliate program.
                  <br/><br/>
                  To apply to the Affiliate Program, login to your account on the LiveNyte website, go to your Profile and click Apply to Affiliate Program.
                  <br/><br/>
                  Creators and Businesses must enter your assigned affiliate code during their registration for your referral to be accredited to your account. If you are a member of the Affiliate Program, your assigned affiliate code can be found through viewing your profile on LiveNyte's website.
                  <br/><br/>The time period starts when that user creates a LiveNyte account with your affiliate code. 
                  </small>
                  <br/>
                </div>
              </Col>
            </Row>
            </CardBody>
            <CardFooter className="bg-transparent text-white border-0">
              <div className="text-center special-underline"> 
                <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                  Please Note
                </h4>
              </div>
              <Row>
                <Col md={9} xs={12} className="ml-auto mr-auto">
                  <div className="text-left pt-3"> 
                    <small className="mt-0 pt-0">
                      Payout Balances will be distributed monthly in a lump sum consisting of all the afilliate's revenue shares accrued for the current monthly period less the processing fees.
                      {/* If an affiliate's net payout balance balance is less than $25, the payout will be delayed until the following month after the net payout balance grows to be greater than $25. */}
                      {/* LiveNyte estimates that the processing fees associated with such a payout are roughly $2.50 in the United States.  */}
                    </small>
                </div>
                </Col>
              </Row>
              <div className="text-center mt-3"> 
                <Button outline color="spc-white" size="sm" onClick={this.toggleAffiliateModal}>
                        Affiliate Program Terms
                </Button>                          
              </div>
            </CardFooter>
          </Card>
          <Row>
              <Modal
              isOpen={this.state.affiliateModal}
              toggle={this.toggleAffiliateModal}
              className="modal-notice text-left"
              size="lg"
              fade={false}
              >
              <ModalHeader toggle={this.toggleAffiliateModal}>
                  Affiliate Program Terms
              </ModalHeader>
                  <AffiliateProgramTermsModal/>
              </Modal>
          </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

const connectedAffiliateGuidelines = connect(mapStateToProps)(AffiliateGuidelines);
export { connectedAffiliateGuidelines as AffiliateGuidelines }; 