import { postsConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from 'helpers';

export const postsActions = {
    getMyPosts,
    getPosts,
    createPost,
    deletePost,
    createComment,
    updateComment,
    deleteComment,
    likeComment,
    
};
function getMyPosts(callback = null) {
    const user = JSON.parse(localStorage.getItem('user'))

    const args = {
        postById: user.user_id,
      };

    return getPosts(args, callback)
}

function getPosts(args, callback = null) {

    const params = {
        request: 'get_posts',
      };

    if (args.hasOwnProperty('assetId')) {
        params['asset_id'] = args.assetId
    }
    if (args.hasOwnProperty('postById')) {
        params['by_id'] = args.postById
    }

      console.log("GetPosts", params)
  
    console.log("getPosts", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getPosts success", data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data.posts)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(false, data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPosts: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.GET_POSTS_REQUEST } }
    function success(data)   { return { type: postsConstants.GET_POSTS_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.GET_POSTS_FAILURE, error } }
}

function createPost(args, callback = null) {
    // args keys { 'comment', image (optional), media_type (optional) }

    const params = {
        request: 'create_post',
        comment: args.comment,
      };
      if (args.hasOwnProperty('title')) {
	  params['title'] = args.title
      }
      if (args.hasOwnProperty('image')) {
          params['image'] = args.image
          params['media_type'] = args.mediaType // one of 'Image', 'Video', 'Music', or 'Youtube'
      }
  
    console.log("createPost", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("createPost success", data)
                    dispatch(getMyPosts())
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createPost: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.CREATE_POST_REQUEST } }
    function success(data)   { return { type: postsConstants.CREATE_POST_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.CREATE_POST_FAILURE, error } }
}

function deletePost(postId, callback = null) {
    const params = {
        request: 'delete_post',
        post_id: postId,
      };
      
  
    console.log("deletePost", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("deletePost success", data)
                    dispatch(getMyPosts())
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deletePost: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.DELETE_POST_REQUEST } }
    function success(data)   { return { type: postsConstants.DELETE_POST_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.DELETE_POST_FAILURE, error } }
}

function createComment(args, postById, assetId = null, callback = null) {
    // args keys { comment, post_id, image (optional), media_type (optional) }
    const params = {
        request: 'create_comment',
        comment: args.comment,
        post_id: args.post_id,
      }
      if (args.hasOwnProperty('title')) {
	    params['title'] = args.title
      }
      if (args.hasOwnProperty('image')) {
          params['image'] = args.image
          params['media_type'] = args.mediaType // one of 'Image', 'Video', 'Music', or 'Youtube'
      }
      if (args.hasOwnProperty('reply_id')) {
          params['reply_id'] = args.reply_id
      }
    

  
    console.log("createComment", params)
    console.log("createComment assetId", assetId)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("createComment success", data)
                    const arg = {
                        postById: postById,
                      };
                    if (assetId) {
                        arg['assetId'] = assetId
                    }

                    dispatch(getPosts(arg))
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createComment: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.CREATE_COMMENT_REQUEST } }
    function success(data)   { return { type: postsConstants.CREATE_COMMENT_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.CREATE_COMMENT_FAILURE, error } }
}

function updateComment(args, postById, assetId = null, callback = null) {
    // args keys { comment, comment_id, image (optional), media_type (optional) }
    const params = {
        request: 'update_comment',
        comment_id: args.comment_id,
        comment: args.comment,
      };
      if (args.hasOwnProperty('title')) {
	  params['title'] = args.title
      }
      if (args.hasOwnProperty('image')) {
          params['image'] = args.image
          params['media_type'] = args.mediaType // one of 'Image', 'Video', 'Music', or 'Youtube'
      }
  
    console.log("updateComment", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("updateComment success", data)
                    const arg = {
                        postById: postById,
                      };
                    if (assetId) {
                        arg['assetId'] = assetId
                    }

                    dispatch(getPosts(arg))
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateComment: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.UPDATE_COMMENT_REQUEST } }
    function success(data)   { return { type: postsConstants.UPDATE_COMMENT_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.UPDATE_COMMENT_FAILURE, error } }
}

function deleteComment(commentId, postById, assetId = null, callback = null) {
    const params = {
        request: 'delete_comment',
        comment_id: commentId,
      };
  
    console.log("deleteComment", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("deleteComment success", data)
                    const arg = {
                        postById: postById,
                      };
                    if (assetId) {
                        arg['assetId'] = assetId
                    }

                    dispatch(getPosts(arg))                    
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteComment: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.DELETE_COMMENT_REQUEST } }
    function success(data)   { return { type: postsConstants.DELETE_COMMENT_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.DELETE_COMMENT_FAILURE, error } }
}

function likeComment(commentId, like, postById, assetId = null, callback = null) {
    // like is true to add like, false to remove like
    const params = {
        request: 'like_comment',
        comment_id: commentId,
        like: like,
      };
  
    console.log("like_comment", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("like_comment success", data)
                    const arg = {
                        postById: postById,
                      };
                    if (assetId) {
                        arg['assetId'] = assetId
                    }

                    dispatch(getPosts(arg))                    
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(data)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("like_comment: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: postsConstants.LIKE_COMMENT_REQUEST } }
    function success(data)   { return { type: postsConstants.LIKE_COMMENT_SUCCESS, data } }
    function failure(error)  { return { type: postsConstants.LIKE_COMMENT_FAILURE, error } }
}
