import React from "react";
import {
  Row,
  Col,
} from "reactstrap";



class WhyLiveNyteBox extends React.Component {
  render() {

    return (
            <section className ="section-app" id="app">
            <div className="landing-row">
                <h2 className="landing-h2">Why use LiveNyte? </h2>
                    <div className="text-center"> 
                        <p className = "long-copy">LiveNyte connects businesses, fans, and talent & creators together so that everyone wins. </p>
                    </div>
            </div>
                <Row className="landing-row"> 

                    <Col md={3}> 
                        <i className="icon ion-md-calendar icon-big-celebrity" ></i>
                        <h4 className="landing-h4">Save Time</h4>
                        <p>All the logistical overhead of booking talent is handled by LiveNyte. LiveNyte's system manages offers and books the best ones.
                        </p>
                    </Col>    
                    <Col md={3}> 
                        <i className="icon ion-ios-heart icon-big-celebrity" ></i>
                        <h4 className="landing-h4">Make More Money</h4>
                        <p>Businesses never overpay for talent-bookings and that talent get booked at events that maximize their revenue and covenience.
                        </p>
                    </Col>   
                    <Col md={3}>
                        <i className="icon ion-ios-apps icon-big-celebrity" ></i>
                        <h4 className="landing-h4">Create More Booking Opportunities </h4>
                        <p>LiveNyte helps create more opportunities for talent to get booked by making it easier and more profitable than ever for businesses + event organizers to book talent and sell tickets.
                        </p>
                    </Col>
                    <Col md={3}>
                        <i className="icon ion-md-checkmark icon-big-celebrity" ></i>
                        <h4 className="landing-h4">Bring Out Local Fans </h4>
                        <p>Local followers of booked talent are notified of event bookings, making it easier than ever to promote events and sell tickets. 
                        </p>
                    </Col>
                </Row>
        </section>
    );
  }
}

export default WhyLiveNyteBox;
