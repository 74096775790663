export const cookiePreferencesConstants = {
    COOKIE_PREFERENCES_SET_REQUEST: 'COOKIE_PREFERENCES_SET_REQUEST',
    COOKIE_PREFERENCES_SET_SUCCESS: 'COOKIE_PREFERENCES_SET_SUCCESS',
    COOKIE_PREFERENCES_SET_FAILURE: 'COOKIE_PREFERENCES_SET_FAILURE',

    COOKIE_PREFERENCES_UNSET_REQUEST: 'COOKIE_PREFERENCES_UNSET_REQUEST',
    COOKIE_PREFERENCES_UNSET_SUCCESS: 'COOKIE_PREFERENCES_UNSET_SUCCESS',
    COOKIE_PREFERENCES_UNSET_FAILURE: 'COOKIE_PREFERENCES_UNSET_FAILURE',
}

