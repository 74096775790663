import { messageConstants, eventConstants } from '_constants';

const initialState = {};

export function messagesData(state = initialState, action) {
    switch(action.type) {
        // Get Messages
        case messageConstants.GET_MESSAGES_REQUEST:
            return {
                ...state,
                fetchingMessages: true,
            };
        case messageConstants.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                fetchingMessages: false,
                messages: action.data.messages,
            };
        // Business Events Query also returns (unread) message
        case eventConstants.GET_BUS_EVENTS_SUCCESS:
            return {
                ...state,
                new_messages: action.new_messages,
            };
        case eventConstants.GET_BUS_EVENT_SUCCESS:
            return {
                ...state,
                new_event_messages: action.new_messages,
            };
        case messageConstants.GET_MESSAGES_FAILURE:
            return {
                ...state,
                fetchingMessages: false,
            };
        // Set Message
        case messageConstants.SET_MESSAGE_REQUEST:
            return {
                ...state,
                setMessageRequest: true,
            };
        case messageConstants.SET_MESSAGE_SUCCESS:
            return {
                ...state,
                setMessageRequest: false,
            };
        case messageConstants.SET_MESSAGE_FAILURE:
            return {
                ...state,
                setMessageRequest: false,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_REQUEST:
            return {
                ...state,
                markMessageAsReadRequest: true,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_SUCCESS:
            return {
                ...state,
                markMessageAsReadRequest: false,
            };
        case messageConstants.MARK_MESSAGE_AS_READ_FAILURE:
            return {
                ...state,
                markMessageAsReadRequest: false,
            };
        default:
            return state
    }
}