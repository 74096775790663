import React from "react";
import {
  Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    

} from "reactstrap";
// import { history } from '_helpers';
import { Link } from 'react-router-dom';
import { numberFunction, parseProductPrice, trimProductSubscribers } from '_helpers';
import noPreviewAvailable from 'assets/img/img-livenyte/NoPreviewAvailable.png'
import defaultAvatar from "assets/img/default-avatar.png";

class ProductImgCard extends React.Component {

  render() {
    const { getCurrentLocation, currentLocation, product } = this.props;
    // console.log("CreateUpdateSchedule: render", this.state, this.props)

    return (
        <Link to={ { pathname: `/dash/marketplaceProduct/${product.id}`, 
            state: {productId: product.id, product: product, }} }
            tag={Link}
        >
            <Card
            className="card-blog card-background"
            data-animation="zooming"
            >
            <div
                className="full-background"
                style={{
                backgroundImage:
                    "url(" + (product.image ? product.image : noPreviewAvailable) + ")",
                }}
            ></div>
                <CardBody  style={{paddingTop:"20px"}}>
                
                <Row className="content-top">
                    <Col className="col-auto pt-2 mt-1">
                        <Link to={ { pathname: `/dash/c_profile/${numberFunction(product.owner_id)}`, 
                            state: {sp_id: product.owner_id, }} } style={{fontSize:"0.75em"}}>
                            <div className="inline-block">
                                <img
                                    alt="..."
                                    src={product.creator_photo}
                                    className="avatar-md-small rounded-circle"
                                    onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                                />

                            </div>
                            <div className="text-left pl-2 ml-1 inline-block " style={{verticalAlign:"top", paddingTop:".15rem"}}>
                                <div className="mb-0">
                                    <span className="text-white ">{product.creator_name}</span>
                                </div>
                                {product.creator_traits && product.creator_traits.length > 0 && 
                                    <small className="text-lighter surtitle-default">{Object.keys(product.creator_traits[0]) }</small>
                                }
                            </div>
                        </Link>
           
                    </Col>
                    
                    <Col className="text-right ml-auto">
                    {!getCurrentLocation && currentLocation.hasOwnProperty('country_code') && parseProductPrice(currentLocation.country_code, product.price_range) &&
                        <h3 className = "pl-3 mb-0 text-white " style={{fontWeight:"800", fontSize:"2.5em"}}>                    
                        {parseProductPrice(currentLocation.country_code, product.price_range, 0)}
                        </h3>
                    }
                        <h5 className="card-category surtitle text-lighter opacity-8">
                        {/* {cardDateFields(post.comments[0].modified, null).month} */}
                        </h5>
                    </Col>
                </Row>
                <div className="content-bottom one-line">
                <h5 className="card-category surtitle text-white opacity-8 one-line pb-1 mb-1">
                    {product.product_type} • {trimProductSubscribers(product.subscriber_count)}
                    </h5  >
                    <CardTitle className="one-line" tag="h3">{product.name}</CardTitle>
                    {/* <Link to={ { pathname: `/dash/c_profile/${numberFunction(product.owner_id)}`, 
                        state: {sp_id: product.owner_id, }} }>
                    <img
                        alt="..."
                        src={product.creator_photo}
                        className="avatar rounded-circle"
                    />
                    </Link> */}
                </div>
                </CardBody>
            </Card>
        </Link>
    );
  }
}

export default ProductImgCard 