import { reviewConstants, } from '_constants';

const initialState = {};

export function reviewsData(state = initialState, action) {
    switch(action.type) {
        // Get Reviews
        case reviewConstants.GET_REVIEWS_REQUEST:
            return {
                ...state,
                fetchingReviews: true,
            };
        case reviewConstants.GET_REVIEWS_SUCCESS:
            return {
                ...state,
                fetchingReviews: false,
                reviews: action.data.reviews,
            };
        case reviewConstants.GET_REVIEWS_FAILURE:
            return {
                ...state,
                fetchingReviews: false,
            };

         // Get Reviews For ID
        case reviewConstants.GET_REVIEWS_FOR_ID_REQUEST:
            return {
                ...state,
                fetchingReviews: true,
            };
        case reviewConstants.GET_REVIEWS_FOR_ID_SUCCESS:
            return {
                ...state,
                fetchingReviews: false,
                reviewsId: action.data.reviews,
            };
        case reviewConstants.GET_REVIEWS_FOR_ID_FAILURE:
            return {
                ...state,
                fetchingReviews: false,
            };

       // Save Review
        case reviewConstants.SAVE_REVIEW_REQUEST:
            return {
                ...state,
                saveReviewRequest: true,
            };
        case reviewConstants.SAVE_REVIEW_SUCCESS:
            return {
                ...state,
                saveReviewRequest: false,
            };
        case reviewConstants.SAVE_REVIEW_FAILURE:
            return {
                ...state,
                saveReviewRequest: false,
            };
       // Mark Review as Read
        case reviewConstants.MARK_REVIEWS_AS_READ_REQUEST:
            return {
                ...state,
                markReviewAsReadRequest: true,
            };
        case reviewConstants.MARK_REVIEWS_AS_READ_SUCCESS:
            return {
                ...state,
                markReviewAsReadRequest: false,
            };
        case reviewConstants.MARK_REVIEWS_AS_READ_FAILURE:
            return {
                ...state,
                markReviewAsReadRequest: false,
            };
        default:
            return state
    }
}