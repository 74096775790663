import { hmpgTmplConstants } from '_constants';

export function hmpgTmplData(state = {}, action) {
    switch(action.type) {
        // Get Base Templates
        case hmpgTmplConstants.GET_TMPL_TYPES_REQUEST:
            return {
                ...state,
                getTmplTypes: true,
            };
        case hmpgTmplConstants.GET_TMPL_TYPES_SUCCESS:
            return {
                ...state,
                baseTmpls: action.tmpls,
                getTmplTypes: false,
            }
        case hmpgTmplConstants.GET_TMPL_TYPES_FAILURE:
            return {
                ...state,
                getTmplTypes: false,
            }

            // Get User Template
        case hmpgTmplConstants.GET_USER_TEMPLATE_REQUEST:
            return {
                ...state,
                getUserTmpl: true,
            }
        case hmpgTmplConstants.GET_USER_TEMPLATE_SUCCESS:
            return {
                ...state,
                userTmpls: action.tmpls,
                getUserTmpl: false,
            }
        case hmpgTmplConstants.GET_USER_TEMPLATE_FAILURE:
            return {
                ...state,
                getUserTmpl: false,
            }
        case hmpgTmplConstants.GET_MEMBER_ID_REQUEST:
            return {
                ...state,
                getMemberIdRequest: true,
            }
        case hmpgTmplConstants.GET_MEMBER_ID_SUCCESS:
            return {
                ...state,
                getMemberIdRequest: false,
            }
        case hmpgTmplConstants.GET_MEMBER_ID_FAILURE:
            return {
                ...state,
                getMemberIdRequest: false,
            }
        case hmpgTmplConstants.SET_USERNAME_REQUEST:
            return {
                ...state,
                setUsernameRequest: true,
            }
        case hmpgTmplConstants.SET_USERNAME_SUCCESS:
            return {
                ...state,
                setUsernameRequest: false,
            }
        case hmpgTmplConstants.SET_USERNAME_FAILURE:
            return {
                ...state,
                setUsernameRequest: false,
            }
        case hmpgTmplConstants.UPDATE_USERNAME_REQUEST:
            return {
                ...state,
                updateUsernameRequest: true,
            }
        case hmpgTmplConstants.UPDATE_USERNAME_SUCCESS:
            return {
                ...state,
                updateUsernameRequest: false,
            }
        case hmpgTmplConstants.UPDATE_USERNAME_FAILURE:
            return {
                ...state,
                updateUsernameRequest: false,
            }

        case hmpgTmplConstants.GET_USERNAME_REQUEST:
            return {
                ...state,
                getUsernameRequest: true,
            }
        case hmpgTmplConstants.GET_USERNAME_SUCCESS:
            return {
                ...state,
                getUsernameRequest: false,
                username: action.data.username,
            }
        case hmpgTmplConstants.GET_USERNAME_FAILURE:
            return {
                ...state,
                getUsernameRequest: false,
            }

        default: return state; 
    }
}
