import { registerConstants } from '_constants';
import { userService } from '_services';
import { alertActions, memberActions, userActions, socialMediaActions } from '_actions';
import {  } from '_actions';
import { history } from '_helpers';


export const registerActions = {
    register,
    registerName,
    registerEmail,
    registerAddress,
    registerPassword,
    registerAccType,
    registerAffiliate,
    clearRegistrationData, 
    // getAll,
    // delete: _delete
};

function register(registrationData, callback = null) {
    let params = {
        request: 'create_user',
        userData: JSON.stringify(registrationData)
    }
    console.log("Registration Data", registrationData)
    return dispatch => {
        dispatch(request());

        userService.register(params)
            .then(
                user => { 
                    if (user.success) {
                        console.log("register", user);                       

                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        user['is_user'] = !(user.is_business || user.is_specialist || user.is_agency)
                        localStorage.setItem('user', JSON.stringify(user));
                        //handle social media connect
                        const authEmail = localStorage.getItem('auth-email')
                        const authSocial = localStorage.getItem('auth-social')
                        const authSocialToken = localStorage.getItem('auth-socialToken')
                        console.log("RegistrationData and auth from storage", registrationData.name, registrationData.username,  authEmail, authSocial, authSocialToken)
                        dispatch(memberActions.memberQuery((success, data) => {
                            if (success && authEmail && authSocial && authSocialToken) {
                                const creds = {
                                    company: authSocial,
                                    name: registrationData.name, 
                                    email: registrationData.username, 
                                    gender: 'NA',
                                    id: authSocialToken,
                                    react: true, 
                                  };
                                  console.log("Social Media Connect, new user", creds)
                                dispatch(socialMediaActions.socialMediaConnect(creds, (success, user) => {
                                    if (success) {
                                        console.log('Social Media Connect Success -> go to Registration ', success, user)
                                    } else {
                                      console.log('Social Media Connect Failed -> go to Registration ', success, user)
                                    } 
                                  }))
                                  localStorage.removeItem('auth-email')
                                  localStorage.removeItem('auth-social')
                                  localStorage.removeItem('auth-socialToken')

                            } else {
                                localStorage.removeItem('auth-email')
                                localStorage.removeItem('auth-social')
                                localStorage.removeItem('auth-socialToken')
                            }
                            localStorage.removeItem('accType')
                        }))
                        dispatch(success(user));     
                        const ad_id = localStorage.getItem('ad_id')
                        console.log("ad_id registration", ad_id)
                        let adOffset = ""
                        if (ad_id) {
                            adOffset = "?ad_success=" + ad_id
                        }
                        history.push('/dash/welcome' + adOffset)
                    } else {
                        const msg = user.message ? user.message : "Unknown Server Error. Please try again"
                        dispatch(failure(msg.toString()));
                        dispatch(alertActions.error(msg.toString()));
                        // localStorage.removeItem('auth-email')
                        // localStorage.removeItem('auth-social')
                        // localStorage.removeItem('auth-socialToken')
                        if (callback) { callback(false, msg) }
                    }
                },
                error => {
                    if (error.status === 401) {
                        console.log("register: fatal error")
                        dispatch(userActions.endSession(error))    
                    }
                }
            )
    };

    function request() { return { type: registerConstants.REGISTER_REQUEST } }
    function success(user) { return { type: registerConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: registerConstants.REGISTER_FAILURE, error } }
}

function registerName(name) {
    return dispatch => {
        dispatch(update(name))
    }
    function update(name) { return { type: registerConstants.REGISTER_NAME, name }}
}

function registerEmail(email) {
    return dispatch => {
        dispatch(update(email))
    }
    function update(email) { return { type: registerConstants.REGISTER_EMAIL, email }}
}

function registerAddress(address) {
    return dispatch => {
        dispatch(update(address))
    }
    function update(address) { return { type: registerConstants.REGISTER_ADDRESS, address }}
}

function registerPassword(password) {
    return dispatch => {
        dispatch(update(password))
    }
    function update(password) { return { type: registerConstants.REGISTER_PASSWORD, password }}
}

function registerAccType(accType) {
    return dispatch => {
        dispatch(update(accType))
    }
    function update(accType) { return { type: registerConstants.REGISTER_ACC_TYPE, accType }}
}

function registerAffiliate(affiliateCode) {
    return dispatch => {
        dispatch(update(affiliateCode))
    }
    function update(affiliateCode) { return { type: registerConstants.REGISTER_AFFILIATE, affiliateCode}}
}

function clearRegistrationData() {
    return dispatch => {
        dispatch(clearData())
    }
    function clearData() { return { type: registerConstants.CLEAR_ALL_REGISTRATION_DATA }}
}

