import { audioPlayerConstants } from '_constants';

export function audioPlayerData(state = {playlist: [], instructions: null, } , action) {
    switch(action.type) {

        case audioPlayerConstants.UPDATE_PLAYLIST_REQUEST:
            return {
                ...state,
                updatePlaylistRequest: true,
            }
        case audioPlayerConstants.UPDATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlist: action.data,
                instructions: 'CLEAR_PLAYLIST',
                updatePlaylistRequest: false,
            }
        case audioPlayerConstants.INSERT_AT_FRONT_OF_PLAYLIST_REQUEST:
            return {
                ...state,
                updatePlaylistRequest: true,
            }
        case audioPlayerConstants.INSERT_AT_FRONT_OF_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlist: action.data,
                instructions: 'INSERT_AT_FRONT',
                updatePlaylistRequest: false,
            }
        case audioPlayerConstants.ADD_TO_END_OF_PLAYLIST_REQUEST:
            return {
                ...state,
                updatePlaylistRequest: true,
            }
        case audioPlayerConstants.ADD_TO_END_OF_PLAYLIST_SUCCESS:
            return {
                ...state,
                instructions: 'ADD_TO_END',
                playlist: action.data,
                updatePlaylistRequest: false,
            }

        case audioPlayerConstants.CLEAR_PLAYLIST_INSTRUCTIONS_REQUEST:
            return {
                ...state,
                instructions: null,
            }
        case audioPlayerConstants.CLEAR_PLAYLIST_INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                instructions: null,
            }
    
            default: return state; 
        }
    }
        