export const reviewConstants = {
    GET_REVIEWS_REQUEST: 'GET_REVIEWS_REQUEST',
    GET_REVIEWS_SUCCESS: 'GET_REVIEWS_SUCCESS',
    GET_REVIEWS_FAILURE: 'GET_REVIEWS_FAILURE',

    GET_REVIEWS_FOR_ID_REQUEST: 'GET_REVIEWS_FOR_ID_REQUEST',
    GET_REVIEWS_FOR_ID_SUCCESS: 'GET_REVIEWS_FOR_ID_SUCCESS',
    GET_REVIEWS_FOR_ID_FAILURE: 'GET_REVIEWS_FOR_ID_FAILURE',

    SAVE_REVIEW_REQUEST: 'SAVE_REVIEW_REQUEST',
    SAVE_REVIEW_SUCCESS: 'SAVE_REVIEW_SUCCESS',
    SAVE_REVIEW_FAILURE: 'SAVE_REVIEW_FAILURE',

    MARK_REVIEWS_AS_READ_REQUEST: 'MARK_REVIEWS_AS_READ_REQUEST',
    MARK_REVIEWS_AS_READ_SUCCESS: 'MARK_REVIEWS_AS_READ_SUCCESS',
    MARK_REVIEWS_AS_READ_FAILURE: 'MARK_REVIEWS_AS_READ_FAILURE',
    
   }
    