import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { connect } from 'react-redux';

// import { Button } from "components";

import defaultImage from "assets/img/image_placeholder.jpg";
import YouTube from 'react-youtube';
import { Button, Row, Modal, ModalHeader, ModalBody, Card, CardImg, } from "reactstrap";
import { LargeMediaViewer } from "components";
import { caseActions, } from '_actions';
import SweetAlert from "components/react-bootstrap-sweetalert/SweetAlert.jsx";
import {getStreamGalleryMediaURL } from '_helpers';
import { notificationMessageActions } from "_actions"



const opts =  {
  height: '240',
  width: '240',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

class MediaViewer extends React.Component {
  constructor(props) {
    super(props);
    console.log("ImageUpload", props)
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      imagePreviewUrl: this.props.avatar   ? defaultImage : 
                       this.props.imageUrl ? this.props.imageUrl : 
                                             defaultImage,
      index: props.index !== undefined ? props.index : null,
      mediaViewerModal: false,
      user: user,
      alert: null,
      mountedTimestamp: props.mountedTimestamp ? props.mountedTimestamp : new Date().getTime()

    };
    this.toggleMediaViewerModal = this.toggleMediaViewerModal.bind(this)
    this.flagAsInappropriateMedia = this.flagAsInappropriateMedia.bind(this);
    this.flagAsInappropriateMediaAlert = this.flagAsInappropriateMediaAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  toggleMediaViewerModal() {
    this.setState({
     mediaViewerModal: !this.state.mediaViewerModal,
   });
 }

 flagAsInappropriateMediaAlert() {
  this.setState({
    alert: (
      <SweetAlert
        danger
        title="Report this Media?"
        onConfirm={() => this.flagAsInappropriateMedia()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        confirmBtnText="Report"
        cancelBtnText="Cancel"
        showCancel
      >
        <span style={{fontSize: "77%"}}> Are you sure you want to report this media as inappropriate?</span>
      </SweetAlert>
    )
  });
}

hideAlert() {
  this.setState({
    alert: null
  });
}


 

 flagAsInappropriateMedia() {
  const name = this.props.name
  const memberType = this.props.memberType
  const reportCode = 5; //reportCode 5 =  reportAsInappropriate 
  const user_id = this.props.memberId
  const media_index =  this.props.selectedIndex
  const cred = {
    user_id: user_id,
    media_index: media_index
  }
  const description = "Reporting " + memberType + " Name: " + name + ", Member ID: " + user_id +  ", Photo Index: " + media_index
  console.log("report as inappropriate", description)
  this.props.dispatch(caseActions.reportNewCase(reportCode, description, cred, (success, msg) => {
    if (success) {
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Media Reported", 1 ))
      this.hideAlert()
    } else {
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Error reporting media", 2))
  } 
  }))
  // this.props.dispatch(notificationMessageActions.addNotificationToQueue("Media Reported", 3 ))

}

  render() {
    console.log("image", this.state)
    const { user, imagePreviewUrl, mountedTimestamp } = this.state;
    const mediaExt = this.state.imagePreviewUrl.split('.').pop()
    const isVideo = mediaExt === 'mov' || mediaExt === 'mp4' || mediaExt === 'ogg' || mediaExt === 'webm' || mediaExt === 'quicktime';
    const isPhoto = mediaExt === 'jpg' || mediaExt === 'jpeg' || mediaExt === 'png';
    const isYoutube =  !isPhoto &&!isVideo && !this.state.imagePreviewUrl.includes('http') && this.state.imagePreviewUrl.length > 0 
    const loggedIn = user && user.hasOwnProperty('email');

    // const request = `/?request=stream_video&video=`
    const videoId = imagePreviewUrl.substring(imagePreviewUrl.lastIndexOf("/") + 1)
    const videoURL = getStreamGalleryMediaURL(videoId) 
    const date = new Date().getTime()

    console.log("videoURL", videoURL)

    console.log("isVideo", isVideo, mediaExt)
    return (
      <div className="fileinput text-center" style={{display:"inherit"}}>
        {this.state.alert}
        <Card className ="mb-0">
          <div >
            { isVideo && 
            <div 
            className="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer" 
            onClick={this.toggleMediaViewerModal }>
              <video onContextMenu={e => e.preventDefault()} width="320" height="240" controls autoPlay={false} style={{pointerEvents: "none"}}>
                  <source src={this.state.imagePreviewUrl} type={"video/" + mediaExt} autostart="false"/>
                  Your browser does not support the video tag.
              </video>
            </div>
              }
          </div>
          {isYoutube && 
            <div onClick={this.toggleMediaViewerModal}>
              <YouTube
              videoId={this.state.imagePreviewUrl}
              opts={opts}
              onReady={this._onReady}
              containerClassName="embed-responsive embed-responsive-1by1 card-img-top cursor-pointer"
              className="pointer-events-none"
              />
            </div>
            }
          { isPhoto &&
            <CardImg
              alt="..."
              src={`${this.state.imagePreviewUrl}?t=${mountedTimestamp}`}
              onClick={this.toggleMediaViewerModal}
              className="cursor-pointer"
              top
            />
              }
          </Card>
          <Row>
            <Modal
              isOpen={this.state.mediaViewerModal}
              toggle={this.toggleMediaViewerModal}
              modalClassName="text-center"
              fade={false}
              size="lg"
              > 
              <ModalHeader toggle={this.toggleMediaViewerModal}> Media </ModalHeader>
              <ModalBody>
                {this.state.imagePreviewUrl && 
                  <LargeMediaViewer img imageUrl={`${this.state.imagePreviewUrl}`}/>
                }
              {loggedIn && 
                <div> 
                  <br/>
                  <Button className="title text-center" outline color="danger" size="sm" onClick={()=> this.flagAsInappropriateMediaAlert()}> 
                  Flag as Inappropriate
                  </Button>
                </div>
                }
              </ModalBody>
            </Modal>
          </Row>
      </div>
    );
  }
}

MediaViewer.propTypes = {
  avatar: PropTypes.bool,
  imageUrl: PropTypes.string,
  memberType: PropTypes.string,
  memberId: PropTypes.number,
  selectedIndex: PropTypes.number,
  name: PropTypes.string,
};


function mapStateToProps(state) {
  return {

  };
}

const connectedMediaViewer = connect(mapStateToProps)(MediaViewer);
export { connectedMediaViewer as MediaViewer  }; 