import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { config } from "_constants";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <nav>
            <ul>
            <li>
                <Link to={ { pathname: '/home', } }>
                      LiveNyte
                </Link>
            </li>
            <li>
                <a href={config.commonUrl + config.termsLocalPath}>
                   Terms of Service
                </a>
            </li>
            <li>
                <a href={config.commonUrl + config.privacyLocalPath}>
                   Privacy Policy
                </a>
            </li>
            <li>
                <Link to={ { pathname: '/dash/faq', } }>
                      FAQ
                </Link>
            </li>
            <li>
                <Link to={ { pathname: '/dash/affiliateProgram', } }>
                      Affiliate Program
                </Link>
            </li>
            <li>
            <a href="mailto:info@livenyte.com">
                   Contact Us
                </a>
            </li>
            </ul>
          </nav>
          <div className="copyright">
         Copyright &copy; {1900 + new Date().getYear()}, by LiveNyte, Inc. All Rights Reserved.
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
