import { agencyConstants } from '_constants';
import { userService } from '_services';
import { memberActions, userActions } from '_actions';
import { history, traitStatusForEvents, subEventsNeedAttention } from '_helpers';
import * as moment from 'moment';

export const agencyActions = {
    agencyQuery,
    getClients,
    loginAsClient,
    logoutAsClient,
    updatePin,
    authorizeAgencyAccess,
    revokeAgencyAccess,
};

function agencyQuery(callback = null) {
    const params = {
        request: 'agency_query',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("agencyQuery: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: agencyConstants.AGENCY_QUERY_REQUEST} }
    function success(data)  { return { type: agencyConstants.AGENCY_QUERY_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.AGENCY_QUERY_FAILURE, error } }
}

function getClients(callback = null) {
    const params = {
        request: 'agency_get_clients',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getClients", data)
                    if (data.bus_clients.length > 0) {
                        for (let i = 0; i < data.bus_clients.length; i++) {
                            let bus = data.bus_clients[i]
                            console.log("bus_events", bus.events)
                            let events = []
                            events = Object.keys(bus.events).map( key => {
                                // prevent overriding event_id if this call is made by business for an
                                // event not of its own
                                if (!bus.events[key].hasOwnProperty('event_id')) {
                                    bus.events[key]['event_id'] = parseInt(key, 10);
                                }
                                return bus.events[key];
                            }).sort( (left, right) => {
                                return moment.utc(left.ev_start_time).diff(moment.utc(right.ev_start_time))
                            });
                            let sbTraitStatus = traitStatusForEvents(null, events)
                            let subEventsNeedingAttention = events.filter( event => subEventsNeedAttention(event, sbTraitStatus))
                            data.bus_clients[i]['events'] = events
                            data.bus_clients[i]['subEventsNeedingAttention'] = subEventsNeedingAttention
                        }                                              
                    }
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getClients: fatal error")
                    dispatch(userActions.endSession(error))    
                } 
            }
        )
    }

    function request()      { return { type: agencyConstants.GET_CLIENTS_REQUEST} }
    function success(data, events)  { return { type: agencyConstants.GET_CLIENTS_SUCCESS, data, events } }
    function failure(error) { return { type: agencyConstants.GET_CLIENTS_FAILURE, error } }
}

function loginAsClient(spId, callback = null) {
    const params = {
        request: 'agency_switch_user',
        sp_id: spId,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("loginAsClient", data)
                    const agencyInfo = localStorage.getItem('user')
                    localStorage.setItem('agencyUser', agencyInfo)
                    dispatch(completeLogin(data));
                    // dispatch(specialistQuery())
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("loginAsClient: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: agencyConstants.LOGIN_AS_CLIENT_REQUEST} }
    //function success(data)  { return { type: agencyConstants.LOGIN_AS_CLIENT_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.LOGIN_AS_CLIENT_FAILURE, error } }
}

function completeLogin(user) {
    return dispatch => {
        console.log("completeLogin Agency", user)
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        user['is_user'] = !(user.is_business || user.is_specialist || user.is_agency)
        user['agency_as_client'] = true
        localStorage.setItem('user', JSON.stringify(user));
        
        dispatch(memberActions.memberQuery());        
        dispatch(success(user));
        if (user.is_business) {
            history.push('/dash/business-dashboard')
        } else if (user.is_specialist) {
            history.push('/dash/celeb-dashboard')
        } else {
            const eventId = localStorage.getItem('eventId');
            if (eventId) {
                //history.push(`${eventId}`)
                history.goBack()
                localStorage.removeItem('eventId');
            } else {
                history.push('/dash/user-dashboard')
            }
        }               
    }

    function success(user) { return { type: agencyConstants.LOGIN_AS_CLIENT_SUCCESS, user } }
}

function logoutAsClient(callback = null) {
    const params = {
        request: 'agency_logout_user',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("logoutAsClient", data)                
                    const agencyInfo = localStorage.getItem('agencyUser')
                    localStorage.setItem('user', agencyInfo)
                    localStorage.removeItem('agencyUser')
                    dispatch(userActions.clearStore())
                    dispatch(memberActions.memberQuery());        
                    dispatch(success(data));
                    history.replace('/dash/agency-dashboard')
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("logoutAsClient: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: agencyConstants.LOGOUT_AS_CLIENT_REQUEST} }
    function success(data)  { return { type: agencyConstants.LOGOUT_AS_CLIENT_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.LOGOUT_AS_CLIENT_FAILURE, error } }
}

function updatePin(pin, callback = null) {
    const params = {
        request: 'agency_update_pin',
        pin: pin,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
       .then(
            data => {
                if (data.success) {
                    console.log("updatePin", data)
                    dispatch(success(data));
                    dispatch(agencyQuery());
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updatePin: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        ) 
    }

    function request()      { return { type: agencyConstants.UPDATE_PIN_REQUEST} }
    function success(data)  { return { type: agencyConstants.UPDATE_PIN_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.UPDATE_PIN_FAILURE, error } }
}


function authorizeAgencyAccess(pin, agency_username, callback = null) {
    const params = {
        request: 'authorize_agency_access',
        agency_username: agency_username,
        pin: pin,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("authorizeAgencyAccess", data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    const userIsBusiness = user && user.is_business
                    const userIsSpecialist = user && user.is_specialist
                    
                    if (userIsBusiness) {
                        const busId = user && user.user_id
                        dispatch(memberActions.businessQuery(busId));        
                    }
                    if (userIsSpecialist) {
                        dispatch(memberActions.specialistQuery());        
                    }  
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("authorizeAgencyAccess: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )        
    }

    function request()      { return { type: agencyConstants.AUTHORIZE_AGENCY_ACCESS_REQUEST} }
    function success(data)  { return { type: agencyConstants.AUTHORIZE_AGENCY_ACCESS_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.AUTHORIZE_AGENCY_ACCESS_FAILURE, error } }
}


function revokeAgencyAccess(callback = null) {
    const params = {
        request: 'revoke_agency_access',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("revokeAgencyAccess", data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    const userIsBusiness = user && user.is_business
                    const userIsSpecialist = user && user.is_specialist

                    if (userIsBusiness) {
                        const busId = user && user.user_id
                        dispatch(memberActions.businessQuery(busId));        
                    }
                    if (userIsSpecialist) {
                        dispatch(memberActions.specialistQuery());        
                    }

                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("revokeAgencyAccess: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: agencyConstants.REVOKE_AGENCY_ACCESS_REQUEST} }
    function success(data)  { return { type: agencyConstants.REVOKE_AGENCY_ACCESS_SUCCESS, data } }
    function failure(error) { return { type: agencyConstants.REVOKE_AGENCY_ACCESS_FAILURE, error } }
}


