import { subscriptionConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const subscriptionActions = {
    getSubscriptions,
    createSubscription,
    cancelSubscription,
    updateSubscription,
    reactivateSubscription,
};

function getSubscriptions(args, callback = null) {
    // required arguments: none
    // optional arguments: 
    // 1) 'purchased': Returns all subscriptions purchased by user
    //   OR
    // 2) filter type
    //  a) 'byId': Returns all subscriptions created byId
    //  b) 'lookFor': Returns all subscriptions where lookFor matches name of subscriptions or name of owner

    const params = {
        request: 'get_subscriptions',
      };

    if (args.hasOwnProperty('purchased')) {
        params['purchased'] = true
    } else {
        if (args.hasOwnProperty('active')) {
            params['active'] = true
        }
        if (args.hasOwnProperty('byId')) {
            params['by_id'] = args.byId
        }
        if (args.hasOwnProperty('lookFor')) {
            params['look_for'] = args.lookFor
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Subscriptions");
                    console.log(data)
                
                    dispatch(success(data.subscriptions));
                    if (callback) {
                        callback(true, data.subscriptions)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getSubscriptions: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()            { return { type: subscriptionConstants.GET_SUBSCRIPTIONS_REQUEST} }
    function success(subscriptions) { return { type: subscriptionConstants.GET_SUBSCRIPTIONS_SUCCESS, subscriptions } }
    function failure(error)       { return { type: subscriptionConstants.GET_SUBSCRIPTIONS_FAILURE, error } }
}

function createSubscription(args, callback=null) {
    /* required arguments: planId, card
     On success: 
        'status'   => active        All good, subscription started
        'status'   => trialing      Plan's trial period started
        'status'   => incomplete    Payment method failed. Notify customer of failure and collect a new payment method
     */
    const params = {
        request: 'create_subscription',
        plan_id: args.planId,
        card:    args.card,
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Created Subscription");
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getSubscriptions({active: true}))
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createSubscription: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: subscriptionConstants.CREATE_SUBSCRIPTION_REQUEST} }
    function success(data)   { return { type: subscriptionConstants.CREATE_SUBSCRIPTION_SUCCESS, data } }
    function failure(error)  { return { type: subscriptionConstants.CREATE_SUBSCRIPTION_FAILURE, error } }
}

function cancelSubscription(args, callback=null) {
    // required arguments: subscriptionId
    const params = {
        request: 'cancel_subscription',
        subscription_id: args.subscriptionId,
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Cancelled subscription");
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getSubscriptions({active: true}))
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("cancelSubscription: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST} }
    function success(data)   { return { type: subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS, data } }
    function failure(error)  { return { type: subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE, error } }
}

function reactivateSubscription(args, callback=null) {
    // required arguments: subscriptionId
    const params = {
        request: 'reactivate_subscription',
        subscription_id: args.subscriptionId,
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Reactivate subscription");
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getSubscriptions({active: true}))
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("reactivateSubscription: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: subscriptionConstants.REACTIVATE_SUBSCRIPTION_REQUEST} }
    function success(data)   { return { type: subscriptionConstants.REACTIVATE_SUBSCRIPTION_SUCCESS, data } }
    function failure(error)  { return { type: subscriptionConstants.REACTIVATE_SUBSCRIPTION_FAILURE, error } }
}
function updateSubscription(args, callback=null) {
    /* required arguments: subscriptionId, card
       on success, status indicates further action
       status == 'succeeded'               => payment method updated
       status == 'requires_payment_method' => The attempt to pay with the new payment method failed. Collect a new payment method.
       status == 'requires_action'         => In order to complete payment, further action is required by the customer
                                              3D secure. Notify customer that further action is required by him/her.
    
        https://stripe.com/docs/billing/subscriptions/payment

        ES-Next
        const stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

        // This can be found on invoice.payment_intent.client_secret
        const paymentIntentSecret = 'pi_91_secret_W9';

        const {paymentIntent, error} = await stripe.handleCardPayment(clientSecret);

        if (error) {
        // Display error.message in your UI.
        } else {
        // The payment has succeeded. Display a success message.
        }

        Javascript
        var stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

        // This can be found on invoice.payment_intent.client_secret
        var paymentIntentSecret = 'pi_91_secret_W9';

        stripe.handleCardPayment(paymentIntentSecret).then(function(result) {
        if (result.error) {
            // Display error.message in your UI.
        } else {
            // The payment has succeeded. Display a success message.
        }
        });
    */
     const params = {
        request: 'update_subscription',
        subscription_id: args.subscriptionId,
        card: args.card,
      };
   console.log("update subscripton params", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Updated Subscription");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateSubscription: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_REQUEST} }
    function success(data)   { return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_SUCCESS, data } }
    function failure(error)  { return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_FAILURE, error } }
}
