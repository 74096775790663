export const planConstants = {
    GET_PLANS_REQUEST: 'GET_PLANS_REQUEST',
    GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
    GET_PLANS_FAILURE: 'GET_PLANS_FAILURE',

    CREATE_PLAN_REQUEST: 'CREATE_PLAN_REQUEST',
    CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
    CREATE_PLAN_FAILURE: 'CREATE_PLAN_FAILURE',

    UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
    UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
    UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',

    DISABLE_PLAN_REQUEST: 'DISABLE_PLAN_REQUEST',
    DISABLE_PLAN_SUCCESS: 'DISABLE_PLAN_SUCCESS',
    DISABLE_PLAN_FAILURE: 'DISABLE_PLAN_FAILURE',

}
