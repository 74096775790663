import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { PanelHeader } from "components";
import { PulseLoader } from 'react-spinners';


class LoadingCard extends React.Component {
  render() {
    return (
    <div className="content">
        <PanelHeader size="sm" />
          <Row>
            <Col lg={12} xs={12}>
              <Card className="card-chart">
                <CardBody>
                  <h2 className="inline-block"> Loading</h2>&nbsp;
                    <div className='sweet-loading inline-block'
                      style={ { transform: "translate(0px,  4px)" }}>
                      <PulseLoader
                        sizeUnit={"px"}
                        size={7}
                        color={'#f06482'}
                        
                      />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      </div>
    );
  }
}

export default LoadingCard;
