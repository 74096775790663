import React from "react";
import {
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';
import Linkify from 'react-linkify';
// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";
import { config } from '_constants';
          

class AffiliateProgramTermsModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("AffiliateProgramTermsModal constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
  }

 

  render() {
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    return (
      <ModalBody>
        <Linkify> 
          <small>  
          Acceptance and continued participation in LiveNyte's Affiliate Program is subject to LiveNyte's approval. We may also in our sole discretion and at any time 
          discontinue providing the Affiliate Program, or any part thereof, with or without notice. 
          You agree that any termination of your access to the Affiliate Program may be effected without prior notice, and you acknowledge and agree that we may immediately 
          deactivate or delete your account and all related information and material in your account and/or bar any further access to such information or to the Affiliate Program.
          Further, you agree that we are not liable to you or any third party for any termination of your access to the Affiliate Program.
          <br/><br/>
          To the maximum extent permitted by applicable law, you agree to hold harmless and indemnify LiveNyte, Inc. and its employees, officers, agents, or other partners, 
          from and against any third party claim arising from or in any way related to your use of the Affiliate Program, including any liability or expense arising from all claims, 
          losses, damages (actual and/or consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature including physical or monetary harm 
          or civil or criminal claims. We shall use good faith efforts to provide you with written notice of such claim, suit or action. In addition, you expressly waive and 
          relinquish any and all rights and benefits which you may have under any other state or federal statute or common law principle of similar effect, to the fullest 
          extent permitted by law.
          <br/><br/>
          This Affiliate Program is void where prohibited by law, and the right to access and use the Services is revoked in such jurisdictions.
          <br/><br/>
          This Affiliate Program, together with our Privacy Policy available at {config.commonUrl + config.privacyLocalPath}, Please review our Privacy Policy carefully. We reserve the right, 
          in our sole discretion, to change, modify, add or remove any and all portions of this Affiliate Program, 
          at any time. It is your responsibility to check this Affiliate Program periodically for changes. 
          The latest version of this Affiliate Program supersedes any previous versions of this Affiliate Program.  
          Your continued use of the Affiliate Program following the posting of changes will mean that you accept and agree to the changes. 
          If you do not agree to the new terms, please stop using the Affiliate Program. Questions about the Terms of Service should be sent to support@livenyte.com.
          </small>
        </Linkify> 
      </ModalBody>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

const connectedAffiliateProgramTermsModal = connect(mapStateToProps)(AffiliateProgramTermsModal);
export { connectedAffiliateProgramTermsModal as AffiliateProgramTermsModal }; 