import { featuredConstants } from '_constants';
import { userService } from '_services';
//import { userActions } from '_actions';

export const featuredActions = {
    featuredCelebs,
};

function featuredCelebs(args, callback = null) {
    let params = {
        request: 'get_featured_celebrities',
      }
    if (args) {
        if  (args.hasOwnProperty('limit')) {
            params['limit'] = args.limit
        }
        if (args.hasOwnProperty('latitude') && args.hasOwnProperty('longitude')) {
            params['latitude'] = args.latitude
            params['longitude'] = args.longitude
        }
        if (args.hasOwnProperty('distance')) {
            params['distance'] = args.distance
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    if (callback) { callback(msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("featuredCelebs: fatal error")
                    //dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: featuredConstants.FEATURED_CELEBS_REQUEST} }
    function success(data)  { return { type: featuredConstants.FEATURED_CELEBS_SUCCESS, data } }
    function failure(error) { return { type: featuredConstants.FEATURED_CELEBS_FAILURE, error } }
}
