import { collectionConstants } from '_constants';

export function collectionData(state = {}, action) {
    switch(action.type) {
        case collectionConstants.GET_COLLECTIONS_REQUEST:
            return {
                ...state,
                getCollections: true,
            };
        case collectionConstants.GET_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collections: action.collections,
                getCollections: false,
            };
        case collectionConstants.GET_COLLECTIONS_FAILURE:
            return {
                ...state,
                getCollections: false,
            };        
        case collectionConstants.CREATE_COLLECTION_REQUEST:
            return {
                ...state,
                createCollection: true,
            };
        case collectionConstants.CREATE_COLLECTION_SUCCESS:
            return {
                ...state,
                createCollection: false,
            };
        case collectionConstants.CREATE_COLLECTION_FAILURE:
            return {
                ...state,
                createCollection: false,
            };         
        case collectionConstants.UPDATE_COLLECTION_REQUEST:
            return {
                ...state,
                updateCollection: true,
            };
        case collectionConstants.UPDATE_COLLECTION_SUCCESS:
            return {
                ...state,
                updateCollection: false,
            };
        case collectionConstants.UPDATE_COLLECTION_FAILURE:
            return {
                ...state,
                updateCollection: false,
            };                       
        default:
            return state;
        }

}
