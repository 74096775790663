import { ticketConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const ticketActions = {
    getTicketsForEvent,
    getTicketForEvent,
    createUpdateTicketForEvent,
    purchaseTickets,
    getPurchasedTickets,
    getPurchasedTicketReceipt,
    refundTickets,
    getPrivateTicketCodes,
};

function getTicketsForEvent(eventId) {
    const params = {
        request: 'get_tickets_for_event',
        event_id: eventId
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Tickets For Event");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTicketsForEvent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: ticketConstants.GET_TICKETS_REQUEST} }
    function success(data)   { return { type: ticketConstants.GET_TICKETS_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.GET_TICKETS_FAILURE, error } }
}

function getTicketForEvent(eventId, ticketId) {
    // Business only function
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    const userIsBusiness = user.is_business;
    console.log(user.is_business);
  
    if (!userIsBusiness) {
        return (dispatch) => {
            dispatch(failure(""));
        }
    }

    const params = {
        request: 'business_ticket_query',
        event_id: eventId,
        ticket_id: ticketId,
      };

      return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Ticket For Event");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTicketForEvent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.GET_TICKET_REQUEST} }
    function success(data)   { return { type: ticketConstants.GET_TICKET_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.GET_TICKET_FAILURE, error } }
}

function createUpdateTicketForEvent(ticket, eventId, callback = null) {
    // Business only function
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("user", user);
    const userIsBusiness = user.is_business;
    console.log(user.is_business, userIsBusiness);
  
    if (!userIsBusiness) {
        return (dispatch) => {
            dispatch(failure("Only Business Can Create/Update Ticket"));
        }
    }

    const params = {
        request: 'business_ticket_create_update',
        event_id: eventId,
        name: ticket.name,
        price: ticket.price,
        quantity: ticket.quantity,
        refundable: ticket.refundable,
        sales_start: ticket.sales_start,
        sales_end: ticket.sales_end,
        description: ticket.description,
        private: ticket.private,
      };
      if (ticket.ticket_id) {
          params['ticket_id'] = ticket.ticket_id
      }
      if (ticket.purchase_limit) {
          params['purchase_limit'] = ticket.purchase_limit
      }

      return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Create/Update Ticket response");
                    console.log(data)
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createUpdateTicketForEvent: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.CREATE_UPDATE_TICKET_REQUEST} }
    function success(data)   { return { type: ticketConstants.CREATE_UPDATE_TICKET_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.CREATE_UPDATE_TICKET_FAILURE, error } }
}

function purchaseTickets(ticket, purchaseInfo, callback = null) {
    let params = {
        request: 'user_purchase_tickets',
        event_id: ticket.event_id,
        ticket_id: ticket.ticket_id,
        quantity: purchaseInfo.num,
        price: ticket.price,
        payment_company: ticket.price > 0 ? 'Stripe' : 'LiveNyte',
        payment_method: ticket.price > 0 ? purchaseInfo.paymentMethod : 'FreeTicket',
        is_token: 1,    
    }

    if (purchaseInfo.hasOwnProperty('email')) {
        params['email'] = purchaseInfo.email
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Purchase Ticket response");
                    console.log(data)
                    dispatch(success(data));
                    dispatch(getTicketsForEvent(ticket.event_id))
                    if (callback) { 
                        callback (true, data)
                    }
                    return { data };
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("purchaseTickets: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.PURCHASE_TICKET_REQUEST} }
    function success(data)   { return { type: ticketConstants.PURCHASE_TICKET_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.PURCHASE_TICKET_FAILURE, error } }
}

function getPurchasedTickets(eventId = null, callback = null)  {
    const params = {
        request: 'user_get_purchased_tickets',
    }
    if (eventId != null) {
        params['event_id'] = eventId    
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Get Purchased Ticket response", data);
                    dispatch(success(data));
                    if (callback !== null ) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPurchasedTickets: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.GET_PURCHASED_TICKET_REQUEST} }
    function success(data)   { return { type: ticketConstants.GET_PURCHASED_TICKET_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.GET_PURCHASED_TICKET_FAILURE, error } }

}

function getPurchasedTicketReceipt(purchaseId, callback = null) {
    const params = {
        request: 'user_get_ticket_receipt',
        purchase_id: purchaseId,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Purchased Ticket Receipt response", data);
                    dispatch(success(data));
                    if (callback !== null ) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPurchasedTicketReceipt: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.GET_PURCHASED_TICKET_RECEIPT_REQUEST} }
    function success(data)   { return { type: ticketConstants.GET_PURCHASED_TICKET_RECEIPT_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.GET_PURCHASED_TICKET_RECEIPT_FAILURE, error } }
}

function refundTickets(purchaseId, callback = null) {
    const params = {
        request: 'user_refund_tickets',
        purchase_id: purchaseId,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("refundTickets response", data);
                    dispatch(success(data));
                    if (callback !== null ) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("refundTickets: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: ticketConstants.REFUND_TICKETS_REQUEST} }
    function success(data)   { return { type: ticketConstants.REFUND_TICKETS_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.REFUND_TICKETS_FAILURE, error } }
}

function getPrivateTicketCodes(ticketId, offset, limit, callback = null) {
    const params = {
        request: 'business_get_private_ticket_codes',
        ticket_id: ticketId,
        offset: offset,   // starting from
        limit: limit,
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getPrivateTicketCodes", data);
                    dispatch(success(data));
                    if (callback !== null ) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("refundTickets: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()       { return { type: ticketConstants.GET_PRIVATE_TICKETS_CODES_REQUEST} }
    function success(data)   { return { type: ticketConstants.GET_PRIVATE_TICKETS_CODES_SUCCESS, data } }
    function failure(error)  { return { type: ticketConstants.GET_PRIVATE_TICKETS_CODES_FAILURE, error } }
}