export const paymentConstants = {
    PAYMENTS_QUERY_REQUEST: 'PAYMENTS_QUERY_REQUEST',
    PAYMENTS_QUERY_SUCCESS: 'PAYMENTS_QUERY_SUCCESS',
    PAYMENTS_QUERY_FAILURE: 'PAYMENTS_QUERY_FAILURE',

    BOOKINGS_QUERY_REQUEST: 'BOOKINGS_QUERY_REQUEST',
    BOOKINGS_QUERY_SUCCESS: 'BOOKINGS_QUERY_SUCCESS',
    BOOKINGS_QUERY_FAILURE: 'BOOKINGS_QUERY_FAILURE',

};

