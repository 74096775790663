import { fanModeConstants } from '_constants';

export function fanModeData(state = {fanMode: false, fanModeRequest: false, } , action) {
    switch(action.type) {

        case fanModeConstants.TURN_FAN_MODE_ON_REQUEST:
            return {
                ...state,
                fanModeRequest: true,
            }
        case fanModeConstants.TURN_FAN_MODE_ON_SUCCESS:
            return {
                ...state,
                fanModeRequest: false,
                fanMode: true
            }
        case fanModeConstants.TURN_FAN_MODE_OFF_REQUEST:
            return {
                ...state,
                fanModeRequest: true,
            }
        case fanModeConstants.TURN_FAN_MODE_OFF_SUCCESS:
            return {
                ...state,
                fanModeRequest: false,
                fanMode: false
            }
            default: return state; 
        }
    }
        