import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";




class AgencyAccountBox extends React.Component {
    render() {
  
      return (
        <div id="agency">
          <div className="text-center text-white pt-2 mt-3"> 
                <h2 className="text-white surtitle text-uppercase">Agency</h2>
                <h4 className="text-white text-uppercase surtitle mt-2">Easily manage your clients</h4>
          </div>
          <div className="pricing card-group flex-column flex-md-row mb-3 mt-6">
            <Card className="card-pricing border-0 text-center mb-4">
              <CardBody className="px-lg-7 pb-4">
                <div className="icon icon-lg icon-shape bg-gradient-livenyte text-white rounded-circle mb-2 mt-3">
                    <i className="fas fa-star " />
                </div>
                <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                  Manage Talent
                </h4>
                <p>Access your connected talents' accounts to help them get booked and sell digital content. 
                </p>
                </CardBody>
                <CardFooter>
                <div className="icon icon-lg icon-shape bg-gradient-danger text-white rounded-circle mb-2 mt-3">
                    <i className="ni ni-building " />
                </div>
                <h4 className="text-uppercase ls-1 text-danger py-3 mb-0">
                  Manage Businesses
                </h4>
                <p>Access your connected businesses' accounts to help them host events, book talent, and sell tickets. 
                </p>                        
                </CardFooter>
              </Card>
              <Card className="card-pricing zoom-in-1 bg-dark  border-0 text-center mb-4 rounded">
                <CardBody className="px-lg-12 pb-3 bg-transparent text-white">
                  <div className="icon icon-lg icon-shape bg-gradient-tertiary text-white rounded-circle mb-2 mt-3">
                    <i className="ni ni-bell-55 " />
                  </div>
                  <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                  Summary Notifications
                  </h4>
                  <p>Get notified when important actions need to be taken on connected accounts.
                  </p>
                </CardBody>
                <CardFooter className="rounded bg-transparent text-white">
                  <div className="icon icon-lg icon-shape bg-gradient-info text-white rounded-circle mb-2 mt-3">
                      <i className="fas fa-signal" />
                  </div>
                  <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                  Enhance workflow
                  </h4>
                  <p>Easily manage your clients and save time booking and promoting events.
                  </p>    
                </CardFooter>
            </Card>
          </div>
    </div>
      );
    }
  }

export default AgencyAccountBox;
