import { productConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const productActions = {
    getProductTypes,
    getProducts,
    createProduct,
    updateProduct,
    disableProduct,
    enableProduct,
    getFeaturedProducts,
    getRemitsForProducts
};

function getProductTypes(args, callback = null) {
    // required arguments: none    

    const params = {
        request: 'get_product_types',
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Product Types");
                    console.log(data)
                
                    dispatch(success(data.product_types));
                    if (callback) {
                        callback(true, data.product_types)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getProducts: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()             { return { type: productConstants.GET_PRODUCT_TYPES_REQUEST} }
    function success(productTypes) { return { type: productConstants.GET_PRODUCT_TYPES_SUCCESS, productTypes } }
    function failure(error)        { return { type: productConstants.GET_PRODUCT_TYPES_FAILURE, error } }
}

function getProducts(args, callback = null) {
    // required arguments: none    
    // optional arguments: 
    // ----- Called by creator ---------
    // 1) 'createdByMe': Returns all products created by user
    //   OR
    // 2) 'productId': Returns  product with this productId
    //   OR
    //-------- Called by user -----------
    // 4) filter type
    //  a) 'byId': Returns all products created byId
    //  b) 'lookFor': Returns all products where lookFor matches name of product or product_type or name of owner

    const params = {
        request: 'get_products',
      };

    if (args.hasOwnProperty('createdByMe')) {
        params['created_by_me'] = true
    } else if (args.hasOwnProperty('productId')) {
        params['product_id'] = args.productId    
    } else  {        
        if (args.hasOwnProperty('byId')) {
            params['by_id'] = args.byId
        }
        if (args.hasOwnProperty('lookFor')) {
            params['look_for'] = args.lookFor
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Products");
                    console.log(data)
                
                    dispatch(success(data.products));
                    if (callback) {
                        callback(true, data.products)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getProducts: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()         { return { type: productConstants.GET_PRODUCTS_REQUEST} }
    function success(products) { return { type: productConstants.GET_PRODUCTS_SUCCESS, products } }
    function failure(error)    { return { type: productConstants.GET_PRODUCTS_FAILURE, error } }
}

function createProduct(args, callback = null) {
    // required arguments: typeId, name, desc
    // optonal arguments: subtypeTypeIds, tags, image, imageType    
    // where subtypeTypeIds is an array of ids 
    // and tags is an array of strings

    const params = {
        request: 'create_product',
        type_id: args.typeId, // product's type_id
        name: args.name,
        desc: args.description,
      };

    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    if (args.hasOwnProperty('subtypeTypeIds')) {
        params['subtype_type_ids'] = args.subtypeTypeIds
    }
    if (args.hasOwnProperty('tags')) {
        params['tags'] = JSON.stringify(args.tags)
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Create Product");
                    console.log(data)

                    dispatch(getProducts({createdByMe: true}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createProduct: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: productConstants.CREATE_PRODUCT_REQUEST} }
    function success(data)  { return { type: productConstants.CREATE_PRODUCT_SUCCESS, data } }
    function failure(error) { return { type: productConstants.CREATE_PRODUCT_FAILURE, error } }
}

function updateProduct(args, callback = null) {
    // required arguments: productId, name, desc
    // optonal arguments: typeId, image, imageType    

    const params = {
        request: 'update_product',
        product_id: args.productId,        
        name: args.name,
        desc: args.description,
      };

    if (args.hasOwnProperty('type_id')) {
        params['type_id'] = args.typeId
    }
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    if (args.hasOwnProperty('subtypeTypeIds')) {
        params['subtype_type_ids'] = args.subtypeTypeIds
    }
    if (args.hasOwnProperty('tags')) {
        params['tags'] = JSON.stringify(args.tags)
    }
    console.log("params.tags", params.tags)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Update Product");
                    console.log(data)
                
                    dispatch(getProducts({createdByMe: true}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateProduct: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: productConstants.UPDATE_PRODUCT_REQUEST} }
    function success(data)   { return { type: productConstants.UPDATE_PRODUCT_SUCCESS, data } }
    function failure(error)  { return { type: productConstants.UPDATE_PRODUCT_FAILURE, error } }
}

function disableProduct(productId, callback = null) {
    // required arguments: productId    

    const params = {
        request: 'disable_product',
        product_id: productId,         
      };

    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Disable Product");
                    console.log(data)
                
                    dispatch(getProducts({createdByMe: true}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("disableProduct: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: productConstants.DISABLE_PRODUCT_REQUEST} }
    function success(data)   { return { type: productConstants.DISABLE_PRODUCT_SUCCESS, data } }
    function failure(error)  { return { type: productConstants.DISABLE_PRODUCT_FAILURE, error } }
}

function enableProduct(productId, callback = null) {
    // required arguments: productId    

    const params = {
        request: 'enable_product',
        product_id: productId,         
      };

    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Enable Product");
                    console.log(data)
                
                    dispatch(getProducts({createdByMe: true}))
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("enableProduct: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: productConstants.ENABLE_PRODUCT_REQUEST} }
    function success(data)   { return { type: productConstants.ENABLE_PRODUCT_SUCCESS, data } }
    function failure(error)  { return { type: productConstants.ENABLE_PRODUCT_FAILURE, error } }
}

function getFeaturedProducts(args, callback = null) {
    // required arguments: none    

    const params = {
        request: 'get_featured_products',
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Featured Products");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getFeaturedProducts: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()             { return { type: productConstants.GET_FEATURED_PRODUCTS_REQUEST} }
    function success(data)         { return { type: productConstants.GET_FEATURED_PRODUCTS_SUCCESS, data } }
    function failure(error)        { return { type: productConstants.GET_FEATURED_PRODUCTS_FAILURE, error } }
}

function getRemitsForProducts(args, callback = null) {
    // required arguments: none
    // optional arguments:
    // 'start_date' one of 'this_month', 'last_three_months', 'last_six_months', 'this_year', 'last_year' or a specified date
    // 'end_date' can be specified date; else it will be determined based on start_date
    // 'product_id'
    const params = {
        request: 'get_remits_for_products',
      };

    if (args.hasOwnProperty('start_date')) {
	params['start_date'] = args.start_date;
    }
    if (args.hasOwnProperty('end_date')) {
	params['end_date'] = args.end_date;
    }
    if (args.hasOwnProperty('product_id')) {
        params['product_id'] = args.product_id;
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Get Remits for Products");
                    console.log(data);
                
                    dispatch(success(data.remits));
                    if (callback) {
                        callback(true, data.remits)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again";
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getRemitsForProducts: fatal error");
                    dispatch(userActions.endSession(error)) ;
                }
            }
        );
    }
    
    function request()       { return { type: productConstants.GET_REMITS_FOR_PRODUCTS_REQUEST} }
    function success(remits) { return { type: productConstants.GET_REMITS_FOR_PRODUCTS_SUCCESS, remits } }
    function failure(error)  { return { type: productConstants.GET_REMITS_FOR_PRODUCTS_FAILURE, error } }
}
