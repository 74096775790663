import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "components";
import {  trim, } from '_helpers';

import { 
  Col, 
  Row
} from "reactstrap";

class CardSocials extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {

    };
    
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="button-container">
      <Row>
        <Col xs={12} lg={11} className="ml-auto mr-auto">
          <Row>
            {this.props.socials.map((prop, key) => {
                return (
                  <Col xs={3} lg={3} key={key} className="ml-auto mr-auto"> 
                      <Button
                        neutral
                        icon
                        round
                        className={prop.buttonClass}
                        size={this.props.size}
                        key={key}
                        // href={prop.link}
                      >
                        <i className={prop.icon} />
                      </Button>
                      {trim(prop.count)}
                  </Col>
                );
              })}
            </Row> 
          </Col>
        </Row>
      </div>
    );
  }
}

CardSocials.propTypes = {
  // size of all social buttons
  size: PropTypes.oneOf(["sm", "lg"]),
  // example: [{icon: "name of icon", href="href of icon"},...]
  socials: PropTypes.arrayOf(PropTypes.object)
};

export default CardSocials;
