export const authConstants = {
    CLAIM_AUTH_CODE_REQUEST: 'CLAIM_AUTH_CODE_REQUEST',
    CLAIM_AUTH_CODE_SUCCESS: 'CLAIM_AUTH_CODE_SUCCESS',
    CLAIM_AUTH_CODE_FAILURE: 'CLAIM_AUTH_CODE_FAILURE',

    CLAIM_AUTH_FOR_ID_REQUEST: 'CLAIM_AUTH_FOR_ID_REQUEST',
    CLAIM_AUTH_FOR_ID_SUCCESS: 'CLAIM_AUTH_FOR_ID_SUCCESS',
    CLAIM_AUTH_FOR_ID_FAILURE: 'CLAIM_AUTH_FOR_ID_FAILURE',

    AUTH_REGISTRATION_EMAIL_REQUEST: 'AUTH_REGISTRATION_EMAIL_REQUEST',
    AUTH_REGISTRATION_EMAIL_SUCCESS: 'AUTH_REGISTRATION_EMAIL_SUCCESS',
    AUTH_REGISTRATION_EMAIL_FAILURE: 'AUTH_REGISTRATION_EMAIL_FAILURE',

    AUTH_RESET_PASSWORD_REQUEST: 'AUTH_RESET_PASSWORD_REQUEST',
    AUTH_RESET_PASSWORD_SUCCESS: 'AUTH_RESET_PASSWORD_SUCCESS',
    AUTH_RESET_PASSWORD_FAILURE: 'AUTH_RESET_PASSWORD_FAILURE',

    AUTH_DELETE_ACCOUNT_REQUEST: 'AUTH_DELETE_ACCOUNT_REQUEST',
    AUTH_DELETE_ACCOUNT_SUCCESS: 'AUTH_DELETE_ACCOUNT_SUCCESS',
    AUTH_DELETE_ACCOUNT_FAILURE: 'AUTH_DELETE_ACCOUNT_FAILURE',

    AUTH_UPDATE_USERNAME_REQUEST: 'AUTH_UPDATE_USERNAME_REQUEST',
    AUTH_UPDATE_USERNAME_SUCCESS: 'AUTH_UPDATE_USERNAME_SUCCESS',
    AUTH_UPDATE_USERNAME_FAILURE: 'AUTH_UPDATE_USERNAME_FAILURE',

    AUTH_PHONE_NUMBER_SEND_TOKEN_REQUEST: 'AUTH_PHONE_NUMBER_SEND_TOKEN_REQUEST',
    AUTH_PHONE_NUMBER_SEND_TOKEN_SUCCESS: 'AUTH_PHONE_NUMBER_SEND_TOKEN_SUCCESS',
    AUTH_PHONE_NUMBER_SEND_TOKEN_FAILURE: 'AUTH_PHONE_NUMBER_SEND_TOKEN_FAILURE',

    AUTH_PHONE_NUMBER_VERIFY_CODE_REQUEST: 'AUTH_PHONE_NUMBER_VERIFY_CODE_REQUEST',
    AUTH_PHONE_NUMBER_VERIFY_CODE_SUCCESS: 'AUTH_PHONE_NUMBER_VERIFY_CODE_SUCCESS',
    AUTH_PHONE_NUMBER_VERIFY_CODE_FAILURE: 'AUTH_PHONE_NUMBER_VERIFY_CODE_FAILURE',

    REACTIVATE_ACCOUNT_REQUEST: 'REACTIVATE_ACCOUNT_REQUEST',
    REACTIVATE_ACCOUNT_SUCCESS: 'REACTIVATE_ACCOUNT_SUCCESS',
    REACTIVATE_ACCOUNT_FAILURE: 'REACTIVATE_ACCOUNT_FAILURE',

}

