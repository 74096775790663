import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";



class CelebAccountBox extends React.Component {
  render() {

    return (
        <div id="app">
            <div className="text-center text-white pt-2 mt-3"> 
                  <h2 className="text-white surtitle text-uppercase">Talent & Creators</h2>
                  <h4 className="text-white text-uppercase surtitle mt-2">Grow on LiveNyte</h4>
            </div>
            <div className="pricing card-group flex-column flex-md-row mb-3 mt-6">
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardBody className="px-lg-7 pb-4">
                    <div className="icon icon-lg icon-shape bg-gradient-info text-white rounded-circle mb-2 mt-3">
                        <i className="ni ni-calendar-grid-58 " />
                    </div>
                    <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                      Smart Scheduling
                    </h4>
                    <p>Easily set your schedule for events by stating your availability. Then, receive bids from businesses. 
                    </p>
                    </CardBody>
                    <CardFooter>
                    <div className="icon icon-lg icon-shape bg-gradient-livenyte text-white rounded-circle mb-2 mt-3">
                        <i className="fas fa-heart " />
                    </div>
                    <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                      Work Safely
                    </h4>
                    <p>Read other talent & creators' reviews of businesses before approving their bids. Approve bids from businesses that you are comfortable with.
                    </p>                        
                    </CardFooter>
                  </Card>
                  <Card className="card-pricing zoom-in-1 bg-dark  border-0 text-center mb-4 rounded">
                  <CardBody className="px-lg-12 pb-3 bg-transparent text-white">
                    <div className="icon icon-lg icon-shape bg-gradient-success text-white rounded-circle mb-2 mt-3">
                        <i className="ni ni-tie-bow " />
                    </div>
                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                    Optimized Bookings
                    </h4>
                    <p>LiveNyte optimizes your bids and books events for you that maximize your booking fees and convenience.
                    </p>
                    </CardBody>
                    <CardFooter className="rounded bg-transparent text-white">
                    <div className="icon icon-lg icon-shape bg-gradient-tertiary text-white rounded-circle mb-2 mt-3">
                        <i className="ni ni-shop" />
                    </div>
                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                    Sell Digital Content
                    </h4>
                    <p>Create and sell subscriptions for your digital content such as newsletters, blogs, photos, videos, and songs.
                    </p>    
                    </CardFooter>
                </Card>
              </div>
        </div>
    );
  }
}

export default CelebAccountBox;
