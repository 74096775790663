export const affiliateConstants = {      
    AFFILIATE_GET_PROMOTION_REQUEST: 'AFFILIATE_GET_PROMOTION_REQUEST',
    AFFILIATE_GET_PROMOTION_SUCCESS: 'AFFILIATE_GET_PROMOTION_SUCCESS',
    AFFILIATE_GET_PROMOTION_FAILURE: 'AFFILIATE_GET_PROMOTION_FAILURE',

    REGISTER_AS_AFFILIATE_REQUEST: 'REGISTER_AS_AFFILIATE_REQUEST',
    REGISTER_AS_AFFILIATE_SUCCESS: 'REGISTER_AS_AFFILIATE_SUCCESS',
    REGISTER_AS_AFFILIATE_FAILURE: 'REGISTER_AS_AFFILIATE_FAILURE',

    GET_AFFILIATE_CLIENTS_REQUEST: 'GET_AFFILIATE_CLIENTS_REQUEST',
    GET_AFFILIATE_CLIENTS_SUCCESS: 'GET_AFFILIATE_CLIENTS_SUCCESS',
    GET_AFFILIATE_CLIENTS_FAILURE: 'GET_AFFILIATE_CLIENTS_FAILURE',

    GET_AFFILIATE_EVENTS_REQUEST: 'GET_AFFILIATE_EVENTS_REQUEST',
    GET_AFFILIATE_EVENTS_SUCCESS: 'GET_AFFILIATE_EVENTS_SUCCESS',
    GET_AFFILIATE_EVENTS_FAILURE: 'GET_AFFILIATE_EVENTS_FAILURE',
    
};
