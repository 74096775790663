export const subscriptionConstants = {
    GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
    GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
    GET_SUBSCRIPTIONS_FAILURE: 'GET_SUBSCRIPTIONS_FAILURE',

    CREATE_SUBSCRIPTION_REQUEST: 'CREATE_SUBSCRIPTION_REQUEST',
    CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
    CREATE_SUBSCRIPTION_FAILURE: 'CREATE_SUBSCRIPTION_FAILURE',

    UPDATE_SUBSCRIPTION_REQUEST: 'UPDATE_SUBSCRIPTION_REQUEST',
    UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'UPDATE_SUBSCRIPTION_FAILURE',

    CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
    CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',

    REACTIVATE_SUBSCRIPTION_REQUEST: 'REACTIVATE_SUBSCRIPTION_REQUEST',
    REACTIVATE_SUBSCRIPTION_SUCCESS: 'REACTIVATE_SUBSCRIPTION_SUCCESS',
    REACTIVATE_SUBSCRIPTION_FAILURE: 'REACTIVATE_SUBSCRIPTION_FAILURE',
}
