import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Button } from "components";
import { randomString } from '_helpers';
import { Link } from 'react-router-dom';
import { config } from '_constants';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { authActions, userActions } from '_actions';
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { notificationMessageActions } from "_actions"
import { history } from '_helpers';

import {
  Row,
  ModalBody,
  Modal,
  ModalFooter,
  ModalHeader,
  Col,
  FormGroup,
  Input,
} from "reactstrap";


class LiveNyteAccountBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: "",
      yelpModal: false,
      phoneState: 'has-danger',
      phone: '',
      yelpVerifyStep: '',
      verifyCodeState: 'has-danger',
      verifyCode: '',
      method: '',
      srv_sid: '',
      errorMessage: '',
    };
    this.checkboxClick = this.checkboxClick.bind(this);
    this.toggleYelpModal = this.toggleYelpModal.bind(this);
    this.submitVerifyCode = this.submitVerifyCode.bind(this);
    this.verifyPhoneNumber = this.verifyPhoneNumber.bind(this);
    // this.testVerifyPhoneNumber = this.testVerifyPhoneNumber.bind(this);

    this.noYelpData = this.noYelpData.bind(this);
    this.errorVerifyCode = this.errorVerifyCode.bind(this);

  }

  checkboxClick() {
    if (this.state.checked !== "") {
      this.setState({
        checked: ""
      });
    } else {
      this.setState({
        checked: " active"
      });
    }
    this.refs.checkbox.click();
  }

  toggleYelpModal() {
    this.setState({
      yelpModal: !this.state.yelpModal
    });
  }

  checkPhoneNumber(e) {
    const phone = e; 
    this.setState({phone: phone, evPasswordState: 'has-success'})
    if (isPossiblePhoneNumber(phone) ) {
      this.setState({phoneState: 'has-success'})
    } else {
      this.setState({phoneState: 'has-danger'})
    }
  }

  checkVerifyCode(e) {
    const verifyCode = e.target.value; 
    this.setState({verifyCode: e.target.value, verifyCodeState: 'has-success'})
    if (verifyCode.length >= 5 && verifyCode.length <= 15 ) {
      this.setState({verifyCodeState: 'has-success'})
    } else {
      this.setState({verifyCodeState: 'has-danger'})
    }
  }

  // testVerifyPhoneNumber(method) {
  //   const { phone, phoneState } = this.state 
  //   this.props.dispatch(userActions.getYelpData(phone, (success, data) => {
  //     if (success) {
  //       this.props.dispatch(notificationMessageActions.addNotificationToQueue(data.message, 1 ))
  //       history.push({
  //         pathname: '/auth/confirm-account-page',
  //         state: {accType: 'Business'}
  //       })
  //     } else {
  //       this.props.dispatch(notificationMessageActions.addNotificationToQueue(data, 2 ))
  //       this.setState({
  //         yelpVerifyStep: 'noYelpData', 
  //         errorMessage: data,
  //       })
  //     }
  //   }));
  // }

  verifyPhoneNumber(method) {
    const { phone, phoneState } = this.state 
    console.log("Verify Phone Number via", method, phone)
    if (phoneState === 'has-success') {
      this.setState({yelpVerifyStep: 'sending', method: method,})
      this.props.dispatch(authActions.authPhoneNumSendToken(phone, method, (success, data) => {
        if (success) {
          const srv_sid = data.srv_sid
          this.props.dispatch(notificationMessageActions.addNotificationToQueue(data.message, 1 ))
          this.setState({
            srv_sid: srv_sid, 
            yelpVerifyStep:'verifyCode',
          })
        } else {
          console.log("FAILURE", data)
          this.props.dispatch(notificationMessageActions.addNotificationToQueue(data, 2 ))
          this.setState({
            yelpVerifyStep:'errorPhoneNumber',
            errorMessage: data,
          })
        }
      }));
    } else {
      this.props.dispatch(notificationMessageActions.addNotificationToQueue("Invalid Phone Number", 2 ))
    }

  }

  goBackToPhoneNumber() {
    // const { phone } = this.state 
    // console.log("Verify Phone Number via",  phone) 
    this.setState({yelpVerifyStep: ''})
  }

  errorVerifyCode() {
    // const { phone } = this.state 
    // console.log("Verify Phone Number via",  phone) 
    this.setState({yelpVerifyStep: 'verifyCode'})
  }

  
  noYelpData(close = null) {
    if (close) {
      this.setState({
        yelpModal: false,
      })
    }

    this.setState({
      yelpVerifyStep: '',
      phoneState: 'has-danger',
      phone: '',
      verifyCodeState: 'has-danger',
      verifyCode: '',
      method: '',
      srv_sid: '',
      errorMessage: '',
    })
    console.log("noYelpData state", this.state)
  }

  submitVerifyCode() {
    const { verifyCode, srv_sid } = this.state 
    const { params } = this.props 
    const phone = params.phone
    console.log("submitVerifyCode", params, phone, verifyCode) 

    this.props.dispatch(authActions.authPhoneNumVerifyCode(phone, verifyCode, srv_sid, (success, data) => {
      if (success) {
        this.props.dispatch(notificationMessageActions.addNotificationToQueue(data.message, 1 ))

        this.props.dispatch(userActions.getYelpData(phone, (success, data) => {
          if (success) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue(data.message, 1 ))
            history.push({
              pathname: '/auth/confirm-account-page',
              state: {accType: 'Business'}
            })
          } else {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue(data, 2 ))
            this.setState({
              yelpVerifyStep: 'noYelpData', 
              errorMessage: data,
            })
          }
        }));
      } else {
        console.log("FAILURE", data)
        this.setState({
          yelpVerifyStep:'errorVerifyCode',
          errorMessage: data,
        })
        this.props.dispatch(notificationMessageActions.addNotificationToQueue(data, 2 ))
      }
    }));

  }



  render() {
    let fb_nonce = localStorage.getItem('fb-nonce');
    if (fb_nonce === null) {
      let rs = randomString(64);
      localStorage.setItem('fb-nonce', rs);
      console.log("rs", rs)
      fb_nonce = rs;
    }
    //console.log("fb-nonce", fb_nonce)
    return (
      <div className="text-center" style={{marginBottom:'60px'}}>
        <div className="ml-auto mr-auto" style={{marginBottom:'30px'}}>
          <i className={this.props.icon + ' ' + this.props.color}  style={{fontSize:'5rem'}} />
        </div>
        {this.props.title !== undefined ? <h3>{this.props.title}</h3> : null}
        <div> 
        {this.props.description !== undefined ? <p className ="text-muted">{this.props.description}</p> : null}
        </div>
        <div>
        {this.props.important !== undefined ? <h6 href="https://itunes.apple.com/us/app/livenyte-celebrity-events/id1378430467?mt=8" className ="text-muted">{this.props.important}</h6> : null}
        </div>
        {(this.props.accType === "Business" && this.props.isCreate ==="true") &&
          <div>
            {/* <b>Yelp Verification Instructions</b>
            <div className="text-left">
               <b> <br/>1. Enter the Phone Number that is displayed on your Yelp Business's page.<br/> 2. Then, click 'Send SMS'.<br/>3. Then click 'Did not receive a code'.<br/> 4. Then, click 'Get a Call'.<br/>5. Facebook will then call your Yelp Business phone number with the verification code.  </b> 
            </div>
          <form method="get" action="https://www.accountkit.com/v1.1/basic/dialog/sms_login/">
              <input type="hidden" name="app_id" value="197943750696053"/>
              <input type="hidden" name="redirect" value={config.serverUrl + "/auth/confirm-account-page"}/>
              <input type="hidden" name="state" value={"Business,acc-kit," + fb_nonce}/>
              <input type="hidden" name="fbAppEventsEnabled" value="true"/>
              <input type="hidden" name="debug" value="true"/>
              <input type="hidden" name="sdk" value="web"/>
              <input type="hidden" name="locale" value="en_US"/>
              <input type="hidden" name="display" value="popup"/> 
              </form> */}
              <Button onClick={() => this.toggleYelpModal()} className="link title" color="danger" style={{borderColor: "#FF1A1A", backgroundColor: "#FF1A1A"}}> <i className="fa-brands fa-yelp pr-2 mr-1"></i>Verify with Yelp</Button>
          <h5 style={{paddingTop:'5px'}}> OR </h5>
        </div>}
        {console.log("this.props.accType", this)}
        {(this.props.accType === "Business" && this.props.isCreate === "true") && <div>
          <form method="get" action="https://www.linkedin.com/oauth/v2/authorization">
              <input type="hidden" name="response_type" value="code"/>
              <input type="hidden" name="client_id" value="86bfhwnr4lbvmp"/>
              <input type="hidden" name="redirect_uri" value={config.serverUrl + "/auth/confirm-account-page"}/>
              <input type="hidden" name="state" value={"Business,linkedin," + fb_nonce}/>
              <input type="hidden" name="scope" value="r_liteprofile rw_organization_admin r_emailaddress"/>
              <Button type="submit" className="link title" color="info" style={{borderColor: "0077B5", backgroundColor: "#0077B5"}}><i className="fa-brands fa-linkedin pr-2 mr-1"></i> Verify with LinkedIn </Button>
          </form>
        </div>}


        {(this.props.accType === "Fan" && this.props.isCreate === "true") && 
        <div>
          <Link
                    to={ {pathname: '/auth/confirm-account-page',  state: {accType: "Fan",} }}
                  >
            <Button color="info" className="link title bg-gradient-info">Select</Button>
          </Link>
        </div>}

        {(this.props.accType === "Specialist" && this.props.isCreate === "true") && 
        <div>
          <Link
                    to={ {pathname: '/auth/confirm-account-page',  state: {accType: "Specialist",} }}
                  >
            <Button color="primary" className="link title bg-gradient-livenyte">Select</Button>
          </Link>
        </div>}
        {(this.props.accType === "Agency" && this.props.isCreate === "true") && 
        <div>
          <form method="get" action="https://www.linkedin.com/oauth/v2/authorization">
              <input type="hidden" name="response_type" value="code"/>
              <input type="hidden" name="client_id" value="86bfhwnr4lbvmp"/>
              <input type="hidden" name="redirect_uri" value={config.serverUrl + "/auth/confirm-account-page"}/>
              <input type="hidden" name="state" value={"Agency,linkedin," + fb_nonce}/>
              <input type="hidden" name="scope" value="r_liteprofile rw_organization_admin r_emailaddress"/>
              <Button type="submit" className="link title" color="info" style={{borderColor: "0077B5", backgroundColor: "#0077B5"}}><i className="fa-brands fa-linkedin pr-2 mr-1"></i> Verify with LinkedIn </Button>
          </form>
        </div>}
        <Row>
          <Modal
                isOpen={this.state.yelpModal}
                className="modal-dialog-centered modal-danger"
                contentClassName="bg-gradient-livenyte "               
                fade={false}
              >
              <ModalHeader toggle={this.toggleYelpModal}>
                <h6 className="modal-title">
                Verify with Yelp Instructions
                </h6>
              </ModalHeader>
              <ModalBody>
              {(this.state.yelpVerifyStep !== 'noYelpData') &&
                <div className="special-underline pt-0 text-center">
                    We will use <b>Yelp</b> to help verify your business.
                </div> 
                }
                {(this.state.yelpVerifyStep !== 'noYelpData') &&
                <div className="py-3">
                  <h6 className="surtitle text-white pt-2">Instructions </h6>
                  <b>
                    <div className="pt-2">
                    1. Enter the Phone Number in the field below that is listed on your Yelp Business's page.
                    </div> 
                    <div className="pt-3">
                    </div>
                    2. Then, choose a code delivery option of either 'Get a Call' or 'Send SMS'.
                    <div className="pt-3">
                    3. Type the code into the 'Verify Code' box.
                    </div>
                    <div className="pt-3">
                    4. Click 'Confirm Code'. 
                    </div>
                  </b>
                </div>
                }
               {this.state.yelpVerifyStep === '' && 
               <Row>
                <Col lg="12" className="pt-4">
                  <h6 className="surtitle text-white">Enter Phone Number </h6>
                  <FormGroup className={"mt-2 " + this.state.phoneState} >
                    <PhoneInput
                      placeholder="Enter your Yelp Business Phone Number"
                      value={this.state.phone}
                      defaultCountry="US"
                      onChange={e => this.checkPhoneNumber(e)}/>
                  </FormGroup>
                </Col>
              </Row>
              }
              {this.state.yelpVerifyStep === 'verifyCode' && 
               <Row>
                <Col lg="12" className="pt-4">
                  <h6 className="surtitle text-white">Enter Code </h6>
                  <FormGroup className={"mt-2 " + this.state.verifyCodeState} >
                    <Input type="number" name="verifyCode"
                    placeholder="Enter the Verify Code"
                    onChange={e => this.checkVerifyCode(e)}
                    onBlur={e => this.checkVerifyCode(e)}
                    value={this.state.verifyCode}
                    valid={
                      this.state.verifyCodeState === "has-success"
                    }
                    invalid={
                      this.state.verifyCodeState === "has-danger"
                    }  
                    />
                  </FormGroup>
                </Col>
              </Row>
              }
            {(this.state.yelpVerifyStep === 'errorVerifyCode' || this.state.yelpVerifyStep === 'errorPhoneNumber') && 
               <Row>
                <Col lg="12" className="pt-4">
                  <div className="text-center text-white"> 
                    <p>
                        <h4>{this.state.errorMessage}</h4>
                    </p>
                  </div>
                 
                </Col>
              </Row>
              }
            {(this.state.yelpVerifyStep === 'noYelpData') && 
               <Row>
                <Col lg="12" className="pt-4">
                  <div className="text-center text-white"> 
                    <div className="special-underline text-center">
                        <h3 className="text-white title">{this.state.errorMessage}</h3>
                    </div>
                    <h4 className="pt-3 text-white">Please verify that the phone number you are using is the phone number on your Yelp Business Page, or try verifying with LinkedIn.</h4>
                  </div>
                 
                </Col>
              </Row>
              }
              {this.state.yelpVerifyStep === 'sending' && 
               <Row>
                <Col lg="12" className="pt-4">
                  <div className="text-center"> 
                    <span className="text-white title">
                      Please wait&nbsp;&nbsp;
                    </span>
                    <br/><br/>
                    <div className='sweet-loading'>
                      <ClipLoader
                        sizeUnit={"px"}
                        size={45}
                        color={'#ffffff'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              }
            </ModalBody>
            <ModalFooter className="text-center">
              {this.state.yelpVerifyStep === '' && 
                <Button
                    color="spc-white"
                    outline
                    type="button"
                    onClick={() => this.verifyPhoneNumber('sms')}
                  >
                    Send SMS
                  </Button>
                  }
                {this.state.yelpVerifyStep === '' && 
                  <Button
                    className="ml-auto"
                    color="spc-white"
                    outline
                    type="button"
                    onClick={() => this.verifyPhoneNumber('call')}

                  >
                    Get a Call
                </Button>
                 }

                {this.state.yelpVerifyStep === 'verifyCode' && 
                <Button
                    className="text-white "
                    color="link"                    
                    type="button"
                    onClick={() => this.goBackToPhoneNumber()}
                  >
                    Back
                  </Button>
                  }
                {this.state.yelpVerifyStep === 'errorPhoneNumber' && 
                  <Button
                    className="text-white "
                    color="link"                    
                    type="button"
                    onClick={() => this.goBackToPhoneNumber()}
                  >
                    Back
                  </Button>
                  }
                  {this.state.yelpVerifyStep === 'errorVerifyCode' && 
                  <Button
                    className="text-white "
                    color="link"                    
                    type="button"
                    onClick={() => this.errorVerifyCode()}
                  >
                    Back
                  </Button>
                  }
                  {this.state.yelpVerifyStep === 'noYelpData' && 
                  <Button
                    className="text-white "
                    color="link"                    
                    type="button"
                    onClick={() => this.noYelpData()}
                  >
                    Back
                  </Button>
                  }
                  {this.state.yelpVerifyStep === 'noYelpData' && 
                  <Button
                    className="text-white ml-auto "
                    color="link"                    
                    type="button"
                    onClick={() => this.noYelpData('close')}
                  >
                    Close
                  </Button>
                  }
                {this.state.yelpVerifyStep === 'verifyCode' && 
                    <Button
                      className="ml-auto"
                      color="tertiary"
                      type="button"
                      onClick={() => this.submitVerifyCode()}

                    >
                      Confirm Code 
                  </Button>
                  }

          </ModalFooter> 
          </Modal>
        </Row>
      </div>

    );
  }
}

LiveNyteAccountBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  const { authData } = state;

  const { params, authPhoneNumberSendTokenRequest } = authData

  return {
    params, authPhoneNumberSendTokenRequest 

  };
}

const connectedLiveNyteAccountBox = connect(mapStateToProps)(LiveNyteAccountBox);
export { connectedLiveNyteAccountBox as LiveNyteAccountBox }; 