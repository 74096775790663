export const memberConstants = {
    MEMBER_QUERY_REQUEST: 'MEMBER_QUERY_REQUEST',
    MEMBER_QUERY_SUCCESS: 'MEMBER_QUERY_SUCCESS',
    MEMBER_QUERY_FAILURE: 'MEMBER_QUERY_FAILURE',

    USER_QUERY_REQUEST: 'USER_QUERY_REQUEST',
    USER_QUERY_SUCCESS: 'USER_QUERY_SUCCESS',
    USER_QUERY_FAILURE: 'USER_QUERY_FAILURE',

    BUSINESS_QUERY_REQUEST: 'BUSINESS_QUERY_REQUEST',
    BUSINESS_QUERY_SUCCESS: 'BUSINESS_QUERY_SUCCESS',
    BUSINESS_QUERY_FAILURE: 'BUSINESS_QUERY_FAILURE',

    SPECIALIST_QUERY_REQUEST: 'SPECIALIST_QUERY_REQUEST',
    SPECIALIST_QUERY_SUCCESS: 'SPECIALIST_QUERY_SUCCESS',
    SPECIALIST_QUERY_FAILURE: 'SPECIALIST_QUERY_FAILURE',

    MEMBER_UPDATE_REQUEST: 'MEMBER_UPDATE_REQUEST',
    MEMBER_UPDATE_SUCCESS: 'MEMBER_UPDATE_SUCCESS',
    MEMBER_UPDATE_FAILURE: 'MEMBER_UPDATE_FAILURE',

    UPDATE_TRAITS_REQUEST: 'UPDATE_TRAITS_REQUEST',
    UPDATE_TRAITS_SUCCESS: 'UPDATE_TRAITS_SUCCESS',
    UPDATE_TRAITS_FAILURE: 'UPDATE_TRAITS_FAILURE',

    UPDATE_BIO_REQUEST: 'UPDATE_BIO_REQUEST',
    UPDATE_BIO_SUCCESS: 'UPDATE_BIO_SUCCESS',
    UPDATE_BIO_FAILURE: 'UPDATE_BIO_FAILURE',

    UPDATE_PROFILE_IMAGE_REQUEST: 'UPDATE_PROFILE_IMAGE_REQUEST',
    UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
    UPDATE_PROFILE_IMAGE_FAILURE: 'UPDATE_PROFILE_IMAGE_FAILURE',

    GET_CELEB_INFO_REQUEST: 'GET_CELEB_INFO_REQUEST',
    GET_CELEB_INFO_SUCCESS: 'GET_CELEB_INFO_SUCCESS',
    GET_CELEB_INFO_FAILURE: 'GET_CELEB_INFO_FAILURE',

    GET_BUSINESS_INFO_REQUEST: 'GET_BUSINESS_INFO_REQUEST',
    GET_BUSINESS_INFO_SUCCESS: 'GET_BUSINESS_INFO_SUCCESS',
    GET_BUSINESS_INFO_FAILURE: 'GET_BUSINESS_INFO_FAILURE',

    DEACTIVATE_ACCOUNT_REQUEST: 'DEACTIVATE_ACCOUNT_REQUEST',
    DEACTIVATE_ACCOUNT_SUCCESS: 'DEACTIVATE_ACCOUNT_SUCCESS',
    DEACTIVATE_ACCOUNT_FAILURE: 'DEACTIVATE_ACCOUNT_FAILURE',

    DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
    DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
};
