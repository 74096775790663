import { collectionConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const collectionActions = {
    getCollections,
    createCollection,
    updateCollection,
};

function getCollections(args, callback = null) {
    // required arguments: none
    // optional arguments: 
    // 1) 'purchased': Returns all collections purchased by user
    //   OR
    // 2) filter type
    //  a) 'byId': Returns all collections created byId
    //  b) 'lookFor': Returns all collections where lookFor matches name of collections or name of owner

    const params = {
        request: 'get_collections',
      };

    if (args.hasOwnProperty('purchased')) {
        params['purchased'] = true
    } else {
        
        if (args.hasOwnProperty('byId')) {
            params['by_id'] = args.byId
        }
        if (args.hasOwnProperty('lookFor')) {
            params['look_for'] = args.lookFor
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Collections");
                    console.log(data)
                
                    dispatch(success(data.collections));
                    if (callback) {
                        callback(true, data.collections)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCollections: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()            { return { type: collectionConstants.GET_COLLECTIONS_REQUEST} }
    function success(collections) { return { type: collectionConstants.GET_COLLECTIONS_SUCCESS, collections } }
    function failure(error)       { return { type: collectionConstants.GET_COLLECTIONS_FAILURE, error } }
}

function createCollection(args, callback=null) {
    // required arguments: name, description, collection, type, price, currency, assetList
    // optional arguments: subscriptionId, collectionId, image, imageType
    // where imageType is one of Image, Video, Music, Youtube
    const params = {
        request: 'create_collection',
        name: args.name,
        description: args.description,
        price: args.price,   
        asset_list: args.assetList,     // 
      };
   
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    console.log("createCollection PARAMS", params)
    const user = JSON.parse(localStorage.getItem('user'))

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Created Collection");
                    console.log(data)
                
                    dispatch(success(data));
                    dispatch(getCollections({byId: user.userId}));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createCollection: fatal error")
                    dispatch(userActions.endSession(error))    
                }                
            }
        )
    }
    
    function request()       { return { type: collectionConstants.CREATE_COLLECTION_REQUEST} }
    function success(data)   { return { type: collectionConstants.CREATE_COLLECTION_SUCCESS, data } }
    function failure(error)  { return { type: collectionConstants.CREATE_COLLECTION_FAILURE, error } }
}

function updateCollection(args, callback=null) {
    // required arguments: collectionId, name, description, price, currency, state
    // optional arguments: image, imageType
    // where imageType is one of Image, Video, Music, Youtube
    const params = {
        request: 'update_collection',
        collection_id: args.collectionId,
        name: args.name,
        description: args.description,
        price: args.price, 
        state: args.state,     // Active, InActive  
      };
   
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    if (args.hasOwnProperty('assetList')) {
        params['asset_list'] = args.assetList
    }

    console.log("updateCollection PARAMS", params)
    const user = JSON.parse(localStorage.getItem('user'))
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Updated Collection");
                    console.log(data)
                
                    dispatch(success(data));
                    dispatch(getCollections({byId: user.userId}));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateCollection: fatal error")
                    dispatch(userActions.endSession(error))    
                }    
            }
        )
    }
    
    function request()       { return { type: collectionConstants.UPDATE_COLLECTION_REQUEST} }
    function success(data)   { return { type: collectionConstants.UPDATE_COLLECTION_SUCCESS, data } }
    function failure(error)  { return { type: collectionConstants.UPDATE_COLLECTION_FAILURE, error } }
}
