import React from "react";
import {
  ModalBody,
  // CardBody,
  // CardHeader,
  // CardFooter,
  // Row,
  // Col,
} from "reactstrap";
// import { history } from '_helpers';
import { eventActions } from '_actions';
// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import ReactTable from 'react-table'

import noImage from "assets/img/img-livenyte/livenyte-logo-with-background.jpg";


class GuestListModal extends React.Component {
  constructor(props) {
    super(props);
    const { eventId } = props.props
    console.log("GuestListModal constructor()", props)
    this.state = {
      eventId: eventId, 
    };
  }

  componentDidMount() {
    this.props.dispatch(eventActions.getGuestList(this.state.eventId));
  }

 


  render() {
    const {getGuestListRequest, guests } = this.props   
    console.log("GuestListModal: render", this.state, guests)

    if (getGuestListRequest || getGuestListRequest === undefined) {
      return(
        <div>Loading</div>
      )
    }
    //ReactTable with Name, ticket_name, Quantity, Checked-In: (sum of num_validated + manual_check_in)
    let _guests = []
    if (guests && guests.length > 0) {
      _guests = guests && guests.reduce( (acc, item) => {
        item['checked_in'] = item['manual_checkin'] + item['num_validated']
        acc.push(item)
        return acc
      }, [])
    }
    console.log("_guests", _guests)

    return (
      <div className="text-left pb-4">
        {_guests.length < 1 && 
        <ModalBody className="pt-0 pb-0">
          <p className="title text-primary"> No tickets have been purchased. </p>
        </ModalBody>
          }
        {_guests && _guests.length > 0 && 
        <div>        
          <ReactTable
            data={_guests}
            filterable
            columns={[
              // {
              //   Header: "",
              //   filterable: false,
              //   sortable: false,
              //   accessor: "image",
              //   className: "img-container",
              //   Cell: props => 
              //       <img className="circular" src={props.value} alt="..." />
              // },
              {
                Header: "Guest",
                accessor: "name",
                sortable: true,
                filterMethod: (filter, row, column) => {
                  const id = filter.pivotId || filter.id
                  //const name = fltCandidates.filter(candidate => candidate.id === row[id])[0].name
                  const name = row[id]
                  console.log("filterMethod", name, row[id], id )
                  return row[id] !== undefined ? name.toLowerCase().indexOf(filter.value) !== -1 : true
                },
                Cell: props => 
                  <div className="text-left" style={{display:'inline-block'}}>                        
                      <div className="text-left" style={{display:'inline-block'}}>
                      <img className="avatar rounded-circle mr-3"  src={props.original.photo !== "" ? props.original.photo : noImage } alt="..." />
                      </div>             
                      <div className="text-left" style={{display:'inline-block'}}> 
                        {props.original.name}
                      </div>
                  </div> 

                                        
              }, {
                Header: "Ticket",
                accessor: "ticket_name",
                className: "inline-block subtitle-size",
                sortable: true,
                filterable: true,
                width: 200,
                filterMethod: (filter, row, column) => {
                  const id = filter.pivotId || filter.id
                  //const name = fltCandidates.filter(candidate => candidate.id === row[id])[0].name
                  const ticket_name = row[id]
                  console.log("filterMethod", row, row[id], id )
                  return row[id] !== undefined ? ticket_name.toLowerCase().indexOf(filter.value) !== -1 : true
                },
                Cell: props => 
                  <div>                        
                      <div className="text-center"> 
                        {props.original.ticket_name}
                      </div>
                  </div> 
              }, {
                Header: "Checked In  /  Purchased",
                accessor: "checked_in",
                filterable: false,
                sortable: true,                   
                Cell: props =>
                  <div className="text-right title">
                    <span 
                    className={ props.original.checked_in === props.original.quantity ? "text-success" : 
                    (props.original.checked_in) === 0 ? "text-danger" : "text-warning"
                    }
                    >
                    {(props.original.checked_in) + '/' + props.original.quantity }
                    </span>
                  </div>
              }
            ]}
          />
          
          </div>   
        }

      </div> 
    );
  }
}

function mapStateToProps(state) {
  const {  eventsData } = state;
  const { getGuestListRequest, guests } = eventsData;
  return {
    getGuestListRequest, guests
  };
}

const connectedGuestListModal = connect(mapStateToProps)(GuestListModal);
export { connectedGuestListModal as GuestListModal }; 