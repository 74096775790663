import { traitConstants } from '_constants';

const initialState = {};

export function traitData(state = initialState, action) {
    switch(action.type) {
        case traitConstants.SPECIALIST_TRAITS_QUERY_REQUEST:
            return {
                ...state,
                fetchingTraits: true,
            };
        case traitConstants.SPECIALIST_TRAITS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingTraits: false,
                spTraits: action.data.sp_traits,
                spSubTraits: action.data.sp_subtraits,
            };
        case traitConstants.SPECIALIST_TRAITS_QUERY_FAILURE:
            return {
                ...state,
                fetchingTraits: false,
            };
        case traitConstants.BUSINESS_TRAITS_QUERY_REQUEST:
            return {
                ...state,
                fetchingTraits: true,
            };
        case traitConstants.BUSINESS_TRAITS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingTraits: false,
                busTraits: action.data.traits,
            };
        case traitConstants.BUSINESS_TRAITS_QUERY_FAILURE:
            return {
                ...state,
                fetchingTraits: false,
            };
        case traitConstants.USER_TRAITS_QUERY_REQUEST:
            return {
                ...state,
                fetchingTraits: true,
            };
        case traitConstants.USER_TRAITS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingTraits: false,
                userTraits: action.data.traits,
            };
        case traitConstants.USER_TRAITS_QUERY_FAILURE:
            return {
                ...state,
                fetchingTraits: false,
            };

        default:
            return state;
    }
}