import React from "react";
import {
  CardBody,

  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { feeActions } from '_actions';
          

class BiddingHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
    this.props.dispatch(feeActions.getFees())
  }

  
  render() {
    const {fees } = this.props   
    const {liveNyteBookingPercentage, liveNyteBookingHandlingFee, stripeFlatFee, stripePercentage, fetchingFees} = fees
    const combinedPercentage = liveNyteBookingPercentage + stripePercentage
    const combinedFlatFee = liveNyteBookingHandlingFee + stripeFlatFee
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("BiddingHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
              <CardBody className="text-left">
                <h4>How Bidding Works</h4>
                By submitting a bid, you are authorizing LiveNyte to competitively bid your bids against other bids for a talent's services (pending that talent's approval of your bid). 
                If you are a winner, you are authorizing LiveNyte to charge your credit card (no more than the your maximum bid) for the winning price when the auction closes.
                If the charge completes, you have successfully booked that talent for your event.
                The winning price is determined to be $1 more than the 2nd highest bidding set.
                <br/><br/>
                LiveNyte will not overbook talent, so you can place as many bids as you'd like and you'll only win up to the maximum number of celebs that you specified.
                <br/><br/>
                <h4>Book Now</h4>
                By submitting a <i>Book Now</i> request, you are authorizing LiveNyte to charge your payment method immediately for booking a talent for your time block at the <i>Book Now </i> price.
                If the charge completes, you have successfully booked that talent for your event.
                <br/><br/>
                <h4>Bid Status</h4>
                • A bid that is <b>Awaiting Approval</b> means that the talent has not acted to approve your bid yet. If there's a talent that you want to book immediately, you can elect to use Book Now after a talent has approved your bid request.
                <br/><br/>
                • A bid that is <b>Winning</b> means that your bid is currently a winning bid.
                <br/><br/>
                • A bid that has <b>Lost</b> means that either you did not have a winning bid when the auction closed, or if another event used Book Now to book that talent and caused a time conflict between your bid and their booking before the auction ended.
                <br/><br/>
                • If your bid has won, the bid status will change to <b>Booked</b>, and you will be charged via your preferred payment method (in Payments tab). The new booking will be displayed on your event page. 
                The amount you're charged is $1 than the second highest bidders.
                <br/><br/>
                • If your bid status changes to <b>Insufficient Funds</b> after an auction has closed, this means that your bid has lost because we were unable to successfully charge your payment method with the amount for the winning bid. 
                Please update your payment methods to ensure that you have the appropriate available credit for booking talent.
                <br/><br/>
                • If your bid status changes to <b>Insufficient Funds</b> after a <i> Book Now</i> attempt, this means we were unable to successfully charge your payment method with the <i>Book Now</i> price. 
                Please update your payment methods to ensure that you have the appropriate available credit for <i> Book Now </i>, and then retry <i>Book Now </i>.
                <br/><br/>
                <h4>Fees</h4>
                LiveNyte lists talent services for {combinedPercentage}% + ${combinedFlatFee} more. From this, LiveNyte collects {liveNyteBookingPercentage}% + ${liveNyteBookingHandlingFee} as commission and pays the payment processor {stripePercentage}% + ${stripeFlatFee}.
                <br/><br/>
                <h4>Grievances</h4>
                If you have a <b> grievance </b> about a booking (ex: <i> Talent was substantially late, no-show, etc. </i>), you have four days after the event to create a case. 
                To do this, click the <i>Help</i> button on the right side of the booking, then click <i>Freeze Payment</i>. 
                LiveNyte will help you and the talent resolve the issue.<br/><b>Abuse of this feature will result in account suspension or termination</b>.
                <br/><br/>
                Note: <i>We highly recommend that you view the talent's reviews (written by other Businesses who booked that talent).
                  {/* For example, if a celebrity has 100K followers on Instagram, but their LiveNyte profile says something substantially different--that LiveNyte profile may be fraudulent. */}
                  {/* Please help us reduce fraudulent profiles by reporting them to us. You can do this by clicking the settings gear on your event's page, clicking 'Help', then filling out a request for 'Other'. */}
                  <br/> 
                  Thank you! 
                  </i>
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future.</i>
              </CardBody>

            </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedBiddingHelpModal = connect(mapStateToProps)(BiddingHelpModal);
export { connectedBiddingHelpModal as BiddingHelpModal }; 