import React from "react";
import {
  CardBody,

  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { feeActions } from '_actions';
          

class FindCelebsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
    this.props.dispatch(feeActions.getFees())
  }


  render() {
    const {fees } = this.props   
    const {liveNyteBookingPercentage, liveNyteBookingHandlingFee, stripeFlatFee, stripePercentage, fetchingFees} = fees
    const combinedPercentage = liveNyteBookingPercentage + stripePercentage
    const combinedFlatFee = liveNyteBookingHandlingFee + stripeFlatFee
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("BiddingHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>

          <Row>
            <Col xs={12}>
              <CardBody className="text-left">
                <h4>How Bidding Works</h4>
                By submitting a bid, you are authorizing LiveNyte to competitively bid your bids against other bids for a talent's services (pending that talent's approval of your bid). 
                If you are a winner, you are authorizing LiveNyte to charge your credit card (no more than the your maximum bid) for the winning price when the auction closes.
                If the charge completes, you have successfully booked that talent for your event.
                The winning price is determined to be $1 more than the 2nd highest bidding set.
                <br/><br/>
                LiveNyte will not overbook talent, so you can place as many bids as you'd like and you'll only win up to the maximum number of celebs that you specified.
                <br/><br/>
                <h4>Book Now</h4>
                By submitting a <i>Book Now</i> request, you are authorizing LiveNyte to charge your payment method immediately for booking a talent for your time block at the <i>Book Now </i> price.
                If the charge completes, you have successfully booked that talent for your event.
                <br/><br/>
                <h4>Quick Tips</h4>
                • Some talents' schedules require that they manually approve each bid. If there's a talent that you want to book immediately AND they have this feature enabled, you will not be able to use <i>Book Now</i> until after a talent has approved your bid request.
                <br/><br/>
                • Please do not use decimal points or '$' when submitting bids. 
                <br/><br/>
                <h4>Fees</h4>
                LiveNyte lists talent services for {combinedPercentage}% + ${combinedFlatFee} more. From this, LiveNyte collects {liveNyteBookingPercentage}% + ${liveNyteBookingHandlingFee} as commission and pays the payment processor {stripePercentage}% + ${stripeFlatFee}.
                <br/><br/>
                <h4>Grievances</h4>
                If you have a <b> grievance </b> about a booking (ex: <i> Talent was substantially late, no-show, etc. </i>), you have four days after the event to create a case. 
                To do this, click the <i>Help</i> button on the right side of the booking, then click <i>Freeze Payment</i>. 
                LiveNyte will help you and the talent resolve the issue.<br/><b>Abuse of this feature will result in account suspension</b>.
                <br/><br/>
                Note: <i>We highly recommend that you view the talent's reviews (written by other Businesses who booked that talent).
                  {/* and compare their social media followers on LiveNyte to their social media accounts before submitting a bid on a talent.
                  For example, if a talent has 100K followers on Instagram, but their LiveNyte profile says something substantially different--that LiveNyte profile may be fraudulent.
                  Please help us reduce fraudulent profiles by reporting them to us. You can do this by clicking the settings gear on your event's page, clicking 'Help', then filling out a request for 'Other'. */}
                  <br/> 
                  Thank you! 
                  </i>
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future.</i>
              </CardBody>

              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedFindCelebsHelpModal = connect(mapStateToProps)(FindCelebsHelpModal);
export { connectedFindCelebsHelpModal as FindCelebsHelpModal }; 