import { paymentConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const paymentActions = {
    paymentsQuery,
    getBookings
};

function paymentsQuery(eventId = null, callback = null) {
    const params = {
        request: 'business_payment_query',
      };
    if (eventId != null) {
        params['event_id'] = eventId
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Payments");
                    console.log(data)
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data.payments, data.tickets)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("paymentsQuery: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: paymentConstants.PAYMENTS_QUERY_REQUEST} }
    function success(data)   { return { type: paymentConstants.PAYMENTS_QUERY_SUCCESS, data } }
    function failure(error)  { return { type: paymentConstants.PAYMENTS_QUERY_FAILURE, error } }
}

function getBookings(eventId = null, callback = null) {
    const params = {
        request: 'specialist_payment_query',
      };
    if (eventId != null) {
        params['event_id'] = eventId
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)        
        .then(
            data => {
                if (data.success) {
                    console.log("Got Bookings");
                    console.log(data)
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data.bookings)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBookings: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: paymentConstants.BOOKINGS_QUERY_REQUEST} }
    function success(data)   { return { type: paymentConstants.BOOKINGS_QUERY_SUCCESS, data } }
    function failure(error)  { return { type: paymentConstants.BOOKINGS_QUERY_FAILURE, error } }
}
