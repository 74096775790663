import { memberConstants } from '_constants';

const initialState = {};

export function memberInfo(state = initialState, action) {
    switch(action.type) {
        // Member Query
        case memberConstants.MEMBER_QUERY_REQUEST:
            return {
                ...state,
                fetchingInfo: true,
            };
        case memberConstants.MEMBER_QUERY_SUCCESS:
            return {
                ...state,
                fetchingInfo: false,
                info: action.data,
            };
        case memberConstants.MEMBER_QUERY_FAILURE:
            return {
                ...state,
                fetchingInfo: false,
            };
                // Member Query
        case memberConstants.USER_QUERY_REQUEST:
            return {
                ...state,
                fetchingUserInfo: true,
            };
        case memberConstants.USER_QUERY_SUCCESS:
            return {
                ...state,
                fetchingUserInfo: false,
                userInfo: action.data,
            };
        case memberConstants.USER_QUERY_FAILURE:
            return {
                ...state,
                fetchingUserInfo: false,
            };
        // Business Query
        case memberConstants.BUSINESS_QUERY_REQUEST:
            return {
                ...state,
                fetchingUserInfo: true,
            };
        case memberConstants.BUSINESS_QUERY_SUCCESS:
            return {
                ...state,
                fetchingUserInfo: false,
                userInfo: action.data,
            };
        case memberConstants.BUSINESS_QUERY_FAILURE:
            return {
                ...state,
                fetchingUserInfo: false,
            };
        // Specialist Query
        case memberConstants.SPECIALIST_QUERY_REQUEST:
            return {
                ...state,
                fetchingUserInfo: true,
            };
        case memberConstants.SPECIALIST_QUERY_SUCCESS:
            return {
                ...state,
                fetchingUserInfo: false,
                userInfo: action.data,
            };
        case memberConstants.SPECIALIST_QUERY_FAILURE:
            return {
                ...state,
                fetchingUserInfo: false,
            };

        // Update username, password, phone
        case memberConstants.MEMBER_UPDATE_REQUEST:
            return {
                ...state,
                updateRequest: true,
            };
        case memberConstants.MEMBER_UPDATE_SUCCESS:
            return {
                ...state,
                updateRequest: false,
            };
        case memberConstants.MEMBER_UPDATE_FAILURE:
            return {
                ...state,
                updateRequest: false,
            };
        // Update BIO
        case memberConstants.UPDATE_BIO_REQUEST:
            return {
                ...state,
                updateBioRequest: true,
            };
        case memberConstants.UPDATE_BIO_SUCCESS:
            return {
                ...state,
                updateBioRequest: false,
            };
        case memberConstants.UPDATE_BIO_FAILURE:
            return {
                ...state,
                updateBioRequest: false,
            };
        // Update Business, User, Specialist Traits
        case memberConstants.UPDATE_TRAITS_REQUEST:
            return {
                ...state,
                updateTraitsRequest: true,
            };
        case memberConstants.UPDATE_TRAITS_SUCCESS:
            return {
                ...state,
                updateTraitsRequest: false,
            };
        case memberConstants.UPDATE_TRAITS_FAILURE:
            return {
                ...state,
                updateTraitsRequest: false,
            };
        // Update Profile Image
        case memberConstants.UPDATE_PROFILE_IMAGE_REQUEST:
            return {
                ...state,
                updateProfileImageRequest: true,
            };
        case memberConstants.UPDATE_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                updateProfileImageRequest: false,
            };
        case memberConstants.UPDATE_PROFILE_IMAGE_FAILURE:
            return {
                ...state,
                updateProfileImageRequest: false,
            };
        case memberConstants.GET_CELEB_INFO_REQUEST:
            return {
                ...state,
                getCelebInfoRequest: true,
            };
        case memberConstants.GET_CELEB_INFO_SUCCESS:
            return {
                ...state,
                getCelebInfoRequest: false,
                selectedSpecialist: action.data.celebs[0]
            };
        case memberConstants.GET_CELEB_INFO_FAILURE:
            return {
                ...state,
                getCelebInfoRequest: false,
            };
        
    // GET BUSINESS INFO
        case memberConstants.GET_BUSINESS_INFO_REQUEST:
            return {
                ...state,
                getBusinessInfoRequest: true,
            };
        case memberConstants.GET_BUSINESS_INFO_SUCCESS:
            return {
                ...state,
                getBusinessInfoRequest: false,
                followingBusinesses: action.data,
                selectedBusiness: action.data,
            };
        case memberConstants.GET_BUSINESS_INFO_FAILURE:
            return {
                ...state,
                getBusinessInfoRequest: false,
            };


     // Deactivate Account
        case memberConstants.DEACTIVATE_ACCOUNT_REQUEST:
            return {
                ...state,
                deactivateAccountRequest: true,
            };

        case memberConstants.DEACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                deactivateAccountRequest: false,
            };
        case memberConstants.DEACTIVATE_ACCOUNT_FAILURE:
            return {
                ...state,
                deactivateAccountRequest: false,
            };

     // DELETE Account
     case memberConstants.DELETE_ACCOUNT_REQUEST:
        return {
            ...state,
            deleteAccountRequest: true,
        };

    case memberConstants.DELETE_ACCOUNT_SUCCESS:
        return {
            ...state,
            deleteAccountRequest: false,
        };
    case memberConstants.DELETE_ACCOUNT_FAILURE:
        return {
            ...state,
            deleteAccountRequest: false,
        };
    default:
        return state;
    }
            

}
