import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { feeActions } from '_actions';
          
import {  currencyFormat } from '_helpers';

class TicketsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
    this.props.dispatch(feeActions.getFees())
  }

  render() {
    const {fees } = this.props   
    const {liveNyteTicketPercentage, liveNyteTicketFlatFee, stripeFlatFee, stripePercentage, fetchingFees} = fees
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("TicketsHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left">
                  <h4>General</h4>
                  Create tickets to sell to event attendees.
                  <br/><br/>
                  You can create multiple types of tickets and specify items such as price, description, quantity, ticket sale duration, and a purchase limit. 
                  <br/><br/>
                  Your check-in staff can use the <i>LiveNyte: Event Check-In</i> app to check in guests by using their phone's camera to scan QR Codes.
                  <br/><br/>
                  Event Staff use your Event's ID (a number that we set) and an event password (that you can set and change by tapping the Edit button) to login to <i>LiveNyte: Event Check-In</i> to check-in guests. 
                  <br/><br/>
                  <h4>{"Payout & Fees"}</h4>
                  Ticket Sales are typically scheduled to be transferred from your Payout Account to you 3 days after your event (see Payments Tab for more details).
                  <br/><br/>
                  LiveNyte collects a handling fee of {liveNyteTicketPercentage}% + {currencyFormat('us', liveNyteTicketFlatFee)} per transaction from the ticket purchaser.
                  Additionally, the ticket purchaser also pays the payment processor fee of {stripePercentage}% + {currencyFormat('us', stripeFlatFee)} per transaction. LiveNyte reserves the right to change these fees in the future.
                  <br/><br/>
                  Each U.S. state has different laws for how sales tax is collected and reported. If you’re not sure if you need to pay tax on your ticket sales, please consult your tax advisor.
                  LiveNyte can't give you tax advice. LiveNyte does not add an additional fee for tax on top of the total ticket price. 
                  You are responsible for paying the appropriate taxes on the ticket revenue you receive to the appropriate entities.
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedTicketsHelpModal = connect(mapStateToProps)(TicketsHelpModal);
export { connectedTicketsHelpModal as TicketsHelpModal }; 