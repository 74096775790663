import { authConstants, userConstants } from '_constants';

const initialState = {};

export function authData(state = initialState, action) {
    switch(action.type) {
        case authConstants.CLAIM_AUTH_CODE_REQUEST:
            return {
                ...state,
                type: action.auth,
                claimAuthCode: true,
            };
        case authConstants.CLAIM_AUTH_CODE_SUCCESS:
            return {
                ...state,
                claimAuthCode: false,
            };
        case authConstants.CLAIM_AUTH_CODE_FAILURE:
            return {
                ...state,
                claimAuthForId: false,
            };        
        case authConstants.CLAIM_AUTH_FOR_ID_REQUEST:
            return {
                ...state,
                type: action.auth,
                claimAuthForId: true,
            };
        case authConstants.CLAIM_AUTH_FOR_ID_SUCCESS:
            return {
                ...state,
                claimAuthForId: false,
            };
        case authConstants.CLAIM_AUTH_FOR_ID_FAILURE:
            return {
                ...state,
                claimAuthForId: false,
            };  
        case authConstants.AUTH_REGISTRATION_EMAIL_REQUEST:
            return {
                ...state,
                type: action.auth,
                registrationEmailReq: true,
            };
        case authConstants.AUTH_REGISTRATION_EMAIL_SUCCESS:
            return {
                ...state,
                registrationEmailReq: false,
            };
        case authConstants.AUTH_REGISTRATION_EMAIL_FAILURE:
            return {
                ...state,
                registrationEmailReq: false,
            };
        case authConstants.AUTH_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                type: action.auth,
                resetPasswordReq: true,
            };
        case authConstants.AUTH_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordReq: false,
            };
        case authConstants.AUTH_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordReq: false,
            }; 
        case authConstants.AUTH_DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                type: action.auth,
                authDeleteAccountReq: true,
            };
        case authConstants.AUTH_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                authDeleteAccountReq: false,
            };
        case authConstants.AUTH_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                authDeleteAccountReq: false,
            }; 
        case authConstants.AUTH_UPDATE_USERNAME_REQUEST:
            return {
                ...state,
                type: action.auth,
                updateUsernameReq: true,
            };
        case authConstants.AUTH_UPDATE_USERNAME_SUCCESS:
            return {
                ...state,
                updateUsernameReq: false,
            };
        case authConstants.AUTH_UPDATE_USERNAME_FAILURE:
            return {
                ...state,
                updateUsernameReq: false,
            }; 
        case userConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetPasswordRequest: true,
            };
        case userConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: true,

            };
        case userConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: false,
            }; 
                                               
        case authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_REQUEST:
            return {
                ...state,
                params: action.params,
                authPhoneNumberSendTokenRequest: true,
                authPhoneNumberSendTokenSuccess: false,
            };
        case authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_SUCCESS:
            return {
                ...state,
                authPhoneNumberSendTokenRequest: false,
                authPhoneNumberSendTokenSuccess: true,

            };
        case authConstants.AUTH_PHONE_NUMBER_SEND_TOKEN_FAILURE:
            return {
                ...state,
                authPhoneNumberSendTokenRequest: false,
                authPhoneNumberSendTokenSuccess: false,
            }; 
        case authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_REQUEST:
            return {
                ...state,
                authPhoneNumberVerifyCodeRequest: true,
                authPhoneNumberVerifyCodeSuccess: false,
            };
        case authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                authPhoneNumberVerifyCodeRequest: false,
                authPhoneNumberVerifyCodeSuccess: true,

            };
        case authConstants.AUTH_PHONE_NUMBER_VERIFY_CODE_FAILURE:
            return {
                ...state,
                authPhoneNumberVerifyCodeRequest: false,
                authPhoneNumberVerifyCodeSuccess: false,
            }; 

                 // Reactivate Account
        case authConstants.REACTIVATE_ACCOUNT_REQUEST:
            return {
                ...state,
                reactivateAccountRequest: true,
            };

        case authConstants.REACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                reactivateAccountRequest: false,
            };
        case authConstants.REACTIVATE_ACCOUNT_FAILURE:
            return {
                ...state,
                reactivateAccountRequest: false,
            };

                                               
        default:
            return state;
        }

        
    }
