export const tierConstants = {    
    GET_TIERS_REQUEST: 'GET_TIERS_REQUEST',
    GET_TIERS_SUCCESS: 'GET_TIERS_SUCCESS',
    GET_TIERS_FAILURE: 'GET_TIERS_FAILURE',
    
    CREATE_TIER_REQUEST: 'CREATE_TIER_REQUEST',
    CREATE_TIER_SUCCESS: 'CREATE_TIER_SUCCESS',
    CREATE_TIER_FAILURE: 'CREATE_TIER_FAILURE',

    UPDATE_TIER_REQUEST: 'UPDATE_TIER_REQUEST',
    UPDATE_TIER_SUCCESS: 'UPDATE_TIER_SUCCESS',
    UPDATE_TIER_FAILURE: 'UPDATE_TIER_FAILURE',

    DISABLE_TIER_REQUEST: 'DISABLE_TIER_REQUEST',
    DISABLE_TIER_SUCCESS: 'DISABLE_TIER_SUCCESS',
    DISABLE_TIER_FAILURE: 'DISABLE_TIER_FAILURE',

    ENABLE_TIER_REQUEST: 'ENABLE_TIER_REQUEST',
    ENABLE_TIER_SUCCESS: 'ENABLE_TIER_SUCCESS',
    ENABLE_TIER_FAILURE: 'ENABLE_TIER_FAILURE',

    GET_REMITS_FOR_TIERS_REQUEST: 'GET_REMITS_FOR_TIERS_REQUEST',
    GET_REMITS_FOR_TIERS_SUCCESS: 'GET_REMITS_FOR_TIERS_SUCCESS',
    GET_REMITS_FOR_TIERS_FAILURE: 'GET_REMITS_FOR_TIERS_FAILURE',

}
