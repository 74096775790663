import { timeLeft } from '_helpers';

// Subevent needs attention
function subEventNeedsAttention(traitStatus) {
    // [TraitStatus] for subEvent
    let needsAttention = 0
    traitStatus.forEach( ts => {
        if ((ts.req > ts.bid.length ||   
             ts.req > ts.num_ok) && ts.req !== ts.booked.length) {
                 needsAttention += 1
             }
         });
    return needsAttention
}

// SubEvents in Event need attention
export function subEventsNeedAttention(event, sbTraitStatus) {
    let numSubEvents = 0
    let okSubEvents = 0
    if (event.sb_event) {
        Object.entries(event.sb_event).forEach( ([sbId, sb_event]) => {
            numSubEvents += 1
            if (sbTraitStatus.hasOwnProperty(sbId)) {
                okSubEvents += (subEventNeedsAttention(sbTraitStatus[sbId]) === 0) ? 1 : 0
            }
        })
    }
    // return {okSubEvents, numSubEvents}
    return okSubEvents < numSubEvents
}

export function businessBidStatusMessage(state, bidState, auction_end_time, slotFull) {
    // State: { Request, Bid, Booked, Rejected, Reversed, Cancelled, InsufficientFunds }
    // BidState: { Win, Lose, Fatal, NA}

    const auctionStatus = timeLeft(auction_end_time)
    switch (state) {
      case 'Request':
        if (auctionStatus === 'Auction Closed') {
          return 'Lost'
        }
        return slotFull ? 'Awaiting Approval\n(Slot Full)': 'Awaiting Approval'
      case 'Bid':
        if (auctionStatus === 'Auction Closed') {
          return 'Lost'
        }
        if (bidState === 'Win') {
          return slotFull ? 'Slot Full' : 'Winning' 
        } else if (bidState === 'Fatal') {
          return 'Lost'
        } else if (bidState === 'Lose') {
          return slotFull ? 'Slot Full' : 'Losing'
        }
        break;
      case 'Booked':
        return 'Booked'
      case 'Rejected':
        return 'Bid Rejected'
      case 'Reversed':
        return 'Booking Reversed'
      case 'Cancelled':
        return 'Booking Cancelled'
      case 'InsufficientFunds':
        return 'Insufficient Funds'
      default:
        return ''
    }
  }

export function businessBidSort(left, right) {
  const bidSort = {
    Booked : 7, 
    Bid: 6, 
    Request: 5, 
    Reversed: 4, 
    Cancelled: 3,
    InsufficientFunds: 2, 
    Rejected: 1, 
  }
  const leftState = bidSort[left.state] ? bidSort[left.state] : 0
  const rightState = bidSort[right.state]? bidSort[right.state] : 0 
  console.log("leftState", leftState, left.state)
  console.log("rightState", rightState, right.state)
  return rightState - leftState
}

export function celebBidStatusMessage(state, bidState, auction_end_time, mode) {
  // State: { Request, Bid, Booked, Rejected, Reversed, Cancelled, InsufficientFunds }
  // BidState: { Win, Lose, Fatal, NA}
  // mode: "Detailed", "Abbreviated", "Schedule"
  const auctionStatus = timeLeft(auction_end_time)
  switch (state) {
    case 'Request':
      if (auctionStatus === 'Auction Closed') {
        if ( mode === "Detailed") return 'This bid request has expired because your schedule is closed.' //Detailed
        if ( mode === "Abbreviated") return 'Expired Bid Request' //Abbreviated
        if ( mode === "Schedule") return 'Expired Request' //Schedule
      }
      if ( mode === "Detailed") return 'Awaiting your approval.' //Detailed--String Match Line 947 Celeb Event
      if ( mode === "Abbreviated") return 'Bid Request' //Abbreviated
      if ( mode === "Schedule") return 'Request' //Schedule
      break;
    case 'Bid':
      if (auctionStatus === 'Auction Closed') {
        if ( mode === "Detailed") return 'Schedule Closed. This bid has lost.' //Detailed
        if ( mode === "Abbreviated") return 'Bid Lost' //Abbreviated
        if ( mode === "Schedule") return 'Bid Lost' //Schedule
      }
      if (bidState === 'Win') {
          if ( mode === "Detailed") return 'This bid is currently winning.' //Detailed
          if ( mode === "Abbreviated") return 'Active Bid' //Abbreviated
          if ( mode === "Schedule") return 'Active Bid' //Schedule
      } else if (bidState === 'Fatal') {
          if ( mode === "Detailed") return 'This bid has lost due to a time-conflict with another booking.' //Detailed
          if ( mode === "Abbreviated") return 'Bid Lost' //Abbreviated
          if ( mode === "Schedule") return 'Time Conflict' //Schedule
      } else if (bidState === 'Lose') {
          if ( mode === "Detailed") return 'This bid is currently losing.' //Detailed
          if ( mode === "Abbreviated") return 'Active Bid' //Abbreviated
          if ( mode === "Schedule") return 'Active Bid' //Schedule
      }
      break;
    case 'Booked':
      if ( mode === "Detailed") return 'Booked' //Detailed
      if ( mode === "Abbreviated") return 'Booked' //Abbreviated
      if ( mode === "Schedule") return 'Booked' //Schedule
      break;
    case 'Rejected':
      if ( mode === "Detailed") return 'You rejected this bid.' //Detailed
      if ( mode === "Abbreviated") return 'Bid Rejected' //Abbreviated
      if ( mode === "Schedule") return 'Rejected' //Schedule
      break;
    case 'Reversed':
      if ( mode === "Detailed") return 'This booking was refunded.' //Detailed
      if ( mode === "Abbreviated") return 'Booking Refunded'  //Abbreviated
      if ( mode === "Schedule") return 'Refunded' //Schedule
      break;
    case 'Cancelled':
      if ( mode === "Detailed") return 'This bid was cancelled.' //Detailed
      if ( mode === "Abbreviated") return 'Bid Cancelled' //Abbreviated
      if ( mode === "Schedule") return 'Cancelled' //Schedule
      break;
    case 'InsufficientFunds':
      if ( mode === "Detailed") return 'This bid has lost.'  //Detailed
      if ( mode === "Abbreviated") return 'Inactive Bid' //Abbreviated
      if ( mode === "Schedule") return ' '  //Schedule
      break;
    default:
      return ''
  }
}

export function celebBidStatusColor(state, bidState, auction_end_time) {
  // State: { Request, Bid, Booked, Rejected, Reversed, Cancelled, InsufficientFunds }
  // BidState: { Win, Lose, Fatal, NA}

  const auctionStatus = timeLeft(auction_end_time)
  switch (state) {
    case 'Request':
      if (auctionStatus === 'Auction Closed') {
        return 'text-gray' //Auction Over
      }
      return 'text-warning' //Show Buttons
    case 'Bid':
      if (auctionStatus === 'Auction Closed') {
        return 'text-gray' //Auction's Over
      }
      if (bidState === 'Win') {
        return 'text-info' //
      } else if (bidState === 'Fatal') {
        return 'text-gray'
      } else if (bidState === 'Lose') {
        return 'text-info'
      }
      break;
    case 'Booked':
      return 'text-primary'
    case 'Rejected':
      return 'text-gray'
    case 'Reversed':
      return 'text-dark'
    case 'Cancelled':
      return 'text-danger'
    case 'InsufficientFunds':
      return 'text-danger' 
    default:
      return ''
  }
}

export function paymentStateColor(state) {
  // case Charged
  // case Pending
  // case Dispute
  // case Completed
  // case Reversed

  switch (state) {
    case 'Charged':
        return 'text-info'
    case 'Pending':
        return 'text-gray'
    case 'Dispute':
      return 'text-danger'
    case 'Completed':
      return 'text-success'
    case 'Reversed':
      return 'text-dark'
    default:
      return ''
  }
}

export function paymentStateDescription(state) {
  // case Charged
  // case Pending
  // case Dispute
  // case Completed
  // case Reversed

  switch (state) {
    case 'Reversed':
      return 'Refunded'
    default:
      return state
  }
}




export function traitStatusForEvents(stateSbTraitStatus, events) {
    
  let sbTraitStatus = {}  // {  sbEventID: TraitStatus }
  if (stateSbTraitStatus) {
      sbTraitStatus = Object.assign({}, stateSbTraitStatus)
  }
  const user = JSON.parse(localStorage.getItem('user'));
  events.forEach( event => {
      if (event.business_id === user.user_id && (event.sb_event !== undefined && event.sb_event !== null && event.sb_event.length > 0) ) {
          console.log("traitStatusForEvents", event)
          Object.entries(event.sb_event).forEach( ([sbId, sb_event]) => {
              console.log("event", event.event_name)
              let traitStatus = []
              Object.entries(sb_event.traits_num).forEach( ([trait, num]) => {
                  traitStatus.push(
                      {name: trait,
                      req: num,
                      booked: [],
                      bid: [],
                      num_ok: 0})
              })
              Object.entries(sb_event.bids).forEach( ([bidId, bid]) => {
                  let trait = bid.sp_trait
                  let pos = traitStatus.findIndex( ts => ts.name === trait)
                  if (bid.bid_state === 'Win') {
                      traitStatus[pos].num_ok += 1
                  }
                  if (bid.state === 'Booked') {
                      traitStatus[pos].booked.push(bidId)
                  } else {
                      traitStatus[pos].bid.push(bidId)
                  }

              })
              sbTraitStatus[sbId] = traitStatus
          })
      }
  })
  return sbTraitStatus
}

