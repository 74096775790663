export function needsReviews(allReviews) {
    let result = { eventIds: [], missingReviews: 0 }
    if (!allReviews) {
        return result
    }
    for ( let spId in allReviews) {
        const reviewForSp = allReviews[spId]
        // [0] => Name, [1] => imageUrl, [2] => Trait, [3] => { eventId: [revId, isSender, message, rating, time, unread]  }
        for (let eventId in reviewForSp[3]) {
            const reviewsForEvent = reviewForSp[3][eventId]
            const reviewsByMe = reviewsForEvent.filter( r => r[1] === true)
            if (reviewsByMe.length === 0) {
                result.missingReviews = result.missingReviews + 1
                if (result.eventIds.indexOf(eventId) < 0) {
                    result.eventIds.push(parseInt(eventId, 10))
                }
            }
        }
    }
    return result
}

export function reviewsForMe(allReviews) {
    let result = []
    if (allReviews) {
        for (let spId in allReviews) {
            // [0] => Name, [1] => imageUrl, [2] => Trait, [3] => { eventId: [revId, isSender, message, rating, time, unread]  }
            const reviewForSp = allReviews[spId]
            // [0] => Name, [1] => imageUrl, [2] => Trait, [3] => { eventId: [revId, isSender, message, rating, time, unread]  }
            for (let eventId in reviewForSp[3]) {
                const reviewsForEvent = reviewForSp[3][eventId]
                const rvMe = reviewsForEvent.filter( r => r[1] === false)
                if (rvMe.length > 0) {
                    rvMe[0].push(spId)
                    rvMe[0].push(reviewForSp[0])
                    rvMe[0].push(reviewForSp[1])
                    rvMe[0].push(eventId)
                    result.push(rvMe[0]) // [ [revId, isSender, message, rating, time, unread, spId, spName, imageUrl, eventId] ]
                }
            }
        }
    }
    return result    
}
