import React from "react";
import { Link } from 'react-router-dom';
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class SchedulingHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {

  }


  render() {
    const {fees } = this.props   
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SchedulingHelpModal: render", this.state)

    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left">
                    <h4>Schedules </h4>
                    Click a day on the calendar to create a schedule.
                    You can create multiple schedules in a day, varying most scheduling preferences such as location, price, time, etc.
                    <i> If multiple schedules are created on the same day, the auction closing period must be the same.</i>
                    <br/><br/>
                    <h4>Bids</h4>
                    After you create a schedule, businesses can submit bids<b>*</b> for your services.
                      <br/>&nbsp;&nbsp;&nbsp;<span className="text-primary title">• Bids</span> are highlighted in pink on the Calendar by checking the <span className="text-primary title">Bid</span> box.
                    <br/><br/><b>*</b>If you have <b>Approve Bids</b> enabled in your schedule, <u>you will receive bid requests first</u>.
                    <br/>&nbsp;&nbsp;&nbsp;• When you receive a bid request, you have the option of <span className= "text-success title">approving</span> or <span className="text-danger title">rejecting</span> a bid. 
                    <br/>&nbsp;&nbsp;&nbsp;• We recommend that you read reviews and communicate details and expectations with the bidder to assist in your decision-making process.
                    <br/>&nbsp;&nbsp;&nbsp;• You can find new bid requests in <Link to={{pathname: `/dash/celeb-dashboard`,}}> Dashboard</Link>, by clicking the 'New Bid Requests' button.  
                    <br/>&nbsp;&nbsp;&nbsp;• You can also find them <span className="text-warning title"> highlighted in yellow</span> on the calendar by checking the <span className="text-warning title">Request</span> box.
                    <br/><br/>
                     <h4>Bookings</h4>
                     When your auction closes, LiveNyte will optimize your active bids by bookings the ones that maximize your revenue and minimize your driving distance throughout all of your schedules for that day.
                     You can also get booked for events before your auction closes via <i>Book Now</i>. 
                     <br/>&nbsp;&nbsp;&nbsp;• You can find your upcoming bookings in <Link to={{pathname: `/dash/celeb-dashboard`,}}> Dashboard</Link>, by clicking the 'Upcoming Bookings' button.  
                     <br/>&nbsp;&nbsp;&nbsp;• You can also find them <span className="text-success title"> highlighted in green</span> on the calendar by checking the <span className="text-success title">Booked</span> box.
                      <br/><br/>
                     <h4>Payouts</h4>
                     You can find Payout information and our fees in <Link to={{pathname: `/dash/celeb-payments`,}}> Payments</Link>, by clicking the <i className="icon icon-primary now-ui-icons travel_info"/> button.  
                    {/* <h4>Locations</h4>
                    Go to <Link to={{pathname: `/dash/scheduling`,}}> Scheduling </Link> (accessible on the left sidebar). Choose an address and specify a radius that you're comfortable driving within for events. Create locations for areas that you frequently work in. <br/><br/><b><u>To create schedules, you must have at least one location created.  </u></b>
                    <br/><br/>
                    <h4>Templates</h4>
                    Go to <Link to={{pathname: `/dash/scheduling`,}}> Scheduling Helpers </Link> (accessible on the left sidebar).Templates are data that you can quickly load into schedules to speed up the schedule creation process. Try creating a template. */}
                    <br/><br/>
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedSchedulingHelpModal = connect(mapStateToProps)(SchedulingHelpModal);
export { connectedSchedulingHelpModal as SchedulingHelpModal }; 