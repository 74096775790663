export const eventConstants = {

    GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_BUS_EVENTS_SUCCESS: 'GET_BUS_EVENTS_SUCCESS',
    GET_SPC_EVENTS_SUCCESS: 'GET_SPC_EVENTS_SUCCESS',
    GET_SPC_MESSAGE_EVENTS_SUCCESS: 'GET_SPC_MESSAGE_EVENTS_SUCCESS',

    GET_SPC_BOOKED_EVENTS_SUCCESS: 'GET_SPC_BOOKED_EVENTS_SUCCESS',
    GET_SPC_BID_EVENTS_SUCCESS: 'GET_SPC_BID_EVENTS_SUCCESS',
    GET_SPC_NOTIFY_EVENTS_SUCCESS: 'GET_SPC_NOTIFY_EVENTS_SUCCESS',
    GET_SPC_EVENT_SUCCESS: 'GET_SPC_EVENT_SUCCESS',

    GET_BUS_EVENT_SUCCESS: 'GET_BUS_EVENT_SUCCESS',
    GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',


    GET_NEARBY_EVENTS_FOR_SPECIALIST_REQUEST: 'GET_NEARBY_EVENTS_FOR_SPECIALIST_REQUEST',
    GET_NEARBY_EVENTS_FOR_SPECIALIST_SUCCESS: 'GET_NEARBY_EVENTS_FOR_SPECIALIST_SUCCESS',
    GET_NEARBY_EVENTS_FOR_SPECIALIST_FAILURE: 'GET_NEARBY_EVENTS_FOR_SPECIALIST_FAILURE',


    CREATE_UPDATE_EVENT_REQUEST: 'CREATE_UPDATE_EVENT_REQUEST',
    CREATE_UPDATE_EVENT_SUCCESS: 'CREATE_UPDATE_EVENT_SUCCESS',
    CREATE_UPDATE_EVENT_FAILURE: 'CREATE_UPDATE_EVENT_FAILURE',

    DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
    DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',

    CREATE_UPDATE_SUBEVENT_REQUEST: 'CREATE_UPDATE_SUBEVENT_REQUEST',
    CREATE_UPDATE_SUBEVENT_SUCCESS: 'CREATE_UPDATE_SUBEVENT_SUCCESS',
    CREATE_UPDATE_SUBEVENT_FAILURE: 'CREATE_UPDATE_SUBEVENT_FAILURE',

    DELETE_SUBEVENT_REQUEST: 'DELETE_SUBEVENT_REQUEST',
    DELETE_SUBEVENT_SUCCESS: 'DELETE_SUBEVENT_SUCCESS',
    DELETE_SUBEVENT_FAILURE: 'DELETE_SUBEVENT_FAILURE',

    FIND_SPECIALIST_FOR_SUBEVENT_REQUEST: 'FIND_SPECIALIST_FOR_SUBEVENT_REQUEST',
    FIND_SPECIALIST_FOR_SUBEVENT_SUCCESS: 'FIND_SPECIALIST_FOR_SUBEVENT_SUCCESS',
    FIND_SPECIALIST_FOR_SUBEVENT_FAILURE: 'FIND_SPECIALIST_FOR_SUBEVENT_FAILURE',

    DELETE_BID_REQUEST: 'DELETE_BID_REQUEST',
    DELETE_BID_SUCCESS: 'DELETE_BID_SUCCESS',
    DELETE_BID_FAILURE: 'DELETE_BID_FAILURE',

    BID_BOOK_REQUEST: 'BID_BOOK_REQUEST',
    BID_BOOK_SUCCESS: 'BID_BOOK_SUCCESS',
    BID_BOOK_FAILURE: 'BID_BOOK_FAILURE',

    FIND_AVAILABILITY_REQUEST: 'FIND_AVAILABILITY_REQUEST',
    FIND_AVAILABILITY_SUCCESS: 'FIND_AVAILABILITY_SUCCESS',
    FIND_AVAILABILITY_FAILURE: 'FIND_AVAILABILITY_FAILURE',

    GET_EVENTS_FOR_USER_REQUEST: 'GET_EVENTS_FOR_USER_REQUEST',
    GET_EVENTS_FOR_USER_SUCCESS: 'GET_EVENTS_FOR_USER_SUCCESS',
    GET_EVENTS_FOR_USER_FAILURE: 'GET_EVENTS_FOR_USER_FAILURE',

    UPDATE_BID_STATE_REQUEST: 'UPDATE_BID_STATE_REQUEST',
    UPDATE_BID_STATE_SUCCESS: 'UPDATE_BID_STATE_SUCCESS',
    UPDATE_BID_STATE_FAILURE: 'UPDATE_BID_STATE_FAILURE',

    GET_GUEST_LIST_REQUEST: 'GET_GUEST_LIST_REQUEST',
    GET_GUEST_LIST_SUCCESS: 'GET_GUEST_LIST_SUCCESS',
    GET_GUEST_LIST_FAILURE: 'GET_GUEST_LIST_FAILURE',

    EVENT_UPDATE_NOTIFY_REQUEST: 'EVENT_UPDATE_NOTIFY_REQUEST',
    EVENT_UPDATE_NOTIFY_SUCCESS: 'EVENT_UPDATE_NOTIFY_SUCCESS',
    EVENT_UPDATE_NOTIFY_FAILURE: 'EVENT_UPDATE_NOTIFY_FAILURE'



}
