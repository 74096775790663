import { screenSizeConstants } from '_constants';

//import { history } from '_helpers';

export const screenSizeActions = {
    setScreenSizeXXS,
    setScreenSizeXS,
    setScreenSizeSM,
    setScreenSizeMD,
    setScreenSizeLG,    
    setScreenSizeXL,
    setScreenSizeXXL
};


function setScreenSizeXXS() {
    console.log("DISPATCH SCREEN SIZE XXS",  window.innerWidth)
    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_XXS_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_XXS_SUCCESS, data }; }
}

function setScreenSizeXS() {
    console.log("DISPATCH SCREEN SIZE XS",  window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_XS_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_XS_SUCCESS, data }; }
}

function setScreenSizeSM() {
    console.log("DISPATCH SCREEN SIZE SM",  window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_SM_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_SM_SUCCESS, data }; }
}

function setScreenSizeMD() {
    console.log("DISPATCH SCREEN SIZE MD",  window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_MD_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_MD_SUCCESS, data }; }
}

function setScreenSizeLG() {
    console.log("DISPATCH SCREEN SIZE LG", window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_LG_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_LG_SUCCESS, data }; }
}

function setScreenSizeXL() {
    console.log("DISPATCH SCREEN SIZE XL", window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_XL_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_XL_SUCCESS, data }; }
}

function setScreenSizeXXL() {
    console.log("DISPATCH SCREEN SIZE XXL",  window.innerWidth)

    return dispatch => {
        dispatch(request());
        dispatch(success())
    };
    
    function request()      { return { type: screenSizeConstants.SET_SCREEN_SIZE_XXL_REQUEST}; }
    function success(data)  { return { type: screenSizeConstants.SET_SCREEN_SIZE_XXL_SUCCESS, data }; }
}