import { socialMediaConstants, } from '_constants';

const initialState = {};

export function socialMediaData(state = initialState, action) {
    switch(action.type) {
        case socialMediaConstants.SOCIAL_MEDIA_REGISTER_REQUEST:
            return {
                ...state,
                socialMediaRegisterRequest: true,
            };
        case socialMediaConstants.SOCIAL_MEDIA_REGISTER_SUCCESS:
            return {
                ...state,
                socialMediaRegisterRequest: false,
            };
        case socialMediaConstants.SOCIAL_MEDIA_REGISTER_FAILURE:
            return {
                ...state,
                socialMediaRegisterRequest:false
            };
        case socialMediaConstants.SOCIAL_MEDIA_CONNECT_REQUEST:
            return {
                ...state,
                socialMediaConnectRequest: true,
            };
        case socialMediaConstants.SOCIAL_MEDIA_CONNECT_SUCCESS:
            return {
                ...state,
                socialMediaConnectRequest: false,
            };
        // Business Events Query also returns (unread) message
        case socialMediaConstants.SOCIAL_MEDIA_CONNECT_FAILURE:
            return {
                ...state,
                socialMediaConnectRequest:false
            };
        case socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_REQUEST:
            return {
                ...state,
                getInstagramAccessToken: true,            
            }
        case socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.data.token,
                getInstagramAccessToken: false,
            }
        case socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_FAILURE:
            return {
                ...state,
                getInstagramAccessToken: false,
            }
        case socialMediaConstants.GET_YOUTUBE_TOKEN_REQUEST:
            return {
                ...state,
                getYoutubeToken: true,            
            }
        case socialMediaConstants.GET_YOUTUBE_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.data.token,
                getYoutubeToken: false,
            }
        case socialMediaConstants.GET_YOUTUBE_TOKEN_FAILURE:
            return {
                ...state,
                getYoutubeToken: false,
            }
        case socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_REQUEST:
            return {
                ...state,
                getTwitterRequestToken: true,            
            }
        case socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_SUCCESS:
            return {
                ...state,
                token: action.data.token,
                getTwitterRequestToken: false,
            }
        case socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_FAILURE:
            return {
                ...state,
                getTwitterRequestToken: false,
            }
        case socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_REQUEST:
            return {
                ...state,
                socialMediaIsConnectedRequest: true,            
        }
        case socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_SUCCESS:
            return {
                ...state,
                socialMediaIsConnectedRequest: false,
            }
        case socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_FAILURE:
            return {
                ...state,
                socialMediaIsConnectedRequest: false,
            }
        case socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_REQUEST:
            return {
                ...state,
                socialMediaIsRegistered: true,            
        }
        case socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_SUCCESS:
            return {
                ...state,
                socialMediaIsRegistered: false,
            }
        case socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_FAILURE:
            return {
                ...state,
                socialMediaIsRegistered: false,
            }
        
        default:
            return state
    }
}