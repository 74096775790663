import { socialMediaConstants } from '_constants';
import { userService } from '_services';
import { memberActions, alertActions, userActions } from '_actions';
// import { servicesVersion } from 'typescript';

export const socialMediaActions = {
    socialMediaRegister,
    socialMediaConnect,  
    getInstagramAccessToken,
    getYouTubeUserId,
    getTwitterRequestToken,
    socialMediaIsRegistered,
    socialMediaIsConnected,
};

function socialMediaRegister(creds, callback = null) { //Register Followers
    // const company = creds.company
    const params = {
        request: 'social_media_register',
        credentials: JSON.stringify(creds),
    }
    console.log("socialMediaRegister", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_business) {
                        dispatch(memberActions.businessQuery(user.user_id))
                    } else if (user.is_specialist) {
                        dispatch(memberActions.specialistQuery())
                    } else if (user.is_user) {
                        dispatch(memberActions.userQuery())
                    }
                    
                    if (callback !== null) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("socialMediaRegister: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.SOCIAL_MEDIA_REGISTER_REQUEST} }
    function success()       { return { type: socialMediaConstants.SOCIAL_MEDIA_REGISTER_SUCCESS } }
    function failure(error)  { return { type: socialMediaConstants.SOCIAL_MEDIA_REGISTER_FAILURE, error } }    
}

function socialMediaConnect(creds, callback=null) { //Easy LOGIN
    const params = {
        request: 'social_media_connect',
        credentials: creds,
    }

    if (creds.hasOwnProperty('login')) {
        params["login"] = true
    }
    console.log("socialMediaConnect", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {                    
                    dispatch(success(data));
                    if (callback) {
                        callback(success, data)
                    }
                } else {
                    if (callback) {
                        //isActive can be false or null on fail. 
                        const isActive = data.hasOwnProperty('active') ? data.active : null 
                        const isRegistered = data.hasOwnProperty('is_registered') ? data.is_registered : null 
                        const isMember = data.hasOwnProperty('is_member') ? data.is_member : null 
                        const email = data.hasOwnProperty('email') ? data.email : null 

                        callback(false, data.message, isActive, email, isRegistered, isMember)
                    }
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("socialMediaConnect: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.SOCIAL_MEDIA_CONNECT_REQUEST} }
    function success()       { return { type: socialMediaConstants.SOCIAL_MEDIA_CONNECT_SUCCESS } }
    function failure(error)  { return { type: socialMediaConstants.SOCIAL_MEDIA_CONNECT_FAILURE, error } }    
}

function getInstagramAccessToken(code, redirectUri, callback = null) {
    const params = {
        request: 'instagram_get_access_token',
        code: code,
        redirect_uri: redirectUri,
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                    const cred = {
                        company: 'Instagram', 
                        token: data.token.access_token + ",v1", 
                        userName: data.token.username,
                        userId: data.token.id,
                    };
                    dispatch(socialMediaRegister(cred, callback));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getInstagramAccessToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_REQUEST} }
    function success(data)   { return { type: socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: socialMediaConstants.GET_INSTAGRAM_ACCESS_TOKEN_FAILURE, error } }
}

function getYouTubeUserId(accessToken, callback = null) {
    const params = {
        request: 'get_youtube_token',
        access_token: accessToken,
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));
                    const cred = {
                        company: 'Youtube', 
                        token: data.token, 
                    };
                    dispatch(socialMediaRegister(cred, callback));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getYouTubeUserId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.GET_YOUTUBE_TOKEN_REQUEST} }
    function success(data)   { return { type: socialMediaConstants.GET_YOUTUBE_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: socialMediaConstants.GET_YOUTUBE_TOKEN_FAILURE, error } }
}

function getTwitterRequestToken(redirectUri, callback = null) {
    const params = {
        request: 'get_twitter_request_token',
        redirect_uri: redirectUri
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));   
                    if (callback) { callback(true, data); }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getTwitterRequestToken: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_REQUEST} }
    function success(data)   { return { type: socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_SUCCESS, data } }
    function failure(error)  { return { type: socialMediaConstants.GET_TWITTER_REQUEST_TOKEN_FAILURE, error } }
}

function socialMediaIsRegistered(company, callback = null) {
    const params = {
        request: 'is_registered',
        company: company
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));   
                    if (callback) { callback(true, data); }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("socialMediaIsRegistered: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_REQUEST} }
    function success(data)   { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_SUCCESS, data } }
    function failure(error)  { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_REGISTERED_FAILURE, error } }
}

function socialMediaIsConnected(company, callback = null) {
    const params = {
        request: 'is_connected',
        company: company
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data));   
                    if (callback) { callback(true, data,); }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("socialMediaIsConnected: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_REQUEST} }
    function success(data)   { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_SUCCESS, data } }
    function failure(error)  { return { type: socialMediaConstants.SOCIAL_MEDIA_IS_CONNECTED_FAILURE, error } }
}

