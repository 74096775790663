import * as moment from 'moment';
import 'moment-timezone';

export function dateFromISO8601(isoDateString, timeZone, format = "LLLL") {
    if (!timeZone) {
        // console.log("ERROR: dateFromISO8601", timeZone)
    }
    
    // http://momentjs.com/docs/#/manipulating/
    let date = moment.tz(isoDateString, timeZone)
    return date.format(format)
}

export function auctionRemainingTime(isoDateString) {
    let now = new Date();
    let closingDate = new Date(isoDateString);
    let seconds = Math.floor((closingDate.getTime() - now.getTime()) / 1000);
    let minutes = Math.floor(seconds / 60) 
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    console.log(days, hours, minutes);
    return {days, hours, minutes};

}

export function assetPublishedDate(isoDateString) {
    if (moment(isoDateString).isSame(new Date(), 'year')) {
        if (Math.abs(moment().diff(isoDateString)) < 60000) { // 60000 milliseconds = 1 second
            return 'just now';
        } else if (moment().diff(isoDateString, 'days') > 5)  {
            return moment.tz(isoDateString, moment.tz.guess()).format('MMM D')
        } else {
            return moment(isoDateString).fromNow();
        }
    } else {
        return moment.tz(isoDateString, moment.tz.guess()).format('MMM D, YYYY')
    }

}
    


export function availableCelebAuctionRemainingTime(isoDateString, lockout) {
    let now = new Date();
    let closingDate = new Date(isoDateString)
    closingDate.setHours(0);
    closingDate.setMinutes(0);
    closingDate.setSeconds(0);
    closingDate.setDate(closingDate.getDate() - lockout )
    let seconds = Math.floor((closingDate.getTime() - now.getTime()) / 1000);
    let minutes = Math.floor(seconds / 60) 
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    console.log(days, hours, minutes);
    return {days, hours, minutes};

}

export function timeLeft(isoDateString) {
    let {days, hours, minutes} = auctionRemainingTime(isoDateString);
    const parsedHours = hours - 24 * days
    const parsedMinutes = minutes - hours * 60
    if (days > 0) {
        if (parsedHours === 0) {
            return `${days} day${days === 1 ? '' : 's'} left`    
        }
        return `${days} day${days === 1 ? '' : 's'} + ${parsedHours} hour${parsedHours === 1 ? '' : 's'} left` 
    } else if (hours > 0) {
        return `${hours} hour${hours === 1 ? '' : 's'} + ${parsedMinutes} minute${parsedMinutes === 1 ? '' : 's'} left` 
    } else if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} left` 
    } else {
        return "Auction Closed"
    }
}

export function timeLeftUntilTicketSalesStart(isoDateString) {
    let {days, hours, minutes} = auctionRemainingTime(isoDateString);
    const parsedHours = hours - 24 * days
    const parsedMinutes = minutes - hours * 60
    if (days > 0) {
        if (parsedHours === 0) {
            return `${days} day${days === 1 ? '' : 's'}`    
        }
        return `${days} day${days === 1 ? '' : 's'} + ${parsedHours} hour${parsedHours === 1 ? '' : 's'}` 
    } else if (hours > 0) {
        return `${hours} hour${hours === 1 ? '' : 's'} + ${parsedMinutes} minute${parsedMinutes === 1 ? '' : 's'}` 
    } else if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'}` 
    } else {
        return "Sold out"
    }
}

export function timeLeftAvailableCelebs(isoDateString, lockout) {
    let {days, hours, minutes} = availableCelebAuctionRemainingTime(isoDateString, lockout);
    const parsedHours = hours - 24 * days
    const parsedMinutes = minutes - hours * 60
    console.log("days", days)
    console.log("hours", hours)
    console.log("minutes", minutes)

    if (days > 0) {
        if (parsedHours === 0) {
            return `${days} day${days === 1 ? '' : 's'} left`    
        }
        return `${days} day${days === 1 ? '' : 's'} + ${parsedHours} hour${parsedHours === 1 ? '' : 's'} left` 
    } else if (hours > 0) {
        return `${hours} hour${hours === 1 ? '' : 's'} + ${parsedMinutes} minute${parsedMinutes === 1 ? '' : 's'} left` 
    } else if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} left` 
    } else {
        return "Auction Closed"
    }
}

export function eventPeriod(startTime, endTime) {
    let eT = new Date(endTime)
    let sT = new Date(startTime)

    return Math.ceil((eT.getTime() - sT.getTime()) / (1000 * 60 * 60))
 }

 export function dispTimePeriod(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispTimePeriod", timeZone)
    }
    let stateDate = moment.tz(start_time, timeZone)
    let endDate = moment.tz(end_time, timeZone)

    let sameMonthSameYear = stateDate.isSame(endDate, "month") && stateDate.isSame(endDate, "year")
     console.log("Same month, same year", sameMonthSameYear)
     let diff = eventPeriod(start_time, end_time)

     if (diff >= 24) {
        if (sameMonthSameYear) {
            return dispSameMonthSameYearEventPeriod(start_time, end_time, timeZone, useYear)
        } else {
            return dispFullTimeEventPeriod(start_time, end_time, timeZone, useYear)
        }
     } else {
        return dispCondensedEventPeriod(start_time, end_time, timeZone, useYear) 
     }

 }

 export function shortFormDateFilterableMonth(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispTimePeriod", timeZone)
    }
    let stateDate = moment.tz(start_time, timeZone)
    let endDate = moment.tz(end_time, timeZone)

    let sameMonthSameYear = stateDate.isSame(endDate, "month") && stateDate.isSame(endDate, "year")
     console.log("Same month, same year", sameMonthSameYear)
     let diff = eventPeriod(start_time, end_time)

     if (diff >= 24) {
        if (sameMonthSameYear) {
            let disp = dateFromISO8601(start_time, timeZone, "MMMM D") +
            ' - ' +
            dateFromISO8601(end_time, timeZone, (useYear ? "D, YYYY " : "D"))

            return disp
        } else {
            let disp = dateFromISO8601(start_time, timeZone, "MMMM D") +
            ' - ' +
            dateFromISO8601(end_time, timeZone, useYear ? "MMMM D, YYYY" : "MMMM D")
            
            return disp
        }
     } else {
        let disp = dateFromISO8601(start_time, timeZone, useYear ? "MMMM D, YYYY" : "MMMM D")
        return disp
     }
 }
 

export function shortFormDate(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispTimePeriod", timeZone)
    }
    let stateDate = moment.tz(start_time, timeZone)
    let endDate = moment.tz(end_time, timeZone)

    let sameMonthSameYear = stateDate.isSame(endDate, "month") && stateDate.isSame(endDate, "year")
     console.log("Same month, same year", sameMonthSameYear)
     let diff = eventPeriod(start_time, end_time)

     if (diff >= 24) {
        if (sameMonthSameYear) {
            let disp = dateFromISO8601(start_time, timeZone, "MMM D") +
            ' - ' +
            dateFromISO8601(end_time, timeZone, (useYear ? "D, YYYY " : "D"))

            return disp
        } else {
            let disp = dateFromISO8601(start_time, timeZone, "MMM D") +
            ' - ' +
            dateFromISO8601(end_time, timeZone, useYear ? "MMM D, YYYY" : "MMM D")

            return disp
        }
     } else {
        let disp = dateFromISO8601(start_time, timeZone, useYear ? "MMM D, YYYY" : "MMM D")
        return disp
     }
}




 export function cardDateFields(date, timeZone) {
    const dayOfWeek = dateFromISO8601(date, timeZone, "ddd")
    const dayOfMonth = dateFromISO8601(date, timeZone, "DD")
    const month = dateFromISO8601(date, timeZone, "MMM")
    const monthDate = dateFromISO8601(date, timeZone, "MMM D")
    return {dayOfWeek: dayOfWeek, dayOfMonth: dayOfMonth, month: month, monthDate: monthDate }  
  }

  export function dispSameMonthSameYearEventPeriod(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispCondensedEventPeriod", timeZone)
    }
    let disp = dateFromISO8601(start_time, timeZone, "MMM D") +
               ' - ' +
               dateFromISO8601(end_time, timeZone, (useYear ? "D, YYYY " : "D")) + 
               "  |  " + 
               dateFromISO8601(start_time, timeZone, "h:mm a") + 
               ' - ' +
               dateFromISO8601(end_time, timeZone, "h:mm a") 
    return disp;
}

export function dispFullTimeEventPeriod(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispCondensedEventPeriod", timeZone)
    }
    let disp = dateFromISO8601(start_time, timeZone, "MMM D") +
               ' - ' +
               dateFromISO8601(end_time, timeZone, useYear ? "MMM D, YYYY" : "MMM D") + 
               "  |  " + 
               dateFromISO8601(start_time, timeZone, "h:mm a") + 
               ' - ' +
               dateFromISO8601(end_time, timeZone, "h:mm a") 
    return disp;
}

 export function dispCondensedEventPeriod(start_time, end_time, timeZone, useYear=false) {
    if (!timeZone) {
        console.log("ERROR: dispCondensedEventPeriod", timeZone)
    }
    let disp = dateFromISO8601(start_time, timeZone, useYear ? "MMM D, YYYY" : "MMM D") + 
                "  |  " + 
               dateFromISO8601(start_time, timeZone, "h:mm a") + 
               ' - ' +
               dateFromISO8601(end_time, timeZone, "h:mm a") 
    return disp;
}

export function scheduleTimePeriod(start_time, end_time, timeZone) {
    if (!timeZone) {
        console.log("ERROR: scheduleTimePeriod", timeZone)
    }
    let disp = dateFromISO8601(start_time, timeZone, "h:mm a") + 
               ' - ' +
               dateFromISO8601(end_time, timeZone, "h:mm a") 
    return disp;
}




 export function dispAbbreviatedTimePeriod(start_time, end_time, timeZone) {
    if (!timeZone) {
        console.log("ERROR: dispAbbreviatedTimePeriod", timeZone)
    }
    let disp = dateFromISO8601(start_time, timeZone, "LT") +
               ' - ' +
               dateFromISO8601(end_time, timeZone, "LT")
    return disp;
}

export function dateCompare(a, b) {
    const a_time = new Date(a)
    const b_time = new Date(b)
    return a_time.getTime() - b_time.getTime()    
}

export function  dateSetTz(t, tz, ltz) {
    return moment.tz( moment.tz(t, tz).format('YYYY-MM-DD HH:mm:ss'), ltz )
}

export function ticketAvailabilityMessage(start_time, end_time, timeZone) {
    if (!timeZone) {
        console.log("ERROR: dispFullTimeEventPeriod", timeZone)
    }
    const now = moment()
    if ( now.isBefore(start_time)) {
      return "Sale starts: " + dateFromISO8601(start_time, timeZone, 'LLL')    

    } else if (now.isAfter(start_time) && now.isBefore(end_time)) {

      return 'On Sale until ' + dateFromISO8601(end_time, timeZone, 'LLL')      

    } else if (now.isAfter(end_time)) {
        return 'Sale Ended!'      
    }
  }
