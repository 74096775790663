import { audioPlayerConstants } from '_constants';

//import { history } from '_helpers';

export const audioPlayerActions = {
    clearPlaylist,
    insertAudioAtFront,
    addAudioToEndOfPlaylist,
    clearPlaylistInstructions,    
};


function clearPlaylist(callback = null) {

    // const audioList1 = [
    //     {
    //       name: 'Dorost Nemisham',
    //       singer: 'Sirvan Khosravi',
    //       cover:
    //         'https://res.cloudinary.com/ehsanahmadi/image/upload/v1573758778/Sirvan-Khosravi-Dorost-Nemisham_glicks.jpg',
    //       musicSrc:
    //         'https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3',
    //     },
    //     {
    //         name: 'Despacito',
    //         singer: 'Luis Fonsi',
    //         cover:
    //           'http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg',
    //         musicSrc:
    //           'http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3',
    //       },
    //       {
    //         name: 'Bedtime Stories',
    //         singer: 'Jay Chou',
    //         cover:
    //           'http://res.cloudinary.com/alick/image/upload/v1502375978/bedtime_stories_bywggz.jpg',
    //         musicSrc:
    //           'http://res.cloudinary.com/alick/video/upload/v1502375674/Bedtime_Stories.mp3',
    //       },
    //   ]
      
      const audioList2 = []
      

    // const deepClone = JSON.parse(JSON.stringify(args));

    return dispatch => {
        dispatch(request());
        dispatch(success(audioList2))

        // if (localChangesMade === true || localChangesMade === false) {
        //     dispatch(setLocalChanges(localChangesMade))
        //     dispatch(setHmpgTmplForUser(deepClone))
        // }
    };
    
    function request()      { return { type: audioPlayerConstants.UPDATE_PLAYLIST_REQUEST}; }
    function success(data)  { return { type: audioPlayerConstants.UPDATE_PLAYLIST_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}

function addAudioToEndOfPlaylist(audio, playlist, callback = null) {

    // playlist.push(audio)
    const deepClone = JSON.parse(JSON.stringify(playlist));
    deepClone.push(audio)
    // const deepClone = JSON.parse(JSON.stringify(args));
    console.log("DEEP CLONE", deepClone)
    return dispatch => {
        dispatch(request());
        dispatch(success(deepClone))

        // if (localChangesMade === true || localChangesMade === false) {
        //     dispatch(setLocalChanges(localChangesMade))
        //     dispatch(setHmpgTmplForUser(deepClone))
        // }
    };
    
    function request()      { return { type: audioPlayerConstants.ADD_TO_END_OF_PLAYLIST_REQUEST}; }
    function success(data)  { return { type: audioPlayerConstants.ADD_TO_END_OF_PLAYLIST_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}

function insertAudioAtFront(audio, playlist, callback = null) {

    // playlist.unshift(audio)
    const deepClone = JSON.parse(JSON.stringify(playlist));
    deepClone.unshift(audio)
    // const deepClone = JSON.parse(JSON.stringify(args));
    console.log("DEEP CLONE", deepClone)

    return dispatch => {
        dispatch(request());
        dispatch(success(deepClone))

        // if (localChangesMade === true || localChangesMade === false) {
        //     dispatch(setLocalChanges(localChangesMade))
        //     dispatch(setHmpgTmplForUser(deepClone))
        // }
    };
    
    function request()      { return { type: audioPlayerConstants.INSERT_AT_FRONT_OF_PLAYLIST_REQUEST}; }
    function success(data)  { return { type: audioPlayerConstants.INSERT_AT_FRONT_OF_PLAYLIST_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}

// function queueAudio(audio, playlist, callback = null) {
    
//     playlist.push(audio)

//     return dispatch => {
//         dispatch(request());
//         dispatch(success())

//         // if (localChangesMade === true || localChangesMade === false) {
//         //     dispatch(setLocalChanges(localChangesMade))
//         //     dispatch(setHmpgTmplForUser(deepClone))
//         // }
//     };
    
//     function request()      { return { type: audioPlayerConstants.QUEUE_AUDIO_REQUEST}; }
//     function success(data)  { return { type: audioPlayerConstants.QUEUE_AUDIO_SUCCESS, data }; }
//     // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
// }

function clearPlaylistInstructions() {
    return dispatch => {
        dispatch(request());
        dispatch(success())

        // if (localChangesMade === true || localChangesMade === false) {
        //     dispatch(setLocalChanges(localChangesMade))
        //     dispatch(setHmpgTmplForUser(deepClone))
        // }
    };
    
    function request()      { return { type: audioPlayerConstants.CLEAR_PLAYLIST_INSTRUCTIONS_REQUEST}; }
    function success(data)  { return { type: audioPlayerConstants.CLEAR_PLAYLIST_INSTRUCTIONS_SUCCESS, data }; }
}


// function setHmpgTmplForUser(args, viewMode, callback = null) {
//     const deepClone = JSON.parse(JSON.stringify(args));

//     console.log("View Mode Called", viewMode)
//     return dispatch => {
//         dispatch(request());
//         dispatch(success(deepClone)) 
//         if (viewMode) {
//             dispatch(setHmpgTmplViewMode(viewMode))
//         }
//         if (callback) {
//             callback(true, deepClone);
//         }
//     };
    
//     function request()      { return { type: audioPlayerConstants.SET_HMPG_TMPL_REQUEST}; }
//     function success(data)  { return { type: audioPlayerConstants.SET_HMPG_TMPL_SUCCESS, data }; }
//     // function failure(error) { return { type: audioPlayerConstants.SET_HMPG_TMPL_FAILURE, error }; }

// }

// function setHmpgTmplViewMode(viewMode, callback = null) {
//     // required arguments: 
//     //   1) 'viewMode': BASE, DRAFT, PUBLISHED
//     console.log("setHmpgTmplViewMode", viewMode)

//     return dispatch => {
//         dispatch(request());
//         if (viewMode === 'DRAFT' || viewMode === 'PUBLISHED') {
//             console.log("setHmpgTmplViewMode, success", viewMode)

//             dispatch(success(viewMode))
//         } else {
//             let failString = "error " + viewMode 
//             dispatch(failure(failString));
//         }
//     };
    
//     function request()      { return { type: audioPlayerConstants.SET_HMPG_VIEW_MODE_REQUEST}; }
//     function success(data)  { return { type: audioPlayerConstants.SET_HMPG_VIEW_MODE_SUCCESS, data }; }
//     function failure(error) { return { type: audioPlayerConstants.SET_HMPG_VIEW_MODE_FAILURE, error }; }

// }

// function setLocalChanges(localChanges, callback = null) {
//     // required arguments: 
//     //   1) 'true or false' 


//     return dispatch => {
//         dispatch(request());
//         dispatch(success(localChanges))
//     };
    
//     function request()      { return { type: audioPlayerConstants.SET_LOCAL_CHANGES_REQUEST}; }
//     function success(data)  { return { type: audioPlayerConstants.SET_LOCAL_CHANGES_SUCCESS, data }; }
//     // function failure(error) { return { type: audioPlayerConstants.SET_LOCAL_CHANGES_FAILURE, error }; }

// }
