import { locationConstants, config } from '_constants';

const initialState = { currentLocation: config.defaultLocation};

export function locationData(state = initialState, action) {
    switch(action.type) {
        // Get Locations
        case locationConstants.GET_LOCATIONS_REQUEST:
        return {
            ...state,
            getLocations: true,
        };
        case locationConstants.GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.data,
                getLocations: false,
            };
        case locationConstants.GET_LOCATIONS_FAILURE:
            return {
                ...state,
                getLocations: false,
            };

        // Create Update Location
        case locationConstants.CREATE_UPDATE_LOCATION_REQUEST:
            return {
                ...state,
                createUpdateLocation: true,
            };
        case locationConstants.CREATE_UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                createUpdateLocation: false,
            };
        case locationConstants.CREATE_UPDATE_LOCATION_FAILURE:
            return {
                ...state,
                createUpdateLocation: false,
            };
        // Delete Location
        case locationConstants.DELETE_LOCATION_REQUEST:
            return {
                ...state,
                deleteLocation: true,
            };
        case locationConstants.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                deleteLocation: false,
            };
        case locationConstants.DELETE_LOCATION_FAILURE:
            return {
                ...state,
                deleteLocation: false,
            };

        case locationConstants.GET_CURRENT_LOCATION_REQUEST:
            return {
                ...state,
                getCurrentLocation: true,
            }
        case locationConstants.GET_CURRENT_LOCATION_SUCCESS:
            return {
                ...state,
                getCurrentLocation: false,
                currentLocation: action.data
            }
        case locationConstants.GET_CURRENT_LOCATION_FAILURE:
            return {
                ...state,
                getCurrentLocation: false,
                currentLocation: action.data,
            }
        default:
            return state;
    }
}