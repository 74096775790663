import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    CardImg,
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod} from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";


class CelebEventCard  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {

  }

  render() {
    const now = moment() 
    const event = this.props.event
    const eventsNeedingReviews = this.props.eventsNeedingReviews
    const newMessageEvents = this.props.newMessageEvents
    return (
        <Link to={ {pathname: `/dash/celeb-event/`, 
                        state: { eventId: event.event_id, businessId: event.business_id, bidId: event.bid_id, schId: event.sch_id} 
                    }}
                                  // activeClassName="active"
                                >
                      <Card>
                        <CardImg
                          alt="..."
                          src={event.image_url}
                          onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
                          top
                        />
                        <CardBody>
                          <CardTitle className="h2 mb-0">
                          {event.event_name}
                          </CardTitle>
                          <h6 className="surtitle pt-2">
                            {dispTimePeriod(event.sb_start_time, event.sb_end_time, event.time_zone, true)}                                              
                          </h6>
                        </CardBody>
                        <CardFooter>
                          <Row>
                            <Col md={6} className="ml-auto pt-1" > 
                              <h4> {event.sp_trait} </h4>
                            </Col> 
                            <Col md={6} className="text-right">
                              <Row className="justify-content-end">
                                { moment(event.sb_start_time).isAfter(now) && event.state ==="Booked" && 
                                <Col className="col-auto pl-0">
                                  <div className="icon icon-sm icon-shape bg-gradient-livenyte text-white rounded-circle shadow">
                                    <i className="fas fa-star" />
                                  </div>
                                </Col>}
                                { moment(event.ev_end_time).isBefore(now) && event.state === "Booked" && 
                                <Col className="col-auto pl-0">
                                    <div className="icon icon-sm icon-shape bg-gradient-gray text-white rounded-circle shadow">
                                      <i className="fas fa-star" />
                                    </div>
                                </Col>}
                                { (newMessageEvents.length > 0 && newMessageEvents.filter(ev => ev.event_id === event.event_id).length > 0) && 
                                <Col className="col-auto pl-0">
                                  <div className="icon icon-sm icon-shape bg-gradient-tertiary text-white rounded-circle shadow">
                                    <i className="ni ni-chat-round" />
                                  </div>
                                </Col>}
                                {moment(event.sb_start_time).isAfter(now) && event.state === "Request" && 
                                  <Col className="col-auto pl-0">
                                    <div className="icon icon-sm icon-shape bg-gradient-danger text-white rounded-circle shadow">
                                    <i className="fas fa-solid fa-meteor"></i>
                                    </div>
                                  </Col>}
                                {eventsNeedingReviews.filter ( e => e.event_id === event.event_id).length > 0 &&
                                <Col className="col-auto pl-0">
                                  <div className="icon icon-sm icon-shape bg-gradient-warning text-white rounded-circle shadow">
                                    <i className="fas fa-pen" />
                                  </div>
                                </Col>}
                              </Row>
                            </Col>
                          </Row>
                        </CardFooter>
                      </Card>
                    </Link>
    );
  }
}

function mapStateToProps(state) {
  
    console.log("BusinessEventCard: mapStateToProps");
    return {

    };
  }


const connectedCelebEventCard = connect(mapStateToProps)(CelebEventCard);
export { connectedCelebEventCard as CelebEventCard   }; 