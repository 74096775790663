import React from "react";
import { connect } from 'react-redux';

// import {
//   Row,
// } from "reactstrap";
import { Link } from 'react-router-dom';

// import { Accordion, PanelHeader } from "components";

class AffiliateFAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="text-center">
          <br/><br/><br/>
        <div className="landing-row ">
            <h2 className="landing-h2">Affiliate Resources</h2>
        </div>
                <Link to={{pathname: `/auth/businessLanding`}} 
                    className={" " }>
                    <h4>Business + Event Organizer Information</h4>                     
                </Link>
                <Link to={{pathname: `/auth/creatorLanding`}} 
                    className={ ""}>
                    <h4>Creator Information</h4>                     
                </Link>    
      </div>
    );
  }
}

function mapStateToProps(state) {
    console.log("FAQ: mapStateToProps");
    return {    
   
    };
  }

const connectedAffiliateFAQ= connect(mapStateToProps)(AffiliateFAQ);
export { connectedAffiliateFAQ as AffiliateFAQ };