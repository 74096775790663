import { scheduleConstants, } from '_constants';

const initialState = {};

function mergeSchedules(schedules, newSchedules) {
    if (schedules && schedules.length > 0) {
        console.log("mergeSchedules", schedules, newSchedules)
        if (newSchedules.length > 0) {
            let mergedSchedules = schedules.reduce( (acc, sch) => {
                if (newSchedules.filter( ns => ns.id === sch.id).length === 0) {
                    acc.push(sch)
                }
                return acc;
            }, [...newSchedules])
            return mergedSchedules;
        } else {
            return schedules;
        }
    } else {
        return newSchedules
    }
}

export function scheduleData(state = initialState, action) {
    switch(action.type) {
        // Get Schedules
        case scheduleConstants.GET_SCHEDULES_REQUEST:
            return {
                ...state,
                fetchingSchedules: true,
            };
        case scheduleConstants.GET_SCHEDULES_SUCCESS:
            return {
                ...state,
                fetchingSchedules: false,
                schedules: mergeSchedules(state.schedules, action.data),
            };
        case scheduleConstants.GET_SCHEDULES_FAILURE:
            return {
                ...state,
                fetchingSchedules: false,
            };
        // Create/Update Schedule
        case scheduleConstants.CREATE_UPDATE_SCHEDULE_REQUEST:
            return {
                ...state,
                createUpdateSchedule: true,
            };
        case scheduleConstants.CREATE_UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                createUpdateSchedule: false,
            };
        case scheduleConstants.CREATE_UPDATE_SCHEDULE_FAILURE:
            return {
                ...state,
                createUpdateSchedule: false,
            };
        // Delete Schedule
        case scheduleConstants.DELETE_SCHEDULE_REQUEST:
            return {
                ...state,
                deleteSchedule: true,
            };
        case scheduleConstants.DELETE_SCHEDULE_SUCCESS:
            return {
                ...state,
                schedules: state.schedules.filter( sch => sch.id !== action.schId),
                deleteSchedule: false,
            };
        case scheduleConstants.DELETE_SCHEDULE_FAILURE:
            return {
                ...state,
                deleteSchedule: false,
            };        
        
        // Get Templates
        case scheduleConstants.GET_TEMPLATES_REQUEST:
            return {
                ...state,
                fetchingTemplates: true,
            };
        case scheduleConstants.GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                fetchingTemplates: false,
                templates: action.data,
            };
        case scheduleConstants.GET_TEMPLATES_FAILURE:
            return {
                ...state,
                fetchingTemplates: false,
                templates: [],
            };
        // Create/Update/Delete Template
        case scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_REQUEST:
            return {
                ...state,
                createUpdateDeleteTemplate: true,
            };
        case scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                createUpdateDeleteTemplate: false,
            };
        case scheduleConstants.CREATE_UPDATE_DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                createUpdateDeleteTemplate: false,
            };        
        default:
            return state;
    }
};
