import { ticketConstants } from '_constants';

function mergeTicket(tickets, newTickets) {
    if (tickets && tickets.length > 0) {
        let newTicket = newTickets[0]
        console.log("mergeTicket", tickets, newTicket)
        let mergedTickets = tickets.map( ticket => ticket.ticket_id === newTicket.ticket_id ? newTicket : ticket)
        return mergedTickets
    } else {
        return newTickets
    }
}

const initialState = {};

export function ticketsData(state = initialState, action) {
    switch(action.type) {
        // Get Tickets
        case ticketConstants.GET_TICKETS_REQUEST:
            return {
                ...state,
                fetchingTickets: true,
            };
        case ticketConstants.GET_TICKETS_SUCCESS:
            return {
                ...state,
                fetchingTickets: false,
                tickets: action.data.tickets,
            };
        case ticketConstants.GET_TICKETS_FAILURE:
            return {
                ...state,
                fetchingTickets: false,
            };
        // Get Ticket
        case ticketConstants.GET_TICKET_REQUEST:
            return {
                ...state,
                fetchingTickets: true,
            };
        case ticketConstants.GET_TICKET_SUCCESS:
            return {
                ...state,
                fetchingTickets: false,
                tickets: mergeTicket(state.tickets, action.data.tickets),
            };
        case ticketConstants.GET_TICKET_FAILURE:
            return {
                ...state,
                fetchingTickets: false,
            };
        // Create/Update Ticket
        case ticketConstants.CREATE_UPDATE_TICKET_REQUEST:
            return {
                ...state,
                createUpdateDone: false,
                createUpdateTicket: true, 
            };
        case ticketConstants.CREATE_UPDATE_TICKET_SUCCESS:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateTicket: false, 

            };
        case ticketConstants.CREATE_UPDATE_TICKET_FAILURE:
            return {
                ...state,
                createUpdateDone: true,
                createUpdateTicket: false, 
            };
        // PURCHASE Ticket
        case ticketConstants.PURCHASE_TICKET_REQUEST:
            return {
                ...state,
                purchaseRequest: true,
            };
        case ticketConstants.PURCHASE_TICKET_SUCCESS:
            return {
                ...state,
                receipt: action.data.receipt,
                purchaseRequest: false,
            };
        case ticketConstants.PURCHASE_TICKET_FAILURE:
            return {
                ...state,
                purchaseRequest: false,
            };
        // PURCHASED Ticket
        case ticketConstants.GET_PURCHASED_TICKET_REQUEST:
            return {
                ...state,
                getPurchasedTicketsRequest: true,
            };
        case ticketConstants.GET_PURCHASED_TICKET_SUCCESS:
            return {
                ...state,
                purchasedTickets: action.data.tickets,
                getPurchasedTicketsRequest: false,
            };
        case ticketConstants.GET_PURCHASED_TICKET_FAILURE:
            return {
                ...state,
                getPurchasedTicketsRequest: false,
            };
        // PURCHASED Ticket Receipt
        case ticketConstants.GET_PURCHASED_TICKET_RECEIPT_REQUEST:
            return {
                ...state,
                getPurchasedTicketReceiptRequest: true,
            };
        case ticketConstants.GET_PURCHASED_TICKET_RECEIPT_SUCCESS:
            return {
                ...state,
                ticketReceipt: action.data,
                getPurchasedTicketReceiptRequest: false,
            };
        case ticketConstants.GET_PURCHASED_TICKET_RECEIPT_FAILURE:
            return {
                ...state,
                getPurchasedTicketReceiptRequest: false,
                    };
        // PURCHASED Ticket Receipt
        case ticketConstants.GET_PRIVATE_TICKETS_CODES_REQUEST:
            return {
                ...state,
                getPrivateTicketCodesRequest: true,
            };
        case ticketConstants.GET_PRIVATE_TICKETS_CODES_SUCCESS:
            return {
                ...state,
                ticketPrivateCodes: action.data,
                getPrivateTicketCodesRequest: false,
            };
        case ticketConstants.GET_PRIVATE_TICKETS_CODES_FAILURE:
            return {
                ...state,
                getPrivateTicketCodesRequest: false,
            };
        // Refund Tickets 
        case ticketConstants.REFUND_TICKETS_REQUEST:
            return {
                ...state,
                refundTicketsRequest: true,
            };
        case ticketConstants.REFUND_TICKETS_SUCCESS:
            return {
                ...state,
                refundTicketsRequest: false,
            };
        case ticketConstants.REFUND_TICKETS_FAILURE:
            return {
                ...state,
                refundTicketsRequest: false,
            };

        default:
            return state;
    }
}