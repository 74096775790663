import React from "react";
import {
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  CardFooter,
//   Button,

} from "reactstrap";
import { connect } from 'react-redux';
import { affiliateActions } from '_actions';
import { dispFullTimeEventPeriod } from '_helpers';

import {
    
  } from "components";

class AffiliateAccountBox extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(affiliateActions.getPromotions());
      }

  render() {
    const smallMode = this.props.smallMode ? true : false 
    const { getAffiliatePromotion, affiliatePromotion } = this.props;
    if (  getAffiliatePromotion || getAffiliatePromotion === undefined ) {
      return(
        <div>
            
        </div>
      )
    }
    
    const businessBookingPercentage = affiliatePromotion && affiliatePromotion.hasOwnProperty('bus_booking_pct') && affiliatePromotion.bus_booking_pct * 100
    const celebrityBookingPercentage = affiliatePromotion && affiliatePromotion.hasOwnProperty('spc_booking_pct') && affiliatePromotion.spc_booking_pct * 100 
    const businessTicketPercentage = affiliatePromotion && affiliatePromotion.hasOwnProperty('ticket_pct') && affiliatePromotion.ticket_pct * 100 
    const promotionDuration = affiliatePromotion && affiliatePromotion.hasOwnProperty('start_date') && dispFullTimeEventPeriod(affiliatePromotion.start_date, affiliatePromotion.end_date)
    const payoutPeriodMonths = affiliatePromotion && affiliatePromotion.hasOwnProperty('payout_period') && affiliatePromotion.payout_period
    // const payoutPeriodYears = affiliatePromotion && affiliatePromotion.hasOwnProperty('payout_period') && (affiliatePromotion.payout_period % 12 === 0 ? affiliatePromotion.payout_period / 12 : (affiliatePromotion.payout_period / 12.0).toFixed(2))

    return (
        
            <section id="fan">
            {/* {smallMode && 
                <div className="landing-row ">
                    <h2 className="landing-h2">Our Affiliates </h2>
                    <p className = "long-copy">If you enjoy using LiveNyte and you want to see it grow, learn more about our affiliate program!  </p>
                    {smallMode &&
                    <div className="text-center">  
                        <Link to={ {pathname: `/dash/affiliateProgram`}}>
                            <Button 
                                simple 
                                className="title" 
                                color="primary">
                                Learn More
                            </Button>
                        </Link>
                    </div>
                    }
                </div>
             } */}
                {!smallMode && affiliatePromotion && affiliatePromotion.hasOwnProperty('start_date') && 
                <div> 
                    <div>
                        <div className="text-center text-white pt-2 mt-1"> 
                                <h2 className="text-white surtitle text-uppercase">Current Promotional Offer</h2>
                                <h4 className="text-white text-uppercase surtitle mt-2">{promotionDuration}</h4>
                        </div>
                        <Row className="justify-content-center">
                            <Col lg="10" md="10" className="mt-5 pt-4">
                            <div className="pricing card-group flex-column flex-md-row mb-3">
                                <Card className="card-pricing border-0 text-center mb-4">
                                <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                                    Refer Talent & Creators
                                    </h4>
                                </CardHeader>
                                <CardBody className="px-lg-7 pb-3">
                                    <div className="display-2">{celebrityBookingPercentage}%</div>
                                    <span className="text-muted">of LiveNyte's booking revenue</span>
                                    <br/>
                                    <h5 className="surtitle mt-1 pt-1 text-primary">for {payoutPeriodMonths} months </h5>
                                 
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={10} sm={8} xs={11} className="ml-auto mr-auto text-center">
                                            <div className="icon icon-lg icon-shape bg-gradient-livenyte text-white rounded-circle pb-3 mb-3">
                                                    <i className="fas fa-star " />
                                            </div>
                                                <p> For every creator you refer during this promotional period, you will receive a revenue share associated with how much revenue that the creator generates for LiveNyte for&nbsp;
                                                    <span className="title">{payoutPeriodMonths} months</span>
                                            </p>
                                        </Col>
                                
                                        
                                    </Row>
                                </CardFooter>
                                </Card>
                                <Card className="card-pricing zoom-in-1 bg-gradient-purple  border-0 text-center mb-4 rounded">
                                <CardHeader className="bg-transparent">
                                    <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                    Refer Businesses
                                    </h4>
                                </CardHeader>
                                <CardBody className="px-lg-12 pb-3 bg-transparent">
                                    <Row>
                                        <Col xs={6}>
                                        <div className="display-2 text-white">{businessBookingPercentage}%</div>
                                            <span className="text-white">of LiveNyte's booking revenue</span>
                                        </Col>
                                        <Col xs={6}>
                                        <div className="display-2 text-white">{businessTicketPercentage}%</div>
                                            <span className="text-white">of LiveNyte's ticket revenue</span>
                                        </Col>
                                    </Row>    
                                    <h5 className="surtitle mt-1 pt-1 text-white mt-3">for {payoutPeriodMonths} months </h5>
                                </CardBody>
                                <CardFooter className="rounded bg-transparent">
                                    <Row>
                                        <Col md={10} sm={8} xs={11} className="ml-auto mr-auto text-center">
                                        <div className="icon icon-lg icon-shape bg-gradient-danger text-white rounded-circle mb-4">
                                            <i className="ni ni-building" />
                                        </div>
                                            <p className="text-white"> For every business you refer during this promotional period, you will receive a revenue share associated with how much revenue that business generates for LiveNyte for&nbsp;
                                                <span className="title">{payoutPeriodMonths} months</span>
                                            </p>
                                        </Col>
                                
                                        
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div> 
            }
            {!smallMode && !affiliatePromotion &&
            <div className="pb-4 mb-4">
                <div className="text-center text-white pt-2 mt-1"> 
                        <h2 className="text-white surtitle text-uppercase">Current Promotional Offer</h2>
                        <h4 className="text-white text-uppercase surtitle mt-2">No Affiliate Promotion. Check back soon!</h4>
                </div>
                
            </div>
            }
        </section>
    );
  }
}

export default AffiliateAccountBox;

function mapStateToProps(state) {
    const {  affiliatesData } = state;
    const { getAffiliatePromotion, affiliatePromotion } = affiliatesData;
    return {
        getAffiliatePromotion, affiliatePromotion
      
    };
  }
  
  const connectedAffiliateAccountBox= connect(mapStateToProps)(AffiliateAccountBox);
  export { connectedAffiliateAccountBox as AffiliateAccountBox };
