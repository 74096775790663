import { marketingEmailConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from 'helpers';

export const marketingEmailActions = {
    getMarketingEmailTypes,
    getMarketingEmailExclusions,
    updateMarketingEmailExclusions,
};

function getMarketingEmailTypes(callback = null) {

    const params = {
        request: 'marketing_email_types',
      };
      
  
    console.log("getMarketingEmailTypes", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("test A marketing_email_types", data, data.marketing_email_types)
                    dispatch(success(data));
                    if (callback) {
                        callback(data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getMarketingEmailTypes: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_REQUEST} }
    function success(data)   { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_SUCCESS, data } }
    function failure(error)  { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_TYPES_FAILURE, error } }
}

function getMarketingEmailExclusions(callback = null) {

    const params = {
        request: 'marketing_email_exclusions',
      };
      
  
    console.log("getMarketingEmailExclusions", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    console.log("test B marketing_email_exclusions", data, data.marketing_email_exclusions)
                    if (callback) {
                        callback(success, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getMarketingEmailExclusions: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_REQUEST} }
    function success(data)   { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_SUCCESS, data } }
    function failure(error)  { return { type: marketingEmailConstants.GET_MARKETING_EMAIL_EXCLUSIONS_FAILURE, error } }
}

function updateMarketingEmailExclusions(_exclusions, callback = null) {
    const exclusions = JSON.stringify(_exclusions)
    console.log("test", _exclusions, exclusions)
    const params = {
        request: 'update_marketing_email_exclusions',
        marketing_email_exclusions: exclusions
      };
      
  
    console.log("updateMarketingEmailExclusions", params)
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateMarketingEmailExclusions: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };

    function request()       { return { type: marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_REQUEST} }
    function success(data)   { return { type: marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_SUCCESS, data } }
    function failure(error)  { return { type: marketingEmailConstants.UPDATE_MARKETING_EMAIL_EXCLUSIONS_FAILURE, error } }
}