import { stripeConstants } from '_constants';


const initialState = {};

export function stripeData(state = initialState, action) {
    switch(action.type) {
        // Get Stripe Connect URL
        case stripeConstants.GET_CONNECT_URL_REQUEST:
            return {
                ...state,
                fetchingURL: true,
            };
        case stripeConstants.GET_CONNECT_URL_SUCCESS:
            return {
                ...state,
                fetchingURL: false,
                url: action.data.url,
            };
        case stripeConstants.GET_CONNECT_URL_FAILURE:
            return {
                ...state,
                fetchingURL: false,
            };
        // Get Stripe Connect ID
        case stripeConstants.GET_CONNECT_ID_REQUEST:
            return {
                ...state,
                fetchingConnectID: true,
            };
        case stripeConstants.GET_CONNECT_ID_SUCCESS:
            return {
                ...state,
                fetchingConnectID: false,
                connect_id: action.data.connect_id,
                isConnectIDRestricted: action.data.restricted,
            };
        case stripeConstants.GET_CONNECT_ID_FAILURE:
            return {
                ...state,
                fetchingConnectID: false,
                connect_id: null, 
            };
        // Get Stripe Customer ID
        case stripeConstants.GET_CUSTOMER_ID_REQUEST:
            return {
                ...state,
                fetchingCustomerID: true,
            };
        case stripeConstants.GET_CUSTOMER_ID_SUCCESS:
            return {
                ...state,
                fetchingCustomerID: false,
                customer_id: action.data.customer_id,
            };
        case stripeConstants.GET_CUSTOMER_ID_FAILURE:
            return {
                ...state,
                fetchingCustomerID: false,
            };
        // Retrieve Sources
        case stripeConstants.RETRIEVE_SOURCES_REQUEST:
            return {
                ...state,
                fetchingSources: true,
            };
        case stripeConstants.RETRIEVE_SOURCES_SUCCESS:
            return {
                ...state,
                fetchingSources: false,
                sources: action.data.sources,
            };
        case stripeConstants.RETRIEVE_SOURCES_FAILURE:
            return {
                ...state,
                fetchingSources: false,
            };
        // Register Payout Account
        case stripeConstants.REGISTER_PAYOUT_ACCOUNT_REQUEST:
            return {
                ...state,
                registeringPayoutAccount: true,
            };
        case stripeConstants.REGISTER_PAYOUT_ACCOUNT_SUCCESS:
            return {
                ...state,
                registeringPayoutAccount: false,
            };
        case stripeConstants.REGISTER_PAYOUT_ACCOUNT_FAILURE:
            return {
                ...state,
                registeringPayoutAccount: false,
            };
        // Source Account
        case stripeConstants.SOURCE_ACTION_REQUEST:
            return {
                ...state,
                stripeSourceActionRequest: true,
                stripeSourceActionSuccess: false,
            };
        case stripeConstants.SOURCE_ACTION_SUCCESS:
            return {
                ...state,
                stripeSourceActionRequest: false,
                stripeSourceActionSuccess: true,
            };
        case stripeConstants.SOURCE_ACTION_FAILURE:
            return {
                ...state,
                stripeSourceActionRequest: false,
                stripeSourceActionSuccess: false,
            };
            case stripeConstants.CREATE_CUSTOMER_REQUEST:
            return {
                ...state,
                stripeCreateCustomerRequest: true,
                stripeCreateCustomerSuccess: false,
            };
        case stripeConstants.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                stripeCreateCustomerRequest: false,
                stripeCreateCustomerSuccess: true,
            };
        case stripeConstants.CREATE_CUSTOMER_FAILURE:
            return {
                ...state,
                stripeCreateCustomerRequest: false,
                stripeCreateCustomerSuccess: false,
            };
        default:
            return state
        }
    }