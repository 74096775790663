import { agencyConstants } from '_constants';

const initialState = {};

export function agencyData(state = initialState, action) {
    switch(action.type) {
        // AGENCY Query
        case agencyConstants.AGENCY_QUERY_REQUEST:
            return {
                ...state,
                fetchingAgencyInfo: true,
            };
        case agencyConstants.AGENCY_QUERY_SUCCESS:
            return {
                ...state,
                fetchingAgencyInfo: false,
                agencyInfo: action.data,
            };
        case agencyConstants.AGENCY_QUERY_FAILURE:
            return {
                ...state,
                fetchingAgencyInfo: false,
            };
        case agencyConstants.GET_CLIENTS_REQUEST:
            return {
                ...state,
                fetchingClients: true,
            };
        case agencyConstants.GET_CLIENTS_SUCCESS:
            return {
                ...state,
                fetchingClients: false,
                clients: action.data.clients,
                bus_clients: action.data.bus_clients,
            };
        case agencyConstants.GET_CLIENTS_FAILURE:
            return {
                ...state,
                fetchingClients: false,
            };
                // Member Query
        case agencyConstants.LOGIN_AS_CLIENT_REQUEST:
            return {
                ...state,
                loginAsClientRequest: true,
            };
        case agencyConstants.LOGIN_AS_CLIENT_SUCCESS:
            return {
                ...state,
                loginAsClientRequest: false,
                agency_specialist: action.data,
            };
        case agencyConstants.LOGIN_AS_CLIENT_FAILURE:
            return {
                ...state,
                loginAsClientRequest: false,
            };
        case agencyConstants.LOGOUT_AS_CLIENT_REQUEST:
            return {
                ...state,
                logoutAsClientRequest: true,
            };
        case agencyConstants.LOGOUT_AS_CLIENT_SUCCESS:
            return {
                ...state,
                logoutAsClientRequest: false,
                miscData: action.data,
            };
        case agencyConstants.LOGOUT_AS_CLIENT_FAILURE:
            return {
                ...state,
                logoutAsClientRequest: false,
            };

        case agencyConstants.UPDATE_PIN_REQUEST:
            return {
                ...state,
                updatePinRequest: true,
            };
        case agencyConstants.UPDATE_PIN_SUCCESS:
            return {
                ...state,
                updatePinRequest: false,
                pin: action.data,
            };
        case agencyConstants.UPDATE_PIN_FAILURE:
            return {
                ...state,
                updatePinRequest: false,
            };
    
        case agencyConstants.AUTHORIZE_AGENCY_ACCESS_REQUEST:
            return {
                ...state,
                authorizeAgencyAccessRequest: true,
            };
        case agencyConstants.AUTHORIZE_AGENCY_ACCESS_SUCCESS:
            return {
                ...state,
                authorizeAgencyAccessRequest: false,
            };
        case agencyConstants.AUTHORIZE_AGENCY_ACCESS_FAILURE:
            return {
                ...state,
                authorizeAgencyAccessRequest: false,
            };
        case agencyConstants.REVOKE_AGENCY_ACCESS_REQUEST:
            return {
                ...state,
                revokeAgencyAccessRequest: true,
            };
        case agencyConstants.REVOKE_AGENCY_ACCESS_SUCCESS:
            return {
                ...state,
                revokeAgencyAccessRequest: false,
            };
        case agencyConstants.REVOKE_AGENCY_ACCESS_FAILURE:
            return {
                ...state,
                revokeAgencyAccessRequest: false,
            };
        default:
            return state;
        }
            

}
