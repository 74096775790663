
import { notificationMessageConstants } from '_constants';

export const notificationMessageActions = {
    addNotificationToQueue,
    clearNotification
};

function addNotificationToQueue(message, color) {

    return dispatch => {
        dispatch(request());
        dispatch(success({message: message, color: color}));
    };
    
    function request()      { return { type: notificationMessageConstants.SET_NOTIFICATION_MESSAGE_REQUEST}; }
    function success(data)  { return { type: notificationMessageConstants.SET_NOTIFICATION_MESSAGE_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}

function clearNotification() {

    return dispatch => {
        dispatch(request());
        dispatch(success());
    };
    
    function request()      { return { type: notificationMessageConstants.CLEAR_NOTIFICATION_MESSAGE_REQUEST}; }
    function success(data)  { return { type: notificationMessageConstants.CLEAR_NOTIFICATION_MESSAGE_SUCCESS, data }; }
    // function failure(error) { return { type: audioPlayerConstants.UPDATE_LOCAL_DRAFT_FAILURE, error }; }
}