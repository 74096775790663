import { reviewConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const reviewActions = {
    getReviews,
    getReviewsForId,  // id: spId or busId
    saveReview,
    markReviewsAsRead,
};

function getReviews(eventId = null, callback = null) {
    const params = {
        request: 'get_reviews',
      };
    if (eventId != null) {
        params['event_id'] = eventId
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Reviews");
                    console.log(data)
                    dispatch(success(data));
                    if (callback !== null) {
                        callback(true, data.reviews)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getReviews: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: reviewConstants.GET_REVIEWS_REQUEST} }
    function success(data)   { return { type: reviewConstants.GET_REVIEWS_SUCCESS, data } }
    function failure(error)  { return { type: reviewConstants.GET_REVIEWS_FAILURE, error } }
}

function getReviewsForId(id) {
    const params = {
        request: 'get_reviews_for_id',
        id: id, // id can be event_id (for specialist user) or sp_id (for business user)
      };

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Reviews For ID XX");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getReviewsForId: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: reviewConstants.GET_REVIEWS_FOR_ID_REQUEST} }
    function success(data)   { return { type: reviewConstants.GET_REVIEWS_FOR_ID_SUCCESS, data } }
    function failure(error)  { return { type: reviewConstants.GET_REVIEWS_FOR_ID_FAILURE, error } }
}

function saveReview(friendId, reviewId, eventId, review, rating, callback = null) {
    const params = {
        request: 'set_review',
        friend_id: friendId,
        event_id: eventId,
        review_id: reviewId,
        review: review,
        rating: rating,
      };

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Save Review", data);
                    // dispatch(getReviews());
                    dispatch(reviewActions.getReviews());
                    dispatch(success(data));

                    if (callback) { callback(true, data)}
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("saveReview: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: reviewConstants.SAVE_REVIEW_REQUEST} }
    function success(data)   { return { type: reviewConstants.SAVE_REVIEW_SUCCESS, data } }
    function failure(error)  { return { type: reviewConstants.SAVE_REVIEW_FAILURE, error } }
}

function markReviewsAsRead(reviewIds) {
    const params = {
        request: 'mark_reviews_as_read',
        review_ids: reviewIds.join(),
      };

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Mark Reviews as Read");
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("markReviewsAsRead: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    };
    
    function request()       { return { type: reviewConstants.MARK_REVIEWS_AS_READ_REQUEST} }
    function success(data)   { return { type: reviewConstants.MARK_REVIEWS_AS_READ_SUCCESS, data } }
    function failure(error)  { return { type: reviewConstants.MARK_REVIEWS_AS_READ_FAILURE, error } }
}