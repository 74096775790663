import { screenSizeConstants } from '_constants';

export function screenSizeData(state = { updateScreenSizeRequest: null, is_xxs: false, is_xs: false, is_sm: false, is_md: false, is_lg: false, is_xl: false, is_xxl: false } , action) {
    switch(action.type) {

        case screenSizeConstants.SET_SCREEN_SIZE_XXS_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XXS_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: true,
                is_xs: true,
                is_sm: true,
                is_md: true,
                is_lg: true,
                is_xl: true,
                is_xxl: true, 
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XS_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XS_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: true,
                is_sm: true,
                is_md: true,
                is_lg: true,
                is_xl: true,
                is_xxl: true, 
            }
        case screenSizeConstants.SET_SCREEN_SIZE_SM_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_SM_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: false,
                is_sm: true,
                is_md: true,
                is_lg: true,
                is_xl: true,
                is_xxl: true, 
            }

        case screenSizeConstants.SET_SCREEN_SIZE_MD_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_MD_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: false,
                is_sm: false,
                is_md: true,
                is_lg: true,
                is_xl: true,
                is_xxl: true, 
            }
        case screenSizeConstants.SET_SCREEN_SIZE_LG_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_LG_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: false,
                is_sm: false,
                is_md: false,
                is_lg: true,
                is_xl: true,
                is_xxl: true, 
            }

        case screenSizeConstants.SET_SCREEN_SIZE_XL_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XL_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: false,
                is_sm: false,
                is_md: false,
                is_lg: false,
                is_xl: true,
                is_xxl: true,  
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XXL_REQUEST:
            return {
                ...state,
                updateScreenSizeRequest: true,
            }
        case screenSizeConstants.SET_SCREEN_SIZE_XXL_SUCCESS:
            return {
                ...state,
                updateScreenSizeRequest: false,
                is_xxs: false,
                is_xs: false,
                is_sm: false,
                is_md: false,
                is_lg: false,
                is_xl: false,
                is_xxl: true, 
            }
        
    
            default: return state; 
        }
    }
        