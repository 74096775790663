import { planConstants } from '_constants';

export function planData(state = {}, action) {
    switch(action.type) {
        case planConstants.GET_PLANS_REQUEST:
            return {
                ...state,
                getPlans: true,
            };
        case planConstants.GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.plans,
                getPlans: false,
            };
        case planConstants.GET_PLANS_FAILURE:
            return {
                ...state,
                getPlans: false,
            };        
        case planConstants.CREATE_PLAN_REQUEST:
            return {
                ...state,
                createPlan: true,
            };
        case planConstants.CREATE_PLAN_SUCCESS:
            return {
                ...state,
                createPlan: false,
            };
        case planConstants.CREATE_PLAN_FAILURE:
            return {
                ...state,
                createPlan: false,
            };         
        case planConstants.UPDATE_PLAN_REQUEST:
            return {
                ...state,
                updatePlan: true,
            };
        case planConstants.UPDATE_PLAN_SUCCESS:
            return {
                ...state,
                updatePlan: false,
            };
        case planConstants.UPDATE_PLAN_FAILURE:
            return {
                ...state,
                updatePlan: false,
            };
        case planConstants.DISABLE_PLAN_REQUEST:
            return {
                ...state,
                disablePlan: true,
            };
        case planConstants.DISABLE_PLAN_SUCCESS:
            return {
                ...state,
                disablePlan: false,
            };
        case planConstants.DISABLE_PLAN_FAILURE:
            return {
                ...state,
                disablePlan: false,
            };                       
        default:
            return state;
        }

}
