export const scheduleConstants = {
    GET_SCHEDULES_REQUEST: 'GET_SCHEDULES_REQUEST',
    GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
    GET_SCHEDULES_FAILURE: 'GET_SCHEDULES_FAILURE',

    CREATE_UPDATE_SCHEDULE_REQUEST: 'CREATE_UPDATE_SCHEDULE_REQUEST',
    CREATE_UPDATE_SCHEDULE_SUCCESS: 'CREATE_UPDATE_SCHEDULE_SUCCESS',
    CREATE_UPDATE_SCHEDULE_FAILURE: 'CREATE_UPDATE_SCHEDULE_FAILURE',

    DELETE_SCHEDULE_REQUEST: 'DELETE_SCHEDULE_REQUEST',
    DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
    DELETE_SCHEDULE_FAILURE: 'DELETE_SCHEDULE_FAILURE',

    GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
    GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
    GET_TEMPLATES_FAILURE: 'GET_TEMPLATES_FAILURE',

    CREATE_UPDATE_DELETE_TEMPLATE_REQUEST: 'CREATE_UPDATE_DELETE_TEMPLATE_REQUEST',
    CREATE_UPDATE_DELETE_TEMPLATE_SUCCESS: 'CREATE_UPDATE_DELETE_TEMPLATE_SUCCESS',
    CREATE_UPDATE_DELETE_TEMPLATE_FAILURE: 'CREATE_UPDATE_DELETE_TEMPLATE_FAILURE',
};
