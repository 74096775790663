import { watchConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';

export const watchActions = {
    watchItem,
    unwatchItem,
    getWatchedItems,
}

function watchItem(args, callback = null) {
    // required arguments: item_id, item_type
    // item_type one of 'PRODUCT', 'EVENT', 'ASSET' or 'POST'
    const params = {
        request: 'watch_item',
        item_id: args.item_id,
        item_type: args.item_type
      };
      console.log("args", args, params)

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Watch Item");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("watchItem: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: watchConstants.WATCH_ITEM_REQUEST} }
    function success(data)  { return { type: watchConstants.WATCH_ITEM_SUCCESS, data } }
    function failure(error) { return { type: watchConstants.WATCH_ITEM_FAILURE, error } }
}

function unwatchItem(args, callback = null) {
    // required arguments: item_id, item_type
    // item_type one of 'PRODUCT', 'EVENT', 'ASSET' or 'POST'

    const params = {
        request: 'unwatch_item',
        item_id: args.item_id,
        item_type: args.item_type
      };
      console.log("args", args, params)

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Unwatch Item");
                    console.log(data)
                
                    dispatch(success(data));
                    dispatch(watchActions.getWatchedItems({item_type: args.item_type}))    
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("unwatchItem: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: watchConstants.UNWATCH_ITEM_REQUEST} }
    function success(data)  { return { type: watchConstants.UNWATCH_ITEM_SUCCESS, data } }
    function failure(error) { return { type: watchConstants.UNWATCH_ITEM_FAILURE, error } }
}

function getWatchedItems(args, callback = null) {
    // required arguments: none
    // optional argument: item_type
    // item_type one of 'PRODUCT', 'EVENT', 'ASSET' or 'POST'

    const params = {
        request: 'get_watched_items',
      };
    
    if (args.hasOwnProperty('item_type')) {
        params['item_type'] = args.item_type
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("getWatchedItems");
                    console.log(data)
                
                    dispatch(success(data));
                    console.log("GetWatchedItems", data)
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getWatchedItems: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: watchConstants.GET_WATCHED_ITEMS_REQUEST} }
    function success(data)  { return { type: watchConstants.GET_WATCHED_ITEMS_SUCCESS, data } }
    function failure(error) { return { type: watchConstants.GET_WATCHED_ITEMS_FAILURE, error } }
}
