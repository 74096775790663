import { affiliateConstants } from '_constants';

const initialState = {};

export function affiliatesData(state = initialState, action) {
    switch(action.type) {

        case affiliateConstants.AFFILIATE_GET_PROMOTION_REQUEST:
            return {
                ...state,
                getAffiliatePromotion: true,
            };
        case affiliateConstants.AFFILIATE_GET_PROMOTION_SUCCESS:
            return {
                ...state,
                getAffiliatePromotion: false,
                affiliatePromotion: action.data.promotion,
            };
        case affiliateConstants.AFFILIATE_GET_PROMOTION_FAILURE:
            return {
                ...state,
                getAffiliatePromotion: false,
            };

        case affiliateConstants.REGISTER_AS_AFFILIATE_REQUEST:
            return {
                ...state,
                registerAsAffiliateRequest: true,
            };
        case affiliateConstants.REGISTER_AS_AFFILIATE_SUCCESS:
            return {
                ...state,
                registerAsAffiliateRequest: false,
                referralCode: action.data.referral_code,
            };
        case affiliateConstants.REGISTER_AS_AFFILIATE_FAILURE:
            return {
                ...state,
                registerAsAffiliateRequest: false,
            };

        case affiliateConstants.GET_AFFILIATE_CLIENTS_REQUEST:
            return {
                ...state,
                fetchingAffiliateClients: true,
            };
        case affiliateConstants.GET_AFFILIATE_CLIENTS_SUCCESS:
            return {
                ...state,
                fetchingAffiliateClients: false,
                affiliateClients: action.data.clients,
            };
        case affiliateConstants.GET_AFFILIATE_CLIENTS_FAILURE:
            return {
                ...state,
                fetchingAffiliateClients: false,
                affiliateClients: [],
            };
        case affiliateConstants.GET_AFFILIATE_EVENTS_REQUEST:
            return {
                ...state,
                fetchingAffiliateEvents: true,
            };
        case affiliateConstants.GET_AFFILIATE_EVENTS_SUCCESS:
            return {
                ...state,
                fetchingAffiliateEvents: false,
                affiliateEvents: action.data.events,
            };
        case affiliateConstants.GET_AFFILIATE_EVENTS_FAILURE:
            return {
                ...state,
                fetchingAffiliateEvents: false,
                affiliateEvents: [],
            };
        default:
            return state;
        }
            

}
