import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '_reducers';
//import { loadState } from '_helpers';

const loggerMiddleware = createLogger();

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        // const serializedStateParsed = JSON.parse(serializedState);
        // console.log("loading serialized state", serializedStateParsed)
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
    
const persistedState = loadState();
export const store = process.env.NODE_ENV !== 'development' ? createStore(rootReducer, persistedState, applyMiddleware(thunkMiddleware)) 
                                                            : createStore(rootReducer, persistedState, applyMiddleware(thunkMiddleware, loggerMiddleware ) 

// export const store = createStore(rootReducer, persistedState, applyMiddleware(thunkMiddleware, loggerMiddleware) 
);