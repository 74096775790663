export const collectionConstants = {
    GET_COLLECTIONS_REQUEST: 'GET_COLLECTIONS_REQUEST',
    GET_COLLECTIONS_SUCCESS: 'GET_COLLECTIONS_SUCCESS',
    GET_COLLECTIONS_FAILURE: 'GET_COLLECTIONS_FAILURE',

    CREATE_COLLECTION_REQUEST: 'CREATE_COLLECTION_REQUEST',
    CREATE_COLLECTION_SUCCESS: 'CREATE_COLLECTION_SUCCESS',
    CREATE_COLLECTION_FAILURE: 'CREATE_COLLECTION_FAILURE',

    UPDATE_COLLECTION_REQUEST: 'UPDATE_COLLECTION_REQUEST',
    UPDATE_COLLECTION_SUCCESS: 'UPDATE_COLLECTION_SUCCESS',
    UPDATE_COLLECTION_FAILURE: 'UPDATE_COLLECTION_FAILURE',

}
