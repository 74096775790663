import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { featuredActions } from '_actions';
import { numberFunction } from '_helpers';
import {
  Card,
  CardBody,
  // CardFooter,
  // CardTitle,
  Row,
  Col,
  Container,
  CardImg

//  UncontrolledTooltip,

} from "reactstrap";

import {
  // Progress
  Button
} from "components";

import defaultAvatar from "assets/img/default-avatar.png"

class FeaturedCelebsSection extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {
      hTabs: "ht1",
      searchTabs: "st1",
      alert: null,
      };

      this.toggleModalNotice = this.toggleModalNotice.bind(this);
      this.hideAlert = this.hideAlert.bind(this);

    }
  
    componentDidMount() {
      // const user = JSON.parse(localStorage.getItem('user'));
      // const userIsUser = user && user.is_user;
      // const userIsBusiness = user && user.is_business;
      // const userIsSpecialist = user && user.is_specialist;
      // const userViewFollowing = userIsSpecialist || userIsUser

      let queryArgs = {limit: 12}
      this.props.dispatch(featuredActions.featuredCelebs(queryArgs, null))
        
    }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }

    hideAlert() {
      this.setState({
        alert: null
      });
    }

    toggleModalNotice() {
      this.setState({
        modalNotice: !this.state.modalNotice
      });
    }

    render() {
      const {
        fetchingfeaturedCelebsInfo, featuredCelebs,} = this.props;
      // const user = JSON.parse(localStorage.getItem('user'));

      if (fetchingfeaturedCelebsInfo) {
        return(
          <div/>    
        )
      }

      let fltFeaturedCelebs = []
      if (featuredCelebs && featuredCelebs.length > 0) {
          fltFeaturedCelebs = featuredCelebs.filter( celeb =>  celeb.sp_photo !== null).slice(0,8)
      }

    // let filterStatus = ""
      return (
    <section className="py-7 section-nucleo-icons bg-secondary overflow-hidden">
      <Container>
        <Row className="justify-content-center">
            <span className="display-3 text-center surtitle text-default  mb-0 livenyte-title special-underline-livenyte mb-2">Featured Talent</span>
              <div>
                <Container fluid className="content">
                  {this.state.alert}
                  {fltFeaturedCelebs && fltFeaturedCelebs.length > 0 &&
                        <div > 
                          <Row>
                          {fltFeaturedCelebs.map( (celeb, idx) =>
                              <Col md={4} xs={6} sm={6} lg={4} xl={3} key={idx} className="pt-4 ml-auto mr-auto">
                                <Link to={ { pathname: `/dash/c_profile/${numberFunction(celeb.member_id)}`, 
                                                state: {sp_id: celeb.member_id, }} }>                                  
                                                {/* {celeb.spName} */}

                                  {/* <Card className={"card-profile mb-0" } data-background="full">
                                        <img
                                            className="img img-raised rounded"
                                            alt={celeb.sp_name}             
                                            src={(celeb.gallery_prefix + celeb.sp_photo)}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                                        ></img>
                                        <div className="card-image"/>
                                        <CardBody className="bg-darkMode" >
                                        <Row>
                                            <Col className="text-left ">
                                                <h4 className="text-white">{celeb.sp_name}   </h4>
                                                <h6 className="surtitle text-light">
                                                {Object.keys(celeb.traits[0]) }
                                                </h6>
                                            </Col>
                                        </Row>
                                      </CardBody>
                                    </Card> */}
                                    
                                    <Card className="">
                                      <CardImg
                                          src={(celeb.gallery_prefix + celeb.sp_photo)}
                                          alt={celeb.sp_name}
                                          onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                                          top
                                          style={{aspectRatio: "1", objectFit: "cover"}}
                                        />
                                        <CardBody className="text-left">
                                            <h3 className="title ">{celeb.sp_name}</h3>
                                            <h4 className="surtitle trait-subtitle" >{Object.keys(celeb.traits[0]) } </h4>
                                        </CardBody> 
                                      </Card>
                                  </Link>
                              </Col>)}
                            </Row>
                            <Row>
                            <Col xs={12} lg={12} className="ml-auto mr-auto" style={{marginTop:"15px",}}>
                                <div className={this.props.is_xs || this.props.is_xxs || this.props.is_sm ? 'text-center' : 'text-right' }> 
                                    <Link to={{pathname: `/dash/featuring`}} >
                                        {!this.props.is_xs && !this.props.is_sm && !this.props.is_xxs && 
                                        <h5 className="text-default surtitle">See more&nbsp; {">"}

                                        </h5>
                                        }
                                        { (this.props.is_xs || this.props.is_sm || this.props.is_xxs) &&
                                        <Button className="title landing-body" block outline color="default" >&nbsp;&nbsp;&nbsp;&nbsp;See More&nbsp;&nbsp;&nbsp;&nbsp;</Button>

                                        }
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        </div> 
                    }
                </Container>
              </div>
            </Row>
        </Container>
      </section>
      );
    }
  }
  
  //export {BusinessDashboard} ;
  function mapStateToProps(state) {
    const { memberInfo, featuredData, screenSizeData } = state;
    const { fetchingfeaturedCelebsInfo, featuredCelebs } = featuredData;
    const { info } = memberInfo;
    const { is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest  } = screenSizeData;

    console.log("Following: mapStateToProps");
    return {
      info,   
      fetchingfeaturedCelebsInfo, featuredCelebs, 
      is_xxs, is_xs, is_sm, is_md, is_lg, is_xl, is_xxl, updateScreenSizeRequest

      
    };
  }
  
  const connectedFeaturedCelebsSection = connect(mapStateToProps)(FeaturedCelebsSection);
  export { connectedFeaturedCelebsSection as FeaturedCelebsSection };
  
  