import { notificationMessageConstants } from '_constants';

export function notificationMessageData(state = {newNotification: null }, action) {
    switch(action.type) {

        case notificationMessageConstants.SET_NOTIFICATION_MESSAGE_REQUEST:
            return {
                ...state,
                newNotificationRequest: false,
                // notificationMessageRequest: true,
            }
        case notificationMessageConstants.SET_NOTIFICATION_MESSAGE_SUCCESS:
            return {
                ...state,
                newNotificationRequest: true,
                newNotification: action.data,
                // notificationMessageRequest: false,
            }
        case notificationMessageConstants.CLEAR_NOTIFICATION_MESSAGE_REQUEST:
            return {
                ...state,
                newNotificationRequest: false,
                // notificationMessageRequest: true,
            }
        case notificationMessageConstants.CLEAR_NOTIFICATION_MESSAGE_SUCCESS:
            return {
                ...state,
                newNotificationRequest: false,
                newNotification: null,
                // notificationMessageRequest: false,
            }
        default: return state; 

    }
}
        