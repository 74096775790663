import { caseConstants } from '_constants';

const initialState = {};

export function caseCodeData(state = initialState, action) {
    switch(action.type) {
        case caseConstants.GET_CASE_CODES_REQUEST:
            return {
                ...state,
                getCaseCodes: true,
            };
        case caseConstants.GET_CASE_CODES_SUCCESS:
            return {
                ...state,
                codes: action.data.codes,
                getCaseCodes: false,
            };
        case caseConstants.GET_CASE_CODES_FAILURE:
            return {
                ...state,
                getCaseCodes: false,
            };
        case caseConstants.REPORT_NEW_CASE_REQUEST:
            return {
                ...state,
                reportNewCase: true,
            };
        case caseConstants.REPORT_NEW_CASE_SUCCESS:
            return {
                ...state,
                reportNewCase: false,
            };
        case caseConstants.REPORT_NEW_CASE_FAILURE:
            return {
                ...state,
                reportNewCase: false,
            };
        
        default:
            return state;
    }
}