export const productConstants = {
    GET_PRODUCT_TYPES_REQUEST: 'GET_PRODUCT_TYPES_REQUEST',
    GET_PRODUCT_TYPES_SUCCESS: 'GET_PRODUCT_TYPES_SUCCESS',
    GET_PRODUCT_TYPES_FAILURE: 'GET_PRODUCT_TYPES_FAILURE',

    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
    
    CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
    CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_FAILURE: 'CREATE_PRODUCT_FAILURE',

    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',

    DISABLE_PRODUCT_REQUEST: 'DISABLE_PRODUCT_REQUEST',
    DISABLE_PRODUCT_SUCCESS: 'DISABLE_PRODUCT_SUCCESS',
    DISABLE_PRODUCT_FAILURE: 'DISABLE_PRODUCT_FAILURE',

    ENABLE_PRODUCT_REQUEST: 'ENABLE_PRODUCT_REQUEST',
    ENABLE_PRODUCT_SUCCESS: 'ENABLE_PRODUCT_SUCCESS',
    ENABLE_PRODUCT_FAILURE: 'ENABLE_PRODUCT_FAILURE',

    GET_FEATURED_PRODUCTS_REQUEST: 'GET_FEATURED_PRODUCTS_REQUEST',
    GET_FEATURED_PRODUCTS_SUCCESS: 'GET_FEATURED_PRODUCTS_SUCCESS',
    GET_FEATURED_PRODUCTS_FAILURE: 'GET_FEATURED_PRODUCTS_FAILURE',

    GET_REMITS_FOR_PRODUCTS_REQUEST: 'GET_REMITS_FOR_PRODUCTS_REQUEST',
    GET_REMITS_FOR_PRODUCTS_SUCCESS: 'GET_REMITS_FOR_PRODUCTS_SUCCESS',
    GET_REMITS_FOR_PRODUCTS_FAILURE: 'GET_REMITS_FOR_PRODUCTS_FAILURE',

    // For Goods
    PURCHASE_PRODUCT_REQUEST: 'PURCHASE_PRODUCT_REQUEST',
    PURCHASE_PRODUCT_SUCCESS: 'PURCHASE_PRODUCT_SUCCESS',
    PURCHASE_PRODUCT_FAILURE: 'PURCHASE_PRODUCT_FAILURE',
}
