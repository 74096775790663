import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "components";
import {  trim, } from '_helpers';

import { 
  // Col, 
  Row
} from "reactstrap";

class CardSocialsVertical extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {

    };
    
  }

  componentDidMount() {

  }

  render() {
    return (
      <div >
      <Row>
          <Row style={{marginLeft:"22px", transform: 'translate(0, -17px)'}}>
            {this.props.socials.map((prop, key) => {
                return (
                  <div key={key} className="ml-auto mr-auto"> 
                      <Button
                        neutral
                        icon
                        round
                        className={prop.buttonClass}
                        size={this.props.size}
                        key={key}
                        // href={prop.link}
                      >
                        <i className={prop.icon} />
                      </Button>
                      <span> {trim(prop.count)} </span>
                  </div>
                );
              })}
            </Row> 
        </Row>
      </div>
    );
  }
}

CardSocialsVertical.propTypes = {
  // size of all social buttons
  size: PropTypes.oneOf(["sm", "lg"]),
  // example: [{icon: "name of icon", href="href of icon"},...]
  socials: PropTypes.arrayOf(PropTypes.object)
};

export default CardSocialsVertical;
