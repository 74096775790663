import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
import { currencyFormat } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import { feeActions } from '_actions';
          

class CelebPaymentsHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };
  }

  

  componentDidMount() {
    this.props.dispatch(feeActions.getFees())
  }

  render() {
    const {fees } = this.props   
    const {liveNyteBookingPercentage, liveNyteBookingHandlingFee, stripeFlatFee, stripePercentage, fetchingFees} = fees
    const combinedPercentage = liveNyteBookingPercentage + stripePercentage
    const combinedFlatFee = liveNyteBookingHandlingFee + stripeFlatFee
    console.log("fees", fees)
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("CelebPaymentsHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>
          <Row>
            <Col xs={12}>
                <CardBody className="text-left">
                <h4>Payout Account</h4>
                Connect a debit card or bank account to receive booking fees when businesses book you. Your payout information will be held secured securely by Stripe. In order to create schedules and receive bids, you need to have a Payout Account set up.
                <br/><br/>
                <h4>Payout Schedule</h4>
                Booking Fees are typically scheduled to deposit from your payout account to either your debit card or your bank account 5 days after an event unless a dispute is filed. If a dispute is filed, LiveNyte will freeze the payout and attempt to help the business and talent resolve the case.
                <br/><br/>
                <b>*Please Note:</b> Your first payout's processing times are increased for security and verification purposes, which may delay the first payout by a few days.
                <br/><br/>
                <h4>Fees</h4>
                LiveNyte lists your services for {combinedPercentage}% + {currencyFormat('us', combinedFlatFee)} more to businesses. 
                From this, LiveNyte collects {liveNyteBookingPercentage}% + {currencyFormat('us', liveNyteBookingHandlingFee)} and pays the payment processor {stripePercentage}% + {currencyFormat('us', stripeFlatFee)}.
                <br/><br/>
                <i>LiveNyte reserves the right to change its fees in the future. </i>
                </CardBody>
   
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedCelebPaymentsHelpModal = connect(mapStateToProps)(CelebPaymentsHelpModal);
export { connectedCelebPaymentsHelpModal as CelebPaymentsHelpModal }; 