import React from "react";
import {
  CardBody,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
// import { history } from '_helpers';

// import Datetime from "react-datetime";
import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
// import * as moment from 'moment';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

          

class SubeventHelpModal extends React.Component {
  constructor(props) {
    super(props);
    console.log("CreateUpdateLocation constructor()", props)
    this.state = {
    };

  }

  

  componentDidMount() {
  }

  render() {
    const {fees } = this.props   
    const {fetchingFees} = fees
    // let remit = num * ticket.price
    // let lvFee = remit * (liveNyteTicketPercentage/100) +
    //             num   * liveNyteTicketFlatFee
    // let stripeFee = (remit + lvFee + stripeFlatFee) / (1 - (stripePercentage/100)) - remit - lvFee
    // console.log("num", num, "remit", remit, "lvFee", lvFee, "stripeFee", stripeFee)
    console.log("SubeventHelpModal: render", this.state)


    if (fetchingFees === true) {
      return(
        <div>Loading</div>
      )
    }
    return (
      <ModalBody>
 
          <Row>
            <Col xs={12}>
                  <CardBody className="text-left pt-0">
                    <h4>General</h4>
                    A time block is a period of time during your event in which you want to book talent. 
                    <br/><br/>
                    Choose the start and end time of your time block, what types of talent, how many you're seeking to book.
                    (Ex: 2 Comedians, 3 Musicians, 1 Actor, 4 Models, etc.)
                    <br/><br/>
                    Place bids on the talent that you're interested in booking. LiveNyte will not overbook talent, so you can place as many bids as you'd like and you'll only book up to the maximum number of talent that you specified. 
                    <br/><br/>
                    If there's a talent that you want to book immediately, you can elect to use <i>Book Now</i> after a talent has approved your bid. 
                  </CardBody>
              </Col>
          </Row>
        </ModalBody> 
    );
  }
}

function mapStateToProps(state) {
  const {  feesData } = state;
  const { fees, fetchingFees } = feesData;
  return {
    fees,fetchingFees
  };
}

const connectedSubeventHelpModal = connect(mapStateToProps)(SubeventHelpModal);
export { connectedSubeventHelpModal as SubeventHelpModal }; 