import { planConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const planActions = {
    getPlans,
    createPlan,
    updatePlan,
    disablePlan,
};

function getPlans(args, callback = null) {
    // required arguments: none
    // optional arguments: 
    // ----- Called by creator ---------
    // 1) 'createdByMe': Returns all plans created by user
    //   OR
    // 2) 'productId': Returns all plans for this product
    //   OR
    // 3) 'planId': Returns plan this one id
    //   OR
    //-------- Called by user -----------
    // 4) filter type
    //  a) 'byId': Returns all plans created byId
    //  b) 'lookFor': Returns all plans where lookFor matches name of plans or name of owner

    const params = {
        request: 'get_plans',
      };

    if (args.hasOwnProperty('createdByMe')) {
        params['created_by_me'] = true
    } else  if (args.hasOwnProperty('productId')) {
        params['product_id'] = args.productId
    } else if (args.hasOwnProperty('planId')) {
        params['plan_id'] = args.planId
    } else  {        
        if (args.hasOwnProperty('byId')) {
            params['by_id'] = args.byId
        }
        if (args.hasOwnProperty('lookFor')) {
            params['look_for'] = args.lookFor
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Plans");
                    console.log(data)
                
                    dispatch(success(data.plans));
                    if (callback) {
                        callback(true, data.plans)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getPlans: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: planConstants.GET_PLANS_REQUEST} }
    function success(plans) { return { type: planConstants.GET_PLANS_SUCCESS, plans } }
    function failure(error) { return { type: planConstants.GET_PLANS_FAILURE, error } }
}

function createPlan(args, callback=null) {
    // required arguments: product_id, name, desc, plan, price, currency, interval, effectiveDate, timeZone
    //   where interval is 'Monthly' or 'Yearly'
    // optional arguments: trial, image, imageType
    // where imageType is one of Image, Video, Music, Youtube
    const params = {
        request: 'create_plan',
        name: args.name,
        desc: args.description,
        price: args.price,
        currency: args.currency,     
        interval: args.interval,        // 'Monthly', 'Yearly'
        eff_date: args.effectiveDate,
        time_zone: args.timeZone,
        // product_id: args.productId,
        tier_id: args.tierId,
      };

    const user = JSON.parse(localStorage.getItem('user'))
   
    if (args.hasOwnProperty('trial')) {
        params['trial'] = args.trial // number of days
    }
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Created Plan");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                    dispatch(getPlans({byId: user.user_id}))
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createPlan: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: planConstants.CREATE_PLAN_REQUEST} }
    function success(data)   { return { type: planConstants.CREATE_PLAN_SUCCESS, data } }
    function failure(error)  { return { type: planConstants.CREATE_PLAN_FAILURE, error } }
}

function updatePlan(args, callback=null) {
    // required arguments: planId, name, description, 
    // optional arguments: productId, price, currency, interval, trial, effectiveDate, timeZone, image, imageType
    // where imageType is one of Image, Video, Music, Youtube
    const params = {
        request: 'update_plan',
        plan_id: args.planId,
        name: args.name,
        desc: args.description,        
      };
    if (args.hasOwnProperty('productId')) {
        params['product_id'] = args.productId
    }
    
    if (args.hasOwnProperty('price')) {
        params['price'] = args.price
    }
    if (args.hasOwnProperty('currency')) {
        params['currency'] = args.currency
    }
    if (args.hasOwnProperty('interval')) {
        params['interval'] = args.interval
    }
    if (args.hasOwnProperty('trial'))  {
        params['trial'] = args.trial
    }
    if (args.hasOwnProperty('effectiveDate') && args.hasOwnProperty('timeZone')) {
        params['eff_date'] = args.effectiveDate
        params['time_zone'] = args.timeZone
    }
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }

    const user = JSON.parse(localStorage.getItem('user'))
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Updated Plan");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                    dispatch(getPlans({byId: user.user_id}))
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updatePlan: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: planConstants.UPDATE_PLAN_REQUEST} }
    function success(data)   { return { type: planConstants.UPDATE_PLAN_SUCCESS, data } }
    function failure(error)  { return { type: planConstants.UPDATE_PLAN_FAILURE, error } }
}

function disablePlan(args, callback=null) {
    // required arguments: planId, 
    // optional arguments: effectiveDate, timeZone
    const params = {
        request: 'disable_plan',
        plan_id: args.planId,
      };

    if (args.hasOwnProperty('effectiveDate') && args.hasOwnProperty('timeZone')) {
        params['eff_date'] = args.effectiveDate
        params['time_zone'] = args.timeZone
    }

    const user = JSON.parse(localStorage.getItem('user'))

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Diabled Plan");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                    dispatch(getPlans({byId: user.user_id}))
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("disablePlan: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: planConstants.DISABLE_PLAN_REQUEST} }
    function success(data)   { return { type: planConstants.DISABLE_PLAN_SUCCESS, data } }
    function failure(error)  { return { type: planConstants.DISABLE_PLAN_FAILURE, error } }
}
