import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,

    CardImg,

    UncontrolledTooltip
  } from "reactstrap";
  // react plugin used to 
// import { history } from '_helpers';
import {  dispTimePeriod, history,  currencyFormat, onlyUniqueFeaturedCelebs, numberFunction } from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { watchActions } from '_actions';

import noImage from "assets/img/img-livenyte/LiveNyteNoImage.jpg";

import Action from "components/FaveButton/src/components/Tweet/Action.js"
import StarButton from "components/FaveButton/src/components/LikeButton/StarButton.js"

class EventForUserCard  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      isWatchClicked: false, 
    };
    this.handleToggleWatch = this.handleToggleWatch.bind(this)
    this.watchHandler = this.watchHandler.bind(this)
    this.goToEvent = this.goToEvent.bind(this)
  }

  componentDidMount() {
    const { user } = this.state
    const { watchedEvents } = this.props 
    const loggedIn = user && user.hasOwnProperty('email');
    const event = this.props.selectedEvent
    const eventId = event.event_id
    if (loggedIn) {
      if (watchedEvents && watchedEvents.filter(watchedEvent => watchedEvent.event_id === eventId) && watchedEvents.filter(watchedEvent => watchedEvent.event_id === eventId).length > 0 ) {
        this.setState({
          isWatchClicked: true 
        })
      } else {
        this.setState({
          isWatchClicked: false 
        })
      }
    }
  }

  handleToggleWatch() {
    const { isWatchClicked } = this.state
    this.setState({
      isWatchClicked: !isWatchClicked
    }, this.watchHandler)
  }

  watchHandler() {
    const { isWatchClicked } = this.state 
    const event = this.props.selectedEvent

    const args = {
      item_id: event.event_id,
      item_type: "EVENT"
    }
    if (isWatchClicked) {
      this.props.dispatch(watchActions.watchItem(args))
    } else {
      this.props.dispatch(watchActions.unwatchItem(args))
    }
  }
  

  goToEvent = () => {
    const event = this.props.selectedEvent
    history.push(`/dash/event/${event.event_id}`)
  }

  render() {
    const event = this.props.selectedEvent
    const { hideBottomBar, showSubadminArea } = this.props 
    const { user } = this.state
    const loggedIn = user && user.hasOwnProperty('email');

    return (  
              <div className={loggedIn ? "mt--5" : ""}>
                {loggedIn && 
                  <Action 
                      color="rgb(224, 36, 94)" 
                      size={48} 
                      onClick={this.handleToggleWatch} 
                      isLeft={10} 
                      translateYbySize={10} 
                    >
                      <StarButton 
                        size={48} 
                        isLiked={this.state.isWatchClicked}
                      />
                  </Action>
                  }
                <div className="text-right">
 
                </div>
                  
                    <Card>
                      <CardImg
                      alt="..."
                      src={event.image_url}
                      onError={(e)=>{e.target.onerror = null; e.target.src=noImage}}
                      onClick={this.goToEvent}
                      className="cursor-pointer"
                      top
                    />
                    {event.celebs && event.celebs.length > 0 && 
                        <div className="text-right pr-3 mt--4">
                          <div className="avatar-group">
                            {event.celebs && onlyUniqueFeaturedCelebs(event.celebs).map( (celeb, key) => 
                                <Link
                                  className="avatar avatar-md-small rounded-circle"
                                  id={`event_${event.event_id}_celeb_${celeb[0]}_key${key}`}
                                  to={ { pathname: `/dash/c_profile/${numberFunction(celeb[0])}`, 
                                  state: {sp_id: celeb[0] }} }
                                  key={key}
                                >
                                  <img
                                    alt="..."
                                    src={celeb[2]}
                                  />
                                  {console.log("Tooltip", `${event.event_id}_celeb_${celeb[0]}`)}
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={`event_${event.event_id}_celeb_${celeb[0]}_key${key}`}
                                    >
                                    {celeb[1]} 
                                  </UncontrolledTooltip>  
                                </Link>
                            )}
                          </div>
                        </div>}
                      <CardBody 
                      style={{paddingBottom:'4px'}} 
                      className={event.celebs && event.celebs.length > 0 ? "cursor-pointer mt--4" : "cursor-pointer mt--1"} 
                      onClick={this.goToEvent}

                      >

                        <CardTitle className="h2 mb-0">
                        {event.event_name}
                        </CardTitle>
                        <h6 className="surtitle py-2">
                          {dispTimePeriod(event.ev_start_time, event.ev_end_time, event.time_zone, true)}                                              
                        </h6>

                      </CardBody>
                      {hideBottomBar && 
                        <div className="pt-3"/>
                      }
                      {!hideBottomBar && 
                      <CardFooter 
                      onClick={this.goToEvent}
                      className="cursor-pointer"
                      >
                          <Row > 
                            <Col >
                            {event.ticket_price_range && event.ticket_price_range[0] !== event.ticket_price_range[1] && event.ticket_price_range[0] !== 0 &&  
                              <Row style={{marginLeft:'0px'}}>
                                <i className="fas fa-solid fa-ticket" style={{marginTop:'1px'}}/>&nbsp;&nbsp;
                                <h5 className="surtitle">{currencyFormat(event.country, event.ticket_price_range[0], 0)  + " - "  +  currencyFormat(event.country, event.ticket_price_range[1])}</h5>
                              </Row>
                            }
                            {event.ticket_price_range && event.ticket_price_range[0] === event.ticket_price_range[1] && event.ticket_price_range[0] !== 0 &&
                              <Row style={{marginLeft:'0px'}}>
                                <i className="fas fa-solid fa-ticket" style={{marginTop:'1px'}}/>&nbsp;&nbsp;
                                <h5 className="surtitle">{ currencyFormat(event.country, event.ticket_price_range[0], 0)   + "+"}</h5>
                              </Row>
                            }
                          {event.ticket_price_range && event.ticket_price_range[0] === event.ticket_price_range[1] && event.ticket_price_range[0] === 0 &&
                              <Row style={{marginLeft:'0px'}}>
                                <i className="fas fa-solid fa-ticket" style={{marginTop:'1px'}}/>&nbsp;&nbsp;
                                <h5 className="surtitle">{'Free'}</h5>
                              </Row>
                            }
                          {event.ticket_price_range && event.ticket_price_range[0] !== event.ticket_price_range[1] && event.ticket_price_range[0] === 0 &&
                              <Row style={{marginLeft:'0px'}}>
                                <i className="fas fa-solid fa-ticket" style={{marginTop:'1px'}}/>&nbsp;&nbsp;
                                <h5 className="surtitle">{"Free - " + currencyFormat(event.country, event.ticket_price_range[1], 0 )}</h5>
                              </Row>
                            }
                          </Col> 
                          <Col xs={6}> 
                          {console.log("Event subadmin", event)}
                        {event.hasOwnProperty("distance") && !showSubadminArea ? (
                          <h5 className="text-right surtitle"> {event.distance}{event.distance === 1 ? " mile away" : " miles away"} </h5>
                        ) : (
                          <h5 className="text-right surtitle "> {event.sub_admin_area} </h5>
                        )}
                          </Col>
                        </Row>
                      </CardFooter>
                      } 
                    </Card>
                  </div>
    );
  }
}

function mapStateToProps(state) {
  const { watchData  } = state;
  const { watchedProducts, watchedEvents, watchedAssets, watchedPosts, getWatchedItemsRequest } = watchData;

    console.log("EventForUserCard: mapStateToProps");
    return {
      watchedProducts, watchedEvents, watchedAssets, watchedPosts, getWatchedItemsRequest

    };
  }


const connectedEventForUserCard = connect(mapStateToProps)(EventForUserCard  );
export { connectedEventForUserCard as EventForUserCard   }; 