import { assetConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const assetActions = {
    getAssetCategories,
    getAssets,
    getAssetsForProduct,
    getAssetForUser,
    createAsset,
    updateAsset,
    deleteAsset,
    purchaseAsset,
    downloadAsset,
    viewAssetInBuffer,
};

function getAssetCategories(args, callback = null) {
    // required arguments: none    

    const params = {
        request: 'get_asset_categories',
      };
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Asset Categories");
                    console.log(data)
                
                    dispatch(success(data.asset_categories));
                    if (callback) {
                        callback(true, data.asset_categories)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getAssetCategories: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()                { return { type: assetConstants.GET_ASSET_CATEGORIES_REQUEST} }
    function success(assetCategories) { return { type: assetConstants.GET_ASSET_CATEGORIES_SUCCESS, assetCategories } }
    function failure(error)           { return { type: assetConstants.GET_ASSET_CATEGORIES_FAILURE, error } }
}

function getAssets(args, callback = null) {
    // required arguments: none
    // optional arguments: 
    // 1) 'purchased': Returns all assets purchased by user
    //   OR
    // 2) 'assetId': Returns asset for creator
    //     optional: 'inline ' - Returns data in string
    // 2) filter type
    //  a) 'type': Returns all assets of 'type'
    //  b) 'byId': Returns all assets created byId
    //  c) 'lookFor': Returns all assets where lookFor matches name of asset, name of creator,
    //      name of subscription or name of collection

    const params = {
        request: 'get_assets',
      };

    if (args.hasOwnProperty('productId')) {
        params['product_id'] = args.productId
    }

    if (args.hasOwnProperty('purchased')) {
        params['purchased'] = true
    } else if (args.hasOwnProperty('assetId')) {
        params['asset_id'] = args.assetId
        if (args.hasOwnProperty('inline')) {
            params['inline'] = true
        }    
    } else {
        if (args.hasOwnProperty('type')) {
            params['type'] = args.type
        }
        if (args.hasOwnProperty('byId')) {
            params['by_id'] = args.byId
        }
        if (args.hasOwnProperty('lookFor')) {
            params['look_for'] = args.lookFor
        }
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Assets");
                    console.log(data)
                
                    dispatch(success(data.assets));
                    if (callback) {
                        callback(true, data.assets)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getAssets: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: assetConstants.GET_ASSETS_REQUEST} }
    function success(assets) { return { type: assetConstants.GET_ASSETS_SUCCESS, assets } }
    function failure(error)  { return { type: assetConstants.GET_ASSETS_FAILURE, error } }
}

function getAssetsForProduct(productId, callback = null) {
    // required arguments: productId
    

    const params = {
        request: 'get_assets_for_product',
        product_id: productId
      };

   
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Assets For Product");
                    console.log(data)
                
                    dispatch(success(data.assets));
                    if (callback) {
                        callback(true, data.assets)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getAssetsForProduct: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: assetConstants.GET_ASSETS_FOR_PRODUCT_REQUEST} }
    function success(assets) { return { type: assetConstants.GET_ASSETS_FOR_PRODUCT_SUCCESS, assets } }
    function failure(error)  { return { type: assetConstants.GET_ASSETS_FOR_PRODUCT_FAILURE, error } }
}

function getAssetForUser(args, callback = null) {
    // required arguments: assetId
    // optional arguments: country
    
    const params = {
        request: 'user_get_asset',
        asset_id: args.assetId
      };

    if (args.hasOwnProperty('country')) {
        params['country'] = args.country
    }

   
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Asset For User");
                    console.log(data)
                
                    dispatch(success(data.asset));
                    if (callback) {
                        callback(true, data.asset)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getAssetForUser: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: assetConstants.GET_ASSET_FOR_USER_REQUEST} }
    function success(asset) { return { type: assetConstants.GET_ASSET_FOR_USER_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.GET_ASSET_FOR_USER_FAILURE, error } }
}

function createAsset(args, callback=null) {
    // required arguments: name, description, type
    // MUST SPECIFY ONE OF asset or assetData
    // optional arguments: price, asset, assetData, image, imageType, preview OR previewData & previewType, state, membership    
    // where price is an array of { currency: price }
    //   where assetData is string that contains the asset (data comes from string and not uploaded file)
    //   where imageType is one of Image, Video, Music, Youtube
    //   where previewType is one of Image, Video, Music, Youtube
    //   where state is one of 'Published', 'Unpublished', 'Inactive' (on creation default: Unpublished)
    //   where membership is one of: 'Any', 'CollectionOnly', 'SubscriptionOnly', or 'ItemOrCollection' (on creation default: 'ItemOrCollection')
    //   where previewData is string that contains the preview (data comes from string and not uploaded file)
    console.log("Create Asset called", args)
    const params = {
        request: 'create_asset',
        name: args.name,
        description: args.description,
        type: args.type,       // Song, Blog, Newsletter, Video, Photograph, Podcast
      };
    if (args.hasOwnProperty('asset')) {
        params['asset'] = args.asset
    } else if (args.hasOwnProperty('assetData')) {
        params['asset_data'] = args.assetData
    } else {
        if (callback) callback(failure, "must specify asset or assetData")
        return;
    }

    if (args.hasOwnProperty('price')) {
        params['price'] = args.price
    }

    if (args.hasOwnProperty('tierIds')) {
        params['tier_ids'] = args.tierIds        
    }    
    
    if (args.hasOwnProperty('state')) {
        params['state'] = args.state // 'Published', 'Unpublished', 'Inactive'
    }
    if (args.hasOwnProperty('membership')) {
        params['membership'] = args.membership  // 'Any', 'CollectionOnly', 'SubscriptionOnly', 'ItemOrCollection
    }      
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }

    // specify 'preview' or previewData and previewType
    if (args.hasOwnProperty('preview')) { // specified uploaded file name: preview
        params['preview'] = args.preview
    } else {
        if (args.hasOwnProperty('previewData')) {
            params['preview_data'] = args.previewData
        }
        if (args.hasOwnProperty('previewType')) {
            params['preview_type'] = args.previewType
        }
    }

    if (args.hasOwnProperty('categoryIds')) {
        params['category_ids'] = args.categoryIds
    }

    console.log("createAsset PARAMS", params)

    const user = JSON.parse(localStorage.getItem('user'))

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Created Asset");
                    console.log(data)
                
                    dispatch(getAssets({byId: user.userId}));
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("createAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: assetConstants.CREATE_ASSET_REQUEST} }
    function success(data)   { return { type: assetConstants.CREATE_ASSET_SUCCESS, data } }
    function failure(error)  { return { type: assetConstants.CREATE_ASSET_FAILURE, error } }
}

function updateAsset(args, callback = null ) {
    // required arguments: assetId, name, description, type, state, membership
    // optional arguments: asset, assetData, price, tierIds, image, imageType, preview OR previewData & previewType
    //   where imageType is one of Image, Video, Music, Youtube
    //   where state is one of: 'Published', 'Unpublished', 'Inactive'
    //   where membership is one of: 'Any', 'CollectionOnly', 'SubscriptionOnly', 'ItemOrCollection'
    //   where tierIds is an array of tier ids
    //   where previewData is string that contains the preview (data comes from string and not uploaded file)
    //   where previewType is one of Image, Video, Music, Youtube

    const params = {
        request: 'update_asset',
        asset_id: args.assetId,
        name: args.name,
        description: args.description,
        type: args.type,       // Song, Blog, Newsletter, Video, Photograph
        state: args.state,     // Published, Unpublished, Inactive
        membership: args.membership    // Any, CollectionOnly, SubscriptionOnly, ItemOrCollection
      };

    if (args.hasOwnProperty('asset')) {
        params['asset'] = args.asset
    } else if (args.hasOwnProperty('assetData')) {
        params['asset_data'] = args.assetData
    }

    if (args.hasOwnProperty('price')) {
        params['price'] = args.price
    }   
    if (args.hasOwnProperty('tierIds')) {
        params['tier_ids'] = args.tierIds        
    }    
    if (args.hasOwnProperty('image')) {
        params['image'] = args.image
    }
    if (args.hasOwnProperty('imageType')) {
        params['image_type'] = args.imageType
    }

    // specify 'preview' or previewData and previewType
    if (args.hasOwnProperty('preview')) { // specified uploaded file name: preview
        params['preview'] = args.preview
    } else {
        if (args.hasOwnProperty('previewData')) {
            params['preview_data'] = args.previewData
        }
        if (args.hasOwnProperty('previewType')) {
            params['preview_type'] = args.previewType
        }
    }

    if (args.hasOwnProperty('categoryIds')) {
        params['category_ids'] = args.categoryIds
    }

    console.log("updateAsset PARAMS", params)
    const user = JSON.parse(localStorage.getItem('user'))
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Updated Asset");
                    console.log(data)
                
                    dispatch(success(data));
                    dispatch(getAssets({byId: user.userId}));

                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: assetConstants.UPDATE_ASSET_REQUEST} }
    function success(data)   { return { type: assetConstants.UPDATE_ASSET_SUCCESS, data } }
    function failure(error)  { return { type: assetConstants.UPDATE_ASSET_FAILURE, error } }
}

function deleteAsset(assetId, callback=null) {

    const params = {
        request: 'delete_asset',    
        asset_id: assetId,   
      };
      
    // const user = JSON.parse(localStorage.getItem('user'))

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Deleted Asset");
                    console.log(data)
                
                    dispatch(success(data.asset_id)); // deletes asset_id from store
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deleteAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()        { return { type: assetConstants.DELETE_ASSET_REQUEST} }
    function success(assetId) { return { type: assetConstants.DELETE_ASSET_SUCCESS, assetId } }
    function failure(error)   { return { type: assetConstants.DELETE_ASSET_FAILURE, error } }
}

function purchaseAsset(asset, purchaseInfo, callback=null) {
    // required args: assetId, price, paymentCompany, token

    const params = {
        request: 'purchase_asset',
        asset_id: asset.assetId,        
        price: asset.price,  
        payment_company: purchaseInfo.paymentCompany, 
        token: purchaseInfo.token,       // purchase token from payment company
        is_token: 1,
      };   
    
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Purchased Asset");
                    console.log(data)
                
                    dispatch(success(data));
                    if (callback) {
                        callback(true, data)
                    }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, msg) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("purchaseAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: assetConstants.PURCHASE_ASSET_REQUEST} }
    function success(data)   { return { type: assetConstants.PURCHASE_ASSET_SUCCESS, data } }
    function failure(error)  { return { type: assetConstants.PURCHASE_ASSET_FAILURE, error } }
}

function downloadAsset(args, callback = null) {
    // required arguments: assetId
    
    const params = {
        request: 'download_asset',
        asset_id: args.assetId
      };
   
    return dispatch => {
        dispatch(request());
        userService.userDownloadRequest(params)
        .then(
            data => {
		if (data.success) {
		    console.log("Downloaded asset to " + data.filename);
		} else {
		    console.log(data.message);
		}
            },
            error => {
                if (error.status === 401) {
                    console.log("downloadAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: assetConstants.DOWNLOAD_ASSET_REQUEST} }
    // function success(asset) { return { type: assetConstants.DOWNLOAD_ASSET_SUCCESS, asset } }
    // function failure(error) { return { type: assetConstants.DOWNLOAD_ASSET_FAILURE, error } }
}

function viewAssetInBuffer(args, callback = null) {
    // required arguments: assetId
    
    const params = {
        request: 'download_asset',
        asset_id: args.assetId
      };
   
    return dispatch => {
        dispatch(request());
        userService.userViewAssetInBufferRequest(params)
        .then(
            data => {
                console.log("promiseResponse", data)
		if (data.success) {
		    console.log("Downloaded asset to " + data.url)
            if (callback) {
                callback(true, data.url)
            }
		} else {
		    console.log(data.message);
            callback(false, data.message)
		}
            },
            error => {
                if (error.status === 401) {
                    console.log("downloadAsset: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()      { return { type: assetConstants.DOWNLOAD_ASSET_REQUEST} }
    // function success(asset) { return { type: assetConstants.DOWNLOAD_ASSET_SUCCESS, asset } }
    // function failure(error) { return { type: assetConstants.DOWNLOAD_ASSET_FAILURE, error } }
}

