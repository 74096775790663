import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetActions, postsActions, audioPlayerActions, watchActions } from '_actions';
import { numberFunction, assetPublishedDate, getStreamAssetMediaURL, getStreamPreviewMediaURL } from '_helpers';
import { CommentsSection }  from "components";
import {
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  CardImg,
  Button
  // Input
} from "reactstrap";
import defaultAvatar from "assets/img/default-avatar.png";
import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
// import ReactPlayer from 'react-player'
import noPreviewAvailable from 'assets/img/img-livenyte/NoPreviewAvailable.png'
import * as moment from 'moment';
import Action from "components/FaveButton/src/components/Tweet/Action.js"
import StarButton from "components/FaveButton/src/components/LikeButton/StarButton.js"

// react plugin used to create charts
// import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
// import eventImage from "assets/img/img-livenyte/sf3.jpg";


class AssetViewer extends React.Component {
  constructor(props) {
      const { createUpdateMode, assetId, hideComments, showPreview } = props
    
    super(props);
    console.log("props-constructor", props)
    const disableContextMenu = function (e) {
      e.preventDefault()
    }

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      selectedAsset: null,
      alert: null,
      selectedPostId: null, 
      mediaURL: null, 
      assetId: assetId, 
      createUpdateMode: createUpdateMode ? createUpdateMode : false,
      showPreview: showPreview ? showPreview : false,
      hideComments: hideComments ? hideComments : false, 
      disableContextMenu: disableContextMenu, 
      isWatchClicked: false, 
    };

  
    // this.handleQuill = this.handleQuill.bind(this)
    console.log("assetId", this.state.assetId)
    this.insertAtFront = this.insertAtFront.bind(this)
    this.addToEnd = this.addToEnd.bind (this)
    this.clearPlaylist = this.clearPlaylist.bind(this);
    this.handleContextMenuPressed = this.handleContextMenuPressed.bind(this)
    this.handleToggleWatch = this.handleToggleWatch.bind(this)

  }

  handleContextMenuPressed(e) {
    const { disableContextMenu } = this.state
    disableContextMenu(e)
  }

    componentDidMount() {
      const { user, assetId } = this.state 
      const loggedIn = user && user.hasOwnProperty('email');

      if (loggedIn) {
        this.props.dispatch(watchActions.getWatchedItems({}, (success, data) => {
          if (success) {
            const { watchedAssets } = this.props 
            if (watchedAssets && watchedAssets.filter(asset => asset.id === assetId) && watchedAssets.filter(asset => asset.id === assetId ).length > 0 ) {
              this.setState({
                isWatchClicked: true 
              })
            } else {
              this.setState({
                isWatchClicked: false 
              })
            }
          }
        }))
        
      }

      this.getAssets()
        document.body.addEventListener('contextmenu', this.handleContextMenuPressed);
    }

    componentWillUnmount() {
      document.body.removeEventListener('contextmenu', this.handleContextMenuPressed );
    }

  getAssets() {
    const { assetId } = this.state 
    this.props.dispatch(assetActions.getAssets({assetId: assetId}, (success, data) => {
      if (success) {
        console.log("assetData", data)
        const asset = data[0]
        console.log("Asset", asset)
        if (asset) {
          if (asset.type === 'Blog' || asset.type === 'Newsletter') {
            this.getInlineAsset()
          } else if (asset.type === 'Video' || asset.type === 'Song' || asset.type === 'Podcast') {
              this.getPostComments(asset)
          } else if (asset.type === 'Photograph') {
              this.getAssetInBuffer(asset)
          }
        }
      }
    })
    )
  }

  getInlineAsset() {
    const { assetId } = this.state 
    this.props.dispatch(assetActions.getAssets({assetId: assetId, inline: true}, (success, data) => {
      if (success) {
        console.log("inline assetData", data)
        const asset = data[0]
        if (asset){
          this.getPostComments(asset)
        }  
      }
    })
    )
  }

  getAssetInBuffer(asset) {
    const { showPreview, assetId } = this.state
    if (showPreview) {
      console.log("ASSET PREVIEW", asset, asset.preview)
      this.setState({mediaURL: asset.preview, selectedAsset: asset})
      // this.getPostComments(asset)
    } else {
      console.log("viewAssetInBuffer")
      this.props.dispatch(assetActions.viewAssetInBuffer({assetId: assetId}, (success, data) => {
        if (success) {
          console.log("viewAssetInBuffer handler:", data)
          const url = data
          this.setState({mediaURL: url})
          this.getPostComments(asset)
        }
      })
      )
    }

  }

  

  getPostComments(asset) {
    this.props.dispatch(postsActions.getPosts({postById: asset.owner_id, assetId: asset.id }, (success, data) => {
      if (success) {
        console.log("postData", data)
        const selectedPost = data[0]
        this.setState({ selectedAsset: asset, selectedPostId: selectedPost && selectedPost.post_id, })
      } else {
        this.setState({ selectedAsset: asset, })
      }
    }
    ))
  }

  handleToggleWatch() {
    const { isWatchClicked } = this.state
    this.setState({
      isWatchClicked: !isWatchClicked
    }, this.watchHandler)
  }

  watchHandler() {
    const { isWatchClicked, assetId } = this.state 

    const args = {
      item_id: assetId,
      item_type: "ASSET"
    }
    if (isWatchClicked) {
      this.props.dispatch(watchActions.watchItem(args))
    } else {
      this.props.dispatch(watchActions.unwatchItem(args))
    }
  }
   
    componentDidUpdate() {
    // this.attachQuillRefs()
    }

    hideAlert() {
        this.setState({
        alert: null
        });
    }





    getAudio(selectedAsset, streamURL) {
      const { showPreview } = this.state

      const audio = {
        singer: selectedAsset.creator_name,
        cover: selectedAsset.image,
        musicSrc: streamURL,
        asset: selectedAsset,
        timestamp: moment(), 
      }

      if (showPreview) {
        audio["name"] = selectedAsset.name + " (Preview)"
      } else {
        audio["name"] = selectedAsset.name 
      }
      return audio 
    }

    clearPlaylist() {
      const { dispatch } = this.props
      console.log("playnew playlist")
      dispatch(audioPlayerActions.clearPlaylist())
    }

    insertAtFront(selectedAsset, streamURL) {
      const { dispatch, playlist } = this.props
      console.log("playnew playlist")
      const audio = this.getAudio(selectedAsset, streamURL)
      dispatch(audioPlayerActions.insertAudioAtFront(audio, playlist))
    }

    addToEnd(selectedAsset, streamURL) {
      const { dispatch, playlist } = this.props
      console.log("playnew playlist")
      const audio = this.getAudio(selectedAsset, streamURL)
      dispatch(audioPlayerActions.addAudioToEndOfPlaylist(audio, playlist  ))
    }


  render() {
    const { posts, fetchGetPosts } = this.props;
    const { selectedAsset, mediaURL, selectedPostId, hideComments, showPreview, user, createUpdateMode } = this.state
    const loggedIn = user && user.hasOwnProperty('email');

    // const request = `/?request=stream_video&asset=`
    // const videoId = selectedAsset && selectedAsset.id 
    // const streamURL = `${config.apiUrl}${request}${videoId}`
    const streamURL = selectedAsset && selectedAsset.id && (showPreview ? getStreamPreviewMediaURL(selectedAsset.id) : getStreamAssetMediaURL(selectedAsset.id)  )

    const selectedPost = posts && selectedPostId && posts.filter(post => post.post_id === selectedPostId).length > 0 && posts.filter(post => post.post_id === selectedPostId)[0]
    console.log("state!!!", this.state)

    if (selectedAsset && createUpdateMode) {
      const view = 
          <div>
            {(selectedAsset.type === "Video") && 
              <CardBody  >
                <Replay 
                  source= {streamURL} 
                  initialPlaybackProps={{ isPaused: true, volume: 0.4 }}
                  // options={{
                  //     controls: {
                  //       includeControls: [
                  //         "playPauseButton",
                  //         "skipButton",
                  //         "timeline",
                  //         "gotoLiveButton",
                  //         "timeDisplay",
                  //         "volume",
                  //         "fullscreenButton",
                  //         "exitButton",
                  //         "qualitySelector",
                  //         // "pipButton"
                  //       ]
                  //     }
                  //   }}
                />
                {/* <ReactPlayer
                    controls
                    url={streamURL}
                    config={{ file: { 
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }}}
                  /> */}
              </CardBody>
              }
            {( selectedAsset.type === "Song" || selectedAsset.type === 'Podcast') && 
              <CardBody>
              {console.log("In audio", mediaURL)}
                {/* <CardImg
                alt="..."
                onError={(e)=>{e.target.onerror = null; e.target.src=noPreviewAvailable}}
                src={selectedAsset.image}
                className={ "cursor-pointer"}
                onClick={  () => this.insertAtFront(selectedAsset, streamURL)}
                top
                /> */}
                <Row className="text-center mt-3 pt-1">
                  <Col className="mx-auto">
                  <Button
                      color="dark"
                      size="sm"
                      outline
                      className="rounded-circle text-center"
                      onClick={() => this.clearPlaylist()}
                      >
                    <i className="fas fa-solid fa-x p-2"></i>

                    </Button>
                    <Button
                      color="dark"
                      size="sm"
                      className="rounded-circle text-center"
                      outline
                      onClick={() => this.insertAtFront(selectedAsset, streamURL)}
                      >
                    <i className="fas fa-play p-2"/>
                    </Button>
                    <Button
                      color="dark"
                      size="sm"
                      className="rounded-circle text-center"
                      outline
                      onClick={() => this.addToEnd(selectedAsset, streamURL)}
                      >
                    <i className="fas fa-solid fa-chevron-right p-2"/>  
                    </Button>
             
                  </Col>

                </Row>
                                        
            </CardBody>
            }
          {(selectedAsset.type === "Photograph") && mediaURL && 
              <CardBody>
                {console.log("In photograph", mediaURL)}
              <CardImg
                  alt="..."
                  onError={(e)=>{e.target.onerror = null; e.target.src=noPreviewAvailable}}
                  src={mediaURL}
                  top
                  />
              </CardBody>
              }
              {(selectedAsset.type === "Blog" || selectedAsset.type === "Newsletter") && 
              <CardBody dangerouslySetInnerHTML={{__html: selectedAsset.asset_data}} className="draft-body">
                  
              </CardBody>
              }
          </div>
      return view  
    }

    return (
      <div>
            {selectedAsset && 
            <div>
                <div className="draftPreview" style={{width:"100%"}}>
                    <CardHeader className="border-0 pb-0"> 
                      <Row>
                        <Col xs={10} sm={11} className="text-left">
                        <h5 className="surtitle">
                          <span>
                          {selectedAsset.type}
                          </span>
                          {!selectedAsset.allow_access && 
                          <span className="">
                            {" • "}
                            <span className="text-info">
                            Preview
                            </span>
                          </span>
                          }
                        </h5>
                        
                        <h1 className="draft-title">
                            {selectedAsset.name}
                        </h1>
                       
                        {(selectedAsset.type === "Newsletter" || selectedAsset.type === "Blog") &&
                        <h4 className="draft-subtitle">
                            {selectedAsset.description}
                        </h4>
                        }
                  
                        <span>

                      </span>
                        </Col>
                        <Col xs={2} sm={1} className="text-right">
                        {loggedIn && 
                          <div>
                          <Action 
                              color="rgb(224, 36, 94)" 
                              size={48} 
                              onClick={this.handleToggleWatch} 
                              isRight={true} 
                              // translateYbySize={10} 
                            >
                              <StarButton 
                                size={48} 
                                isLiked={this.state.isWatchClicked}
                              />
                          </Action>
                        </div>
                        }
                        </Col>
                      </Row>

                        <ListGroup className="list bg-transparent border-0 pt-2" flush>
                            <ListGroupItem className="px-0 border-0 pt-1 pb-0">
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                <Link to={ { pathname: `/dash/c_profile/${numberFunction(selectedAsset.owner_id)}`, 
                                    state: {sp_id: selectedAsset.owner_id, }} }>
                                    <img
                                    alt="..."
                                    src={selectedAsset.creator_photo}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                                    className="avatar rounded-circle"
                                    />
                                </Link>

                                </Col>
                                <div className="col ml--2">
                                <h4 className="mb-0 name-small">
                                    <Link className="text-primary" to={ { pathname: `/dash/c_profile/${numberFunction(selectedAsset.owner_id)}`, 
                                        state: {sp_id: selectedAsset.owner_id, }} }>
                                    <span className="name-small">{selectedAsset.creator_name}</span>
                                    </Link>
                                </h4>
                                <span className="specialty-small">{assetPublishedDate(selectedAsset.published)}</span> 
                                </div>
                            </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </CardHeader>
                    {(selectedAsset.type === "Video") && 
                        <CardBody>
                          <Replay 
                            source= {streamURL} 
                            initialPlaybackProps={{ isPaused: true, volume: 0.4 }}
                            // options={{
                            //     controls: {
                            //       includeControls: [
                            //         "playPauseButton",
                            //         "skipButton",
                            //         "timeline",
                            //         "gotoLiveButton",
                            //         "timeDisplay",
                            //         "volume",
                            //         "fullscreenButton",
                            //         "exitButton",
                            //         "qualitySelector",
                            //         // "pipButton"
                            //       ]
                            //     }
                            //   }}
                            
                            />
                     
                          {/* <ReactPlayer
                            controls
                            url={streamURL}
                            config={{ file: { 
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }}}
                          /> */}
                        </CardBody>
                        }
                  {( selectedAsset.type === "Song" || selectedAsset.type === 'Podcast') && 
                      <CardBody>
                      {console.log("In audio", mediaURL)}
                        {/* <CardImg
                        alt="..."
                        onError={(e)=>{e.target.onerror = null; e.target.src=noPreviewAvailable}}
                        src={selectedAsset.image}
                        className={ "cursor-pointer"}
                        onClick={  () => this.insertAtFront(selectedAsset, streamURL)}
                        top
                        /> */}
                      <Row className="text-center mt-3 pt-1">
                        <Col className="mx-auto">
                            <Button
                              color="dark"
                              size="sm"
                              outline
                              className="rounded-circle text-center"
                              onClick={() => this.clearPlaylist()}
                              >
                            <i className="fas fa-solid fa-x p-2"></i>

                            </Button>
                            <Button
                              color="dark"
                              size="sm"
                              className="rounded-circle text-center"
                              outline
                              onClick={() => this.insertAtFront(selectedAsset, streamURL)}
                              >
                            <i className="fas fa-play p-2"/>
                            </Button>
                            <Button
                              color="dark"
                              size="sm"
                              className="rounded-circle text-center"
                              outline
                              onClick={() => this.addToEnd(selectedAsset, streamURL)}
                              >
                            <i className="fas fa-solid fa-chevron-right p-2"/>  
                            </Button>
             

         
          
                          </Col>
                        </Row>                
                    </CardBody>
                    }
                    {(selectedAsset.type === "Photograph") && mediaURL && 
                        <CardBody>
                        <CardImg
                            alt="..."
                            onError={(e)=>{e.target.onerror = null; e.target.src=noPreviewAvailable}}
                            src={mediaURL}
                            top
                            />
                        </CardBody>
                      }
                        {(selectedAsset.type === "Blog" || selectedAsset.type === "Newsletter") ? ( 
                        <CardBody dangerouslySetInnerHTML={{__html: selectedAsset.asset_data}} className="draft-body">
                            
                        </CardBody>
                        ) : (
                          <CardBody className="mt-0 pt-0">                        
                          <div className="line-styling">
                              {selectedAsset.description}
                          </div>
                        </CardBody>
                        )}
                
                    {selectedAsset && 
                        <CardFooter className="border-0 pt-0">
                          <small>This {selectedAsset.type.toLowerCase()} is a part of&nbsp;
                          {selectedAsset.hasOwnProperty("product_ids") && selectedAsset.product_ids.length > 0 && selectedAsset.product_ids.map( (productArray, key) => 
                            <Link to={ { pathname: `/dash/marketplaceProduct/${productArray[0]}`} }
                            tag={Link}
                            key={key}
                            >
                              {console.log("Test check", (key +1), productArray.length)}
                            {(key + 1) === selectedAsset.product_ids.length ?  `${productArray[1]}.` :  `${productArray[1]}, `  }
                          </Link>
                          )}
                          </small>
                        {console.log("Failing", selectedPost)}
                        {(!hideComments || (!fetchGetPosts && selectedPost) ) && 
                            <CommentsSection assetId={selectedAsset.id} imageUrl={selectedPost.comments[0].media_url} post={selectedPost}> 
                            </CommentsSection>
                        }
                        </CardFooter>
                    } 
            </div>
        </div>
        }
    </div> 
    );
  }
}

//export default Dashboard;
function mapStateToProps(state) {
  const { memberInfo, assetData, postsData, audioPlayerData, watchData } = state;
  const { assets, getAssets } = assetData;
  const { posts, fetchGetPosts } = postsData;
  const { info } = memberInfo;
  const { playlist } = audioPlayerData;
  const { watchedProducts, watchedEvents, watchedAssets, watchedPosts, getWatchedItemsRequest } = watchData;


  console.log("PurchasedTickets: mapStateToProps");
  return {
      info, 
      assets, getAssets, 
      posts, fetchGetPosts,
      playlist,
      watchedProducts, watchedEvents, watchedAssets, watchedPosts, getWatchedItemsRequest,
    };
}

const connectedAssetViewer = connect(mapStateToProps)(AssetViewer);
export { connectedAssetViewer as AssetViewer };