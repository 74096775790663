export const registerConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_NAME:     'REGISTER_NAME',
    REGISTER_EMAIL:    'REGISTER_EMAIL',
    REGISTER_ADDRESS:  'REGISTER_ADDRESS',
    REGISTER_PASSWORD: 'REGISTER_PASSWORD',
    REGISTER_ACC_TYPE: 'REGISTER_ACC_TYPE',
    REGISTER_AFFILIATE: 'REGISTER_AFFILIATE',
    CLEAR_ALL_REGISTRATION_DATA: 'CLEAR_ALL_REGISTRATION_DATA',

};
