import ReactCrop from 'react-image-crop';
import React from 'react';
// import PropTypes from "prop-types";
import { Button } from "components";

import defaultImage from "assets/img/event_image_placeholder.jpg";
import defaultAvatar from "assets/img/event_image_placeholder.jpg";
import 'react-image-crop/dist/ReactCrop.css';
import { notificationMessageActions } from "_actions"

class PostUpload extends React.Component {
    constructor(props) {
      super(props);
      console.log("PostUpload", props)
      this.state = {
        file: null,
        croppedImageUrl: null,
        imagePreviewUrl: this.props.avatar   ? defaultAvatar : 
                         this.props.imageUrl ? this.props.imageUrl : 
                                               defaultImage,
        index: props.index !== undefined ? props.index : null,
        src: null,
        cropOpen: false,
        crop: {
          aspect: 1,
          width: 50,
          x: 0,
          y: 0,
        }, 

      };
    //   this.handleImageChange = this.handleImageChange.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClick = this.handleClick.bind(this);
    //   this.handleRemove = this.handleRemove.bind(this);
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          if (file.size > 7500000) {
            this.props.dispatch(notificationMessageActions.addNotificationToQueue("Your post image must be less than 7.5 mb.", 2 ))
              return
          } else {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
              this.setState({ src: reader.result }),
            );
            reader.readAsDataURL(file);
          }
        }
      };

      handleClick() {
        this.refs.fileInput.click();
        this.setState({ cropOpen: true })
        console.log("ImageUpload: handleClick:", this.state)
      }
    
      onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    
        // Make the library regenerate aspect crops if loading new images.
        const { crop } = this.state;

        if (crop.aspect && crop.height && crop.width) {
          this.setState({
            crop: { ...crop, height: null },
          });
        } else {
          this.makeClientCrop(crop, pixelCrop);
        }
      };
    
      onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
      };
    
      onCropChange = crop => {
        this.setState({ crop });
      };
    
      async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            pixelCrop,
            'newFile.jpeg',
          )
          .then( 
            resolve => {
              console.log("resolve", resolve, croppedImageUrl)
              this.setState({blob: resolve})
            },
            reject => {
              console.log("reject", reject)
              this.setState({blob: reject})
            }
          );
        }
      }


    
      getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            blob.name = fileName;
            resolve(blob);
          }, 'image/jpeg');
        });

      }

      handleImageChange(e) {
        console.log("handleImageChange", this.state)
        e.preventDefault();
        let reader = new FileReader();
        reader.onloadend = () => {
          this.setState({
            imagePreviewUrl: reader.result
          });
          this.props.callback({state: this.state, newImage: true})
        };
        reader.readAsDataURL(this.state.blob)
        this.imageRef = undefined
        this.setState({ cropOpen: false })
      }

    
    
      render() {
        const { crop, croppedImageUrl, src, cropOpen } = this.state;
        console.log("imageRef", this.imageRef)
        console.log("state/props", this.state, this.props)
        return (

            <div className="fileinput text-center full-width">
              <input type="file"  accept=".jpg, .jpeg, .png," onChange={this.onSelectFile} ref="fileInput" />
              <div className="thumbnail">
                {(this.imageRef === null || this.imageRef === undefined) && 
                <img width="240" height="240"   src={this.state.imagePreviewUrl} alt="..." />
                }
              {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />}
              </div>
              {src && cropOpen === true && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  className="wrap-max-height"
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
              <div>
                {(this.imageRef === null || this.imageRef === undefined) ? (
                  <Button className="btn-primary" simple color="primary"  onClick={() => this.handleClick()}>
                    {this.state.imagePreviewUrl !== defaultAvatar ? "Update Image" : "Add Image"}
                  </Button>
                ) : (
                  <span>
                    <Button simple color="default" onClick={() => this.handleClick()}>
                      Change
                    </Button>
                    {this.props.avatar ? <br /> : null}
                    <Button color="warning" simple onClick={(e) => this.handleImageChange(e)}>
                      <i className="fa fa-times" /> Save
                    </Button>
                  </span>
                )}
                  {this.state.file !== null &&
                      <Button simple color="primary"  onClick={() => this.handleClick()}>
                      Change
                  </Button> }
              </div>
          </div>

        //   <div>
        //     <div className="fileinput text-center">
        //       <input type="file" onChange={this.onSelectFile} />
        //       <Button className="btn-primary" round onClick={() => this.handleClick()}>
        //       Update Profile
        //      </Button>
        //     </div>
        //     {src && (
        //       <ReactCrop
        //         src={src}
        //         crop={crop}
        //         onImageLoaded={this.onImageLoaded}
        //         onComplete={this.onCropComplete}
        //         onChange={this.onCropChange}
        //       />
        //     )}
        //     {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />}
        //   </div>
        );
      }
    }


      export default PostUpload;


