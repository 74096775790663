import React from "react";
import {
  CardFooter,
  Row,
  Col,

} from "reactstrap";
// import { history } from '_helpers';
import { Link } from 'react-router-dom';
import { numberFunction, parseProductPrice, trimProductSubscribers } from '_helpers';

import {  Button, } from "components";
// import { Redirect } from "react-router-dom";
import defaultAvatar from "assets/img/default-avatar.png";

class TopChartsProduct extends React.Component {

  render() {
    const { getCurrentLocation, currentLocation, subscriptions, product, key } = this.props;

    return (
        <CardFooter className="px-0 bg-transparent border-0" key={key}>
            <Row className="align-items-center">
            <Col className="col-auto">
                <Link to={ { pathname: `/dash/c_profile/${numberFunction(product.owner_id)}`, 
                    state: {sp_id: product.owner_id, }} }>
                <img
                    alt="..."
                    src={product.creator_photo}
                    className="avatar rounded-circle"
                    onError={(e)=>{e.target.onerror = null; e.target.src=defaultAvatar}}
                />
                </Link>
                <div className="text-center" style={{marginTop:'3px'}}>
                    <h6 className="surtitle text-lighter"> {trimProductSubscribers(product.subscriber_count)}</h6>
                </div>
            </Col>
            <div className="col ml--2">
                <h4 className="mb-0">
                    <span className="text-white">{product.name}</span>
                </h4>
                <small className="text-lighter">{product.creator_name}</small>

            </div>
            <Col className="col-auto">
                <h6 className="surtitle text-lighter">{subscriptions && subscriptions.some(a => a.product_id === product.id) ? "Subscribed" :"Starting at"}</h6>
                <div className="mt-1 text-center"> 
                {!getCurrentLocation && currentLocation.hasOwnProperty('country_code') && parseProductPrice(currentLocation.country_code, product.price_range) &&
                    <Link to={ { pathname: `/dash/marketplaceProduct/${product.id}`, 
                    state: {productId: product.id, product: product, }} }>
                    <Button color="spc-white" outline size="sm" type="button">
                    { subscriptions && subscriptions.some(a => a.product_id === product.id) ? "Go" : parseProductPrice(currentLocation.country_code, product.price_range)}
                    </Button>
                    </Link>
                }
                </div>
            </Col>
        </Row>
    </CardFooter>
    );
  }
}

export default TopChartsProduct 