export const screenSizeConstants = {
    
    SET_SCREEN_SIZE_XXS_REQUEST: 'SET_SCREEN_SIZE_XXS_REQUEST',
    SET_SCREEN_SIZE_XXS_SUCCESS: 'SET_SCREEN_SIZE_XXS_SUCCESS',

    SET_SCREEN_SIZE_XS_REQUEST: 'SET_SCREEN_SIZE_XS_REQUEST',
    SET_SCREEN_SIZE_XS_SUCCESS: 'SET_SCREEN_SIZE_XS_SUCCESS',

    SET_SCREEN_SIZE_SM_REQUEST: 'SET_SCREEN_SIZE_SM_REQUEST',
    SET_SCREEN_SIZE_SM_SUCCESS: 'SET_SCREEN_SIZE_SM_SUCCESS',

    SET_SCREEN_SIZE_MD_REQUEST: 'SET_SCREEN_SIZE_MD_REQUEST',
    SET_SCREEN_SIZE_MD_SUCCESS: 'SET_SCREEN_SIZE_MD_SUCCESS',

    SET_SCREEN_SIZE_LG_REQUEST: 'SET_SCREEN_SIZE_LG_REQUEST',
    SET_SCREEN_SIZE_LG_SUCCESS: 'SET_SCREEN_SIZE_LG_SUCCESS',

    SET_SCREEN_SIZE_XL_REQUEST: 'SET_SCREEN_SIZE_XL_REQUEST',
    SET_SCREEN_SIZE_XL_SUCCESS: 'SET_SCREEN_SIZE_XL_SUCCESS',

    SET_SCREEN_SIZE_XXL_REQUEST: 'SET_SCREEN_SIZE_XXL_REQUEST',
    SET_SCREEN_SIZE_XXL_SUCCESS: 'SET_SCREEN_SIZE_XXL_SUCCESS',
}
