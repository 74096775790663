export const watchConstants = {
    WATCH_ITEM_REQUEST: 'WATCH_ITEM_REQUEST',
    WATCH_ITEM_SUCCESS: 'WATCH_ITEM_SUCCESS',
    WATCH_ITEM_FAILURE: 'WATCH_ITEM_FAILURE',

    UNWATCH_ITEM_REQUEST: 'UNWATCH_ITEM_REQUEST',
    UNWATCH_ITEM_SUCCESS: 'UNWATCH_ITEM_SUCCESS',
    UNWATCH_ITEM_FAILURE: 'UNWATCH_ITEM_FAILURE',

    GET_WATCHED_ITEMS_REQUEST: 'GET_WATCHED_ITEMS_REQUEST',
    GET_WATCHED_ITEMS_SUCCESS: 'GET_WATCHED_ITEMS_SUCCESS',
    GET_WATCHED_ITEMS_FAILURE: 'GET_WATCHED_ITEMS_FAILURE',
}
