import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";



class UserAccountBox extends React.Component {
  render() {

    return (
    
            <div id="fan">
            <div className="text-center text-white pt-2 mt-3"> 
                  <h2 className="text-white surtitle text-uppercase">Fans</h2>
                  <h4 className="text-white text-uppercase surtitle mt-2">Attend the best events and support your favorite talent & creators.</h4>
            </div>
            <div className="pricing card-group flex-column flex-md-row mb-3 mt-6">
                <Card className="card-pricing border-0 text-center mb-4">
                  <CardBody className="px-lg-7 pb-4">
                    <div className="icon icon-lg icon-shape bg-gradient-tertiary text-white rounded-circle mb-2 mt-3">
                        <i className="ni ni-building  " />
                    </div>
                    <h4 className="text-uppercase ls-1 text-tertiary py-3 mb-0">
                        Rediscover Your City
                        </h4>
                    <p>See which talent & creators are booked at nearby events or in areas that you're traveling to. 
                    </p>
                    </CardBody>
                    <CardFooter>
                    <div className="icon icon-lg icon-shape bg-gradient-livenyte text-white rounded-circle mb-2 mt-3">
                        <i className="fas fa-star" />
                    </div>
                    <h4 className="text-uppercase ls-1 text-primary py-3 mb-0">
                    Follow Talent & Creators
                    </h4>
                    <p>Get notified when talent & creators you follow are booked at nearby events.
                    </p>                        
                    </CardFooter>
                  </Card>
                  <Card className="card-pricing zoom-in-1 bg-dark  border-0 text-center mb-4 rounded">
                    <CardBody className="px-lg-12 pb-3 bg-transparent text-white">
                      <div className="icon icon-lg icon-shape bg-gradient-danger text-white rounded-circle mb-2 mt-3">
                          <i className="ni ni-tie-bow " />
                      </div>
                      <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                      Discover Events
                      </h4>
                      <p>Attend the hottest events in your area any night of the week.
                      </p>
                    </CardBody>
                    <CardFooter className="rounded bg-transparent text-white">
                      <div className="icon icon-lg icon-shape bg-gradient-success text-white rounded-circle mb-2 mt-3">
                          <i className="ni ni-shop" />
                      </div>
                      <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                      Explore Marketplace
                      </h4>
                      <p>Discover great content from talent & creators. Support your favorites by purchasing subscriptions to their premium content. 
                      </p>     
                    </CardFooter>
                </Card>
              </div>
        </div>
    );
  }
}

export default UserAccountBox;
