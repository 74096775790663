import { memberConstants } from '_constants';
import { userService } from '_services';
import { alertActions, agencyActions, userActions } from '_actions';

export const memberActions = {
    memberQuery,
    businessQuery,
    userQuery,
    updateUserName,
    updatePassword,
    updateName,
    updatePhone,
    updateBio,
    updateBusinessTraits,
    updateUserTraits,
    updateSpecialistTraits,
    uploadProfileImage,
    getCelebInfo,
    getBusinessInfo,
    specialistQuery,
    deactivateAccount,
    deleteAccount
};

function memberQuery(callback = null) {
    const params = {
        request: 'member_query',
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)        
        .then(
            data => {
                if (data.success) {
                    console.log("memberQuery", data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_business) {
                        dispatch(businessQuery(user.user_id))
                    } else if (user.is_specialist) {
                        dispatch(specialistQuery())
                    } else if (user.is_user) {
                        dispatch(userQuery())
                    } else if (user.is_agency) {
                        dispatch (agencyActions.agencyQuery())
                    }

                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("memberQuery: fatal error")
                    dispatch(userActions.endSession(error))
                }
            }
        )
    }

    function request()      { return { type: memberConstants.MEMBER_QUERY_REQUEST} }
    function success(data)  { return { type: memberConstants.MEMBER_QUERY_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.MEMBER_QUERY_FAILURE, error } }
}

function userQuery(businessId, callback = null) {
    const params = {
        request: 'user_query',
        business_id: businessId,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("userQuery: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.USER_QUERY_REQUEST} }
    function success(data)  { return { type: memberConstants.USER_QUERY_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.USER_QUERY_FAILURE, error } }
}

function businessQuery(businessId, callback = null) {
    const params = {
        request: 'business_query',
        business_id: businessId,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("businessQuery: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.BUSINESS_QUERY_REQUEST} }
    function success(data)  { return { type: memberConstants.BUSINESS_QUERY_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.BUSINESS_QUERY_FAILURE, error } }
}

function specialistQuery(callback = null) {
    const params = {
        request: 'specialist_query',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("specialistQuery: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.SPECIALIST_QUERY_REQUEST} }
    function success(data)  { return { type: memberConstants.SPECIALIST_QUERY_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.SPECIALIST_QUERY_FAILURE, error } }
}


function updateUserName(newUserName, callback = null) {
    const params = {
        request: 'member_update',
        tag: 'username',
        username: newUserName,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success());
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateUserName: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.MEMBER_UPDATE_REQUEST } }
    function success()      { return { type: memberConstants.MEMBER_UPDATE_SUCCESS } }
    function failure(error) { return { type: memberConstants.MEMBER_UPDATE_FAILURE, error } }
}

function updatePassword(oldPassword, newPassword, callback = null) {
    const params = {
        request: 'member_update',
        tag: 'password',
        curr_password: oldPassword,
        new_password: newPassword,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    dispatch(alertActions.success(data.message));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updatePassword: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.MEMBER_UPDATE_REQUEST } }
    function success()      { return { type: memberConstants.MEMBER_UPDATE_SUCCESS } }
    function failure(error) { return { type: memberConstants.MEMBER_UPDATE_FAILURE, error } }
}

function updatePhone(phone, callback = null) {
    const params = {
        request: 'member_update',
        tag: 'phone',
        phone: phone,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success());
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updatePhone: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.MEMBER_UPDATE_REQUEST } }
    function success()      { return { type: memberConstants.MEMBER_UPDATE_SUCCESS } }
    function failure(error) { return { type: memberConstants.MEMBER_UPDATE_FAILURE, error } }
}

function updateName(name, callback = null) {
    const params = {
        request: 'member_update',
        tag: 'name',
        name: name,
    }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success());
                    dispatch(memberQuery())
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateName: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.MEMBER_UPDATE_REQUEST } }
    function success()      { return { type: memberConstants.MEMBER_UPDATE_SUCCESS } }
    function failure(error) { return { type: memberConstants.MEMBER_UPDATE_FAILURE, error } }
}


function updateBio(bio, callback = null) {
    let user = JSON.parse(localStorage.getItem('user'));
    const params = {
        request: user.is_business ? 'business_update_bio' : 'specialist_update_bio',
        bio: bio,
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_business) {
                        dispatch(businessQuery(user.user_id))
                    }
                    if (user.is_specialist) {
                        dispatch(specialistQuery())
                    }
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateBio: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.UPDATE_BIO_REQUEST} }
    function success(data)  { return { type: memberConstants.UPDATE_BIO_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.UPDATE_BIO_FAILURE, error } }
}

function updateBusinessTraits(traits, callback = null) {
    const params = {
        request: 'business_update_traits',
        business_traits: JSON.stringify(traits),
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_business) {
                        dispatch(businessQuery(user.user_id))
                    }
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateBusinessTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.UPDATE_TRAITS_REQUEST} }
    function success(data)  { return { type: memberConstants.UPDATE_TRAITS_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.UPDATE_TRAITS_FAILURE, error } }
}

function updateUserTraits(traits, callback = null) {
    const params = {
        request: 'user_update_traits',
        user_traits: JSON.stringify(traits),
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_user) {
                        dispatch(userQuery())
                    }
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(false, msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateUserTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.UPDATE_TRAITS_REQUEST} }
    function success(data)  { return { type: memberConstants.UPDATE_TRAITS_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.UPDATE_TRAITS_FAILURE, error } }
}

function updateSpecialistTraits(traitNames, sbTraitIds, callback = null) {
    const params = {
        request: 'specialist_update_traits',
        sp_traits: JSON.stringify(traitNames),
        sp_subtrait_ids: JSON.stringify(sbTraitIds)
    }

    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Update success", data)
                    dispatch(success(data));
                    const user = JSON.parse(localStorage.getItem('user'))
                    if (user.is_specialist) {
                        dispatch(specialistQuery())
                    }
                    if (callback !== null) { 
                        callback(true, data) 
                    }
                } else {
                    console.log("Update failure", data)
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    console.log("Is callback", callback)
                    if (callback !== null) {
                        callback(false, msg)
                    }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("updateSpecialistTraits: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.UPDATE_TRAITS_REQUEST} }
    function success(data)  { return { type: memberConstants.UPDATE_TRAITS_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.UPDATE_TRAITS_FAILURE, error } }
}

function uploadProfileImage(image, mediaType, galleryIdx, callback = null) {
    const params = {
        request: 'update_profile_image',
        media_type: mediaType, // one of 'Image', 'Video', 'Music', or 'Youtube'
        image: image,        
    }
    if (galleryIdx !== null) {
        params['portfolio'] = galleryIdx;
    }

    return dispatch => {
        console.log("upLoadProfileImage", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    //const user = JSON.parse(localStorage.getItem('user'))
                    dispatch(memberQuery())
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) callback(msg)
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("uploadProfileImage: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.UPDATE_PROFILE_IMAGE_REQUEST} }
    function success(data)  { return { type: memberConstants.UPDATE_PROFILE_IMAGE_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.UPDATE_PROFILE_IMAGE_FAILURE, error } }
}

function getCelebInfo(sp_id) {
    const params = {
        request: 'get_celebrities_from_id',
        celebs_id: sp_id
    }

    return dispatch => {
        console.log("getCelebInfo", params)
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getCelebInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    

    function request()      { return { type: memberConstants.GET_CELEB_INFO_REQUEST} }
    function success(data)  { return { type: memberConstants.GET_CELEB_INFO_SUCCESS , data } }
    function failure(error) { return { type: memberConstants.GET_CELEB_INFO_FAILURE, error } }
}

function getBusinessInfo(businessId, callback = null) {
    const params = {
        request: 'business_query',
        business_id: businessId,
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    if (callback) { callback(data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getBusinessInfo: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.GET_BUSINESS_INFO_REQUEST} }
    function success(data)  { return { type: memberConstants.GET_BUSINESS_INFO_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.GET_BUSINESS_INFO_FAILURE, error } }
}

function deactivateAccount(callback = null) {
    const params = {
        request: 'deactivate_account',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    // dispatch(userActions.logout());
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, data.message) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("deactivateAccount: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.DEACTIVATE_ACCOUNT_REQUEST} }
    function success(data)  { return { type: memberConstants.DEACTIVATE_ACCOUNT_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.DEACTIVATE_ACCOUNT_FAILURE, error } }
}

function deleteAccount(callback = null) {
    const params = {
        request: 'delete_account',
      }
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log(data)
                    dispatch(success(data));
                    // dispatch(userActions.logout());
                    if (callback) { callback(true, data) }
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                    if (callback) { callback(false, data.message) }
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("delete_account: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }

    function request()      { return { type: memberConstants.DELETE_ACCOUNT_REQUEST} }
    function success(data)  { return { type: memberConstants.DELETE_ACCOUNT_SUCCESS, data } }
    function failure(error) { return { type: memberConstants.DELETE_ACCOUNT_FAILURE, error } }
}




