import { feeConstants } from '_constants';
import { userService } from '_services';
import { alertActions, userActions } from '_actions';
//import { history } from '_helpers';

export const feeActions = {
    getFees,
};

function getFees() {
    const params = {
        request: 'get_fees',
      };
    return dispatch => {
        dispatch(request());
        userService.userRequest(params)
        .then(
            data => {
                if (data.success) {
                    console.log("Got Fees");
                    console.log(data)
                    let fees = {
                        liveNyteBookingPercentage: data.liveNyteBookingPercentage,
                        liveNyteBookingHandlingFee: data.liveNyteBookingHandlingFee,
                        liveNyteTicketPercentage: data.liveNyteTicketPercentage, 
                        liveNyteTicketFlatFee: data.liveNyteTicketFlatFee,
                        stripeFlatFee: data.stripeFlatFee,
                        stripePercentage: data.stripePercentage,
                        stripeMonthlyPercentage: data.stripeMonthlyPercentage,
                        liveNyteSubscriptionPercentage: data.liveNyteSubscriptionPercentage,
                    }
                    dispatch(success(fees));
                } else {
                    const msg = data.message ? data.message : "Unknown Server Error. Please try again"
                    dispatch(failure(msg.toString()));
                    dispatch(alertActions.error(msg.toString()));
                }
            },
            error => {
                if (error.status === 401) {
                    console.log("getFees: fatal error")
                    dispatch(userActions.endSession(error))    
                }
            }
        )
    }
    
    function request()       { return { type: feeConstants.GET_FEES_REQUEST} }
    function success(fees)   { return { type: feeConstants.GET_FEES_SUCCESS, fees } }
    function failure(error)  { return { type: feeConstants.GET_FEES_FAILURE, error } }
}
