import React, { useEffect, createContext, useContext,} from 'react';

const AppleSignIn = (props) => {
    const { config, render, onSuccess, onFailure  } = props;

    const APPLEID_AUTH_SDK_URL = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    const APPLEID_AUTH_SDK_ELEMENT_ID = "__appleid_auth_sdk";
    const AppleIDConfigContext = createContext({
        loaded: false,
    });

    const loadSDK = () => new Promise((resolve, reject) => {
        const existingScript = document.getElementById(APPLEID_AUTH_SDK_ELEMENT_ID);
        if (existingScript) {
            resolve();
            return;
        }
        const script = document.createElement('script');
        script.src = APPLEID_AUTH_SDK_URL;
        script.id = APPLEID_AUTH_SDK_ELEMENT_ID;
        document.body.appendChild(script);
        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            reject();
        };
        return;
    });

    const initSDK = async (config) => {
        window.AppleID.auth.init(config);
    };

    const ctx = useContext(AppleIDConfigContext);
    useEffect(() => {
        loadSDK().then(() => initSDK(config)).then(() => {
            ctx.loaded = true;
        });
    }, [ctx, config]);
    
    const onClick = async (e) => {
        if (e) {
            e.preventDefault();
        }

        try {
            const data = await window.AppleID.auth.signIn()
            // console.log("initalize AppleSignIn request", data)
            onSuccess(data)
        } catch ( error ) {
            // console.log("Data from AppleSignIn", error)
            onFailure(error)
        }
        
        // console.log("Data from AppleSignIn", data)
    };
    
    if (typeof render === 'function') {
        return render({ onClick });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "appleid-signin", onClick: onClick },
            React.createElement("img", { src: `https://developer.apple.com/design/human-interface-guidelines/sign-in-with-apple/images/apple-id-continue-with_2x.png` }))));
};

export default AppleSignIn;
 